import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { analyticsLogger } from 'commons/src/analytics';
import { BUILDING_CONFIGURED_BATTERY_LIFE } from 'commons/src/analytics/AnalyticsEvents';
import { mapDeviceType } from 'commons/src/commonFunctions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import ComponentTabNavigation from 'commons/src/components/headers/ComponentTabNavigation';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { BleMode, DeviceTypeNames, SensorTypes, ViewDisplayUnits } from 'commons/src/models/commonEnums';
import {
    AnyDeviceType,
    BuildingConfiguration,
    DeviceTypeBatteryConfig,
    DeviceWithKeyInfo,
    ErrorType,
} from 'commons/src/models/commonTypeScript';
import { ComponentTab } from 'commons/src/models/menuModels';
import {
    UpdateLocationConfigWithDeviceType,
    updateLocationConfigWithDeviceType,
} from '../../../actions/locationActions';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import ConfigureBatteryLife from './ConfigureBatteryLife';

export type ParentProps = {
    locationId: string;
    buildingDevices: string[];
};

type StateProps = {
    configurations: { [locationId: string]: BuildingConfiguration };
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    loading: boolean;
    error?: ErrorType;
};

type ActionProps = {
    onUpdateBuildingConfig: (
        config: {
            selectedSensorIntervals?: { [deviceType: string]: { [sensorType: string]: number } };
            screens?: {
                [deviceType: string]: {
                    selectedScreens: { screenIndex: number; insights: string[] }[];
                    measurementUnits: ViewDisplayUnits;
                };
            };
            bleMode?: BleMode;
        },
        locationId: string
    ) => void;
};

type BatterySettings = {
    [deviceType: string]: DeviceTypeBatteryConfig[];
};

export type Props = StateProps & ParentProps & ActionProps;

export const BatterySettingsComponent = ({
    locationId,
    buildingDevices,
    configurations,
    onUpdateBuildingConfig,
    devicesWithKeyInfo,
    loading,
    error,
}: Props): React.ReactElement | null => {
    const { t: txt } = useTranslation();
    const buildingConfig = configurations[locationId];

    const initialBatteryConfig = buildingConfig && buildingConfig.configuration.batteryConfigurations;
    const [batteryConfig, setBatteryConfig] = useState<BatterySettings>(initialBatteryConfig);

    const tabOptions = initialBatteryConfig
        ? Object.keys(initialBatteryConfig).map(deviceType => ({
              deviceType,
              id: `displaySettings${deviceType}`,
          }))
        : [];

    const tabOptionsForDevicesInBuilding = tabOptions.filter(option =>
        buildingDevices.find(
            serialNumber =>
                devicesWithKeyInfo[serialNumber] && devicesWithKeyInfo[serialNumber].type === option.deviceType
        )
    );

    const [activeTab, setActiveTab] = useState(0);
    const [activeDeviceType, setActiveDeviceType] = useState<DeviceTypeNames | undefined>(
        tabOptionsForDevicesInBuilding[activeTab].deviceType as DeviceTypeNames
    );

    const updateSensorIntervals = (
        deviceType: string,
        updatedIntervals: { sensorType: SensorTypes; options: number[]; current: number }[]
    ): void => {
        const updatedSensorInterval: BatterySettings = {
            ...batteryConfig,
            [deviceType]: updatedIntervals,
        };
        setBatteryConfig(updatedSensorInterval);
    };

    const onSubmit = (): void => {
        analyticsLogger(BUILDING_CONFIGURED_BATTERY_LIFE, {
            selectedSensorIntervals: batteryConfig,
        });
        const updateConfigPayload = Object.keys(batteryConfig).reduce((payload, deviceType) => {
            const deviceConfig = batteryConfig[deviceType];
            const sensorConfigs = deviceConfig.reduce((sensorConfig, config) => {
                return {
                    ...sensorConfig,
                    [config.sensorType]: config.current,
                };
            }, {});
            return { ...payload, [deviceType]: sensorConfigs };
        }, {});
        onUpdateBuildingConfig({ selectedSensorIntervals: updateConfigPayload }, locationId);
    };

    const selectTab = (index: number): void => {
        const deviceTypeTab = tabOptionsForDevicesInBuilding[index].deviceType;
        setActiveTab(index);
        setActiveDeviceType(deviceTypeTab as DeviceTypeNames);
    };

    const tabs: ComponentTab[] = tabOptionsForDevicesInBuilding.map(tab => ({
        title: txt(`${mapDeviceType(tab.deviceType as AnyDeviceType)}FullName`),
        id: tab.id,
        testAttr: `battery-settings-${tab.deviceType.toLowerCase()}`,
    }));

    return (
        <div className="page-wrapper__inner">
            <ComponentTabNavigation tabs={tabs} activeTab={activeTab} setActiveTab={selectTab} />
            {activeDeviceType && (
                <ConfigureBatteryLife
                    configurations={batteryConfig[activeDeviceType]}
                    updateConfig={updateSensorIntervals}
                    deviceType={activeDeviceType}
                    locationId={locationId}
                />
            )}
            {error && <ResponseBox text="SomethingWentWrong" subtext={txt(`ErrorCodes.${error.error}`)} />}
            <div className="change-location__form__buttons">
                <PrimaryButton
                    id="submit"
                    type="submit"
                    title="Save"
                    loading={loading}
                    color="primary"
                    onClick={onSubmit}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        requests: {
            [RequestType.UpdateLocationConfigurationWithDeviceType]: { loading, error: configError },
        },
        buildings: { configurations },
        devices: { devicesWithKeyInfo },
    } = state;

    return {
        configurations,
        loading,
        error: configError,
        devicesWithKeyInfo,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onUpdateBuildingConfig: (
        config: {
            selectedSensorIntervals?: { [deviceType: string]: { [sensorType: string]: number } };
            screens?: {
                [deviceType: string]: {
                    selectedScreens: { screenIndex: number; insights: string[] }[];
                    measurementUnits: ViewDisplayUnits;
                };
            };
            bleMode?: BleMode;
        },
        locationId: string
    ): UpdateLocationConfigWithDeviceType => dispatch(updateLocationConfigWithDeviceType(config, locationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BatterySettingsComponent);
