import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Dispatch } from 'redux';
import {
    addPublicDashboard,
    AddPublicDashboard,
    deletePublicDashboard,
    DeletePublicDashboard,
    EditPublicDashboardSettings,
    editPublicDashboardSettings,
} from '../../../actions/DashboardActions';
import { analyticsLogger, PageType } from '../../../analytics';
import { PUBLIC_DASHBOARD_CREATE, PUBLIC_DASHBOARD_EDIT } from '../../../analytics/AnalyticsEvents';
import { getDashboardPath } from '../../../commonFunctions';
import FeatureToggle from '../../../components/buttons/FeatureToggle';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import PageHeader from '../../../components/headers/PageHeader';
import SubHeader from '../../../components/headers/SubHeader';
import Input from '../../../components/input/Input';
import DeleteConfirmModal from '../../../components/modals/DeleteConfirmModal';
import { mediumFormLoader } from '../../../components/placeholder';
import ResponseBox from '../../../components/responseMessages/ResponseBox';
import { businessPaths } from '../../../constants';
import { DashboardVisibility, RequiredRoleLevel } from '../../../models/commonEnums';
import { DashboardProps, ErrorType } from '../../../models/commonTypeScript';
import { ActionButton } from '../../../models/menuModels';
import { Store } from '../../../reducers';
import { CommonRequestType } from '../../../reducers/requestReducer';

type StateProps = {
    addLoading: boolean;
    error?: ErrorType;
    editError?: ErrorType;
    dashboardProps?: DashboardProps;
    loadingDeleteDashboard: boolean;
    loading: boolean;
    errorDeleteDashboard?: ErrorType;
};

interface ActionProps {
    deleteDashboard: (id: string) => void;
    createDashboard: (payload: { name: string; visibility: DashboardVisibility }) => void;
    editDashboard: (payload: DashboardProps) => void;
}

export type Props = StateProps & ActionProps;

export const AddPublicDashboardComponent = ({
    addLoading,
    createDashboard,
    error,
    dashboardProps,
    loading,
    editDashboard,
    editError,
    errorDeleteDashboard,
    loadingDeleteDashboard,
    deleteDashboard,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const userIsEditing = pathname.includes(businessPaths.dashboardSettings);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [name, setName] = useState(userIsEditing && dashboardProps ? dashboardProps.name : '');
    const [isPublic, setIsPublic] = useState(
        (userIsEditing && dashboardProps && dashboardProps.visibility === DashboardVisibility.userGroupPublic) || false
    );
    const [validate, setValidate] = useState(false);

    useEffect((): (() => void) => {
        (document.body as HTMLElement).className = 'blue-body';
        return (): void => {
            (document.body as HTMLElement).className = '';
        };
    }, []);

    useEffect(() => {
        if (deleteLoading && !loadingDeleteDashboard) {
            setDeleteLoading(false);
            if (!errorDeleteDashboard) setDeleteModalOpen(false);
        }
    }, [errorDeleteDashboard, loadingDeleteDashboard]);

    useEffect((): void => {
        if (dashboardProps && userIsEditing) {
            if (dashboardProps.visibility === DashboardVisibility.user) {
                navigate(getDashboardPath());
            }
            setIsPublic(dashboardProps.visibility === DashboardVisibility.userGroupPublic);
            if (name.length === 0) setName(dashboardProps.name);
        }
    }, [dashboardProps]);

    const goBack = (): void => {
        navigate(getDashboardPath());
    };

    const submit = (e: SyntheticEvent<HTMLElement>): void => {
        e.preventDefault();
        const isValid = name.trim().length > 1;
        setValidate(!isValid);
        if (isValid) {
            const visibility = isPublic ? DashboardVisibility.userGroupPublic : DashboardVisibility.userGroup;
            if (!userIsEditing) {
                analyticsLogger(PUBLIC_DASHBOARD_CREATE, { pageType: PageType.PublicDashboard, isPublic });
                createDashboard({
                    name,
                    visibility,
                });
            } else if (dashboardProps) {
                analyticsLogger(PUBLIC_DASHBOARD_EDIT, { pageType: PageType.PublicDashboard, isPublic });
                editDashboard({ ...dashboardProps, visibility, name });
            }
        }
    };

    const onDeleteDashboard = (): void => {
        const id = dashboardProps && dashboardProps.id;
        if (id) {
            setDeleteLoading(true);
            deleteDashboard(id);
        }
    };

    const onDelete = (): void => {
        setDeleteModalOpen(!deleteModalOpen);
    };

    const togglePublicSharing = (): void => {
        setIsPublic(!isPublic);
    };

    const actionButtons: ActionButton[] = [
        {
            onClick: goBack,
            testAttr: 'close-add-dashboard',
            id: 'closeAddDashboard',
            color: 'secondary',
            title: txt('Close'),
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            requiredGroupTypes: [],
        },
    ];

    const errorInState = userIsEditing ? editError : error;

    return (
        <div>
            <PageHeader
                title={
                    userIsEditing
                        ? txt('DashboardSettings.EditDashboardName', {
                              dashboardName: (dashboardProps && dashboardProps.name) || '',
                          })
                        : txt('DashboardSettings.AddPublicDashboard')
                }
            />
            <ReactPlaceholder ready={!userIsEditing || !loading} customPlaceholder={mediumFormLoader}>
                <div>
                    <div className="page-wrapper__medium form">
                        <SubHeader actionButtons={actionButtons} />
                        <h2 className="settings__header settings__header--no-margin-top">
                            {txt('DashboardSettings.PublicDashboardSettings')}
                        </h2>
                        <div className="settings-details-container">
                            <div className="page-wrapper__inner page-wrapper__inner--slim change-location">
                                <form className="settings-view__form" onSubmit={submit}>
                                    <p className="segment-properties-form__text">
                                        {txt('DashboardSettings.PublicDashboardDescription')}
                                    </p>
                                    <Input
                                        label="DashboardSettings.DashboardName"
                                        type="text"
                                        id="name"
                                        maxLength={25}
                                        required
                                        hint="DashboardSettings.DashboardNameHint"
                                        currentValue={name}
                                        validate={validate && name.trim().length <= 1}
                                        isValid={name.trim().length > 1}
                                        onChange={(e): void => setName(e.currentTarget.value)}
                                    />
                                    <FeatureToggle
                                        title="DashboardSettings.Public"
                                        description="DashboardSettings.EnablePublicDashboard"
                                        enabled={isPublic}
                                        onUpdate={togglePublicSharing}
                                    />
                                    {errorInState && <ResponseBox text={`ErrorCodes.${errorInState.error}`} />}
                                    <div className="change-location__form__buttons">
                                        <PrimaryButton
                                            id="submit"
                                            type="submit"
                                            title={userIsEditing ? 'Save' : 'DashboardSettings.AddDashboard'}
                                            color="primary"
                                            loading={addLoading}
                                            onClick={submit}
                                            disabled={false}
                                            testId="submit"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="page-wrapper__medium">
                            {deleteModalOpen && (
                                <DeleteConfirmModal
                                    loading={loadingDeleteDashboard}
                                    onCancel={(): void => setDeleteModalOpen(false)}
                                    onSubmit={onDeleteDashboard}
                                    title="PublicDashboard.DeletePublicDashboard"
                                    description={txt('PublicDashboard.DeletePublicDashboardDescription', {
                                        dashboardName: (dashboardProps && dashboardProps.name) || '',
                                    })}
                                    error={!!errorDeleteDashboard}
                                    errorText={errorDeleteDashboard && txt(`ErrorCodes.${errorDeleteDashboard.error}`)}
                                    onSubmitText="Delete"
                                    onCancelText="Cancel"
                                />
                            )}
                            {userIsEditing && (
                                <div className="change-location__form__buttons">
                                    <PrimaryButton
                                        onClick={onDelete}
                                        color="alert"
                                        title="PublicDashboard.Delete"
                                        testId="delete-dashboard"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </ReactPlaceholder>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        commonRequests: {
            [CommonRequestType.AddPublicDashboard]: { loading: addLoading, error },
            [CommonRequestType.EditPublicCDashboardSettings]: { loading: editLoading, error: editError },
            [CommonRequestType.DeletePublicDashboard]: { loading: loadingDeleteDashboard, error: errorDeleteDashboard },
        },
        dashboardData: { dashboardProps, loading },
    } = store;

    return {
        addLoading: addLoading || editLoading,
        error,
        editError,
        dashboardProps,
        loading,
        loadingDeleteDashboard,
        errorDeleteDashboard,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    createDashboard: (payload): AddPublicDashboard => dispatch(addPublicDashboard(payload)),
    deleteDashboard: (id: string): DeletePublicDashboard => dispatch(deletePublicDashboard(id)),
    editDashboard: (payload): EditPublicDashboardSettings => dispatch(editPublicDashboardSettings(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPublicDashboardComponent);
