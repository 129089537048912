import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import ModalWrapper from 'commons/src/components/modals/ModalWrapper';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { SpaceProperty } from 'commons/src/models/commonTypeScript';
import { editSpace } from '../../../actions/spaceActions';
import { IndoorSpace } from '../../../models/spaceModels';
import { Store } from '../../../reducers';
import RequestType from '../../../reducers/BusinessRequestType';
import { RootState } from '../../../store';
import SpacePropertyEditMain from '../properties/SpacePropertyEditMain';
import styles from './SpaceEdit.module.scss';
import SpaceSettingsForm, { FormActions } from './SpaceSettingsForm';

export type Props = {
    isOpen: boolean;
    onClose: () => void;
    space: IndoorSpace;
};
const SpaceEdit = ({ isOpen, onClose, space }: Props): ReactElement => {
    const dispatch = useDispatch();
    const formRef = useRef<FormActions>(null);

    const [isLoading, setIsLoading] = useState(false);
    const [selectedProperty, setSelectedProperty] = useState<{ id: string; data: SpaceProperty } | undefined>(
        undefined
    );

    const { loading, error } = useSelector((state: RootState) => state.requests[RequestType.EditSpace]);

    const spacePropDefs: { [p: string]: SpaceProperty } = useSelector((state: Store) => state.config.spacePropDefs);

    useEffect(() => {
        const property: [string, SpaceProperty] | undefined = Object.entries(spacePropDefs).find(
            ([key]) => key === selectedProperty?.id
        );
        setSelectedProperty(property ? { id: property[0], data: property[1] } : undefined);
    }, [spacePropDefs]);

    const editProperty = (propertyId: string): void => {
        const property: [string, SpaceProperty] | undefined = Object.entries(spacePropDefs).find(
            ([key]) => key === propertyId
        );
        setSelectedProperty(property ? { id: property[0], data: property[1] } : undefined);
    };

    const onSave = (): void => {
        if (formRef.current && formRef.current.IsValid()) {
            const { name, properties, updateDeviceNames } = formRef.current.Values();
            setIsLoading(true);
            dispatch(
                editSpace({
                    name,
                    updateDeviceNames,
                    devices: space.devices,
                    hubs: space.hubs,
                    properties,
                    placement: space.placement,
                    locationId: space.locationId,
                    spaceId: space.id,
                    endedSegments: space.endedSegments,
                })
            );
        }
    };

    useEffect(() => {
        if (isLoading && !loading) {
            setIsLoading(false);
            if (!error) {
                onClose();
            }
        }
    }, [loading, error]);

    return (
        <ModalWrapper
            isOpen={isOpen}
            onClose={onClose}
            header={selectedProperty ? undefined : 'Space.SpaceSettings'}
            size="medium"
        >
            {selectedProperty ? (
                <SpacePropertyEditMain
                    backToSpace={(): void => setSelectedProperty(undefined)}
                    property={selectedProperty}
                    allPropertyNames={Object.values(spacePropDefs).map(it => it.propertyName)}
                />
            ) : (
                <div className={styles.body}>
                    <SpaceSettingsForm
                        ref={formRef}
                        spaceName={space.name}
                        locationId={space.locationId}
                        spaceId={space.id}
                        editProperty={editProperty}
                    />
                    <div className={styles.buttonWrapper}>
                        {error && (
                            <div className={styles.errorWrapper}>
                                <ResponseBox text={`ErrorCodes.${error.error}`} />
                            </div>
                        )}
                        <PrimaryButton onClick={onSave} title="Save" loading={isLoading} color="primary" />
                    </div>
                </div>
            )}
        </ModalWrapper>
    );
};
export default SpaceEdit;
