import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { UpdateUserProfile, updateUserProfile } from '../../../actions/SettingsActions';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import Input from '../../../components/input/Input';
import InputLabel from '../../../components/input/InputLabel';
import ResponseBox from '../../../components/responseMessages/ResponseBox';
import { ErrorType } from '../../../models/commonTypeScript';
import { Store } from '../../../reducers';
import { CommonRequestType } from '../../../reducers/requestReducer';

type StateProps = {
    email: string;
    userName: string;
    loading: boolean;
    error?: ErrorType;
};

type ParentProps = {
    onClose: () => void;
};

type ActionProps = {
    onSubmit: (name: string) => void;
};

type Props = StateProps & ActionProps & ParentProps;

const EditUserInfoComponent = ({ userName, email, loading, onClose, onSubmit, error }: Props): React.ReactElement => {
    const [updatedName, setUpdatedName] = useState<string>(userName);
    const [nameIsValid, setNameIsValid] = useState<boolean>(true);
    const [validateName, setValidateName] = useState<boolean>(false);

    const prevLoadingRef = useRef(loading);
    useEffect(() => {
        if (prevLoadingRef.current && !loading && !error) {
            onClose();
        }
        prevLoadingRef.current = loading;
    }, [error, loading]);

    const onChangeName = (e: SyntheticEvent<HTMLInputElement>): void => {
        e.preventDefault();
        const name = e.currentTarget.value.trim();
        setUpdatedName(name);
        setValidateName(false);
    };

    const onBlurName = (e: SyntheticEvent<HTMLInputElement>): void => {
        e.preventDefault();
        const name = e.currentTarget.value.trim();
        if (name.length > 0) {
            setUpdatedName(name);
            setNameIsValid(true);
        } else {
            setValidateName(true);
            setNameIsValid(false);
            setUpdatedName(name);
        }
    };

    const onSubmitUserInfo = (e: React.SyntheticEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        if (nameIsValid) {
            onSubmit(updatedName.length ? updatedName : userName);
        }
    };

    return (
        <form>
            {error && <ResponseBox text={`ErrorCodes.${error.error}`} />}
            <div className="settings__row__profile-container">
                <div className="security-settings">
                    <div className="form__field form__field--single-width">
                        <Input
                            type="text"
                            id="name"
                            label="Name"
                            defaultValue={userName}
                            maxLength={199}
                            hint="EnterName"
                            onChange={onChangeName}
                            onBlur={onBlurName}
                            validate={validateName}
                            isValid={nameIsValid}
                        />
                    </div>
                    <div className="settings__row">
                        <InputLabel htmlFor="email" label="Email" />
                        <div id="email" className="settings__row__content">
                            {email}
                        </div>
                    </div>
                </div>
            </div>
            <div className="form__row">
                <div className="form__attr--element small-padding-right">
                    <PrimaryButton color="secondary" title="Cancel" onClick={onClose} disabled={loading} />
                </div>
                <div className="form__attr--element">
                    <PrimaryButton
                        id="submitUserInfoButton"
                        title="SaveChanges"
                        type="submit"
                        loading={loading}
                        onClick={onSubmitUserInfo}
                        color="primary"
                        testId="save-changes"
                    />
                </div>
            </div>
        </form>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { email, userName, error },
        commonRequests: {
            [CommonRequestType.UpdateUserProfile]: { loading },
        },
    } = state;

    return {
        email,
        userName,
        loading,
        error,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onSubmit: (name: string): UpdateUserProfile => dispatch(updateUserProfile(name)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditUserInfoComponent);
