import React from 'react';
import { ConfigProvider } from 'antd';
import { Locale } from 'antd/lib/locale-provider';

import da from 'antd/lib/locale/da_DK';
import de from 'antd/lib/locale/de_DE';
import en from 'antd/lib/locale/en_US';
import fr from 'antd/lib/locale/fr_FR';
import no from 'antd/lib/locale/nb_NO';

const toLocale = (language: string): Locale => {
    switch (language) {
        case 'no':
        case 'nn':
        case 'nb':
            return no;
        case 'de':
            return de;
        case 'da':
            return da;
        case 'fr':
            return fr;
        default:
            return en;
    }
};

type Props = {
    children?: React.ReactElement;
};

/**
 * Config provider for Ant Design components
 */
const AntdConfigProvider = ({ children }: Props): React.ReactElement => {
    const language = localStorage.getItem('i18nextLng') || 'en';
    return (
        <ConfigProvider
            getPopupContainer={(trigger?: HTMLElement): HTMLElement =>
                (trigger && trigger.parentElement) || document.body
            }
            locale={toLocale(language)}
        >
            {children}
        </ConfigProvider>
    );
};

export default AntdConfigProvider;
