import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import {
    AddApiClient,
    addApiClient,
    DeleteApiClient,
    deleteApiClient,
    UpdateApiClient,
    updateApiClient,
} from '../../../actions/apiClientActions';
import FlipButton from '../../../components/buttons/FlipButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import Dropdown from '../../../components/dropdown/MultipleAttrDropdown';
import CheckBox from '../../../components/input/Checkbox';
import Input from '../../../components/input/Input';
import InputLabel from '../../../components/input/InputLabel';
import RadioButtons from '../../../components/input/Radio';
import Textarea from '../../../components/input/Textarea';
import { IntercomAPI } from '../../../components/Intercom';
import DeleteConfirmModal from '../../../components/modals/DeleteConfirmModal';
import { ApiClient, NewApiClient } from '../../../models/apiClient';
import { FeatureToggleEnum, GroupType } from '../../../models/commonEnums';
import { ErrorType } from '../../../models/commonTypeScript';
import { Store } from '../../../reducers';
import { hasFeatureToggle } from '../../featureToggle/filterOnFeatureToggles';
import ApiClientSecret from './ClientSecret';
import MapRedirectUris from './MapRedirectUris';

const OAUTH_ACCESS_TYPE_CONFIDENTIAL = 'CONFIDENTIAL';
const OAUTH_ACCESS_TYPE_PUBLIC = 'PUBLIC';
const oauthAccessTypeOptions = [OAUTH_ACCESS_TYPE_PUBLIC, OAUTH_ACCESS_TYPE_CONFIDENTIAL];
const OAUTH_CLIENT_FLOW_CLIENTCREDENTIALS = 'CLIENT_CREDENTIALS';
const OAUTH_CLIENT_FLOW_CODEFLOW = 'CODE_FLOW';
const oauthClientFlowOptions = [OAUTH_CLIENT_FLOW_CODEFLOW, OAUTH_CLIENT_FLOW_CLIENTCREDENTIALS];
const availableResourceScopesPartnerConsumer = ['read:device'];
const availableResourceScopesBusiness = ['read:device', 'write:device', 'profile'];
const availableResourceScopesConsumer = ['read:device:current_values'];
const consumerDefaults = { scope: 'read:device:current_values', flow: OAUTH_CLIENT_FLOW_CLIENTCREDENTIALS };
const partnerConsumerDefaults = { scope: 'read:device', flow: OAUTH_CLIENT_FLOW_CLIENTCREDENTIALS };

type StateProps = {
    apiClients: ApiClient[];
    deleteLoading: boolean;
    saveLoading: boolean;
    deleteClientError?: ErrorType;
    groupType?: GroupType;
    isPartnerConsumer?: boolean;
};

type ActionProp = {
    updateClientDetails: (apiClient: ApiClient) => void;
    createNewClient: (apiClient: NewApiClient) => void;
    deleteClient: (clientId: string) => void;
};

export type Props = ActionProp & StateProps;

export const ApiClientFormComponent = ({
    apiClients,
    createNewClient,
    updateClientDetails,
    deleteClient,
    deleteLoading,
    saveLoading,
    deleteClientError,
    groupType,
    isPartnerConsumer,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { clientId } = useParams<'clientId'>();

    const isConsumer = groupType === GroupType.consumer;

    const getDefaultFlowType = (): string => {
        if (isConsumer) {
            return consumerDefaults.flow;
        }
        if (isPartnerConsumer) {
            return partnerConsumerDefaults.flow;
        }
        return OAUTH_CLIENT_FLOW_CODEFLOW;
    };

    const selectedClient =
        clientId && apiClients.length > 0 ? apiClients.find(client => client.id === clientId) : undefined;
    const [name, setName] = useState(selectedClient ? selectedClient.name : '');
    const [redirectUris, setRedirectUris] = useState(
        selectedClient && selectedClient.redirectUris ? selectedClient.redirectUris : []
    );
    const [active, setActive] = useState(selectedClient ? selectedClient.active : false);
    const [accessType, setAccessType] = useState(
        selectedClient ? selectedClient.accessType : OAUTH_ACCESS_TYPE_CONFIDENTIAL
    );
    const [description, setDescription] = useState(selectedClient ? selectedClient.description : '');
    const [flowType, setFlowType] = useState(selectedClient ? selectedClient.flowType : getDefaultFlowType());
    const [selectedResourceScope, setSelectedResourceScope] = useState<string[]>(
        selectedClient ? selectedClient.authScope : []
    );
    const [displayValidation, setDisplayValidation] = useState(false);
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);

    const updateInput = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { value } = e.currentTarget;
        setName(value);
    };

    const validUris = OAUTH_CLIENT_FLOW_CODEFLOW ? redirectUris.filter(uri => uri.includes('*')).length === 0 : true;

    const validateClient = (): boolean => {
        const validName = name.length > 0;
        const validScope = selectedResourceScope.length > 0;
        const hasRequiredUris =
            flowType === OAUTH_CLIENT_FLOW_CODEFLOW
                ? redirectUris.filter(uri => uri.length > 0).length > 0 && validUris
                : true;
        if (validScope && hasRequiredUris && validName) {
            return true;
        }
        setDisplayValidation(true);
        return false;
    };

    const toggleResourceScope = (e: SyntheticEvent<HTMLElement>): void => {
        const scope = e.currentTarget.id;
        const alreadyInScope = selectedResourceScope.includes(scope);
        const updatedScope = alreadyInScope
            ? [...selectedResourceScope].filter(resource => resource !== scope)
            : [...selectedResourceScope, scope];
        setSelectedResourceScope(updatedScope);
    };

    const availableResourceScopes = (): string[] => {
        if (isConsumer) {
            return availableResourceScopesConsumer;
        }
        if (isPartnerConsumer) {
            return availableResourceScopesPartnerConsumer;
        }
        return availableResourceScopesBusiness;
    };

    const resourceScopeOpts = availableResourceScopes().map((scope: string, i: number): React.ReactElement => {
        const elementKey = `resource-scope-${scope}-${i}`;
        return (
            <CheckBox
                key={elementKey}
                label={scope}
                skipTranslate
                id={scope}
                checked={selectedResourceScope.includes(scope)}
                onChange={toggleResourceScope}
                testId={`${elementKey}`}
            />
        );
    });

    const saveClient = (): void => {
        const clientIsValid = validateClient();
        if (clientIsValid) {
            const validRedirectUris =
                flowType === OAUTH_CLIENT_FLOW_CLIENTCREDENTIALS
                    ? undefined
                    : redirectUris.filter(uri => uri.length > 0);
            const updatedClientDetails = {
                name,
                active,
                description,
                accessType,
                flowType,
                redirectUris: validRedirectUris,
                authScope: selectedResourceScope,
            };
            if (selectedClient) {
                updateClientDetails({ ...selectedClient, ...updatedClientDetails });
            } else {
                IntercomAPI('trackEvent', 'add-api-client', {
                    clientName: name,
                });
                createNewClient(updatedClientDetails);
            }
        }
    };

    const onDeleteClick = (e: SyntheticEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        if (selectedClient && selectedClient.id) {
            setDisplayDeleteModal(true);
        }
    };
    const updateActive = (): void => {
        setActive(!active);
    };

    const closeDeleteModal = (): void => {
        setDisplayDeleteModal(false);
    };

    const onDeleteHook = (): void => {
        if (selectedClient && selectedClient.id) {
            deleteClient(selectedClient.id);
        }
    };

    const selectAccessType = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { value } = e.currentTarget;
        setAccessType(value);
        setFlowType(getDefaultFlowType());
    };

    const dropdownSelectFlowType = ({ id }: { id: string; inputValue: string }): void => {
        setFlowType(id);
    };

    let flowTypeOptions = oauthClientFlowOptions.map(option => ({
        id: option,
        inputValue: txt(`ApiIntegration.${option}`),
    }));
    if (accessType === OAUTH_ACCESS_TYPE_PUBLIC) {
        flowTypeOptions = flowTypeOptions.filter(option => option.id === OAUTH_CLIENT_FLOW_CODEFLOW);
    }
    if (isConsumer || isPartnerConsumer) {
        flowTypeOptions = flowTypeOptions.filter(option => option.id === OAUTH_CLIENT_FLOW_CLIENTCREDENTIALS);
    }

    const accessTypeOptions = [
        {
            value: oauthAccessTypeOptions[1],
            testId: 'access-type-confidential',
            label: `ApiIntegration.${oauthAccessTypeOptions[1]}`,
        },
    ];
    const accessTypeConfidential = {
        value: oauthAccessTypeOptions[0],
        label: `ApiIntegration.${oauthAccessTypeOptions[0]}`,
        testId: 'access-type-public',
    };

    return (
        <div className="page-wrapper__medium page-wrapper__medium--white">
            {displayDeleteModal && (
                <DeleteConfirmModal
                    title="ApiIntegration.DeleteClient"
                    description={txt('Webhooks.DeleteDescription', { name })}
                    onSubmit={onDeleteHook}
                    onCancel={closeDeleteModal}
                    onSubmitText="Delete"
                    onCancelText="Cancel"
                    loading={deleteLoading}
                    error={!!(deleteClientError && deleteClientError.error)}
                    errorText="SomethingWentWrong"
                />
            )}
            <div className="settings-details-container">
                <form>
                    <div className="form__row">
                        <div className="form__field form__field--single-width">
                            <Input
                                type="text"
                                id="api-client-name"
                                validate={displayValidation && name.length === 0}
                                isValid={name.length > 0}
                                hint="ApiIntegration.NameHint"
                                label="Name"
                                maxLength={25}
                                currentValue={name}
                                onChange={updateInput}
                                testId="api-client-name"
                            />
                        </div>
                        <div className="form__field">
                            {selectedClient && (
                                <div className="input-container">
                                    <InputLabel htmlFor="client-id" label="Id" />
                                    <div className="form__attr input-container__string" id="client-name">
                                        {selectedClient.id}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__field">
                            <Textarea
                                autoComplete=""
                                id="api-client-description"
                                label="ApiIntegration.Description"
                                maxRows={4}
                                maxLength={255}
                                rows={4}
                                onChange={setDescription}
                                defaultValue={description}
                                displayValidationResult={false}
                                isValid
                                markedMandatory={false}
                                required={false}
                                inputInfo={isConsumer ? 'ApiIntegration.ConsumerDescriptionInfo' : undefined}
                                testId="api-client-description"
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__field form__field--single-width">
                            <div className="input-container form__attr--element">
                                <InputLabel htmlFor="resourceScope" label="ApiIntegration.ResourceScope" />
                                {resourceScopeOpts}
                                {displayValidation && selectedResourceScope.length === 0 && (
                                    <span className="input-container__error">
                                        {txt('ApiIntegration.ResourceScopeHint')}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__attr--element">
                            <InputLabel
                                htmlFor="accessType"
                                label="ApiIntegration.AccessType"
                                infoText="ApiIntegration.AccessTypeInfo"
                            />
                            <RadioButtons
                                buttons={
                                    !isConsumer && !isPartnerConsumer
                                        ? [...accessTypeOptions, accessTypeConfidential]
                                        : accessTypeOptions
                                }
                                selectorName="accessType"
                                row
                                onChange={selectAccessType}
                                value={accessType}
                                labelId="select-accessType"
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__field form__field--single-width">
                            <Dropdown
                                id="flowTypeSelector"
                                title="ApiIntegration.FlowType"
                                options={flowTypeOptions}
                                loading={false}
                                hint="test"
                                isValid
                                validate={false}
                                defaultOption={txt('ApiIntegration.Select')}
                                disabled={false}
                                value={flowType ? txt(`ApiIntegration.${flowType}`) : ''}
                                onSelect={dropdownSelectFlowType}
                                testAttr="access-type"
                                isRequired={false}
                                inputInfo={
                                    isConsumer ? 'ApiIntegration.ConsumerFlowTypeInfo' : 'ApiIntegration.FlowTypeInfo'
                                }
                                testId="flow-type-dropdown"
                            />
                        </div>
                    </div>
                    {flowType === OAUTH_CLIENT_FLOW_CODEFLOW && (
                        <MapRedirectUris
                            elementList={redirectUris}
                            updateList={setRedirectUris}
                            displayValidation={displayValidation}
                        />
                    )}
                    {selectedClient && accessType === OAUTH_ACCESS_TYPE_CONFIDENTIAL && <ApiClientSecret />}
                    <div className="inline-header-lined inline-header-lined--extra-margin" />
                    <div className="form__row form__row--centered-padded">
                        <div>
                            <InputLabel htmlFor="enable" label="Webhooks.Enable" />
                            <FlipButton
                                id="enable"
                                onClick={updateActive}
                                leftSelected={active}
                                leftText="On"
                                rightText="Off"
                                testIdOn="enable-on"
                                testIdOff="enable-off"
                            />
                        </div>
                    </div>
                    <div className="form__row form__button-container">
                        {selectedClient && (
                            <div className="form__attr--element centered">
                                <PrimaryButton
                                    onClick={onDeleteClick}
                                    type="button"
                                    title="Delete"
                                    color="alert"
                                    loading={deleteLoading}
                                    testAttr="delete-api-client"
                                    testId="delete-api-client"
                                />
                            </div>
                        )}
                        <div className="form__attr--element centered">
                            <PrimaryButton
                                onClick={saveClient}
                                type="button"
                                title="Save"
                                loading={saveLoading}
                                disabled={!validUris}
                                color="primary"
                                testAttr="add-api-client"
                                testId="add-api-client"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        apiClients: { apiClients },
        userSettings: { selectedGroup },
        commonRequests: {
            DELETE_API_CLIENT: { loading: deleteLoading, error: deleteClientError },
            UPDATE_API_CLIENT: { loading: updateLoading },
            ADD_API_CLIENT: { loading: addLoading },
        },
    } = state;
    return {
        apiClients,
        groupType: selectedGroup && selectedGroup.groupType,
        deleteLoading,
        deleteClientError,
        saveLoading: updateLoading || addLoading,
        isPartnerConsumer:
            selectedGroup &&
            selectedGroup.groupType === GroupType.partner &&
            hasFeatureToggle(FeatureToggleEnum.partnerWithConsumerCustomers, selectedGroup.features),
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProp => ({
    updateClientDetails: (apiClient: ApiClient): UpdateApiClient => dispatch(updateApiClient(apiClient)),
    createNewClient: (apiClient: NewApiClient): AddApiClient => dispatch(addApiClient(apiClient)),
    deleteClient: (clientId: string): DeleteApiClient => dispatch(deleteApiClient(clientId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiClientFormComponent);
