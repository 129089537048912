import fetch from './fetch';
import getFetchHeaders from './getFetchHeaders';

type RadonReportResponse = {
    radonReportId: string;
    radonUnit: string;
    thresholds: number[];
    report: {
        serialNumber: string;
        radonStep1: number;
        radonStep1StdDev: number;
        radonStep1Lld: number;
    };
};

export const generateRadonReport = async ({
    serialNumber,
    fromDate,
    toDate,
    segmentId,
}: {
    serialNumber: string;
    fromDate: string;
    toDate: string;
    segmentId: string;
}): Promise<RadonReportResponse> =>
    fetch<RadonReportResponse>(`/devices/${serialNumber}/segments/${segmentId}/radon-report`, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ from: fromDate, to: toDate }),
    });

const csvUrl = (serialNumber: string, segmentId: string, from: string, to: string): string =>
    `/devices/${serialNumber}/segments/${segmentId}/csv?from=${from}&to=${to}`;

export type CSVDownloadAction = { serialNumber: string; from: string; to: string; segmentId?: string };
export type CSVDownloadResponse = { filename: string; url: string };

type UrlResponse = { url: string };
type CSVUrlResponse = UrlResponse & { urlVirtual?: string; urlOccupancy?: string };

export const downloadCSV = async (params: CSVDownloadAction): Promise<CSVDownloadResponse[]> => {
    const { serialNumber, from, to, segmentId = 'latest' } = params;
    return fetch<CSVDownloadResponse>(csvUrl(serialNumber, segmentId, from, to), {
        headers: await getFetchHeaders(),
    }).then((response: CSVUrlResponse) => [
        {
            filename: `${serialNumber}.latest.csv`,
            url: response.url,
        },
        ...(response.urlVirtual !== undefined
            ? [
                  {
                      filename: `${serialNumber}.latest.virtual.csv`,
                      url: response.urlVirtual,
                  },
              ]
            : []),
        ...(response.urlOccupancy !== undefined
            ? [
                  {
                      filename: `${serialNumber}.latest.occupancy.csv`,
                      url: response.urlOccupancy,
                  },
              ]
            : []),
    ]);
};
