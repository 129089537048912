import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import PrimaryButton from '../buttons/PrimaryButton';
import ModalHeader from './ModalHeader';

type Props = {
    onClose: () => void;
};

const WelcomeModal = ({ onClose }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <Modal
            isOpen
            appElement={document.body}
            onRequestClose={onClose}
            className="modal__content modal__content--welcome"
            overlayClassName="modal modal__overlay"
        >
            <ModalHeader headerText="WelcomeToDashboard" />
            <div className="modal__content__body" data-welcome-modal>
                <p>{txt('WelcomeToDashboardMessage')}</p>
                <p>
                    {txt('WelcomeToDashboardContactMessage')}
                    <a target="_blank" rel="noopener noreferrer" href="https://airthings.com">
                        Airthings.com
                    </a>
                </p>
                <div className="modal__content__buttons">
                    <PrimaryButton
                        color="secondary"
                        title={txt('GotIt')}
                        loading={false}
                        onClick={onClose}
                        autoFocus
                        testAttr="welcome-modal-button"
                    />
                </div>
            </div>
        </Modal>
    );
};

export default WelcomeModal;
