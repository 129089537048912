import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import { dateFormats } from 'commons/src/constants';
import { GroupType } from 'commons/src/models/commonEnums';
import { paths } from '../../../../constants';
import subscriptionCheckMark from '../../../../img/subscription_check_mark.svg';
import { SubscriptionInfo } from '../../../../models/subscriptionModels';
import { Store } from '../../../../reducers';
import BillingInfoSection from './BillingInfoSection';
import styles from './SummarySubscription.module.scss';

type StateProps = {
    subscriptionNumber?: string;
};
type ParentProps = {
    previewCostTable: React.ReactElement;
    subscriptionInfo: SubscriptionInfo;
    dateFormat: keyof typeof dateFormats;
    groupType?: GroupType;
};

type Props = StateProps & ParentProps;

const SummarySubscription = ({
    previewCostTable,
    subscriptionInfo,
    dateFormat,
    subscriptionNumber,
    groupType,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();
    const { childUserGroupId } = useParams();

    const finishSubscription = (): void => {
        if (groupType === GroupType.partner && childUserGroupId) {
            navigate({
                pathname: `/${generatePath(paths.partnerCustomerPage, {
                    childUserGroupId,
                })}`,
            });
        } else {
            navigate({
                pathname: `/${generatePath(paths.subscription)}`,
            });
        }
    };

    return (
        <div className="page-wrapper__medium">
            <h2>{txt('Partner.Summary')}</h2>
            <div className={styles.wrapper}>
                <div className={styles.imageContainer}>
                    <img src={subscriptionCheckMark} alt={txt('Subscription.GreenCheckMarkImage')} />
                </div>
                <p className={styles.thanksInfo}>{txt('Subscription.ThankYouForSubscriptionStart')}</p>
                <p className={styles.confirmationInfo}>{txt('Subscription.SubscriptionConfirmationInfo')}</p>
                <BillingInfoSection
                    previewCostTable={previewCostTable}
                    subscriptionInfo={subscriptionInfo}
                    dateFormat={dateFormat}
                    subscriptionNumber={subscriptionNumber}
                />
                <div className={styles.buttonWrapper}>
                    <PrimaryButton onClick={finishSubscription} title="Close" color="primary" />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        partner: { newSubscriptionNumber: partnerNewSubscription },
        subscription: { newSubscriptionNumber: endCustomerNewSubscription },
    } = store;
    return {
        subscriptionNumber: partnerNewSubscription || endCustomerNewSubscription,
    };
};
export default connect(mapStateToProps)(SummarySubscription);
