import React, { SyntheticEvent, useEffect } from 'react';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import TertiaryButton from '../../../components/buttons/TertiaryButton';
import Input from '../../../components/input/Input';
import MaterialIcon from '../../../components/MaterialIcon';

export type Props = {
    elementList: string[];
    updateList: (list: string[]) => void;
    displayValidation: boolean;
};

const MapRedirectUris = ({ elementList, updateList, displayValidation }: Props): React.ReactElement => {
    const updateArrayValues = (e: SyntheticEvent<HTMLInputElement>): void => {
        e.preventDefault();
        const value = e.currentTarget.value.trim();
        const id = parseInt(e.currentTarget.id, 10);
        const updatedList = [...elementList];
        updatedList[id] = value;
        updateList(updatedList);
    };
    const addRow = (): void => {
        updateList([...elementList, '']);
    };

    useEffect(() => {
        if (elementList.length === 0) {
            addRow();
        }
    }, [elementList]);

    const removeRow = (e: SyntheticEvent<HTMLButtonElement>, id: number): void => {
        e.preventDefault();
        const updatedList = [...elementList];
        updatedList.splice(id, 1);
        updateList(updatedList);
    };
    const hasValidRedirectUris = elementList.filter((uri: string) => uri.length > 0).length > 0;

    return (
        <>
            {elementList.map((uri, i) => {
                const elementKey = `redirectUri-${i}`;
                return (
                    <div className="form__row" key={elementKey}>
                        <div className="form__field form__field--single-width">
                            <Input
                                label="ApiIntegration.RedirectUri"
                                type="text"
                                id={i.toString()}
                                currentValue={elementList[i]}
                                maxLength={256}
                                hint={
                                    uri.includes('*')
                                        ? 'ApiIntegration.RedirectUriInvalidHint'
                                        : 'ApiIntegration.RedirectUriHint'
                                }
                                autoComplete="redirectUri"
                                validate={uri.includes('*') || (displayValidation && !hasValidRedirectUris)}
                                infoText={i === 0 ? 'ApiIntegration.RedirectUriInfo' : undefined}
                                isValid={!uri.includes('*') && displayValidation && hasValidRedirectUris}
                                onChange={updateArrayValues}
                                required
                                testAttr="redirect_uri"
                                testId={`${i}-redirect-uri`}
                            />
                        </div>
                        {elementList.length > 1 && (
                            <div className="form__row form__row--undertext">
                                <TertiaryButton
                                    onClick={(e: SyntheticEvent<HTMLButtonElement>): void => removeRow(e, i)}
                                    title="Remove"
                                    testAttr="remove-redirect-uri"
                                    disabled={false}
                                />
                            </div>
                        )}
                    </div>
                );
            })}
            <div className="form__row form__attr--element">
                <PrimaryButton
                    color="secondary"
                    onClick={addRow}
                    type="button"
                    title="ApiIntegration.AddUri"
                    testAttr="add-redirect-uri-element"
                    disabled={false}
                    icon={<MaterialIcon name="add_circle_outline" />}
                    testId="add-redirect-uri-element"
                />
            </div>
        </>
    );
};

export default MapRedirectUris;
