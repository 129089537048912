import { EventAggregationAction, EventAggregationActions } from '../actions/eventAggregationActions';
import { AggregationDigest } from '../models/eventAggregationModels';

export interface EventAggregationStore {
    spaceId: string;
    aggregates: AggregationDigest;
}

const initialStore: EventAggregationStore = {
    spaceId: '',
    aggregates: { digests: [] },
};

const reducer = (
    state: EventAggregationStore = initialStore,
    action: EventAggregationAction
): EventAggregationStore => {
    switch (action.type) {
        case EventAggregationActions.FetchSpaceAggregationDigestSuccess:
            return {
                ...state,
                spaceId: action.spaceId,
                aggregates: action.aggregates,
            };

        default:
            return state;
    }
};

export default reducer;
