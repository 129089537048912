import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { lastSyncedTime } from 'commons/src/commonFunctions';
import LinkButton from 'commons/src/components/buttons/LinkButton';
import RssiSignalStrength from 'commons/src/components/sensors/RssiSignalStrength';
import { Store } from '../../../../reducers';
import styles from './HubDeviceElement.module.scss';

type Props = {
    serialNumber: string;
    currentSpaceId: string;
    device: { lastSynced: string; rssi: number; segmentName: string; spaceId: string };
    locationId: string;
};

const HubDeviceElement = ({ serialNumber, currentSpaceId, device, locationId }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const dateFormat = useSelector((state: Store) => state.userSettings.dateFormat);
    const lastSyncedString = lastSyncedTime(device.lastSynced, dateFormat);
    const deviceInCurrentSpace = device.spaceId === currentSpaceId;
    return (
        <div className={styles.element}>
            <div className={styles.linkButton}>
                {deviceInCurrentSpace ? (
                    device.segmentName
                ) : (
                    <LinkButton
                        link={`/buildings/${locationId}/spaces/${device.spaceId}`}
                        title={device.segmentName}
                        testId={`link-to-${serialNumber}`}
                        noLineBreak
                    />
                )}
            </div>
            <div className={styles.serialNumber}>{serialNumber}</div>
            <div className={styles.lastSynced}>
                {txt('LastSyncedNoSemicolon')} {lastSyncedString}
            </div>
            <div className={styles.rssi}>
                <RssiSignalStrength rssi={device.rssi} displayDescription />
            </div>
        </div>
    );
};

export default HubDeviceElement;
