import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { SelectGroup, selectGroup as selectGroupAction } from '../../actions/selectGroupActions';
import { analyticsLogger, mixpanelRegisterSuperProperties } from '../../analytics';
import { ACCOUNT_SWAPPED_ACCOUNT } from '../../analytics/AnalyticsEvents';
import { groupName } from '../../features/group';
import { GroupType } from '../../models/commonEnums';
import { Group, SelectedGroup } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import PrimaryButton from '../buttons/PrimaryButton';
import ChangeAccountGroupList from './ChangeAccountGroupList';
import ChangeSelectedSearchHeader from './ChangeSelectedSearchHeader';

type StateProps = {
    groups: Group[];
    selectedGroup?: Group;
    userName: string;
    loading: boolean;
};

type ActionProps = {
    selectGroup: (selectedGroup: SelectedGroup) => void;
};

type PassedProps = {
    onClose: () => void;
};

export type Props = PassedProps & StateProps & ActionProps;

export const ChangeOrganizationModalComponent = (props: Props): React.ReactElement => {
    const { selectedGroup, selectGroup, onClose, groups, userName, loading } = props;
    const { t: txt } = useTranslation();
    const [searchText, setSearchText] = useState('');
    const filteredGroups = groups.filter(group => {
        const inName = !!group.groupName && group.groupName.toLowerCase().includes(searchText.toLowerCase());
        const inOrgName =
            group.organizationName && group.organizationName.toLowerCase().includes(searchText.toLowerCase());
        const inRole =
            group.role && txt(`AuthorizationRole.${group.role}`).toLowerCase().includes(searchText.toLowerCase());
        const inUserName =
            group.groupType === GroupType.consumer && userName.toLowerCase().includes(searchText.toLowerCase());
        return inName || inOrgName || inRole || inUserName;
    });
    const organizations = filteredGroups.filter(group => group.groupType === GroupType.business);
    const consumerAccounts = filteredGroups.filter(group => group.groupType === GroupType.consumer);
    const partnerOrgs = filteredGroups.filter(group => group.groupType === GroupType.partner);

    const setSelectedGroup = (newSelectedGroup: SelectedGroup): void => {
        selectGroup(newSelectedGroup);
        const isOrganizationAccount = selectedGroup && selectedGroup.groupType !== GroupType.consumer;

        if (isOrganizationAccount && newSelectedGroup.groupType !== GroupType.consumer) onClose();

        const newlySelectedGroup = groups.find(group => group.id === newSelectedGroup.userGroupId);

        analyticsLogger(ACCOUNT_SWAPPED_ACCOUNT, { organizationName: groupName(newlySelectedGroup) });
        if (newlySelectedGroup) {
            mixpanelRegisterSuperProperties({
                organizationId: newlySelectedGroup.organizationId,
                groupId: newlySelectedGroup.id,
                organization: newlySelectedGroup.organizationName,
                role: newlySelectedGroup.role,
                groupType: newlySelectedGroup.groupType,
                managedBy: newlySelectedGroup.managedByOrganization,
                name: groupName(newlySelectedGroup),
            });
        }
    };

    return (
        <Modal
            isOpen
            appElement={document.body}
            onRequestClose={onClose}
            className="modal__content modal__content--swap-organization"
            overlayClassName="modal modal__overlay"
        >
            <div className="modal__content__sticky">
                <ChangeSelectedSearchHeader
                    setSearchText={setSearchText}
                    headerText="SwapOrganization"
                    displaySearch={groups.length > 5}
                />
                <div className="modal__content__body modal__content__body--scroll">
                    {consumerAccounts.length > 0 && (
                        <ChangeAccountGroupList
                            groupsOfType={consumerAccounts}
                            groupHeader="Personal"
                            selectGroup={setSelectedGroup}
                            selectedGroup={selectedGroup}
                            loading={loading}
                            topLine={false}
                            userName={userName}
                        />
                    )}
                    {partnerOrgs.length > 0 && (
                        <ChangeAccountGroupList
                            groupsOfType={partnerOrgs}
                            groupHeader="Partner.Partner"
                            selectGroup={setSelectedGroup}
                            selectedGroup={selectedGroup}
                            loading={loading}
                            topLine={consumerAccounts.length > 0}
                        />
                    )}
                    {organizations.length > 0 && (
                        <ChangeAccountGroupList
                            groupsOfType={organizations}
                            groupHeader="BusinessAccounts"
                            selectGroup={setSelectedGroup}
                            selectedGroup={selectedGroup}
                            loading={loading}
                            topLine={consumerAccounts.length > 0 || partnerOrgs.length > 0}
                        />
                    )}
                </div>
                <div className="modal__content__buttons modal__content__buttons--sticky">
                    <PrimaryButton
                        color="secondary"
                        title="Cancel"
                        loading={false}
                        disabled={false}
                        onClick={onClose}
                    />
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { userName, groups, loading, selectedGroup },
    } = state;

    return {
        selectedGroup,
        groups,
        userName,
        loading,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    selectGroup: (selectedGroup: SelectedGroup): SelectGroup => dispatch(selectGroupAction(selectedGroup)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeOrganizationModalComponent);
