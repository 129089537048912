import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { businessBuildingTypes, ventilationTypeOptions } from 'commons/src/components/dropdown/commonOptions';
import Dropdown from 'commons/src/components/dropdown/MultipleAttrDropdown';
import Input from 'commons/src/components/input/Input';
import NumberInput from 'commons/src/components/input/Number';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { FloorRange } from '../../../constants';
import { Ventilation, BuildingOptions } from '../../../models/common';
import timezoneOptions from './timeZones';

export type PassedProps = {
    buildingYear: string;
    validateForm: boolean;
    ventilation: Ventilation | string | undefined;
    buildingType: BuildingOptions | string | undefined;
    setBuildingYear: (year: string) => void;
    setVentilation: (type: string) => void;
    setBuildingType: (type: string) => void;
    setValidBuildingDetails: (valid: boolean) => void;
    buildingTimezone: { id: string; inputValue: string };
    setBuildingTimezone: (zone: { id: string; inputValue: string }) => void;
    floors?: number;
    setFloors: (floor: number | undefined) => void;
};

export type Props = PassedProps;

export const BuildingDetailsComponent = (props: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [validBuildingYear, setValidBuildingYear] = useState(true);
    const {
        buildingYear,
        ventilation,
        buildingType,
        setBuildingYear,
        setVentilation,
        setBuildingType,
        setValidBuildingDetails,
        buildingTimezone,
        setBuildingTimezone,
        floors,
        setFloors,
        validateForm,
    } = props;

    const buildingTypes = businessBuildingTypes.map((selector: string) => ({
        id: selector,
        inputValue: txt(`BuildingTypeOptions.${selector}`),
    }));
    const ventilationTypes = ventilationTypeOptions.map((selector: string) => ({
        id: selector,
        inputValue: txt(`VentilationTypeOptions.${selector}`),
    }));

    const selectBuildingType = (type: { id: string; inputValue: string }): void => {
        setBuildingType(type.id);
    };
    const selectVentilation = (type: { id: string; inputValue: string }): void => {
        setVentilation(type.id);
    };

    const updateBuildingYear = (e: SyntheticEvent<HTMLInputElement>): void => {
        const value = e.currentTarget.value.trim();
        setBuildingYear(value.replace(/[^0-9]+/g, ''));
    };
    const validateBuildingYear = (): void => {
        const validYear = buildingYear.length === 4 || buildingYear.length === 0;
        setValidBuildingYear(validYear);
        setValidBuildingDetails(validYear);
    };

    const handleInvalidValue = (value: number): number | undefined => (value > 0 ? value : undefined);

    const handleSetFloors = (event: SyntheticEvent<HTMLInputElement>): void => {
        const value = parseInt(event.currentTarget.value, 10);
        setFloors(handleInvalidValue(value));
    };

    const validateFloors = validateForm && (!floors || floors > FloorRange.high);
    return (
        <div className="form__row--padded-medium">
            <div className="form__row">
                <div className="form__field form__field--half">
                    <Dropdown
                        id="buildingTypeSelector"
                        title="BuildingType"
                        options={buildingTypes}
                        loading={false}
                        isRequired
                        defaultOption="Select"
                        validate={validateForm && !buildingType}
                        value={buildingType ? txt(`BuildingTypeOptions.${buildingType}`) : ''}
                        onSelect={selectBuildingType}
                        testAttr="building-type-selector"
                        testId="building-type-selector"
                    />
                </div>
                <div className="form__field form__field--half">
                    <Dropdown
                        id="ventilationTypeSelector"
                        title="Ventilation"
                        isRequired
                        options={ventilationTypes}
                        loading={false}
                        validate={validateForm && !ventilation}
                        defaultOption="Select"
                        value={ventilation ? txt(`VentilationTypeOptions.${ventilation}`) : ''}
                        onSelect={selectVentilation}
                        testAttr="ventilation-selector"
                        testId="ventilation-type-selector"
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__field form__field--half">
                    <Input
                        type="text"
                        validate={!validBuildingYear}
                        label="BuildingYear"
                        id="buildingYear"
                        placeholder={txt('Building.BuildingYearExample')}
                        maxLength={4}
                        hint="Building.BuildingYearHint"
                        onBlur={validateBuildingYear}
                        onChange={updateBuildingYear}
                        currentValue={buildingYear}
                        testId="building-year"
                    />
                </div>
                <div className="form__field--half form__field--padded-small">
                    <NumberInput
                        label="Building.Floors"
                        step={1}
                        id="floorsInput"
                        markedMandatory
                        validate={validateFloors}
                        currentValue={(floors || '').toString()}
                        onChange={handleSetFloors}
                        hint={
                            floors && floors > FloorRange.high
                                ? txt('Building.InvalidFloors', { max: FloorRange.high.toString() })
                                : undefined
                        }
                        testId="floors-input"
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="flex flex--align-center">
                    <MaterialIcon extraClass="small-padding-right" name="info" />
                    <div className="text-small">{txt('Building.WhySetBuildingInfo')}</div>
                </div>
            </div>
            <div className="form__row">
                <div className="form__field">
                    <Dropdown
                        id="timezoneSelector"
                        title="Building.Timezone"
                        optionsAlreadySorted
                        options={timezoneOptions()}
                        loading={false}
                        defaultOption="Select"
                        value={buildingTimezone.inputValue}
                        onSelect={setBuildingTimezone}
                        testAttr="ventilation-selector"
                        testId="timezone-selector"
                    />
                </div>
            </div>
        </div>
    );
};

export default BuildingDetailsComponent;
