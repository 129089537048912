import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userRoleAboveRequiredLevel } from '../../features/authorization/userRoleAboveRequiredLevel';
import { RequiredRoleLevel } from '../../models/commonEnums';
import { MenuItem } from '../../models/menuModels';
import { Store } from '../../reducers';
import MaterialIcon from '../MaterialIcon';
import filterMenuElements from '../menu/FilterMenuElements';
import styles from './OptionButton.module.scss';

type Props = {
    id: string;
    title: string;
    onClick: () => void;
    testAttr: string;
    options: MenuItem[];
    requiredRoleLevel: RequiredRoleLevel;
    accessWithResourceFilter?: boolean;
};

const OptionButton = (props: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { id, title, options, testAttr, onClick, requiredRoleLevel, accessWithResourceFilter } = props;
    const { selectedGroup, featureToggles } = useSelector((state: Store) => state.userSettings);
    const { role: userRole, groupType } = selectedGroup || {};
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [inFocus, setInFocus] = useState(0);

    const onDropdownClick = (e: React.SyntheticEvent<HTMLElement>): void => {
        const buttonElement = document.getElementById(id);
        if (buttonElement) buttonElement.focus();
        e.preventDefault();
        e.stopPropagation();
        setIsDropdownOpen(!isDropdownOpen);
        setInFocus(0);
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLElement>): void => {
        if (!isDropdownOpen) {
            return;
        }
        const optionsLength = options.length;
        let toBeFocused;

        switch (e.key) {
            case 'ArrowDown':
                if (inFocus >= optionsLength) {
                    toBeFocused = 1;
                } else {
                    toBeFocused = inFocus + 1;
                }

                break;
            case 'ArrowUp':
                if (inFocus <= 1) {
                    toBeFocused = optionsLength;
                } else {
                    toBeFocused = inFocus - 1;
                }

                break;
            case 'Enter':
                if (inFocus === 0) {
                    onDropdownClick(e);
                } else {
                    options[inFocus - 1].onClick();
                }

                break;
            default:
                return;
        }

        e.preventDefault();
        e.stopPropagation();
        if (toBeFocused) {
            setInFocus(toBeFocused);
        }
    };

    const onBlur = (e: React.SyntheticEvent<HTMLElement>): void => {
        e.preventDefault();
        const buttonElement = document.getElementById(id);
        if (buttonElement) buttonElement.blur();
        setIsDropdownOpen(false);
    };

    const onSelect = (e: React.MouseEvent<HTMLDivElement>, click: () => void): void => {
        e.stopPropagation();
        e.preventDefault();
        if (e.button === 0) {
            setIsDropdownOpen(false);
            click();
        }
    };

    const mainButtonAllowedToBeDisplayed =
        userRole && userRoleAboveRequiredLevel(userRole, requiredRoleLevel, accessWithResourceFilter);
    const allowedOptionsForDisplay: MenuItem[] =
        options && filterMenuElements(options, groupType, userRole, accessWithResourceFilter, featureToggles);
    if (!allowedOptionsForDisplay || allowedOptionsForDisplay.length === 0 || !mainButtonAllowedToBeDisplayed) {
        return <div />;
    }

    return (
        <div className={styles.buttonWrapper}>
            <button
                type="button"
                onClick={onClick}
                className={styles.leftButton}
                {...{ [`data-${testAttr || 'button'}`]: true }}
            >
                {txt(title)}
            </button>
            <button
                type="button"
                id={id}
                className={styles.rightButton}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onClick={onDropdownClick}
            >
                <MaterialIcon name="arrow_drop_down" />
            </button>
            {isDropdownOpen && (
                <div role="list" className={styles.list}>
                    {allowedOptionsForDisplay.map((optionElement, i) => (
                        <div
                            role="button"
                            id={optionElement.id}
                            tabIndex={0}
                            onMouseDown={(e): void => {
                                onSelect(e, optionElement.onClick);
                            }}
                            key={`dropdown-option-element-${optionElement.id}`}
                            className={classNames(styles.listElement, {
                                [styles.inFocus]: inFocus === i + 1,
                            })}
                        >
                            {txt(optionElement.text)}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default OptionButton;
