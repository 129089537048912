/* eslint-disable import/prefer-default-export */
/* This file has no default export */

export enum BuildingProps {
    buildingType = 'buildingType',
    ventilation = 'ventilation',
    buildingYear = 'buildingYear',
    floors = 'floors',
    openingHours = 'openingHours',
}

export enum IotCertificateStatus {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    Revoked = 'REVOKED',
    PendingTransfer = 'PENDING_TRANSFER',
    RegisterInactive = 'REGISTER_INACTIVE',
    PendingActivation = 'PENDING_ACTIVATION',
}

export enum BillingType {
    ANNUAL = 'ANNUAL',
    FULL = 'FULL',
}

export enum SubscriptionStatus {
    Active = 'Active',
    Cancelled = 'Cancelled',
}

export enum PresenceViewType {
    DayView = 'DayView',
    DeviceView = 'DeviceView',
}

export enum ServicePeriod {
    OneYear = 'OneYear',
    ThreeYears = 'ThreeYears',
    FiveYears = 'FiveYears',
}

export enum IntercomArticleId {
    TROUBLESHOOTING_OFFLINE_HUB = '77643',
    TROUBLESHOOTING_OFFLINE_DEVICE = '77642',
    TROUBLESHOOTING_CONNECTION_ISSUES = '81214',
    SUBSCRIPTION_MGMT = '101615',
    HEATING_COOLING = '81135',
    SPACE_UTILIZATION = '81138',
    SSO_VERIFICATION = '81146-business-dashboard-single-sign-on#h_5dfd430d9a',
}
