import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import LabeledText from 'commons/src/components/LabeledText';
import { BuildingType } from 'commons/src/models/commonTypeScript';
import { BuildingProps } from '../../../models/commonEnums';

type ParentProps = {
    building: BuildingType | undefined;
    validate: boolean;
    optionalBuildingProps: string[];
};

type Props = ParentProps;

export const BuildingInfoComponent = (props: Props): React.ReactElement => {
    const { building, optionalBuildingProps, validate } = props;
    const { t: txt } = useTranslation();
    return (
        <div className="form__row">
            <div className="labeled-text form__field--standard-width">
                <span
                    className={classNames('labeled-text__label', {
                        'labeled-text__label--red': validate && !!building && !building.address,
                    })}
                >
                    {txt('Address')}
                </span>
                <span className="labeled-text__multiline text-large">
                    {(building && building.address && building.address.replace(/,/g, '\n')) || '-'}
                </span>
            </div>
            <div className="form__field--medium-slim">
                <LabeledText
                    label="BuildingType"
                    largeText
                    invalid={
                        validate &&
                        !optionalBuildingProps.includes(BuildingProps.buildingType) &&
                        !!building &&
                        !building.buildingType
                    }
                    id="buildingType"
                    value={
                        (building &&
                            building.buildingType &&
                            txt(`BuildingTypeOptions.${building.buildingType.toString()}`)) ||
                        '-'
                    }
                />
            </div>
            <div className="form__field--medium-slim">
                <LabeledText
                    label="BuildingYear"
                    largeText
                    invalid={
                        validate &&
                        !optionalBuildingProps.includes(BuildingProps.buildingYear) &&
                        !!building &&
                        !building.buildingYear
                    }
                    id="buildingYear"
                    value={(building && building.buildingYear && building.buildingYear.toString()) || '-'}
                />
            </div>
            <div className="form__field--medium-slim">
                <LabeledText
                    label="Ventilation"
                    id="ventilation"
                    largeText
                    invalid={
                        validate &&
                        !optionalBuildingProps.includes(BuildingProps.ventilation) &&
                        !!building &&
                        !building.ventilationType
                    }
                    value={
                        (building &&
                            building.ventilationType &&
                            txt(`VentilationTypeOptions.${building.ventilationType.toString()}`)) ||
                        '-'
                    }
                />
            </div>
            <div className="form__field--medium-slim">
                <LabeledText
                    label="NumberOfFloors"
                    id="numberOfFloors"
                    largeText
                    invalid={
                        validate &&
                        !optionalBuildingProps.includes(BuildingProps.floors) &&
                        !!building &&
                        !building.floors
                    }
                    value={(building && building.floors && building.floors.toString()) || '-'}
                />
            </div>
        </div>
    );
};

export default BuildingInfoComponent;
