import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import Input from 'commons/src/components/input/Input';
import NumberInput from 'commons/src/components/input/Number';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import DeleteConfirmModal from 'commons/src/components/modals/DeleteConfirmModal';
import { deleteSpaceFloorPlan, editSpaceFloorPlan } from '../../../actions/floorPlanSpaceActions';
import { SpaceFloorPlan } from '../../../models/spaceFloorPlanModels';
import { Store } from '../../../reducers';
import styles from './EditFloorPlanDetails.module.scss';

type Props = {
    locationId: string;
    floorPlan: SpaceFloorPlan;
    onClose: () => void;
};
const EditFloorPlanDetails = ({ locationId, floorPlan, onClose }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const [floorName, setFloorName] = useState(floorPlan.name);
    const [floorNr, setFloorNr] = useState<number | undefined>(floorPlan.floor);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [loading, setLoading] = useState<'delete' | 'edit' | undefined>(undefined);
    const { loading: editLoading, error: editError } = useSelector(
        (state: Store) => state.requests.EDIT_SPACE_FLOOR_PLAN
    );
    const { loading: deleteLoading, error: deleteError } = useSelector(
        (state: Store) => state.requests.DELETE_SPACE_FLOOR_PLAN
    );

    const handleNameChange = ({ currentTarget }: SyntheticEvent<HTMLInputElement>): void => {
        setFloorName(currentTarget.value.trim());
    };

    const handleSubmit = (): void => {
        const floorIsNumber = !Number.isNaN(floorNr);
        const valid = floorName.length > 0 && floorIsNumber;
        if (valid) {
            setLoading('edit');
            dispatch(
                editSpaceFloorPlan({ ...floorPlan, name: floorName, floor: Number(floorNr) }, floorPlan.id, locationId)
            );
        }
    };
    const onClickDelete = (): void => {
        setDeleteModalOpen(true);
    };

    const onDelete = (): void => {
        setLoading('delete');
        dispatch(deleteSpaceFloorPlan(floorPlan.id, locationId));
    };

    useEffect(() => {
        if (loading && !editLoading && !deleteLoading) {
            if (loading === 'delete' && !deleteError) {
                setDeleteModalOpen(false);
                onClose();
            } else if (loading === 'edit' && !editError) {
                onClose();
            }
            setLoading(undefined);
        }
    }, [editLoading, deleteLoading]);

    return (
        <form onSubmit={handleSubmit} className={styles.editForm}>
            <div className={styles.floorDetails}>
                {deleteModalOpen && (
                    <DeleteConfirmModal
                        onSubmit={onDelete}
                        onCancel={(): void => setDeleteModalOpen(false)}
                        onSubmitText="Delete"
                        description={txt('FloorPlan.DeleteFloorPlanDescription', { name: floorName })}
                        onCancelText="Cancel"
                        title="FloorPlan.DeleteFloorPlan"
                        loading={deleteLoading}
                        error={!!deleteError}
                        errorText={deleteError && `ErrorCodes.${deleteError.error}`}
                    />
                )}
                <NumberInput
                    id="floorNr"
                    validate={Number.isNaN(floorNr)}
                    onChange={(e): void => setFloorNr(parseInt(e.currentTarget.value, 10))}
                    step={1}
                    testId="data-floor-nr"
                    currentValue={floorNr?.toString()}
                    label={txt('Floor')}
                    width="minimum"
                    size="small"
                />
                <Input
                    type="text"
                    id="floorname"
                    label="Name"
                    testId="data-floor-name"
                    isValid={floorName.length > 0}
                    onChange={handleNameChange}
                    validate={floorName.length < 1}
                    maxLength={50}
                    currentValue={floorName}
                    noBottomMargin
                    size="small"
                />
                <PrimaryButton
                    type="button"
                    title="Save"
                    color="primary"
                    loading={editLoading}
                    onClick={handleSubmit}
                    testId="save-edit-floor-plan"
                    disabled={deleteLoading}
                />
                <PrimaryButton
                    color="alert"
                    type="button"
                    title="Delete"
                    loading={deleteLoading}
                    onClick={onClickDelete}
                    testId="delete-floor-plan"
                    disabled={editLoading}
                />
            </div>
            <TertiaryButton onClick={onClose} icon={<MaterialIcon name="close" />} noColor />
        </form>
    );
};
export default EditFloorPlanDetails;
