import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import { userRoleAboveRequiredLevel } from 'commons/src/features/authorization/userRoleAboveRequiredLevel';
import { RequiredRoleLevel, Role } from 'commons/src/models/commonEnums';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { InvitedMember, Member } from '../../../models/common';
import { Store } from '../../../reducers';
import EditUserModal from './EditUserModal';
import MemberDetails from './MemberDetails';

type StateProps = {
    loadingEditingUser: boolean;
    errorEditingUser?: ErrorType;
};

export type Props = {
    user: InvitedMember | Member;
    loadingDeleteInvite?: boolean;
    loadingRemoveMember?: boolean;
    loadingResendInvite?: boolean;
    removeIsDisabled: boolean;
    onRemoveMember?: () => void;
    onResendInvite?: () => void;
    onDeleteInvite?: () => void;
    subject: Member | undefined;
} & StateProps;

export const MemberListRowComponent = (props: Props): React.ReactElement => {
    const {
        removeIsDisabled,
        subject,
        user,
        onDeleteInvite,
        onResendInvite,
        onRemoveMember,
        loadingDeleteInvite,
        loadingRemoveMember,
        loadingResendInvite,
        loadingEditingUser,
        errorEditingUser,
    } = props;

    const { email, name, role, userId } = user;

    const [showRemoveAlert, setShowRemoveAlert] = useState(false);
    const [actionTitle, setActionTitle] = useState('');
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const [acceptAction, setAcceptAction] = useState({ action: () => {} });
    const [displayEditModal, setDisplayEditModal] = useState(false);

    const { t: txt } = useTranslation();

    const setAction = (action: () => void, buttonTitle: string) => (): void => {
        setShowRemoveAlert(true);
        setActionTitle(buttonTitle);
        const actionAndClose = (): void => {
            action();
            setShowRemoveAlert(false);
            setActionTitle('');
        };
        setAcceptAction({ action: actionAndClose });
    };

    const isAdmin =
        subject && subject.role && userRoleAboveRequiredLevel(subject.role as Role, RequiredRoleLevel.ABOVE_ADMIN);
    const isLoggedInUser = subject && subject.userId === userId;

    const openEditModal = (): void => setDisplayEditModal(true);
    const closeEditModal = (): void => setDisplayEditModal(false);

    const prevLoadingRef = useRef(loadingEditingUser);
    useEffect(() => {
        if (prevLoadingRef && !loadingEditingUser && !errorEditingUser) {
            setDisplayEditModal(false);
        }
        prevLoadingRef.current = loadingEditingUser;
    }, [prevLoadingRef, loadingEditingUser]);

    return (
        <div
            className={classNames('list__row', {
                'list__row--danger': showRemoveAlert,
                'list__row--greyed': isLoggedInUser,
            })}
        >
            <EditUserModal onClose={closeEditModal} isOpen={displayEditModal} userToEdit={user} />
            <MemberDetails name={name} email={email} role={role} />
            <div className="list__row__actions">
                {isAdmin && !onDeleteInvite && !showRemoveAlert && !isLoggedInUser && (
                    <PrimaryButton
                        color="secondary"
                        loading={loadingDeleteInvite}
                        onClick={openEditModal}
                        title={txt('Edit')}
                    />
                )}
                {isAdmin && onRemoveMember && !showRemoveAlert && (
                    <PrimaryButton
                        color="secondary"
                        loading={loadingRemoveMember}
                        onClick={setAction(onRemoveMember, txt(isLoggedInUser ? 'LeaveOrganization' : 'Remove'))}
                        disabled={removeIsDisabled}
                        title={isLoggedInUser ? 'Leave' : 'Remove'}
                    />
                )}
                {isAdmin && onResendInvite && !showRemoveAlert && (
                    <PrimaryButton
                        color="secondary"
                        loading={loadingResendInvite}
                        onClick={setAction(onResendInvite, txt('ResendInvite'))}
                        title="ResendInvite"
                    />
                )}
                {isAdmin && onDeleteInvite && !showRemoveAlert && (
                    <PrimaryButton
                        color="secondary"
                        loading={loadingDeleteInvite}
                        onClick={setAction(onDeleteInvite, txt('Delete'))}
                        title="Delete"
                    />
                )}
                {showRemoveAlert && (
                    <>
                        <PrimaryButton
                            color="alert"
                            loading={false}
                            onClick={acceptAction.action}
                            title={actionTitle}
                            translate={false}
                        />
                        <PrimaryButton
                            color="secondary"
                            onClick={(): void => setShowRemoveAlert(false)}
                            title="Cancel"
                        />
                    </>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        requests: {
            CHANGE_MEMBERSHIP_ROLE: { loading: loadingEditingUser, error: errorEditingUser },
        },
    } = store;
    return {
        loadingEditingUser,
        errorEditingUser,
    };
};

export default connect(mapStateToProps)(MemberListRowComponent);
