import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PillStatus } from '../../models/commonEnums';
import MaterialIcon from '../MaterialIcon';
import styles from './StatusPill.module.scss';

type Props = {
    status: PillStatus;
    outline?: boolean;
    size?: 'small' | 'medium';
    text?: string | ReactElement;
    icon?: string;
    rotate?: number;
    onClick?: () => void;
};
const StatusPillButton = ({ status, outline, size, text, icon, rotate, onClick }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const rotateStyle = rotate ? styles[`rotate${rotate}`] : {};

    const onClickHandler = (): void => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <div
            className={classNames(styles[status], {
                [styles[`outline-${status}`]]: outline,
                [styles.small]: size === 'small',
            })}
            role="button"
            tabIndex={0}
            onClick={onClickHandler}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>): false | void => e.key === 'Enter' && onClickHandler()}
        >
            {icon && <MaterialIcon name={icon} extraClass={rotateStyle} />}
            {text || txt(`PillStatus.${status}`)}
        </div>
    );
};

export default StatusPillButton;
