import React from 'react';
import { LatLngExpression } from 'leaflet';
import { Polygon } from 'react-leaflet';
import { FloorPlanPosition } from 'commons/src/models/commonTypeScript';
import { SpaceFloorPlanMode } from '../../../models/spaceFloorPlanModels';
import styles from './SpaceZone.module.scss';

type Props = {
    spaceZone: {
        id: string;
        boundary: FloorPlanPosition[];
    };
    onClick: (zoneId?: string) => void;
    selectedSpace: string | undefined;
    mode?: SpaceFloorPlanMode;
};

const SpaceZone = ({ spaceZone, onClick, selectedSpace, mode }: Props): React.ReactElement => {
    const zonePositions = spaceZone.boundary.map((points): LatLngExpression => [points.lat, points.lng]);
    const isSelected = selectedSpace === spaceZone.id;
    const clickHandler = (): void => {
        if (mode === SpaceFloorPlanMode.EDIT || mode === SpaceFloorPlanMode.CREATE) return;
        if (isSelected) onClick();
        else onClick(spaceZone.id);
    };

    if (selectedSpace === spaceZone.id && mode === SpaceFloorPlanMode.EDIT) {
        return <div />;
    }

    return (
        <Polygon
            key={`${spaceZone.id}:polygon`}
            positions={zonePositions}
            className={styles.zone}
            pathOptions={{ weight: isSelected ? 3 : 1 }}
            eventHandlers={{ click: clickHandler }}
        />
    );
};

export default SpaceZone;
