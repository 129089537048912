import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { analyticsLogger, PageType, ReportType } from 'commons/src/analytics';
import { INSIGHT_CREATED_REPORT } from 'commons/src/analytics/AnalyticsEvents';
import Error from 'commons/src/components/errorComponents/Error';
import PageHeader from 'commons/src/components/headers/PageHeader';
import SubHeader from 'commons/src/components/headers/SubHeader';
import ReactPdfDownloadModal from 'commons/src/components/PDF/ReactPdfDownloadModal';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import { dateFormats } from 'commons/src/constants';
import { DeviceTypeNames, SensorTypes } from 'commons/src/models/commonEnums';
import { BuildingType } from 'commons/src/models/commonTypeScript';
import { paths } from '../../../constants';
import { BuildingProps } from '../../../models/commonEnums';
import { Store } from '../../../reducers';
import GeneratePdf from '../insightPdfs/IaqCertificatePdf/IaqCertificate';
import InsightMultipleDevices, { ReportData } from '../reportSelector/InsightMultipleDevices';

type StateProps = {
    dateFormat: keyof typeof dateFormats;
    locationsLoading: boolean;
    locationsError: boolean;
    buildings: { [buildingId: string]: BuildingType };
};

type Props = StateProps;

export const IAQCertificatePageComponent = ({
    locationsError,
    locationsLoading,
    buildings,
    dateFormat,
}: Props): React.ReactElement => {
    const navigate = useNavigate();
    const { t: txt } = useTranslation();
    const [displayResultSection, setDisplayResultSection] = useState(false);
    const [reportProps, setReportProps] = useState<{ building: BuildingType; sensors: string[] } | undefined>(
        undefined
    );
    const optionalBuildingProps = [BuildingProps.floors, BuildingProps.openingHours, BuildingProps.buildingYear];

    if (locationsError) {
        return <Error />;
    }

    const generateCertificate = (reportData: ReportData): void => {
        const { sensors, locationId } = reportData;
        const building = buildings[locationId];
        if (building) {
            setReportProps({ building, sensors });

            analyticsLogger(INSIGHT_CREATED_REPORT, {
                pageType: PageType.Insight,
                reportType: ReportType.IAQCertificate,
                reportData,
            });
        } else {
            setDisplayResultSection(false);
        }
    };

    const possibleSensorTypes = [
        SensorTypes.co2,
        SensorTypes.voc,
        SensorTypes.humidity,
        SensorTypes.temp,
        SensorTypes.radonShortTermAvg,
        SensorTypes.pm25,
        SensorTypes.sla,
        SensorTypes.pm1,
    ];

    const goBack = (): void => {
        navigate({ pathname: `/${paths.reports}` });
    };
    return (
        <div className="radon-insight-view">
            <PageHeader title={txt('InsightCertificate.IAQCertificate')} />
            <div className="page-wrapper-flex">
                <SubHeader onGoBack={goBack} backTitle="Reports.Reports" actionButtons={[]} />
            </div>
            <div className="page-wrapper-flex page-wrapper__medium--white">
                <ReactPlaceholder ready={!locationsLoading} customPlaceholder={mediumFormLoader}>
                    <div className="form form__wide-container">
                        <div className="header-2">
                            <span className="text-large">{txt('InsightCertificate.CertificateCardDescription')}</span>
                        </div>
                        <InsightMultipleDevices
                            setDisplayResultSection={setDisplayResultSection}
                            fetchReportDataLoading={false}
                            fetchReportData={generateCertificate}
                            possibleSensorTypes={possibleSensorTypes}
                            acceptedDeviceTypes={[
                                DeviceTypeNames.wavePlus,
                                DeviceTypeNames.mini,
                                DeviceTypeNames.viewPlus,
                                DeviceTypeNames.viewPlusBusiness,
                                DeviceTypeNames.viewCo2,
                                DeviceTypeNames.spaceCo2Mini,
                                DeviceTypeNames.waveNano,
                            ]}
                            minNumberOfDays={0}
                            reportStartTime={null}
                            reportEndTime={null}
                            allowLogo={false}
                            optionalBuildingProps={optionalBuildingProps}
                        />
                    </div>
                </ReactPlaceholder>
            </div>
            {displayResultSection && reportProps && (
                <ReactPdfDownloadModal title="Download" onClose={(): void => setDisplayResultSection(false)}>
                    <GeneratePdf {...reportProps} dateFormat={dateFormat} />
                </ReactPdfDownloadModal>
            )}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        locations: { locations, loading, error: locationsError },
        buildings: { buildings },
        userSettings: { dateFormat },
    } = state;
    return {
        dateFormat,
        buildings,
        locationsLoading: loading && locations.length === 0,
        locationsError,
    };
};

export default connect(mapStateToProps)(IAQCertificatePageComponent);
