import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getValidLanguageTagForMoment } from '../../../commonFunctions';
import { statusColors } from '../../../constants';
import { DeviceTypeNames } from '../../../models/commonEnums';
import { AnyDeviceType } from '../../../models/commonTypeScript';

type Props = {
    segmentName: string;
    latestSample?: string;
    serialNumber: string;
    tileStatus: string;
    deviceType: AnyDeviceType;
};

const DeviceListHeader = ({
    segmentName,
    latestSample,
    serialNumber,
    tileStatus,
    deviceType,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const language = getValidLanguageTagForMoment();
    const lastSynced = latestSample ? moment.utc(latestSample).local().locale(language).fromNow() : txt('neverSynced');
    return (
        <div className="list-tile__header">
            <div className="list-tile__header__section">
                <div
                    className={classNames('list-tile__status-circle', {
                        'sensor__line--ok': tileStatus === statusColors.green,
                        'sensor__line--alert': tileStatus === statusColors.red,
                        'sensor__line--warning': tileStatus === statusColors.yellow,
                        'sensor__line--disabled': tileStatus === statusColors.grey,
                    })}
                >
                    <div className="list-tile__status-circle__middle" />
                </div>
            </div>
            <div className="list-tile__header__section">
                <div className="list-tile__header__title">
                    <h2 className="list-tile__header__title--name">{segmentName}</h2>
                    <span className="tile-header__title--address">{serialNumber}</span>
                    {deviceType !== DeviceTypeNames.ap1 && (
                        <div className="list-tile__header__last-synced">{[txt('LastSynced'), ' ', lastSynced]}</div>
                    )}
                </div>
            </div>
        </div>
    );
};

DeviceListHeader.defaultProps = {
    latestSample: undefined,
};

export default DeviceListHeader;
