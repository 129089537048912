import React, { SyntheticEvent } from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import DatePicker from 'commons/src/components/DatePicker';
import CheckBox from 'commons/src/components/input/Checkbox';
import Input from 'commons/src/components/input/Input';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { dateFormats } from 'commons/src/constants';
import { GroupType } from 'commons/src/models/commonEnums';
import { getBusinessIntercomArticleLink, paths } from '../../../../constants';
import { IntercomArticleId, ServicePeriod } from '../../../../models/commonEnums';
import { RatePlansPerPeriod, SubscriptionSeats } from '../../../../models/subscriptionModels';
import AddSeats from './AddSeats';
import EstimatedCost from './EstimatedCost';
import styles from './SetupSubscriptionView.module.scss';

type Props = {
    seatsPerDevice: SubscriptionSeats[];
    setSeatsPerDevice: (newServicePeriod: ServicePeriod, newSeats: SubscriptionSeats[]) => void;
    previewCostTable: React.ReactElement;
    separateInvoice: boolean;
    toggleSeparateInvoice: (e: SyntheticEvent<HTMLInputElement>) => void;
    childUserGroupId?: string;
    setStepNumber: (step: number) => void;
    servicePeriod: ServicePeriod;
    setServicePeriod: (period: ServicePeriod) => void;
    purchaseOrderNumber: string;
    setPurchaseOrderNumber: (purchaseOrder: string) => void;
    ratePlans?: RatePlansPerPeriod;
    groupType?: GroupType;
    dateFormat: keyof typeof dateFormats;
    startDate: string;
    setStartDate: (date: string) => void;
};

const SetupSubscriptionView = ({
    seatsPerDevice,
    setSeatsPerDevice,
    previewCostTable,
    toggleSeparateInvoice,
    separateInvoice,
    childUserGroupId,
    setStepNumber,
    servicePeriod,
    setServicePeriod,
    purchaseOrderNumber,
    setPurchaseOrderNumber,
    ratePlans,
    groupType,
    dateFormat,
    startDate,
    setStartDate,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();
    const PO_NUMBER_MAX_LENGTH = 100;

    const cancel = (): void => {
        if (groupType === GroupType.partner && childUserGroupId) {
            navigate({
                pathname: `/${generatePath(paths.partnerCustomerPage, {
                    childUserGroupId,
                })}`,
            });
        } else {
            navigate({
                pathname: `/${generatePath(paths.subscription)}`,
            });
        }
    };

    const disableButton = (): boolean => {
        const seatsCountInvalid = seatsPerDevice.reduce((count, device) => count + device.seats, 0) === 0;
        const pONumberInvalid = purchaseOrderNumber.length === 0 || purchaseOrderNumber.length > PO_NUMBER_MAX_LENGTH;
        return seatsCountInvalid || pONumberInvalid;
    };

    const updatePONumber = (event: SyntheticEvent<HTMLInputElement>): void => {
        const { value } = event.currentTarget;
        setPurchaseOrderNumber(value);
    };

    const onStartDateChanged = (date: Moment): void => {
        if (moment(date).startOf('day').valueOf() !== moment(startDate).valueOf()) {
            setStartDate(date.format('YYYY-MM-DD'));
        }
    };

    const isOutsideDateRange = (day: Moment): boolean => {
        if (!moment.isMoment(day)) return false;
        return day.isBefore(moment().subtract(6, 'months')) || day.isAfter(moment().add(60, 'days'));
    };

    return (
        <div className="page-wrapper__medium">
            <h2>{txt('Subscription.SelectProductsHeader')}</h2>
            <div className="page-wrapper__medium page-wrapper__medium--no-margin-top page-wrapper__medium--white">
                <div className={styles.container}>
                    <div className={styles.content}>
                        <div className={styles.inputContainer}>
                            <DatePicker
                                id="startDate"
                                onChange={onStartDateChanged}
                                dateFormat={dateFormat as keyof typeof dateFormats}
                                selectedDate={moment(startDate, 'YYYY-MM-DD')}
                                disabledDate={isOutsideDateRange}
                                placeholder="StartDate"
                                label="StartDate"
                                mandatory
                            />
                        </div>
                        <div className={styles.startDateText}>{txt('Subscription.StartDateDescription')}</div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.inputContainer}>
                            <Input
                                required
                                markedMandatory
                                validate={false}
                                type="text"
                                id="poNumber"
                                label="Subscription.PONumber"
                                currentValue={purchaseOrderNumber}
                                maxLength={100}
                                onBlur={updatePONumber}
                                onChange={updatePONumber}
                            />
                        </div>
                        <div className={styles.pOText}>
                            {txt('Subscription.PONumberDescription')}
                            <a
                                className={styles.iconWithText}
                                href={getBusinessIntercomArticleLink(IntercomArticleId.SUBSCRIPTION_MGMT)}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {txt('Subscription.ReadAboutPO')}
                                <MaterialIcon name="launch" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.ratePlanSections}>
                <AddSeats
                    servicePeriod={servicePeriod}
                    seatsPerDevice={seatsPerDevice}
                    updateSeats={setSeatsPerDevice}
                />
                <EstimatedCost
                    previewCostTable={previewCostTable}
                    servicePeriod={servicePeriod}
                    setServicePeriod={setServicePeriod}
                    ratePlans={ratePlans}
                />
            </div>

            <div className="page-wrapper__medium page-wrapper__medium--white">
                <div className={styles.container}>
                    <CheckBox
                        id="separateInvoice"
                        checked={separateInvoice}
                        label={txt('Subscription.BillInSeparateInvoice')}
                        onChange={toggleSeparateInvoice}
                    />
                    <div className={styles.buttonsContainer}>
                        <PrimaryButton color="secondary" onClick={cancel} title="Cancel" />
                        <PrimaryButton
                            onClick={(): void => setStepNumber(2)}
                            title="Subscription.ReviewPurchase"
                            color="primary"
                            disabled={disableButton()}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SetupSubscriptionView;
