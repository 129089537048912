import React from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import BoldText from 'commons/src/components/texts/BoldText';
import { dateFormats } from 'commons/src/constants';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import subscriptionCheckMark from '../../../../img/subscription_check_mark.svg';
import { EstimatedSubscription, Subscription } from '../../../../models/subscriptionModels';
import { Store } from '../../../../reducers';
import { BusinessRequestType as RequestType } from '../../../../reducers/BusinessRequestType';
import styles from './AddSeatsSuccessView.module.scss';
import SubscriptionSummary from './SubscriptionSummary';

type ParentProps = {
    costPreview?: EstimatedSubscription;
    oldSubscription: Subscription;
    dateFormat: keyof typeof dateFormats;
    poNumber: string;
    onClose: () => void;
};

type StateProps = {
    loading: boolean;
    error?: ErrorType;
};

type Props = ParentProps & StateProps;

const AddSeatsSuccessView = ({
    oldSubscription,
    loading,
    error,
    costPreview,
    dateFormat,
    poNumber,
    onClose,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className="page-wrapper__medium">
            <h2>{txt('AddSeats.Summary')}</h2>
            <div className={styles.wrapper}>
                <div className={styles.imageContainer}>
                    <img src={subscriptionCheckMark} alt={txt('Subscription.GreenCheckMarkImage')} />
                </div>
                <div className={styles.textWrapper}>
                    <BoldText text={txt('AddSeats.ThankYouForAddingSeats')} />
                    <div>{txt('AddSeats.InvoiceEmailWillBeSentToBilling')}</div>
                </div>
                <SubscriptionSummary
                    oldSubscription={oldSubscription}
                    costPreview={costPreview}
                    dateFormat={dateFormat}
                    poNumber={poNumber}
                    displayDiscounts={false}
                />
                <div className={styles.buttonWrapper}>
                    <PrimaryButton onClick={onClose} title="Close" disabled={false} loading={false} color="primary" />
                </div>
                {error && !loading && <ResponseBox text={`ErrorCodes.${error.error}`} />}
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        requests: {
            [RequestType.AddSeatsToSubscription]: { loading, error },
        },
    } = store;
    return {
        loading,
        error,
    };
};

export default connect(mapStateToProps)(AddSeatsSuccessView);
