import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { OrganizationInviteResponse, organizationInviteResponse } from '../../actions/OrganizationInvitesActions';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import ModalHeader from '../../components/modals/ModalHeader';
import { organizationInviteResponses } from '../../constants';
import { OrganizationInviteType } from '../../models/commonTypeScript';
import { Store } from '../../reducers';

type StateProps = {
    invites: Array<OrganizationInviteType>;
    acceptInviteLoading: boolean;
    email: string;
};

export type ParentProps = {
    sendResponse: (invite: OrganizationInviteType, status: string, userGroupId?: string) => void;
};

type Props = StateProps & ParentProps;

const OrganizationInvitation = ({
    invite,
    email,
}: {
    invite: OrganizationInviteType;
    email: string;
}): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <>
            <div className="modal__content__description">
                {txt('InviteOrganizationMessage')}
                <span className="modal__content__description--bold">{invite.userGroupName}</span>
                {txt('OrganizationAccount')}
            </div>
            <div className="modal__content__description modal__content__description--bold modal__content__description--padded-large">
                {email}
                {' ➞ '}
                {invite.userGroupName}
            </div>
        </>
    );
};

export const JoinInvitationComponent = (props: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { acceptInviteLoading, invites, email, sendResponse } = props;
    const invite = invites[0];

    const onDeny = (e: SyntheticEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        sendResponse(invite, organizationInviteResponses.deny);
    };

    const onAccept = (e: SyntheticEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        sendResponse(invite, organizationInviteResponses.accept);
    };

    return (
        <div>
            <ModalHeader headerText="Invitation" />
            <div className="modal__content__body modal__content__body--large" data-organization-invite>
                <OrganizationInvitation email={email} invite={invite} />
                <div className="modal__content__buttons modal__content__buttons--flex-end">
                    <PrimaryButton
                        title={txt('Deny')}
                        loading={false}
                        onClick={onDeny}
                        disabled={acceptInviteLoading}
                        color="alert"
                        autoFocus
                        testAttr="deny-org-invite-button"
                    />
                    <PrimaryButton
                        title={txt('Accept')}
                        loading={acceptInviteLoading}
                        onClick={onAccept}
                        disabled={acceptInviteLoading}
                        autoFocus
                        color="primary"
                        testAttr="accept-org-invite-button"
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        organizationInvites: { invites, acceptInviteLoading },
        userSettings: { email },
    } = state;
    return {
        invites,
        acceptInviteLoading,
        email,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ParentProps => ({
    sendResponse: (invite: OrganizationInviteType, status: string): OrganizationInviteResponse =>
        dispatch(organizationInviteResponse(invite, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JoinInvitationComponent);
