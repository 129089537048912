import React, { useState, SyntheticEvent, useCallback } from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generateRadonReport } from '../../../../api/generateDownload';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import DatePicker from '../../../../components/DatePicker';
import {
    buildingTypeOptions,
    floorOptions,
    roomTypeOptions,
    ventilationTypeOptions,
} from '../../../../components/dropdown/commonOptions';
import Dropdown from '../../../../components/dropdown/MultipleAttrDropdown';
import Error from '../../../../components/errorComponents/Error';
import Input from '../../../../components/input/Input';
import Textarea from '../../../../components/input/Textarea';
import { IntercomAPI } from '../../../../components/Intercom';
import ReactPdfDownloadModal from '../../../../components/PDF/ReactPdfDownloadModal';
import ResponseBox from '../../../../components/responseMessages/ResponseBox';
import { dateFormats, howToMeasureRadonLink, sensorUnits } from '../../../../constants';
import { DeviceType, ErrorType } from '../../../../models/commonTypeScript';
import { Store } from '../../../../reducers';
import RadonReportResponse from './RadonReportResponse';

type StateProps = {
    dateFormat: keyof typeof dateFormats;
    error: boolean;
    userName: string;
};

type ParentProps = {
    serialNumber: string;
    segmentId: string;
    selectedSegment: DeviceType | undefined;
};

type Props = StateProps & ParentProps;

type ReportData = {
    fromDate: string;
    toDate: string;
    duration: number;
    room: string;
    floor: string;
    ventilation: string;
    buildingType?: string;
    buildingYear?: string;
    address: string;
};

type ReportResponse = {
    radonReportId: string;
    radonUnit: string;
    thresholds: number[];
    report: {
        serialNumber: string;
        radonStep1: number;
        radonStep1StdDev: number;
        radonStep1Lld: number;
    };
};

export const RadonReportFormComponent = (props: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { error, dateFormat, selectedSegment, serialNumber, segmentId, userName } = props;
    const minDaysSelection = 7;
    const [reportData, setReportData] = useState<ReportData | undefined>(undefined);
    const [floor, setFloor] = useState('');
    const [address, setAddress] = useState('');
    const [ventilationType, setVentilationType] = useState('');
    const [buildingType, setBuildingType] = useState('');
    const [buildingYear, setBuildingYear] = useState('');
    const [endDate, setEndDate] = useState<Moment | undefined>(undefined);
    const [startDate, setStartDate] = useState<Moment | undefined>(undefined);
    const [room, setRoom] = useState('');
    const [displayValidationStartDate, setDisplayValidationStartDate] = useState(false);
    const [displayValidationEndDate, setDisplayValidationEndDate] = useState(false);
    const [displayValidationFloor, setDisplayValidationFloor] = useState(false);
    const [displayValidationAddress, setDisplayValidationAddress] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [endDateHint, setEndDateHint] = useState(
        txt('RadonReportEndDateHint', { minDays: minDaysSelection.toString() })
    );
    const [reportResponse, setReportResponse] = useState<ReportResponse | undefined>(undefined);
    const [generateReportError, setGenerateReportError] = useState<ErrorType | undefined>(undefined);

    const onModalClose = (): void => {
        setModalOpen(false);
    };

    const onChangeTextArea = (text: string): void => {
        setAddress(text);
        setDisplayValidationAddress(false);
    };

    const onInputChange = (e: SyntheticEvent<HTMLInputElement>): void => {
        const value = e.currentTarget.value.trim();
        setBuildingYear(value);
    };

    const onDropdownChange = (id: string, value: string): void => {
        if (id === 'floor') {
            setFloor(value);
            setDisplayValidationFloor(false);
        } else if (id === 'ventilationType') {
            setVentilationType(value);
        } else if (id === 'room') {
            setRoom(value);
        } else if (id === 'buildingType') {
            setBuildingType(value);
        }
    };

    const onEndDateChange = (date: Moment): void => {
        const isSegmentEndDate =
            date &&
            selectedSegment &&
            selectedSegment.segmentEnd &&
            moment(date).isSame(selectedSegment.segmentEnd, 'day');
        const endOfSelectedDay = date && date.endOf('day');
        const newEndDate =
            isSegmentEndDate && selectedSegment ? moment.utc(selectedSegment.segmentEnd) : endOfSelectedDay;
        setEndDate(newEndDate);
        setDisplayValidationEndDate(false);
        setDisplayValidationStartDate(false);
    };

    const onStartDateChange = (date: Moment): void => {
        const isSegmentStartDate =
            date &&
            selectedSegment &&
            selectedSegment.segmentStart &&
            moment(date).isSame(selectedSegment.segmentStart, 'day');
        const startOfSelectedDay = date && date.startOf('day');
        const newStartDate =
            isSegmentStartDate && selectedSegment ? moment.utc(selectedSegment.segmentStart) : startOfSelectedDay;
        setStartDate(newStartDate);
        setDisplayValidationStartDate(false);
        setDisplayValidationEndDate(false);
    };

    const outsideStartDateRange = (day: Moment): boolean => {
        if (!selectedSegment) return true;
        const segmentEndDate =
            (selectedSegment && selectedSegment.segmentEnd) || moment().subtract(1, 'days').endOf('day');
        if (!moment.isMoment(day)) return false;

        const lessThanSevenDaysBeforeEndDate = moment(day)
            .endOf('day')
            .isAfter(moment(segmentEndDate).subtract(minDaysSelection, 'days').endOf('day'));
        const beforeSegmentStartDate = moment(day)
            .startOf('day')
            .isBefore(moment(selectedSegment.segmentStart).endOf('day'));
        const yearBeforeSelectedEndDate =
            endDate && moment(day).endOf('day').isBefore(moment(endDate).subtract(1, 'years').startOf('day'));
        return lessThanSevenDaysBeforeEndDate || beforeSegmentStartDate || !!yearBeforeSelectedEndDate;
    };

    const outsideEndDateRange = (day: Moment): boolean => {
        if (!selectedSegment) return true;
        const segmentEndDate =
            (selectedSegment && selectedSegment.segmentEnd) || moment().subtract(1, 'days').endOf('day');
        if (!moment.isMoment(day)) return false;

        const afterEndDate = moment(day).endOf('day').isAfter(moment(segmentEndDate).endOf('day'));
        const beforeSegmentStartDate = moment(day)
            .endOf('day')
            .isBefore(moment(selectedSegment.segmentStart).startOf('day'));
        const yearAfterSelectedStartDate =
            startDate && moment(day).endOf('day').isAfter(moment(startDate).add(1, 'years').endOf('day'));
        return afterEndDate || beforeSegmentStartDate || !!yearAfterSelectedStartDate;
    };

    const validateReportInput = (): boolean => {
        const validAddress = address.length > 0;
        const validFloor = floor.length > 0;

        const segmentEndDate =
            selectedSegment && selectedSegment.segmentEnd
                ? moment(selectedSegment.segmentEnd).endOf('day')
                : moment().startOf('day');

        const endDateBeforeSegmentEnd = endDate && endDate.isBefore(segmentEndDate);
        const endDateValid =
            (endDate && !startDate) ||
            (endDate &&
                startDate &&
                endDate.isAfter(moment(startDate).add(minDaysSelection - 1, 'days'), 'day') &&
                endDateBeforeSegmentEnd);

        const startDateValid =
            (!endDate && startDate) || (endDate && startDate && startDate.isBefore(moment(endDate), 'day'));

        setDisplayValidationStartDate(!startDateValid);
        setDisplayValidationEndDate(!endDateValid);
        setDisplayValidationFloor(!validFloor);
        setDisplayValidationAddress(!validAddress);
        setEndDateHint(
            endDateBeforeSegmentEnd
                ? txt('RadonReportEndDateHint', { minDays: minDaysSelection.toString() })
                : txt('ReportEndDateBeforeEndHint')
        );
        return validAddress && validFloor && !!endDateValid && !!startDateValid;
    };

    const getReportValues = useCallback(async (from: string, to: string) => {
        const response = await generateRadonReport({
            serialNumber,
            fromDate: from,
            toDate: to,
            segmentId,
        }).catch((err: ErrorType) => {
            setGenerateReportError(err);
        });

        if (response) {
            setReportResponse(response);
        }
    }, []);

    const generateReport = (e: SyntheticEvent<HTMLButtonElement> | SyntheticEvent<HTMLFormElement>): void => {
        e.preventDefault();
        setReportResponse(undefined);
        setGenerateReportError(undefined);
        const validInput = validateReportInput();
        if (validInput && startDate && endDate) {
            const reportPayload = {
                fromDate: moment(startDate).format(dateFormats[dateFormat].shortFormat),
                toDate: moment(endDate).format(dateFormats[dateFormat].shortFormat),
                duration: moment(endDate.endOf('day')).diff(startDate.startOf('day'), 'day') + 1,
                room,
                floor,
                ventilation: ventilationType,
                buildingType,
                buildingYear,
                address,
            };
            setGenerateReportError(undefined);
            setReportData(reportPayload);
            const from = moment(startDate).format('YYYY-MM-DD');
            const to = moment(endDate).format('YYYY-MM-DD');

            getReportValues(from, to).catch(err => {
                setGenerateReportError(err);
            });

            setModalOpen(true);
            IntercomAPI('trackEvent', 'created-radon-report', {
                serialNumber,
                startDate,
                endDate,
                room,
                floor,
                ventilation: ventilationType,
                buildingType,
                buildingYear,
            });
        }
    };

    const roomTypes = roomTypeOptions.map(selector => ({
        id: selector,
        inputValue: txt(`RoomTypeOptions.${selector}`),
    }));
    const buildingTypes = buildingTypeOptions.map(selector => ({
        id: selector,
        inputValue: txt(`BuildingTypeOptions.${selector}`),
    }));
    const floors = floorOptions.map(selector => ({
        id: selector,
        inputValue: txt(`FloorTypeOptions.${selector}`),
    }));
    const ventilationTypes = ventilationTypeOptions.map(selector => ({
        id: selector,
        inputValue: txt(`VentilationTypeOptions.${selector}`),
    }));

    const displayFormat = dateFormats[dateFormat].calendarFormat;
    const segmentStartDate =
        selectedSegment &&
        selectedSegment.segmentStart &&
        moment.utc(selectedSegment.segmentStart, 'YYYY-MM-DD').local().format(displayFormat);
    const segmentEndDate =
        selectedSegment &&
        selectedSegment.segmentEnd &&
        moment.utc(selectedSegment.segmentEnd, 'YYYY-MM-DD').local().format(displayFormat);

    if (error) {
        return <Error />;
    }

    return (
        <div className="page-wrapper__medium page-wrapper__medium--white">
            <div className="radon-report__header">
                <h3 className="radon-report__header__text radon-report__header__text--no-margin-bottom">
                    {selectedSegment && selectedSegment.segmentEnd
                        ? txt('GenerateReportEndedMeasurement')
                        : txt('GenerateReportForDevice')}{' '}
                    {'\u0022'}
                    {selectedSegment && selectedSegment.segmentName}
                    {'\u0022'}
                </h3>
                <div className="radon-report__header__text radon-report__header__text--small">
                    {selectedSegment && selectedSegment.segmentEnd
                        ? txt('GenerateReportEndedMeasurementWithAttrs', {
                              serialNumber,
                              segmentStartDate: segmentStartDate || '',
                              segmentEndDate: segmentEndDate || '',
                          })
                        : txt('GenerateReportForDeviceWithAttrs', {
                              serialNumber,
                              segmentStartDate: segmentStartDate || '',
                          })}
                </div>
                <p className="radon-report__form__section--text">
                    {txt('RadonReportRequirements')}
                    <a href={howToMeasureRadonLink} target="_blank" rel="noopener noreferrer">
                        {txt('ReadMoreMeasureRadon')}
                    </a>
                </p>
            </div>
            {modalOpen && selectedSegment && reportData && (
                <ReactPdfDownloadModal title="Download" onClose={onModalClose}>
                    {generateReportError ? (
                        <ResponseBox text={`ErrorCodes.${generateReportError.error}`} />
                    ) : (
                        <RadonReportResponse
                            serialNumber={serialNumber}
                            reportData={reportData}
                            segmentName={selectedSegment && selectedSegment.segmentName}
                            deviceType={selectedSegment && selectedSegment.type}
                            unit={reportResponse && (reportResponse.radonUnit as keyof typeof sensorUnits)}
                            data={reportResponse && reportResponse.report}
                            thresholds={reportResponse && reportResponse.thresholds}
                            reportId={reportResponse && reportResponse.radonReportId}
                            dateFormat={dateFormat}
                            userName={userName}
                            loading={false}
                        />
                    )}
                </ReactPdfDownloadModal>
            )}
            <form className="radon-report__form" onSubmit={generateReport}>
                <div className="radon-report__form__section">
                    <Textarea
                        id="address"
                        label="MeasurementAddress"
                        autoComplete="street-address"
                        markedMandatory
                        required
                        maxRows={4}
                        maxLength={120}
                        hint="AddressHint"
                        isValid={false}
                        displayValidationResult={displayValidationAddress}
                        rows={4}
                        onChange={onChangeTextArea}
                    />
                    <div className="radon-report__form__section__row">
                        <DatePicker
                            id="startDate"
                            onChange={onStartDateChange}
                            dateFormat={dateFormat}
                            mandatory
                            displayDateValidation={displayValidationStartDate}
                            label="StartDate"
                            initialVisibleMonth={selectedSegment ? moment(selectedSegment.segmentStart) : moment()}
                            disabledDate={outsideStartDateRange}
                            selectedDate={startDate}
                            selectedDateHint="ReportStartDateHint"
                        />
                        <DatePicker
                            id="endDate"
                            onChange={onEndDateChange}
                            dateFormat={dateFormat}
                            mandatory
                            displayDateValidation={displayValidationEndDate}
                            label="EndDate"
                            initialVisibleMonth={
                                selectedSegment && selectedSegment.segmentEnd
                                    ? moment(selectedSegment.segmentEnd)
                                    : moment()
                            }
                            disabledDate={outsideEndDateRange}
                            selectedDate={endDate}
                            selectedDateHint={endDateHint}
                        />
                    </div>
                    <div className="radon-report__form__section__row radon-report__form__section__row--three">
                        <Dropdown
                            title="Floor"
                            id="selectFloorType"
                            testAttr="select-floor-type"
                            value={floor && txt(`FloorTypeOptions.${floor}`)}
                            defaultOption="Select"
                            options={floors}
                            loading={false}
                            validate={displayValidationFloor}
                            isRequired
                            hint="FloorHint"
                            isValid={!displayValidationFloor}
                            onSelect={({ id }): void => onDropdownChange('floor', id)}
                        />
                        <Dropdown
                            title="Ventilation"
                            id="selectVentilationType"
                            testAttr="select-ventilation-type"
                            value={ventilationType && txt(`VentilationTypeOptions.${ventilationType}`)}
                            defaultOption="Select"
                            options={ventilationTypes}
                            loading={false}
                            validate={false}
                            isRequired
                            isValid
                            onSelect={({ id }): void => onDropdownChange('ventilationType', id)}
                        />
                        <Dropdown
                            title="Room"
                            value={room && txt(`RoomTypeOptions.${room}`)}
                            id="selectRoomType"
                            testAttr="select-room-type"
                            defaultOption="Select"
                            options={roomTypes}
                            isRequired
                            loading={false}
                            validate={false}
                            isValid
                            onSelect={({ id }): void => onDropdownChange('room', id)}
                        />
                    </div>
                    <div className="radon-report__form__section__row">
                        <Dropdown
                            title="BuildingType"
                            id="selectBuildingType"
                            testAttr="select-building-type"
                            value={buildingType && txt(`BuildingTypeOptions.${buildingType}`)}
                            defaultOption="Select"
                            options={buildingTypes}
                            loading={false}
                            validate={false}
                            isValid
                            onSelect={({ id }): void => onDropdownChange('buildingType', id)}
                        />
                        <Input
                            type="text"
                            id="buildingYear"
                            label="BuildingYear"
                            required
                            autoComplete="building-year"
                            maxLength={4}
                            hint="BuildingYearHint"
                            isValid={false}
                            validate={false}
                            onChange={onInputChange}
                        />
                    </div>
                </div>
                <div className="radon-report__form__section__row radon-report__form__section__row--buttons">
                    <PrimaryButton
                        type="submit"
                        id="generateRadonReportButton"
                        title="GenerateReport"
                        loading={false}
                        onClick={generateReport}
                        color="primary"
                        testId="submit"
                    />
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat, userName },
        locations: { error: locationError },
        app: { error: appError },
    } = state;
    return {
        dateFormat,
        error: locationError || appError,
        userName,
    };
};

export default connect(mapStateToProps)(RadonReportFormComponent);
