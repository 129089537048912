import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { BUILDING_PREVIEWED_EMAIL } from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import Input from 'commons/src/components/input/Input';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import EmailSummaryPreviewModal from './preview/EmailSummaryPreviewModal';

type Props = {
    emailSubject: string;
    onNext: () => void;
    onUpdate: (name: string) => void;
    showButton: boolean;
};

const EmailSummaryNamingSectionComponent = ({
    emailSubject,
    showButton,
    onNext,
    onUpdate,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const [displayNameValidation, setDisplayNameValidation] = useState(false);
    const [previewModalOpen, setPreviewModalOpen] = useState<boolean>(false);

    const hasInvalidFields = emailSubject.trim().length === 0;

    const goToNextSection = (): void => {
        if (!hasInvalidFields) onNext();
        else setDisplayNameValidation(true);
    };

    const openPreviewModal = (): void => {
        setPreviewModalOpen(true);
        analyticsLogger(BUILDING_PREVIEWED_EMAIL, { pageType: PageType.Building });
    };

    return (
        <div className="settings-details-container">
            <div className="form small-padding-bottom">
                <p className="text-medium text-paragraph">{txt('EmailSummary.ConceptDescription')}</p>
                <p className="text-medium text-paragraph margin-20-bottom">{txt('EmailSummary.EmailSchedule')}</p>
                {previewModalOpen && <EmailSummaryPreviewModal onClose={(): void => setPreviewModalOpen(false)} />}
                <i
                    tabIndex={0}
                    role="button"
                    onClick={openPreviewModal}
                    onKeyUp={(e): void | boolean => e.key === 'Enter' && setPreviewModalOpen(!previewModalOpen)}
                    className="flex flex--align-center hover"
                >
                    <MaterialIcon name="visibility" extraClass="small-padding-right" />
                    <span className="text-medium text-paragraph">{txt('EmailSummary.PreviewEmail')}</span>
                </i>
                <div className="margin-20-bottom" />
                <div className="form__field form__field--single-width settings__row">
                    <Input
                        type="text"
                        id="email-subject"
                        validate={displayNameValidation && hasInvalidFields}
                        isValid={!hasInvalidFields}
                        hint="EmailSummary.SubjectHint"
                        label="Name"
                        maxLength={255}
                        currentValue={emailSubject}
                        onChange={(e: SyntheticEvent<HTMLInputElement>): void => onUpdate(e.currentTarget.value)}
                    />
                </div>
                <div className="form__button-container form__button-container--no-padding">
                    {showButton && (
                        <PrimaryButton
                            id="submit"
                            type="button"
                            title="Next"
                            color="primary"
                            disabled={hasInvalidFields}
                            onClick={goToNextSection}
                            testId="exit-naming-section"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default EmailSummaryNamingSectionComponent;
