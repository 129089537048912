import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import { SPACE_ADDED_DEVICE } from 'commons/src/analytics/AnalyticsEvents';
import { getDeviceTypeFromPrefix } from 'commons/src/commonFunctions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import Input from 'commons/src/components/input/Input';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import { addDeviceToSpace } from '../../../../actions/spaceActions';
import { AddDevicePayload } from '../../../../models/spaceModels';
import { Store } from '../../../../reducers';
import { DeviceFormState, Touched } from './models';
import styles from './SpaceAddDeviceForm.module.scss';
import useValidateDevice from './useValidateDevice';

type AddDeviceFormProps = {
    spaceId: string;
    buildingId: string;
    onSuccess?: () => void;
    onError?: () => void;
};

const defaultState: DeviceFormState = {
    serialNumber: '',
    checkCode: '',
};

const defaultTouched: Touched = {
    serialNumber: false,
    checkCode: false,
};

const SpaceAddDeviceForm = ({ spaceId, buildingId, onSuccess, onError }: AddDeviceFormProps): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    const [isAddingInProgress, setIsAddingInProgress] = useState(false);
    const [values, setValues] = useState<DeviceFormState>(defaultState);
    const [touched, setTouched] = useState<Touched>(defaultTouched);

    const request = useSelector((state: Store) => state.requests.ADD_DEVICE_TO_SPACE);

    const {
        verifyDevice,
        isDeviceVerified,
        isVerificationInProgress,
        isSerialNumberValid,
        isCheckCodeValid,
        error,
        serialNumberHint,
        checkCodeHint,
    } = useValidateDevice(values);

    const cleanForm = (): void => {
        setValues(defaultState);
        setTouched(defaultTouched);
        setIsAddingInProgress(false);
    };

    useEffect(() => {
        cleanForm();
    }, [spaceId, buildingId]);

    useEffect(() => {
        if (isAddingInProgress && !request.loading) {
            if (request.error) onError?.();
            else if (onSuccess) onSuccess();
            cleanForm();
        }
    }, [isAddingInProgress, request]);

    useEffect(() => {
        verifyDevice();
    }, [values.checkCode, values.serialNumber]);

    const onBlur = (key: string) => (): void => setTouched(current => ({ ...current, [key]: true }));

    const onChangeCheckCode = (e: React.SyntheticEvent<HTMLInputElement>): void => {
        const newCheckCode = e.currentTarget.value.trim().toUpperCase();
        setValues(current => ({ ...current, checkCode: newCheckCode }));
    };

    const onChangeSerialNumber = (e: React.SyntheticEvent<HTMLInputElement>): void => {
        const newSerialNumber = e.currentTarget.value.trim();
        setValues(current => ({ ...current, serialNumber: newSerialNumber }));
    };

    const onSubmit = (e?: SyntheticEvent<HTMLElement>): void => {
        e?.preventDefault();
        setTouched({ serialNumber: true, checkCode: true });

        if (isDeviceVerified && isSerialNumberValid() && isCheckCodeValid()) {
            const payload: AddDevicePayload = {
                serialNumber: values.serialNumber,
                checkCode: values.checkCode,
            };
            setIsAddingInProgress(true);
            dispatch(addDeviceToSpace(buildingId, spaceId, payload));
            analyticsLogger(SPACE_ADDED_DEVICE, {
                buildingId,
                spaceId,
                payload,
            });
        }
    };

    return (
        <form onSubmit={onSubmit} className={styles.formWrapper}>
            <fieldset className={styles.formFieldset} disabled={isAddingInProgress || isVerificationInProgress}>
                <div className={styles.inputWrapper}>
                    <Input
                        type="text"
                        id="serialNumber"
                        label="SerialNumber"
                        isValid={isSerialNumberValid()}
                        validate={touched.serialNumber}
                        maxLength={10}
                        hint={serialNumberHint}
                        onChange={onChangeSerialNumber}
                        onBlur={onBlur('serialNumber')}
                        currentValue={values.serialNumber}
                        testId="add-device-serialnumber"
                        infoText={txt('SerialNumberHint')}
                    />
                </div>
                <div className={styles.inputWrapper}>
                    <Input
                        type="text"
                        id="checkCode"
                        label="CheckCode"
                        hint={checkCodeHint}
                        isValid={isCheckCodeValid()}
                        validate={touched.checkCode}
                        maxLength={7}
                        onChange={onChangeCheckCode}
                        onBlur={onBlur('checkCode')}
                        currentValue={values.checkCode}
                        testId="add-device-check-code"
                        infoText={txt('CheckCodeHint')}
                    />
                </div>
                <div className={styles.addButton}>
                    <PrimaryButton
                        onClick={(): void => undefined}
                        color="primary"
                        title="Add"
                        type="submit"
                        id="AddDevice"
                        disabled={!!error || isAddingInProgress}
                        loading={isAddingInProgress || isVerificationInProgress}
                    />
                </div>
            </fieldset>
            {error && <ResponseBox text={`ErrorCodes.${error.error}`} />}
            {getDeviceTypeFromPrefix(values.serialNumber) === DeviceTypeNames.hub && (
                <div className={styles.notice}>{txt('AddDevice.HubFirmwareUpgrade')}</div>
            )}
        </form>
    );
};

export default SpaceAddDeviceForm;
