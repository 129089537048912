import React, { ReactElement, useRef, useState } from 'react';
import L, { latLngBounds, LatLngExpression } from 'leaflet';
import { MapContainer, ZoomControl } from 'react-leaflet';
import { useSelector } from 'react-redux';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import DropdownList from 'commons/src/components/dropdown/DropdownList';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { PlacementType } from 'commons/src/models/commonEnums';
import { FloorPlanData, SpaceFloorPlanMode } from '../../../models/spaceFloorPlanModels';
import { Store } from '../../../reducers';
import FloorMapImage from '../../floorplan/floorMap/FloorMapImage';
import { spacesSelector } from '../../spaces/space/spaceSelectors';
import FloorEditor from './FloorEditor';
import styles from './FloorMap.module.scss';
import SpaceZone from './SpaceZone';

type Props = {
    floorData: FloorPlanData;
    mode?: SpaceFloorPlanMode;
    locationId: string;
    spaceZone?: number[][] | LatLngExpression[][];
    setSpaceZone: (zone: number[][] | LatLngExpression[][]) => void;
    selectedSpace: string | undefined;
    setSelectedSpace: (spaceId?: string) => void;
    fullscreenMode: boolean;
    toggleFullScreenMode: () => void;
};
const FloorMap = ({
    floorData,
    mode,
    locationId,
    setSelectedSpace,
    setSpaceZone,
    spaceZone,
    selectedSpace,
    fullscreenMode,
    toggleFullScreenMode,
}: Props): ReactElement => {
    const [bounds, setBounds] = useState<L.LatLngBounds>(
        latLngBounds([
            [0, 0],
            [1000, 1000],
        ])
    );

    const [mapIsReady, setMapIsReady] = useState(false);
    const floorPlanEditorRef = useRef<{ zone: L.Polygon; marker: L.Marker }>();
    const {
        spaces: { spaces },
    } = useSelector((store: Store) => spacesSelector(store, locationId));

    const updateBounds = (): void => {
        const displayedImage = new Image();
        displayedImage.onload = (): void => {
            const { width, height } = displayedImage;
            const newBounds = latLngBounds([
                [0, width],
                [-height, 0],
            ]);
            setBounds(newBounds);
        };
        displayedImage.src = floorData.image;
    };

    const onMapReady = (): void => {
        updateBounds();
        setMapIsReady(true);
    };

    const spaceList = (): ReactElement => {
        const spacesAlreadyMapped = floorData.spaces.map(space => space.id);
        const options = spaces
            .filter(space => !spacesAlreadyMapped.includes(space.id) && space.placement === PlacementType.ROOM)
            .map(space => ({ id: space.id, text: space.name }));

        return (
            <div className={styles.spaceList}>
                <DropdownList
                    onSelect={setSelectedSpace}
                    options={options}
                    title="SpaceFloorPlan.SelectSpace"
                    id="space-selector"
                />
            </div>
        );
    };

    return (
        <div className={styles.mapWrapper}>
            {mode === SpaceFloorPlanMode.CREATE && spaceZone && !selectedSpace && spaceList()}
            <MapContainer
                zoomControl={false}
                scrollWheelZoom={false}
                crs={L.CRS.Simple}
                bounds={bounds}
                className={styles.container}
                zoomDelta={0.2}
                zoomSnap={0.1}
                minZoom={-4}
                whenReady={onMapReady}
            >
                <div className={styles.fullscreenButton}>
                    <TertiaryButton
                        noColor
                        onClick={toggleFullScreenMode}
                        icon={<MaterialIcon name={fullscreenMode ? 'fullscreen_exit' : 'fullscreen'} />}
                    />
                </div>
                <FloorMapImage
                    bounds={bounds}
                    floorPlanImage={floorData.image}
                    mapReady={mapIsReady}
                    onMapReady={onMapReady}
                />
                {!!mode && (
                    <FloorEditor
                        selectedSpace={selectedSpace}
                        onZoneUpdated={setSpaceZone}
                        innerRef={floorPlanEditorRef}
                        spaceZone={spaceZone}
                    />
                )}
                {floorData.spaces.map(space => (
                    <SpaceZone
                        mode={mode}
                        spaceZone={space}
                        key={`space-zone-${space.id}`}
                        selectedSpace={selectedSpace}
                        onClick={setSelectedSpace}
                    />
                ))}
                <ZoomControl position="bottomright" />
            </MapContainer>
        </div>
    );
};

export default FloorMap;
