import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AddFloorplan, addFloorplan } from 'commons/src/actions/FloorPlanActions';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { BUILDING_ADDED_FLOORPLAN } from 'commons/src/analytics/AnalyticsEvents';
import CircleButton from 'commons/src/components/buttons/CircleButton';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import PageHeader from 'commons/src/components/headers/PageHeader';
import SubHeader from 'commons/src/components/headers/SubHeader';
import Input from 'commons/src/components/input/Input';
import { IntercomAPI } from 'commons/src/components/Intercom';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { RequiredRoleLevel } from 'commons/src/models/commonEnums';
import { BuildingType } from 'commons/src/models/commonTypeScript';
import { ActionButton } from 'commons/src/models/menuModels';
import { SetFloorplanModalVisible, setFloorplanModalVisible } from '../../../actions/floorplanActions';
import { Store } from '../../../reducers';
import { FloorplanError, FloorplanErrors } from '../../../reducers/Floorplans';

type ParentProps = {
    building: BuildingType;
};
type StateProps = {
    error: FloorplanError | string;
    adding: boolean;
};
type ActionProps = {
    onAddFloorplan: (locationId: string, floorplanName: string, floorplanImage: string) => void;
    setModalVisible: (visible: boolean) => void;
};

type Props = StateProps & ActionProps & ParentProps;

export const AddFloorPlan = (props: Props): React.ReactElement => {
    const { adding, error, building, onAddFloorplan, setModalVisible } = props;

    const imageMaxSize = 2000000;
    const [floorplanName, setFloorplanName] = useState('');
    const [floorplanImage, setFloorplanImage] = useState('');
    const [submitPressed, setSubmitPressed] = useState(false);
    const [imageSizeTooLarge, setImageSizeTooLarge] = useState(false);
    const [invalidFileType, setInvalidFileType] = useState(false);
    const [fileName, setFileName] = useState('');
    const { t: txt } = useTranslation();

    const handleNameChange = ({ currentTarget }: SyntheticEvent<HTMLInputElement>): void => {
        setFloorplanName(currentTarget.value.trim());
    };

    const handleImageChange = ({ currentTarget }: SyntheticEvent<HTMLInputElement>): void => {
        const file = currentTarget.files && currentTarget.files.length > 0 && currentTarget.files[0];
        setInvalidFileType(false);
        setImageSizeTooLarge(false);
        if (!file) return;
        if (!file.type.includes('image')) {
            setInvalidFileType(true);
        } else if (file.size > imageMaxSize) {
            setImageSizeTooLarge(true);
        }

        // eslint-disable-next-line no-undef
        const reader = new FileReader();
        reader.onloadend = (): void => {
            const base64Image: string = reader.result as string;
            setFloorplanImage(base64Image);
            setFileName(file.name);
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = (): void => {
        setSubmitPressed(true);
        const valid = floorplanName.length > 0 && floorplanImage.length > 0 && !imageSizeTooLarge && !invalidFileType;
        if (!valid) return;
        IntercomAPI('trackEvent', 'upload-floorplan', {
            locationId: building.id,
            floorplanName,
            fileName,
        });
        onAddFloorplan(building.id, floorplanName, floorplanImage);
        analyticsLogger(BUILDING_ADDED_FLOORPLAN, { pageType: PageType.Building });
    };

    const errorMessage = (): React.ReactElement | null => {
        if (error !== FloorplanErrors.ADD_FLOORPLAN_ERROR) return null;
        return <p className="form__response-message form__response-message--failed">{txt('SomethingWentWrong')}</p>;
    };
    const onClose = (): void => {
        setModalVisible(false);
    };

    const actionButtons: ActionButton[] = [
        {
            testAttr: 'close-add-floorplan',
            id: 'closeAddFloorPlan',
            title: 'Close',
            color: 'secondary',
            onClick: onClose,
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            requiredGroupTypes: [],
        },
    ];

    const clearFloorFile = (): void => {
        setFileName('');
        setFloorplanImage('');
        setImageSizeTooLarge(false);
    };

    const triggerFileUpload = (): void => {
        const fileLoader = document.getElementById('floorFileUploader');
        if (fileLoader) {
            fileLoader.click();
        }
    };
    useEffect(() => {
        document.body.className = 'blue-body';
        return (): void => {
            document.body.className = '';
        };
    });

    const invalidFileMessage = (): string => {
        if (submitPressed && !(floorplanImage.length > 0)) {
            return txt('FloorPlan.FloorPlanFileHint');
        }
        if (invalidFileType) {
            return txt('FloorPlan.InvalidFloorPlanFileType');
        }
        if (imageSizeTooLarge) {
            return txt('ImageTooLargeMaxSize', { size: `${imageMaxSize / 1000000}MB` });
        }
        return '';
    };

    return (
        <>
            <PageHeader title={txt('FloorPlan.AddFloorplan')} />
            <div className="page-wrapper__medium">
                <SubHeader actionButtons={actionButtons} />
            </div>
            <div className="page-wrapper__medium page-wrapper__medium--white form">
                <h2 className="form__header">{txt('FloorPlan.AddFloorplan')}</h2>
                <div className="page-wrapper__inner page-wrapper__inner--slim">
                    <form onSubmit={handleSubmit}>
                        <div
                            className="form__attr form__attr--edit-image"
                            role="button"
                            tabIndex={0}
                            onClick={triggerFileUpload}
                            onKeyUp={(e): void => {
                                if (e.key === 'Enter') triggerFileUpload();
                            }}
                        >
                            <div id="editImage" className="form__attr form__attr--image form__attr--image--large">
                                {floorplanImage ? (
                                    <img src={floorplanImage} alt={txt('BuildingImage')} />
                                ) : (
                                    <div className="form__attr--image__placeholder">
                                        <MaterialIcon extraClass="building-tile__image__icon" name="photo" />
                                        {txt('FloorPlan.ClickToUploadFloorPlan')}
                                        <br />
                                        {txt('FloorPlan.AcceptedFloorPlanFormat')}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="form__container">
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                id="floorFileUploader"
                                onChange={handleImageChange}
                                accept="image/jpeg,image/png"
                            />
                            <div className="input-container input-container--margin-bottom">
                                <div className="add-floor__input-upload">
                                    <CircleButton
                                        onClick={triggerFileUpload}
                                        testAttr="file-upload"
                                        iconName="cloud_upload"
                                        color="secondary"
                                    />
                                    <span className="add-floor__file-name">{fileName || txt('NoFileChosen')}</span>
                                    <button className="add-floor__remove-upload" type="button" onClick={clearFloorFile}>
                                        <MaterialIcon name="close" />
                                    </button>
                                </div>
                                <p className="form__response-message form__response-message--failed">
                                    {invalidFileMessage()}
                                </p>
                            </div>
                            <Input
                                type="text"
                                id="floorname"
                                label="Name"
                                isValid={floorplanName.length > 0}
                                onChange={handleNameChange}
                                validate={submitPressed && floorplanName.length < 1}
                                hint="FloorPlan.FloorPlanNameHint"
                                maxLength={50}
                            />
                            <div className="form__button-container">
                                <div className="add-floor__actions">
                                    <PrimaryButton
                                        color="secondary"
                                        type="button"
                                        title="Close"
                                        disabled={adding}
                                        onClick={onClose}
                                        testId="cancel-button"
                                    />
                                    <PrimaryButton
                                        color="primary"
                                        type="button"
                                        title="Submit"
                                        loading={adding}
                                        onClick={handleSubmit}
                                        testId="submit-floorplan"
                                    />
                                </div>
                            </div>
                            <div>{errorMessage()}</div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        floorplans: { adding, error },
    } = state;
    return { adding, error };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onAddFloorplan: (locationId: string, floorplanName: string, floorplanImage: string): AddFloorplan =>
        dispatch(addFloorplan(locationId, floorplanName, floorplanImage)),
    setModalVisible: (visible: boolean): SetFloorplanModalVisible => dispatch(setFloorplanModalVisible(visible)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddFloorPlan);
