import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { hubConnectionIsUp, lastSyncedDate } from 'commons/src/commonFunctions';
import AccordionButton from 'commons/src/components/buttons/AccordionButton';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import DeviceStatusPill from 'commons/src/components/pills/DeviceStatusPill';
import { dateFormats, paths } from 'commons/src/constants';
import { getHubConnectionString } from 'commons/src/features/hubs/hubFunctions';
import Ethernet from 'commons/src/img/icon-ethernet';
import SmartLinkIcon from 'commons/src/img/icon-smartlink';
import DeviceHealthStatus from 'commons/src/models/deviceHealthStatus';
import { SpaceHub } from '../../../../models/spaceModels';
import { Store } from '../../../../reducers';
import styles from './HubDetails.module.scss';
import HubDeviceElement from './HubDeviceElement';
import HubOptions from './HubOptions';

export type ParentProps = {
    hub: SpaceHub | undefined;
    locationId: string;
};

export const HubDetailsComponent = ({ hub, locationId }: ParentProps): React.ReactElement => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { t: txt } = useTranslation();
    const dateFormat: keyof typeof dateFormats = useSelector((state: Store) => state.userSettings.dateFormat);
    const navigate = useNavigate();

    if (!hub) {
        return <div />;
    }

    const lastSeen = hub.metaData?.lastSeen;
    const hubRecentlySynced = lastSeen && hubConnectionIsUp(lastSeen);
    const syncedTime = lastSeen ? lastSyncedDate(lastSeen, dateFormat as keyof typeof dateFormats) : txt('neverSynced');

    const toggleDropdown = (): void => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const hubConnectionType = getHubConnectionString(hub.metaData, txt);

    const healthStatus = (): React.ReactElement => {
        switch (hub.healthStatus as DeviceHealthStatus) {
            case DeviceHealthStatus.offline:
                return (
                    <div className={styles.deviceStatus}>
                        <div className={styles.alert}>
                            {txt('LastSyncedNoSemicolon')}
                            {lastSeen && !hubRecentlySynced && syncedTime}
                        </div>
                        <DeviceStatusPill status={hub.healthStatus as DeviceHealthStatus} deviceType="hub" />
                        <div className={styles.offlineDetails}>
                            {hub.metaData.cell ? (
                                <MaterialIcon extraClass="icon-rotated" name="sim_card" />
                            ) : (
                                <Ethernet />
                            )}
                            <span>{hubConnectionType}</span>
                            <HubOptions hub={hub} locationId={locationId} />
                        </div>
                    </div>
                );

            case DeviceHealthStatus.online:
                return (
                    <div className={styles.deviceStatus}>
                        <div className={styles.iconAndText}>
                            <MaterialIcon name="cloud" />
                            <span>{txt('StatusPill.DeviceOnline')}</span>
                        </div>
                        <div className={styles.iconAndText}>
                            {hub.metaData.cell ? (
                                <MaterialIcon extraClass="icon-rotated" name="sim_card" />
                            ) : (
                                <Ethernet />
                            )}
                            <span>{hubConnectionType}</span>
                        </div>
                        <HubOptions hub={hub} locationId={locationId} />
                    </div>
                );
            case DeviceHealthStatus.notSynced:
                return (
                    <div className={styles.deviceStatus}>
                        <DeviceStatusPill status={hub.healthStatus as DeviceHealthStatus} deviceType="hub" />
                        <HubOptions hub={hub} locationId={locationId} />
                    </div>
                );
            default:
                return <div />;
        }
    };
    const navToHub = (serialNumber: string): void => {
        navigate(`/${paths.hub}/${serialNumber}`);
    };

    const hubDevices = hub.metaData?.devices || {};
    const deviceSerialNumbers = Object.keys(hubDevices).map(device => device);
    const numberOfConnectedDevices = deviceSerialNumbers.length;

    return (
        <div className={styles.infoHeader}>
            <div className={styles.topSection}>
                <div className={styles.deviceInfo}>
                    <PrimaryButton
                        color="secondary"
                        icon={<SmartLinkIcon />}
                        key={hub.serialNumber}
                        translate={false}
                        title={hub.segmentName}
                        onClick={(): void => navToHub(hub.serialNumber)}
                        testId="nav-to-hub"
                    />
                    <div className={styles.serialNumber}>{hub.serialNumber}</div>
                    {hub.healthStatus === DeviceHealthStatus.online &&
                        (numberOfConnectedDevices > 0 ? (
                            <AccordionButton
                                onClick={toggleDropdown}
                                expanded={isDropdownOpen}
                                slim
                                translated
                                id="buttondropdown"
                                title={txt('HubStrings.DevicesConnected', {
                                    nrOfConnectedDevices: numberOfConnectedDevices,
                                })}
                            />
                        ) : (
                            <div className={styles.noDevicesConnected}>
                                {txt('HubStrings.DevicesConnected', {
                                    nrOfConnectedDevices: numberOfConnectedDevices,
                                })}
                            </div>
                        ))}
                </div>
                {healthStatus()}
            </div>
            {isDropdownOpen && (
                <div className={styles.deviceList}>
                    {deviceSerialNumbers.map(serialNumber => (
                        <HubDeviceElement
                            key={serialNumber}
                            device={hubDevices[serialNumber]}
                            serialNumber={serialNumber}
                            currentSpaceId={hub.spaceId}
                            locationId={locationId}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default HubDetailsComponent;
