import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import { SPACE_CREATED_SPACE } from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import ModalWrapper from 'commons/src/components/modals/ModalWrapper';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { PlacementType } from 'commons/src/models/commonEnums';
import { SpaceProperty } from 'commons/src/models/commonTypeScript';
import { createSpace } from '../../../actions/spaceActions';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import { RootState } from '../../../store';
import SpacePropertyEditMain from '../properties/SpacePropertyEditMain';
import styles from './SpaceAddModal.module.scss';
import SpaceSettingsForm, { FormActions } from './SpaceSettingsForm';

export type Props = {
    isOpen: boolean;
    onClose: () => void;
    locationId: string;
};

const SpaceAddModal = ({ isOpen, onClose, locationId }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const formRef = useRef<FormActions>(null);

    const { loading, error } = useSelector((state: RootState) => state.requests[RequestType.CreateSpace]);
    const [isLoading, setIsLoading] = useState(false);
    const [addAnother, setAddAnother] = useState(false);

    const [selectedProperty, setSelectedProperty] = useState<{ id: string; data: SpaceProperty } | undefined>(
        undefined
    );

    const spacePropDefs: { [p: string]: SpaceProperty } = useSelector((state: Store) => state.config.spacePropDefs);

    useEffect(() => {
        const property: [string, SpaceProperty] | undefined = Object.entries(spacePropDefs).find(
            ([key]) => key === selectedProperty?.id
        );
        setSelectedProperty(property ? { id: property[0], data: property[1] } : undefined);
    }, [spacePropDefs]);

    useEffect(() => {
        if (!loading && isLoading) {
            setIsLoading(false);
            setAddAnother(false);
            if (!error) {
                formRef.current?.Clear();
                if (!addAnother) {
                    onClose();
                }
            }
        }
    }, [loading]);

    const onSave = (redirectToSpace: boolean): void => {
        if (formRef.current && formRef.current.IsValid()) {
            const { name, properties } = formRef.current.Values();
            const payload = {
                name,
                placement: PlacementType.ROOM,
                properties,
            };
            dispatch(createSpace(payload, locationId, redirectToSpace));
            setIsLoading(true);
            analyticsLogger(SPACE_CREATED_SPACE, { payload, locationId });
        }
    };

    const saveAndAddAnother = (): void => {
        setAddAnother(true);
        onSave(false);
    };

    const editProperty = (propertyId: string): void => {
        const property: [string, SpaceProperty] | undefined = Object.entries(spacePropDefs).find(
            ([key]) => key === propertyId
        );
        setSelectedProperty(property ? { id: property[0], data: property[1] } : undefined);
    };

    return (
        <ModalWrapper
            isOpen={isOpen}
            onClose={onClose}
            header={selectedProperty ? undefined : 'AddSpace.AddSpace'}
            size="medium"
        >
            {selectedProperty ? (
                <SpacePropertyEditMain
                    backToSpace={(): void => setSelectedProperty(undefined)}
                    property={selectedProperty}
                    allPropertyNames={Object.values(spacePropDefs).map(it => it.propertyName)}
                />
            ) : (
                <div className={styles.body} data-add-space-modal>
                    <p>{txt('AddSpace.AddSpaceDescription')}</p>
                    <SpaceSettingsForm ref={formRef} locationId={locationId} editProperty={editProperty} />
                    <div className={styles.buttonWrapper}>
                        <PrimaryButton
                            color="secondary"
                            onClick={saveAndAddAnother}
                            title="AddSpace.SaveAndAddAnother"
                            loading={isLoading && addAnother}
                        />
                        <PrimaryButton
                            onClick={(): void => onSave(true)}
                            title="Save"
                            id="CreateSpaceButton"
                            color="primary"
                            loading={isLoading && !addAnother}
                        />
                    </div>
                    {error && <ResponseBox text={`ErrorCodes.${error.error}`} />}
                </div>
            )}
        </ModalWrapper>
    );
};
export default SpaceAddModal;
