import React, { RefObject, SyntheticEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import checkmark from './Checkmark';
import InputLabel from './InputLabel';

export type Props = {
    label?: string;
    type: string;
    id: string;
    required?: boolean;
    hint?: string;
    validate: boolean;
    isValid?: boolean;
    onChange?: (e: SyntheticEvent<HTMLInputElement>) => void;
    onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void;
    onKeyUp?: (e: React.KeyboardEvent) => void;
    autoComplete?: string;
    defaultValue?: string;
    maxLength?: number;
    markedMandatory?: boolean;
    infoText?: string;
    testAttr?: string;
    currentValue?: string;
    accept?: string;
    placeholder?: string;
    infoTextTranslated?: boolean;
    testId?: string;
    disabled?: boolean;
    innerRef?: RefObject<null>;
    className?: string;
    autoFocus?: boolean;
    noBottomMargin?: boolean;
    hideLabel?: boolean;
    size?: 'small' | 'medium';
};

const Input = (props: Props): React.ReactElement => {
    const {
        label,
        type,
        id,
        required = false,
        hint,
        validate,
        isValid = false,
        onChange,
        onBlur,
        onKeyUp,
        autoComplete,
        defaultValue,
        maxLength,
        markedMandatory,
        infoText,
        testAttr,
        currentValue,
        accept,
        placeholder,
        infoTextTranslated,
        testId,
        disabled = false,
        innerRef,
        className,
        autoFocus,
        noBottomMargin,
        hideLabel,
        size,
    } = props;
    const { t: txt } = useTranslation();
    return (
        <div className={classNames('input-container', className)}>
            {hideLabel !== true && (
                <InputLabel
                    label={label}
                    mandatory={markedMandatory}
                    infoText={infoText}
                    infoTextTranslated={infoTextTranslated}
                    htmlFor={id}
                />
            )}
            <input
                className={classNames('form__attr form__attr--input', {
                    'input--validated': validate,
                    'input--invalid': !isValid && validate,
                    'input--no-bottom-margin': noBottomMargin === true,
                    'form__attr--input--bottom-margin': validate && !isValid && !!hint,
                    'dropdown__list--disabled': disabled,
                    'form__attr--input--small': size === 'small',
                })}
                type={type}
                id={id}
                required={required}
                onChange={onChange}
                onBlur={onBlur}
                onKeyUp={onKeyUp}
                placeholder={placeholder}
                autoComplete={autoComplete}
                defaultValue={defaultValue}
                maxLength={maxLength}
                {...(testAttr !== undefined ? { [`data-${testAttr}`]: true } : {})}
                value={currentValue}
                accept={accept}
                disabled={disabled}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={autoFocus}
                data-form-type="other" // preventing Dashlane from trying to auto fill
                {...{ 'data-testid': testId }}
                ref={innerRef}
            />
            {validate && !isValid && hint && <div className="input-container__error">{hint && txt(hint)}</div>}
            {validate && isValid && checkmark}
        </div>
    );
};

Input.defaultProps = {
    infoTextTranslated: false,
};

export default Input;
