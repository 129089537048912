import { Rating, SensorTypes } from 'commons/src/models/commonEnums';
import { ThresholdRange } from 'commons/src/models/commonTypeScript';

const filteredRanges = (rangesForSensor: ThresholdRange[], sensor: SensorTypes): ThresholdRange[] => {
    return (rangesForSensor || []).filter(
        range => range.rating !== Rating.GOOD && !(sensor === SensorTypes.humidity && range.rating === Rating.FAIR)
    );
};

// eslint-disable-next-line import/prefer-default-export
export const getThresholdRanges = (
    rangesForSensor: ThresholdRange[],
    sensor: SensorTypes
): { modifiedRanges: ThresholdRange[]; lowest: number; highest: number } => {
    const ranges = filteredRanges(rangesForSensor, sensor);

    const allValues = ranges.flatMap(range => [range.from, range.to]).filter(value => value !== undefined) as number[];
    const lowest = Math.min(...allValues);
    const highest = Math.max(...allValues);

    return {
        modifiedRanges: ranges,
        lowest,
        highest,
    };
};
