import React from 'react';
import { t as txt } from 'i18next';
import { PillStatus } from '../../models/commonEnums';
import DeviceHealthStatus from '../../models/deviceHealthStatus';
import StatusPill from './StatusPill';

type Props = {
    status: DeviceHealthStatus;
    deviceType: 'hub' | 'device';
};

const renderHubPill = (status: DeviceHealthStatus): React.ReactElement => {
    switch (status) {
        case DeviceHealthStatus.offline:
            return (
                <StatusPill text={txt('StatusPill.HubOffline')} status={PillStatus.alert} icon="cloud_off" outline />
            );
        case DeviceHealthStatus.online:
            return <StatusPill text={txt('StatusPill.HubOnline')} status={PillStatus.success} icon="cloud" outline />;
        case DeviceHealthStatus.notSynced:
            return (
                <StatusPill
                    text={txt('StatusPill.HubNotSynced')}
                    status={PillStatus.warning}
                    icon="sync_problem"
                    outline
                />
            );
        default:
            return <div />;
    }
};

const renderDevicePill = (status: DeviceHealthStatus): React.ReactElement => {
    switch (status) {
        case DeviceHealthStatus.offline:
            return (
                <StatusPill text={txt('StatusPill.DeviceOffline')} status={PillStatus.alert} icon="wifi_off" outline />
            );
        case DeviceHealthStatus.online:
            return <StatusPill text={txt('StatusPill.DeviceOnline')} status={PillStatus.success} icon="wifi" outline />;
        case DeviceHealthStatus.lowBattery:
            return (
                <StatusPill
                    text={txt('StatusPill.DeviceLowBattery')}
                    status={PillStatus.alert}
                    icon="battery_low"
                    outline
                    rotate={180}
                />
            );
        case DeviceHealthStatus.notSynced:
            return (
                <StatusPill
                    text={txt('StatusPill.DeviceNotSynced')}
                    status={PillStatus.warning}
                    icon="sync_problem"
                    outline
                />
            );
        default:
            return <div />;
    }
};

const DeviceStatusPill = ({ status, deviceType }: Props): React.ReactElement => {
    if (deviceType === 'hub') {
        return renderHubPill(status);
    }
    return renderDevicePill(status);
};

export default DeviceStatusPill;
