import { generatePath } from 'react-router-dom';
import fetch from 'commons/src/api/fetch';
import getFetchHeaders from 'commons/src/api/getFetchHeaders';
import { BUILDING_HEALTH_TABLE_LENGTH, ORGANIZATION_HEALTH_TABLE_LENGTH } from '../constants';
import {
    OrganizationBuildingOverview,
    OrganizationBuildingResponse,
    OrganizationHealthApiResponse,
    OrganizationHealthResponse,
} from '../models/organizationHealthModels';

const convertApiResponseToOrganizationSummary = (
    apiResponse: OrganizationHealthApiResponse
): OrganizationBuildingOverview => ({
    locationId: apiResponse.locationId,
    locationName: apiResponse.locationName,
    hubStats: {
        total: apiResponse.totalHubs,
        offline: apiResponse.offlineHubs,
    },
    deviceStats: {
        total: apiResponse.totalDevices,
        offline: apiResponse.offlineDevices,
        lowBattery: apiResponse.lowBatteryDevices,
    },
});

export const getOrganizationHealth = async (
    limit: number = ORGANIZATION_HEALTH_TABLE_LENGTH,
    offset = 0
): Promise<OrganizationHealthResponse> => {
    const queryString = new URLSearchParams({ limit: String(limit), offset: String(offset) });
    const url = `/health-status?${queryString.toString()}`;
    const { totalBuildings, buildings } = await fetch<{
        totalBuildings: number;
        buildings: OrganizationHealthApiResponse[];
    }>(url, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
    const convertedBuildings = buildings.map(convertApiResponseToOrganizationSummary);
    return { totalLocations: totalBuildings, locations: convertedBuildings };
};

export const getOrganizationBuildingData = async (
    locationId: string,
    limit: number = BUILDING_HEALTH_TABLE_LENGTH,
    offset = 0
): Promise<OrganizationBuildingResponse> => {
    const baseUrl = generatePath('/locations/:locationId/health-status/hubs-and-devices', { locationId });
    const queryString = new URLSearchParams({ limit: String(limit), offset: String(offset) });
    const url = `${baseUrl}?${queryString.toString()}`;
    return fetch<OrganizationBuildingResponse>(url, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
};
