import React, { useState, useEffect, SyntheticEvent, useRef, RefObject } from 'react';
import moment, { Moment } from 'moment';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { GenerateRadonHomeReport, generateRadonHomeReport, HomeReportData } from '../../actions/HomeReportActions';
import { validateMeasurementData } from '../../api/generateHomeReport';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import Dropdown, { Option } from '../../components/dropdown/MultipleAttrDropdown';
import PageHeader from '../../components/headers/PageHeader';
import CheckBox from '../../components/input/Checkbox';
import ReactPdfDownloadModal from '../../components/PDF/ReactPdfDownloadModal';
import ResponseBox from '../../components/responseMessages/ResponseBox';
import { languages, linkToTermsOfUse } from '../../constants';
import i18next from '../../i18n';
import { ErrorType, LocationAddressType } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import LocationFormSection from './HomeLocation';
import HomeReportResult from './HomeReportResult';
import UserInfo from './HomeUserInfo';
import MeasurementFormSection from './MeasurementFormSection';

type StateProps = {
    loading: boolean;
    error?: ErrorType;
};

type ActionProps = {
    sendReport: (payload: HomeReportData) => void;
};

export type Props = StateProps & ActionProps;

export const HomeReportFormPage = (props: Props): React.ReactElement => {
    const { sendReport, loading, error } = props;
    const { t: txt } = useTranslation();
    const formRef: RefObject<HTMLElement> = useRef(null);
    const selectedLanguage = localStorage.getItem('i18nextLng') || 'en';

    const [name, setName] = useState('');
    const [invalidName, setInvalidName] = useState(false);
    const [locationAddress, setLocationAddress] = useState<LocationAddressType | null>(null);
    const [invalidAddress, setInvalidAddress] = useState(false);
    const [roomType, setRoomType] = useState('');
    const [invalidRoomType, setInvalidRoomType] = useState(false);
    const [buildingType, setBuildingType] = useState('');
    const [invalidBuildingType, setInvalidBuildingType] = useState(false);
    const [language, setLanguage] = useState(selectedLanguage);
    const [buildingYear, setBuildingYear] = useState('');
    const [invalidBuildingYear, setInvalidBuildingYear] = useState(false);
    const [floor, setFloor] = useState('');
    const [invalidFloor, setInvalidFloor] = useState(false);
    const [serialNumber, setSerialNumber] = useState('');
    const [invalidSerialNumber, setInvalidSerialNumber] = useState(false);
    const [endDate, setEndDate] = useState<Moment | null>(null);
    const [invalidEndDate, setInvalidEndDate] = useState(false);
    const [radonLevel, setRadonLevel] = useState('');
    const [invalidRadonLevel, setInvalidRadonLevel] = useState(false);
    const [daysMeasured, setDaysMeasured] = useState('');
    const [code, setCode] = useState('');
    const [invalidCode, setInvalidCode] = useState(false);
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [invalidDaysMeasured, setInvalidDaysMeasured] = useState(false);
    const [invalidTerms, setInvalidTerms] = useState(false);
    const [downloadModalOpen, setDownloadModalOpen] = useState(false);
    const [validDeviceData, setValidDeviceData] = useState(true);
    const [validatingDeviceData, setValidatingDeviceData] = useState(false);
    const [validatingDeviceDataError, setValidatingDeviceDataError] = useState(false);

    useEffect((): (() => void) => {
        const rootElement = document.getElementById('root');
        if (rootElement) {
            rootElement.className = 'no-margin';
        }
        return (): void => {
            if (rootElement) {
                rootElement.className = '';
            }
        };
    }, []);

    const onInputChange = (e: SyntheticEvent<HTMLInputElement>): void => {
        const value = e.currentTarget.value.trim();
        const { id } = e.currentTarget;
        if (id === 'name') {
            setName(value);
            setInvalidName(false);
        } else if (id === 'buildingYear') {
            setBuildingYear(value);
            setInvalidBuildingYear(false);
        } else if (id === 'serialNumber') {
            setSerialNumber(value);
            setInvalidSerialNumber(false);
        } else if (id === 'radonLevel') {
            setRadonLevel(value);
            setInvalidRadonLevel(false);
        } else if (id === 'code') {
            setCode(value);
            setInvalidCode(false);
        } else if (id === 'daysMeasured') {
            setDaysMeasured(value);
            setInvalidDaysMeasured(false);
        }
    };

    const resetDeviceDataValidations = (): void => {
        setInvalidCode(false);
        setInvalidDaysMeasured(false);
        setInvalidSerialNumber(false);
        setInvalidRadonLevel(false);
    };

    const onDropdownChange = (id: string, value: string): void => {
        if (id === 'buildingType') {
            setBuildingType(value);
            setInvalidBuildingType(false);
        } else if (id === 'roomType') {
            setRoomType(value);
            setInvalidRoomType(false);
        } else if (id === 'floor') {
            setFloor(value);
            setInvalidFloor(false);
        }
    };

    const onDateChange = (date: Moment): void => {
        setEndDate(date);
        setInvalidEndDate(false);
    };

    const onCaptchaComplete = async (captchaToken: string | null | undefined): Promise<void> => {
        if (locationAddress === null || captchaToken === null || !locationAddress.countryCode) {
            return;
        }
        const reportData: HomeReportData = {
            serialNumber,
            data: {
                captchaToken: captchaToken || '',
                name,
                address: locationAddress.address || '',
                coords: locationAddress.coords,
                countryCode: locationAddress.countryCode,
                buildingType,
                buildingYear: parseInt(buildingYear, 10),
                roomType,
                floor,
                checkCode: code,
                duration: parseInt(daysMeasured, 10),
                radonLongTermAvg: parseFloat(radonLevel),
                ended: moment(endDate).format('YYYY-MM-DDThh:mm:ss'),
            },
        };
        sendReport(reportData);
        setDownloadModalOpen(true);
    };

    type RemoteValidation = {
        deviceDataValid: boolean;
        remoteValidations:
            | undefined
            | {
                  invalidSerialNumber: boolean;
                  invalidCode: boolean;
                  invalidDaysMeasured: boolean;
                  invalidRadonLevel: boolean;
              };
    };
    const remoteValidation = async (
        snr: string,
        checkCode: string,
        days: string,
        radon: string
    ): Promise<RemoteValidation> => {
        setValidatingDeviceData(true);
        let deviceDataValid = false;
        try {
            deviceDataValid = await validateMeasurementData(snr, {
                crc: checkCode,
                duration: parseInt(days, 10),
                radonLongTermAvg: parseFloat(radon),
            }).then(res => {
                if (res.valid !== undefined) {
                    return res.valid;
                }
                return false;
            });
        } catch (e) {
            setValidatingDeviceDataError(true);
        }

        setValidatingDeviceData(false);
        setValidDeviceData(deviceDataValid);

        let remoteValidations;
        if (!deviceDataValid) {
            remoteValidations = {
                invalidSerialNumber: true,
                invalidCode: true,
                invalidDaysMeasured: true,
                invalidRadonLevel: true,
            };
        }
        return { deviceDataValid, remoteValidations };
    };

    const validateAddress = (locationAddr: LocationAddressType | null): boolean => {
        const validAddress =
            !!locationAddr && locationAddr.address.length > 0 && locationAddr.address.split(', ').length > 1;
        const validCountry = locationAddr && locationAddr.countryCode;
        setInvalidAddress(!validAddress || !validCountry);
        return !validAddress || !validCountry;
    };

    const validateForm = async (): Promise<boolean> => {
        // eslint-disable-next-line security/detect-unsafe-regex
        const radonLevelRegEx = /^\d+([.,]\d{1,2})?$/;
        const clientValidations = {
            invalidName: name.length === 0,
            invalidAddress: validateAddress(locationAddress),
            invalidReportLanguage: language.length === 0,
            invalidBuildingType: buildingType.length === 0,
            invalidBuildingYear: !(buildingYear.length === 0 || /^\d{4}$/.test(buildingYear)),
            invalidEndDate: endDate === null,
            invalidTerms: !agreedToTerms,
            invalidRoomType: roomType.length === 0,
            invalidFloor: floor.length === 0,
            invalidDaysMeasured: !(/^\d+$/.test(daysMeasured) && parseInt(daysMeasured, 10) >= 30),
            invalidRadonLevel: !radonLevelRegEx.test(radonLevel),
            invalidSerialNumber: !(serialNumber.length === 10 && /^\d+$/.test(serialNumber)),
            invalidCode: !(code.length === 4 && /^\d+$/.test(code)),
        };

        const validateRemotely =
            !clientValidations.invalidDaysMeasured &&
            !clientValidations.invalidRadonLevel &&
            !clientValidations.invalidSerialNumber &&
            !clientValidations.invalidCode;

        const { deviceDataValid, remoteValidations } = validateRemotely
            ? await remoteValidation(serialNumber, code, daysMeasured, radonLevel)
            : { deviceDataValid: false, remoteValidations: undefined };

        setInvalidName(clientValidations.invalidName);
        setInvalidBuildingType(clientValidations.invalidBuildingType);
        setInvalidBuildingYear(clientValidations.invalidBuildingYear);
        setInvalidEndDate(clientValidations.invalidEndDate);
        setInvalidTerms(clientValidations.invalidTerms);
        setInvalidRoomType(clientValidations.invalidRoomType);
        setInvalidFloor(clientValidations.invalidFloor);
        setInvalidDaysMeasured(
            remoteValidations ? remoteValidations.invalidDaysMeasured : clientValidations.invalidDaysMeasured
        );
        setInvalidRadonLevel(
            remoteValidations ? remoteValidations.invalidRadonLevel : clientValidations.invalidRadonLevel
        );
        setInvalidSerialNumber(
            remoteValidations ? remoteValidations.invalidSerialNumber : clientValidations.invalidSerialNumber
        );
        setInvalidCode(remoteValidations ? remoteValidations.invalidCode : clientValidations.invalidCode);

        const validFormConstraints = !Object.values(clientValidations).includes(true);

        return validFormConstraints && deviceDataValid;
    };

    const onSubmit = async (e: SyntheticEvent<HTMLFormElement> | SyntheticEvent<HTMLButtonElement>): Promise<void> => {
        e.preventDefault();

        const validForm = await validateForm();
        if (validForm && agreedToTerms && formRef.current) {
            formRef.current.execute();
        }
    };

    const updateAddress = (locationAddr: LocationAddressType): void => {
        validateAddress(locationAddr);
        setLocationAddress(locationAddr);
    };

    const onChangeAgreeTerms = (): void => {
        setAgreedToTerms(!agreedToTerms);
        setInvalidTerms(false);
    };

    const getText = (term: string): string => txt(`CorentiumHomeRadonReport.${term}`);

    const setTermContext = (term: string, context: string): string => `${context}.${term}`;

    const createDropdownOption = (term: string, context: string | null = null): Option => {
        const label = context === null ? txt(term) : txt(setTermContext(term, context));
        return Object.create({ id: term, inputValue: label });
    };

    const selectedOptionValue = (term: string, context: string): string =>
        term ? txt(setTermContext(term, context)) : '';

    const selectLanguage = ({ id }: { id: string; inputValue: string }): void => {
        localStorage.setItem('i18nextLng', id);
        setLanguage(id);
        i18next.changeLanguage(id);
        moment.locale(id);
    };

    const languageOptions = Object.keys(languages)
        .map(key => ({ id: key, inputValue: languages[key] }))
        .sort((lang1, lang2) => lang1.inputValue.localeCompare(lang2.inputValue));

    return (
        <div>
            <PageHeader title={getText('PageTitle')} />
            <div className="page-wrapper page-wrapper__medium page-wrapper__medium--white">
                <form className="radon-report__form" onSubmit={onSubmit}>
                    <ReCAPTCHA
                        ref={formRef}
                        size="invisible"
                        onChange={onCaptchaComplete}
                        sitekey="6LevScMUAAAAABmi8b8ymTtuM8US_OJdPwoNblGs"
                    />
                    <div
                        className={
                            'radon-report__form__section' +
                            ' radon-report__form__section--border' +
                            ' radon-report__form__section--text'
                        }
                    >
                        <p>{getText('IntroText')}</p>
                        <span>{getText('PrerequisiteMeasurementDays')}</span>
                        <p className="small-text">{getText('Disclaimer')}</p>
                    </div>
                    <div className="radon-report__form__section__row-resp-column-collapse">
                        <Dropdown
                            id="languageSelector"
                            title="ReportLanguage"
                            testAttr="language"
                            value={txt(language)}
                            defaultOption="Select"
                            options={languageOptions}
                            loading={false}
                            hint="ReportLanguageHint"
                            isValid={false}
                            onSelect={selectLanguage}
                            isRequired
                        />
                    </div>
                    <UserInfo invalidName={invalidName} onInputChange={onInputChange} getText={getText} />
                    <LocationFormSection
                        buildingType={buildingType}
                        invalidBuildingType={invalidBuildingType}
                        roomType={roomType}
                        floor={floor}
                        address={locationAddress ? locationAddress.address : ''}
                        updateAddress={updateAddress}
                        invalidAddress={invalidAddress}
                        invalidBuildingYear={invalidBuildingYear}
                        invalidRoomType={invalidRoomType}
                        invalidFloor={invalidFloor}
                        onInputChange={onInputChange}
                        onDropdownChange={onDropdownChange}
                        createDropdownOption={createDropdownOption}
                        selectedOptionValue={selectedOptionValue}
                        getText={getText}
                    />
                    <MeasurementFormSection
                        code={code}
                        daysMeasured={daysMeasured}
                        endDate={endDate}
                        serialNumber={serialNumber}
                        validDeviceData={validDeviceData}
                        invalidCode={invalidCode}
                        invalidDaysMeasured={invalidDaysMeasured}
                        invalidEndDate={invalidEndDate}
                        invalidSerialNumber={invalidSerialNumber}
                        invalidRadonLevel={invalidRadonLevel}
                        onInputChange={onInputChange}
                        onDateChange={onDateChange}
                        getText={getText}
                        resetDeviceDataValidations={resetDeviceDataValidations}
                    />
                    <div>
                        <CheckBox
                            labelIsElement
                            label={
                                <div>
                                    {txt('AgreeToTerms')}{' '}
                                    <a href={linkToTermsOfUse} target="_blank" rel="noopener noreferrer">
                                        {txt('AgreeToTermsLinkText')}
                                    </a>
                                </div>
                            }
                            id="agreeToTerms"
                            checked={agreedToTerms}
                            hint="AgreeToTermsHint"
                            validate={invalidTerms}
                            onChange={onChangeAgreeTerms}
                            required
                            testAttr="agree-terms"
                        />
                    </div>
                    {validatingDeviceDataError && <ResponseBox text="SomethingWentWrongTryAgain" />}
                    <div className="radon-report__form--submit-button">
                        <PrimaryButton
                            id="generateHomeReportButton"
                            type="submit"
                            title="GenerateReport"
                            disabled={loading || validatingDeviceData || !agreedToTerms}
                            loading={validatingDeviceData}
                            onClick={onSubmit}
                            color="primary"
                        />
                    </div>
                </form>
                {downloadModalOpen && (
                    <ReactPdfDownloadModal title="Download" onClose={(): void => setDownloadModalOpen(false)}>
                        {error ? <ResponseBox text={`ErrorCodes.${error.error}`} /> : <HomeReportResult />}
                    </ReactPdfDownloadModal>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        generateHomeRadonReport: { loading, error },
    } = state;
    return {
        loading,
        error,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    sendReport: (payload: HomeReportData): GenerateRadonHomeReport => dispatch(generateRadonHomeReport(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeReportFormPage);
