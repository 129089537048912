import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updatePropertyDefinition } from 'commons/src/actions/SettingsActions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import Input from 'commons/src/components/input/Input';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { PredefinedProperty } from 'commons/src/models/commonEnums';
import { SpaceProperty } from 'commons/src/models/commonTypeScript';
import { RequestState } from 'commons/src/reducers/requestReducer';
import { Store } from '../../../reducers';
import { SpacePropertyEditState } from './SpacePropertyEditCommon';
import styles from './SpacePropertyEditName.module.scss';

export type Props = {
    allPropertyNames: string[];
    activeSection?: SpacePropertyEditState;
    setActiveSection: (section: SpacePropertyEditState) => void;
    property: { id: string; data: SpaceProperty };
    setShowDeleteView: (arg: boolean) => void;
};

const SpacePropertyEditName = ({
    activeSection,
    setActiveSection,
    allPropertyNames,
    property,
    setShowDeleteView,
}: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    const [name, setName] = useState<string>(property.data.propertyName);
    const [showEditView, setShowEditView] = useState<boolean>(false);
    const [displayValidation, setDisplayValidation] = useState(false);

    const updateActionState: RequestState = useSelector(
        (store: Store) => store.commonRequests.UPDATE_PROPERTY_DEFINITION
    );

    const prevLoading: React.MutableRefObject<boolean> = useRef(updateActionState.loading);
    useEffect((): void => {
        if (prevLoading.current && !updateActionState.loading && updateActionState.error === undefined) {
            setShowEditView(false);
            setName(property.data.propertyName);
        }
        prevLoading.current = updateActionState.loading;
    }, [updateActionState.loading]);

    useEffect(() => {
        if (activeSection !== SpacePropertyEditState.EDIT_NAME) {
            setShowEditView(false);
            setName(property.data.propertyName);
            setDisplayValidation(false);
        }
    }, [activeSection]);

    const isValid = (): boolean => name.length > 0 && !allPropertyNames.some(prop => prop === name);

    const onRename = (): void => {
        if (name === property.data.propertyName) {
            setDisplayValidation(false);
            setShowEditView(false);
            return;
        }
        setDisplayValidation(!isValid());
        if (isValid()) {
            dispatch(updatePropertyDefinition(property.id, name.trim()));
        }
    };

    const onSetEditToTrue = (): void => {
        setActiveSection(SpacePropertyEditState.EDIT_NAME);
        setShowEditView(true);
    };

    const onSetEditToFalse = (): void => {
        setName(property.data.propertyName);
        setDisplayValidation(false);
        setShowEditView(false);
    };

    const isCustomProperty: boolean = property.data.predefinedType === PredefinedProperty.Custom;
    return (
        <div className={styles.body}>
            {!showEditView ? (
                <>
                    <div className={styles.editNameSection}>
                        <div className={styles.text}>
                            {property.data.predefinedType === PredefinedProperty.Custom
                                ? property.data.propertyName
                                : txt(`PredefinedProperties.${property.data.predefinedType}`)}
                        </div>
                        {isCustomProperty && (
                            <TertiaryButton
                                size="medium"
                                noColor
                                onClick={onSetEditToTrue}
                                icon={<MaterialIcon name="edit" />}
                                testId="edit-button"
                            />
                        )}
                        <div className={styles.subText}>{txt('CustomProperty.AddEditOrDelete')}</div>
                    </div>
                    {isCustomProperty && (
                        <PrimaryButton
                            onClick={(): void => setShowDeleteView(true)}
                            color="alert"
                            icon={<MaterialIcon name="delete" />}
                            testId="delete-property-button"
                        />
                    )}
                </>
            ) : (
                <div className={styles.inputSection}>
                    <div className={styles.input}>
                        <Input
                            type="text"
                            id="property-name"
                            isValid={isValid()}
                            validate={displayValidation && !isValid()}
                            maxLength={30}
                            autoComplete="off"
                            onChange={(e): void => setName(e.currentTarget.value)}
                            onKeyUp={(e): void => {
                                if (e.key === 'Enter') onRename();
                            }}
                            currentValue={name}
                            noBottomMargin
                            hideLabel
                            autoFocus
                            testId="name-input"
                        />
                    </div>
                    <PrimaryButton
                        color="secondary"
                        onClick={onRename}
                        loading={updateActionState.loading}
                        icon={<MaterialIcon name="check" />}
                        testId="rename-button"
                    />
                    <TertiaryButton onClick={onSetEditToFalse} icon={<MaterialIcon name="close" />} noColor />
                </div>
            )}
        </div>
    );
};

export default SpacePropertyEditName;
