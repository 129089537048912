import { RequestActionType } from 'commons/src/actions/requestActions';
import { SelectedPeriod } from 'commons/src/models/commonTypeScript';
import { BusinessActionRequestType } from '../models/common';
import { AggregationDigest } from '../models/eventAggregationModels';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export enum EventAggregationActions {
    FetchSpaceAggregationDigestInit = 'FETCH_SPACE_AGGREGATION_DIGEST/INIT',
    FetchSpaceAggregationDigestSuccess = 'FETCH_SPACE_AGGREGATION_DIGEST_SUCCESS',
}

export interface FetchSpaceAggregationDigest extends BusinessActionRequestType {
    type: EventAggregationActions.FetchSpaceAggregationDigestInit;
    spaceId: string;
    selectedInterval: SelectedPeriod;
}

export const fetchSpaceAggregationDigest = (
    spaceId: string,
    selectedInterval: SelectedPeriod
): FetchSpaceAggregationDigest => ({
    type: EventAggregationActions.FetchSpaceAggregationDigestInit,
    requestType: RequestType.FetchSpaceAggregationDigest,
    requestActionType: RequestActionType.Init,
    spaceId,
    selectedInterval,
});

export interface FetchSpaceAggregationDigestSuccess {
    type: EventAggregationActions.FetchSpaceAggregationDigestSuccess;
    spaceId: string;
    aggregates: AggregationDigest;
}

export const fetchSpaceAggregationDigestSuccess = (
    spaceId: string,
    aggregates: AggregationDigest
): FetchSpaceAggregationDigestSuccess => ({
    type: EventAggregationActions.FetchSpaceAggregationDigestSuccess,
    spaceId,
    aggregates,
});

export type EventAggregationAction = FetchSpaceAggregationDigestSuccess;
