import { all, call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import { customPeriodName } from 'commons/src/constants';
import { DashboardSensorData } from 'commons/src/models/commonTypeScript';
import { getFetchAttributes } from 'commons/src/sagas/DevicePageSagas/fetchCustomDeviceSegment';
import { toErrorType } from 'commons/src/sagas/isErrorType';
import { setFetchInterval } from 'commons/src/sagas/pollData/pollDeviceData';
import {
    EventAggregationActions,
    FetchSpaceAggregationDigest,
    fetchSpaceAggregationDigestSuccess,
} from '../actions/eventAggregationActions';
import { fetchSpaceAggregation } from '../api/eventAggregationApi';
import { AggregationDigest } from '../models/eventAggregationModels';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

type FetchAggregationDigestSagaReturnType = Generator<
    | CallEffect<{ from: string; to: string; lastDataOlderThanResolutionDuration: boolean }>
    | CallEffect<AggregationDigest>
    | PutEffect
    | RequestActionType,
    void,
    AggregationDigest & {
        from: string;
        to: string;
        lastDataOlderThanResolutionDuration: boolean;
    }
>;
export function* fetchSpaceAggregationDigestSaga({
    spaceId,
    selectedInterval,
}: FetchSpaceAggregationDigest): FetchAggregationDigestSagaReturnType {
    try {
        const prevDeviceData = {};
        let fetchAttrs: { from: string; to: string; lastDataOlderThanResolutionDuration?: boolean };
        if (selectedInterval.name === customPeriodName && selectedInterval.startDate && selectedInterval.endDate) {
            fetchAttrs = getFetchAttributes(selectedInterval);
        } else {
            fetchAttrs = yield call(setFetchInterval, selectedInterval, prevDeviceData as DashboardSensorData);
        }
        const response: AggregationDigest = yield call(fetchSpaceAggregation, spaceId, {
            from: fetchAttrs.from,
            to: fetchAttrs.to,
            resolution: selectedInterval.resolution,
        });
        yield put(fetchSpaceAggregationDigestSuccess(spaceId, response));
        yield put(requestSuccess(RequestType.FetchSpaceAggregationDigest, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.FetchSpaceAggregationDigest, RequestActionType.Error));
    }
}

export default function* eventAggregationSagas(): Generator {
    yield all([takeEvery(EventAggregationActions.FetchSpaceAggregationDigestInit, fetchSpaceAggregationDigestSaga)]);
}
