import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'commons/src/components/containers/PageWrapper';
import styles from './AddFirstFloorPlan.module.scss';
import AddFloorPlan from './AddFloorPlan';

type Props = {
    locationId: string;
};
const AddFirstFloorPlan = ({ locationId }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <PageWrapper pageType="slim">
            <h2 className={styles.header}>{txt('SpaceFloorPlan.MapYourSpaces')}</h2>
            <p className={styles.subHeader}>{txt('SpaceFloorPlan.WhatYouGet')}</p>
            <AddFloorPlan locationId={locationId} />
        </PageWrapper>
    );
};
export default AddFirstFloorPlan;
