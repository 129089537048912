import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { fullwidthListElement } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import useWindowSize from 'commons/src/hooks/useWindowSize';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { PartnerOfflineDevicesResponse } from '../../actions/partnerActions';
import { getBusinessIntercomArticleLink } from '../../constants';
import { IntercomArticleId } from '../../models/commonEnums';
import { Store } from '../../reducers';
import { BusinessRequestType } from '../../reducers/BusinessRequestType';
import { Row } from '../status/buildingStatus/components/common';
import { DownloadPartnerCsvHealthStatusComponent } from './components/DownloadPartnerCsvOfflineDevices';
import PartnerDesktopTable from './components/PartnerDesktopTable';
import PartnerMobileTable from './components/PartnerMobileTable';
import styles from './PartnerStatus.module.scss';

type StateProps = {
    totalCustomers: number;
    offlineDevices: PartnerOfflineDevicesResponse[];
    partnerOfflineDevicesLoading: boolean;
    organizationName?: string;
    error?: ErrorType;
};

export type Props = StateProps;

type pixels = number;
const MOBILE_TABLE_BREAKPOINT: pixels = 1000;

export const PartnerStatusComponent = ({
    totalCustomers,
    offlineDevices,
    partnerOfflineDevicesLoading,
    organizationName,
    error,
}: Props): React.ReactElement => {
    const { width } = useWindowSize();
    const totalNumberOfDevicesInOrg = offlineDevices.reduce((acc, val) => acc + val.totalDevices, 0);

    const linkToSupportGuide = (articleId: IntercomArticleId): void => {
        const linkToArticle = getBusinessIntercomArticleLink(articleId);
        const newWindow = window.open(linkToArticle, '_blank', 'noopener noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    return (
        <div className={styles.page}>
            <div className={styles.content}>
                <ReactPlaceholder
                    ready={!partnerOfflineDevicesLoading || offlineDevices.length > 0}
                    className="container"
                    customPlaceholder={fullwidthListElement}
                >
                    <div className={styles.statusRow}>
                        <div className={styles.buttonWrapper}>
                            <div className="small-padding-right small-padding-bottom">
                                <PrimaryButton
                                    color="tertiary"
                                    onClick={(): void =>
                                        linkToSupportGuide(IntercomArticleId.TROUBLESHOOTING_OFFLINE_HUB)
                                    }
                                    title="BuildingStatus.HubSupport"
                                    icon={<MaterialIcon name="launch" />}
                                />
                            </div>
                            <div className="small-padding-right small-padding-bottom">
                                <PrimaryButton
                                    color="tertiary"
                                    onClick={(): void =>
                                        linkToSupportGuide(IntercomArticleId.TROUBLESHOOTING_OFFLINE_DEVICE)
                                    }
                                    title="BuildingStatus.DeviceSupport"
                                    icon={<MaterialIcon name="launch" />}
                                />
                            </div>
                            <div className="small-padding-bottom">
                                <DownloadPartnerCsvHealthStatusComponent
                                    offlineDevices={offlineDevices.filter(
                                        customer => customer.offlineLocations.length > 0
                                    )}
                                    organizationName={organizationName}
                                />
                            </div>
                        </div>
                    </div>
                    {error ? (
                        <ResponseBox text={error.error} />
                    ) : (
                        <Row>
                            {width > MOBILE_TABLE_BREAKPOINT ? (
                                <PartnerDesktopTable
                                    data={offlineDevices}
                                    numberOfDevicesInOrg={totalNumberOfDevicesInOrg}
                                    numberOfCustomers={totalCustomers}
                                />
                            ) : (
                                <PartnerMobileTable
                                    data={offlineDevices}
                                    numberOfDevicesInOrg={totalNumberOfDevicesInOrg}
                                />
                            )}
                        </Row>
                    )}
                </ReactPlaceholder>
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { selectedGroup },
        partner: { totalCustomers, offlineDevices },
        requests: {
            [BusinessRequestType.FetchPartnerOfflineDevices]: { loading: partnerOfflineDevicesLoading, error },
        },
    } = store;

    return {
        error,
        totalCustomers,
        offlineDevices,
        partnerOfflineDevicesLoading,
        organizationName: selectedGroup && selectedGroup.organizationName,
    };
};

export default connect(mapStateToProps)(PartnerStatusComponent);
