import React, { ReactElement } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import { SPACE_SELECTED_SPARKLINE } from 'commons/src/analytics/AnalyticsEvents';
import { virtualSensors } from 'commons/src/constants';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { Store } from '../../../../reducers';
import { BusinessRequestType } from '../../../../reducers/BusinessRequestType';
import styles from './SpaceSparkLine.module.scss';
import spaceSparkLineConfig from './SpaceSparkLineConfig';

type ParentProps = {
    data: { data: number[][]; id: string };
    sensor: SensorTypes;
    selected: SensorTypes;
    onSelect: (sensor: SensorTypes) => void;
    graphStartAndEnd: { max: number; min: number };
    spaceId: string;
    locationId: string;
};

type Props = ParentProps;
const SpaceSparkLine = ({
    data,
    sensor,
    selected,
    onSelect,
    graphStartAndEnd,
    spaceId,
    locationId,
}: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const { loading } = useSelector((state: Store) => state.requests[BusinessRequestType.FetchSpaceSensorData]);
    const { loading: loadingVirtualSensor } = useSelector(
        (state: Store) => state.requests[BusinessRequestType.FetchSpaceVirtualSensorData]
    );
    const sparkLineConfig = spaceSparkLineConfig({
        data,
        graphStartAndEnd,
    });
    const onClick = (): void => {
        onSelect(sensor);
        analyticsLogger(SPACE_SELECTED_SPARKLINE, {
            sensor,
            spaceId,
            locationId,
        });
    };

    const isVirtual = virtualSensors.includes(sensor);
    const loadingSensorData = isVirtual ? loadingVirtualSensor : loading;

    return (
        <div
            className={selected === sensor ? styles.selectedSensor : styles.sparkLine}
            role="button"
            tabIndex={0}
            onClick={onClick}
            onKeyDown={(e): void => {
                if (e.key === 'Enter') onClick();
            }}
        >
            <div className={styles.header}>{txt(sensor)}</div>
            <div className={styles.graphWrapper}>
                {loadingSensorData ? (
                    txt('Loading')
                ) : (
                    <HighchartsReact highcharts={Highcharts} options={sparkLineConfig} />
                )}
            </div>
        </div>
    );
};

export default SpaceSparkLine;
