import React, { useEffect, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import ReactPlaceHolder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { analyticsLogger } from 'commons/src/analytics';
import { ACCOUNT_SSO_ENABLE } from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { getFederatedClients } from '../../../../actions/singleSignOnActions';
import { paths } from '../../../../constants';
import { SingleSignOnClient } from '../../../../models/common';
import { Store } from '../../../../reducers';
import { BusinessRequestType } from '../../../../reducers/BusinessRequestType';
import styles from './SSOSection.module.scss';
import SSOProviderTile from './SSOTile';

type StateProps = {
    singleSignOnClients: SingleSignOnClient[];
    selectedUserGroupId?: string;
    clientsLoading: boolean;
    clientsError?: ErrorType;
    createLoading: boolean;
    createError?: ErrorType;
};

export const SSOComponent = ({
    singleSignOnClients,
    selectedUserGroupId,
    clientsLoading,
    clientsError,
    createLoading,
    createError,
}: StateProps): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const [ssoSectionOpen, setSsoSectionOpen] = useState(false);
    const [addAnotherSsoFormOpen, setAddAnotherSsoFormOpen] = useState(false);

    useEffect((): void => {
        if (selectedUserGroupId) {
            dispatch(getFederatedClients());
        }
    }, [selectedUserGroupId]);

    const prevLoadingRef = useRef(createLoading);

    useEffect((): void => {
        if (prevLoadingRef.current && !createLoading && !createError) {
            setAddAnotherSsoFormOpen(false);
        }
        prevLoadingRef.current = createLoading;
    }, [createLoading, prevLoadingRef]);

    const onEnable = (): void => {
        setSsoSectionOpen(true);
        analyticsLogger(ACCOUNT_SSO_ENABLE, {});
    };

    return (
        <div>
            <h2 className="settings__header">{txt('SingleSignOn.Sso')}</h2>
            <ReactPlaceHolder ready={!clientsLoading && !!selectedUserGroupId} customPlaceholder={mediumFormLoader}>
                <div className={styles.howToAddUsers}>
                    <MaterialIcon name="email" />
                    <div>
                        <Trans i18nKey="SingleSignOn.HowToAddUsers">
                            <Link to={`/${paths.userManagement}`}>{txt('Users')}</Link>
                        </Trans>
                    </div>
                </div>
                {singleSignOnClients.length === 0 && selectedUserGroupId && (
                    <div>
                        {!ssoSectionOpen ? (
                            <div className="settings-details-container">
                                <div className={styles.enableSection}>
                                    <p className={styles.setupText}>{txt('SingleSignOn.SetupText')}</p>
                                    <PrimaryButton
                                        loading={false}
                                        color="primary"
                                        title="Enable"
                                        onClick={(): void => onEnable()}
                                    />
                                </div>
                            </div>
                        ) : (
                            <SSOProviderTile openSection={ssoSectionOpen} />
                        )}
                    </div>
                )}
                {singleSignOnClients.length > 0 &&
                    selectedUserGroupId &&
                    (clientsError ? (
                        <div className={styles.errorWrapper}>
                            <ResponseBox text={`ErrorCodes.${clientsError.error}`} />
                        </div>
                    ) : (
                        <div>
                            {singleSignOnClients.map(ssoClient => (
                                <SSOProviderTile
                                    key={ssoClient.singleSignOnId}
                                    openSection={ssoSectionOpen}
                                    singleSignOnClient={ssoClient}
                                />
                            ))}
                            {addAnotherSsoFormOpen && <SSOProviderTile openSection={addAnotherSsoFormOpen} />}
                            <PrimaryButton
                                loading={false}
                                color="primary"
                                title="SingleSignOn.AddSsoButton"
                                icon={<MaterialIcon name="add" />}
                                onClick={(): void => setAddAnotherSsoFormOpen(true)}
                            />
                        </div>
                    ))}
            </ReactPlaceHolder>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        settings: { singleSignOnClients },
        userSettings: { selectedGroup },
        requests: {
            [BusinessRequestType.GetFederatedClients]: { loading: clientsLoading, error: clientsError },
            [BusinessRequestType.CreateFederatedClient]: { loading: createLoading, error: createError },
        },
    } = state;
    return {
        singleSignOnClients,
        selectedUserGroupId: selectedGroup && selectedGroup.id,
        clientsLoading,
        clientsError,
        createLoading,
        createError,
    };
};

export default connect(mapStateToProps)(SSOComponent);
