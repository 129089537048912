import React, { ReactElement } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import StatusPillButton from 'commons/src/components/pills/StatusPillButton';
import { customPeriodName, sensorGraphPeriods } from 'commons/src/constants';
import { DigestDuration, PillStatus } from 'commons/src/models/commonEnums';
import { SelectedPeriod } from 'commons/src/models/commonTypeScript';
import { Digest } from '../../../../models/eventAggregationModels';
import { Store } from '../../../../reducers';
import styles from '../graphs/SpaceGraphSection.module.scss';

type DigestProps = {
    digests: Digest[];
    selectedPeriod: SelectedPeriod;
    onStatusPillClick: (digest: Digest) => void;
    spaceId: string;
};

const DigestsComponent = ({ digests, onStatusPillClick, spaceId, selectedPeriod }: DigestProps): ReactElement => {
    const { t: txt } = useTranslation();
    const spaceIdInState = useSelector((state: Store) => state.eventAggregation.spaceId);

    const calculateDuration = (duration: number, durationType: DigestDuration): number => {
        if (selectedPeriod.name === sensorGraphPeriods.month.name && durationType === DigestDuration.DAY) {
            return duration * 30;
        }
        if (selectedPeriod.name === sensorGraphPeriods.year.name && durationType === DigestDuration.WEEK) {
            return duration * 52;
        }

        return duration;
    };

    const getDaysOrWeeks = (durationType: DigestDuration): string => {
        if (selectedPeriod.name === sensorGraphPeriods.halfDay.name && durationType === DigestDuration.HOUR) {
            return txt('EventAggregation.Hours');
        }
        if (selectedPeriod.name === sensorGraphPeriods.year.name && durationType === DigestDuration.WEEK) {
            return txt('EventAggregation.Weeks');
        }
        return txt('EventAggregation.Days');
    };

    const getDigestText = (
        range: string,
        sensorName: string,
        amount: number,
        durationType: DigestDuration
    ): ReactElement => {
        const numberOfDaysOrPeriod: number =
            selectedPeriod.name === customPeriodName
                ? moment(selectedPeriod.endDate).diff(moment(selectedPeriod.startDate), 'days')
                : selectedPeriod.number;

        const calculatedDuration: number = calculateDuration(numberOfDaysOrPeriod, durationType);
        const dayOrWeekString: string = getDaysOrWeeks(durationType);

        return (
            <Trans
                i18nKey="EventAggregation.Digests"
                values={{
                    range: txt(`EventAggregation.${range}`),
                    sensorName: txt(sensorName),
                    amount,
                    calculatedDuration,
                    dayOrWeekString,
                }}
            >
                <span className={styles.bold} />
            </Trans>
        );
    };

    const getNoIssuesText = (): ReactElement => {
        return (
            <Trans i18nKey="EventAggregation.NoIssues" values={{ period: txt(selectedPeriod.name) }}>
                <span className={styles.bold} />
            </Trans>
        );
    };

    return (
        <div className={styles.digestWrapper}>
            {spaceIdInState === spaceId && digests.length > 0 ? (
                digests.map(digest => (
                    <StatusPillButton
                        text={getDigestText(digest.range, digest.sensorType, digest.amount, digest.durationType)}
                        status={PillStatus.alert}
                        outline
                        onClick={(): void => onStatusPillClick(digest)}
                        key={digest.sensorType}
                    />
                ))
            ) : (
                <StatusPillButton text={getNoIssuesText()} status={PillStatus.success} outline />
            )}
        </div>
    );
};

export default DigestsComponent;
