import { all } from 'redux-saga/effects';
import validateSerialNumber from './AddDeviceSagas/validateSerialNumber';
import buildingOptimizationSaga from './buildingOptimizationSaga';
import buildingsHealthStatusSagas from './buildingsHealthStatusSaga';
import buildingsTimeOverThresholdsSaga from './buildingsTimeOverThresholdSaga';
import downloadCSVSaga from './downloadCSVSaga';
import editLocationDetails from './editLocationDetails';
import eventAggregationSagas from './eventAggregationSaga';
import fetchOutdoorDataSaga from './fetchOutdoorDataSaga';
import floorplans from './floorplans';
import radonInsightSaga from './insightSaga';
import mqttSagas from './mqttSaga';
import notificationAlerts from './notificationAlerts';
import organizationHealth from './organizationHealthSaga';
import organizationsSaga from './organizationsSaga';
import outdoorDataSagas from './outdoorDataSaga';
import partnerCustomerSaga from './partnerSagas/partnerCustomerSaga';
import partnerSaga from './partnerSagas/partnerSaga';
import segmentPropertiesSaga from './segmentPropertiesSaga';
import singleSignOnSaga from './singleSignOnSaga';
import spaceFloorPlanSaga from './spaceFloorPlanSaga';
import spaceSagas from './spaceSaga';
import subscriptionSaga from './subscriptionSagas/subscriptionSaga';
import thirdPartyIntegrations from './thirdPartyIntegrationsSaga';
import thresholdBreachInsight from './thresholdBreachInsightSaga';
import virtualDeviceSagas from './virtualDeviceSagas';
import webhooks from './webhookSaga';

export default function* saga(): Generator {
    yield all([
        buildingsHealthStatusSagas(),
        buildingOptimizationSaga(),
        buildingsTimeOverThresholdsSaga(),
        downloadCSVSaga(),
        editLocationDetails(),
        eventAggregationSagas(),
        fetchOutdoorDataSaga(),
        floorplans(),
        mqttSagas(),
        notificationAlerts(),
        organizationsSaga(),
        outdoorDataSagas(),
        partnerSaga(),
        partnerCustomerSaga(),
        radonInsightSaga(),
        segmentPropertiesSaga(),
        validateSerialNumber(),
        singleSignOnSaga(),
        spaceSagas(),
        spaceFloorPlanSaga(),
        subscriptionSaga(),
        thirdPartyIntegrations(),
        thresholdBreachInsight(),
        virtualDeviceSagas(),
        webhooks(),
        organizationHealth(),
    ]);
}
