import { RequestActionType } from 'commons/src/actions/requestActions';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { SelectedPeriod } from 'commons/src/models/commonTypeScript';
import { BusinessActionRequestType } from '../models/common';
import {
    AddDevicePayload,
    IndoorSpace,
    Space,
    SpaceDeviceEntity,
    CreateSpacePayload,
    EditSpacePayload,
    SpaceData,
} from '../models/spaceModels';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export enum SpaceActions {
    CreateSpaceInit = 'CREATE_SPACE/INIT',
    CreateSpaceSuccess = 'CREATE_SPACE_SUCCESS',
    FetchSpacesInit = 'FETCH_SPACES/INIT',
    FetchSpacesSuccess = 'FETCH_SPACES_SUCCESS',
    EditSpaceInit = 'EDIT_SPACE/INIT',
    EditSpaceSuccess = 'EDIT_SPACE_SUCCESS',
    DeleteSpaceInit = 'DELETE_SPACE/INIT',
    DeleteSpaceSuccess = 'DELETE_SPACE_SUCCESS',
    AddDeviceToSpaceInit = 'ADD_DEVICE_TO_SPACE/INIT',
    AddDeviceToSpaceSuccess = 'ADD_DEVICE_TO_SPACE_SUCCESS',
    RemoveDeviceFromSpaceInit = 'REMOVE_DEVICE_FROM_SPACE/INIT',
    RemoveDeviceFromSpaceSuccess = 'REMOVE_DEVICE_FROM_SPACE_SUCCESS',
    MoveDeviceBetweenSpacesInit = 'MOVE_DEVICE_BETWEEN_SPACES/INIT',
    MoveDeviceBetweenSpacesSuccess = 'MOVE_DEVICES_BETWEEN_SPACES_SUCCESS',
    FetchSpaceSensorDataInit = 'FETCH_SPACE_SENSOR_DATA/INIT',
    FetchSpaceSensorDataSuccess = 'FETCH_SPACE_SENSOR_DATA_SUCCESS',
    FetchSpaceVirtualSensorDataInit = 'FETCH_SPACE_VIRTUAL_SENSOR_DATA/INIT',
    FetchSpaceVirtualSensorDataSuccess = 'FETCH_SPACE_VIRTUAL_SENSOR_DATA_SUCCESS',
    MigrateToSpacesInit = 'MIGRATE_TO_SPACES/INIT',
}

export interface MigrateToSpaces extends BusinessActionRequestType {
    type: SpaceActions.MigrateToSpacesInit;
}

export const migrateToSpaces = (): MigrateToSpaces => ({
    type: SpaceActions.MigrateToSpacesInit,
    requestType: RequestType.MigrateToSpaces,
    requestActionType: RequestActionType.Init,
});

export interface FetchSpaces extends BusinessActionRequestType {
    type: SpaceActions.FetchSpacesInit;
    locationId: string;
}
export const fetchSpaces = (locationId: string): FetchSpaces => ({
    type: SpaceActions.FetchSpacesInit,
    requestType: RequestType.FetchSpaces,
    requestActionType: RequestActionType.Init,
    locationId,
});

export interface FetchSpacesSuccess {
    type: SpaceActions.FetchSpacesSuccess;
    locationId: string;
    spaces: Space[];
    availableSensors: SensorTypes[];
}

export const fetchSpacesSuccess = (
    locationId: string,
    spaces: Space[],
    availableSensors: SensorTypes[]
): FetchSpacesSuccess => ({
    type: SpaceActions.FetchSpacesSuccess,
    locationId,
    spaces,
    availableSensors,
});

export interface FetchSpaceSensorData extends BusinessActionRequestType {
    type: SpaceActions.FetchSpaceSensorDataInit;
    locationId: string;
    spaceId: string;
    selectedInterval: SelectedPeriod;
}

export const fetchSpaceSensorData = (
    locationId: string,
    spaceId: string,
    selectedInterval: SelectedPeriod
): FetchSpaceSensorData => ({
    type: SpaceActions.FetchSpaceSensorDataInit,
    requestType: RequestType.FetchSpaceSensorData,
    requestActionType: RequestActionType.Init,
    locationId,
    spaceId,
    selectedInterval,
});

export interface FetchSpaceSensorDataSuccess {
    type: SpaceActions.FetchSpaceSensorDataSuccess;
    locationId: string;
    spaceId: string;
    spaceData: SpaceData;
    fetchedInterval: string;
}

export const fetchSpaceSensorDataSuccess = (
    locationId: string,
    spaceId: string,
    spaceData: SpaceData,
    fetchedInterval: string
): FetchSpaceSensorDataSuccess => ({
    type: SpaceActions.FetchSpaceSensorDataSuccess,
    locationId,
    spaceId,
    spaceData,
    fetchedInterval,
});

export interface FetchSpaceVirtualSensorData extends BusinessActionRequestType {
    type: SpaceActions.FetchSpaceVirtualSensorDataInit;
    locationId: string;
    spaceId: string;
    selectedInterval: SelectedPeriod;
}
export const fetchSpaceVirtualSensorData = (
    locationId: string,
    spaceId: string,
    selectedInterval: SelectedPeriod
): FetchSpaceVirtualSensorData => ({
    type: SpaceActions.FetchSpaceVirtualSensorDataInit,
    requestType: RequestType.FetchSpaceVirtualSensorData,
    requestActionType: RequestActionType.Init,
    locationId,
    spaceId,
    selectedInterval,
});

export interface FetchSpaceVirtualSensorDataSuccess {
    type: SpaceActions.FetchSpaceVirtualSensorDataSuccess;
    locationId: string;
    spaceId: string;
    spaceData: SpaceData;
    fetchedInterval: string;
}

export const fetchSpaceVirtualSensorDataSuccess = (
    locationId: string,
    spaceId: string,
    spaceData: SpaceData,
    fetchedInterval: string
): FetchSpaceVirtualSensorDataSuccess => ({
    type: SpaceActions.FetchSpaceVirtualSensorDataSuccess,
    locationId,
    spaceId,
    spaceData,
    fetchedInterval,
});

export interface CreateSpace extends BusinessActionRequestType {
    type: SpaceActions.CreateSpaceInit;
    newSpace: CreateSpacePayload;
    locationId: string;
    redirectToSpace: boolean;
}

export const createSpace = (
    newSpace: CreateSpacePayload,
    locationId: string,
    redirectToSpace: boolean
): CreateSpace => ({
    type: SpaceActions.CreateSpaceInit,
    requestType: RequestType.CreateSpace,
    requestActionType: RequestActionType.Init,
    newSpace,
    locationId,
    redirectToSpace,
});

export interface CreateSpaceSuccess {
    type: SpaceActions.CreateSpaceSuccess;
    space: IndoorSpace;
}

export const createSpaceSuccess = (space: IndoorSpace): CreateSpaceSuccess => ({
    type: SpaceActions.CreateSpaceSuccess,
    space,
});

export interface EditSpace extends BusinessActionRequestType {
    type: SpaceActions.EditSpaceInit;
    requestType: RequestType.EditSpace;
    requestActionType: RequestActionType.Init;
    space: EditSpacePayload;
}
export const editSpace = (space: EditSpacePayload): EditSpace => ({
    type: SpaceActions.EditSpaceInit,
    requestType: RequestType.EditSpace,
    requestActionType: RequestActionType.Init,
    space,
});

export interface EditSpaceSuccess {
    type: SpaceActions.EditSpaceSuccess;
    space: IndoorSpace;
}

export const editSpaceSuccess = (space: IndoorSpace): EditSpaceSuccess => ({
    type: SpaceActions.EditSpaceSuccess,
    space,
});

export interface DeleteSpace extends BusinessActionRequestType {
    type: SpaceActions.DeleteSpaceInit;
    requestType: RequestType.DeleteSpace;
    space: IndoorSpace;
}

export const deleteSpace = (space: IndoorSpace): DeleteSpace => ({
    type: SpaceActions.DeleteSpaceInit,
    requestType: RequestType.DeleteSpace,
    requestActionType: RequestActionType.Init,
    space,
});

export interface DeleteSpaceSuccess {
    type: SpaceActions.DeleteSpaceSuccess;
    space: IndoorSpace;
}

export const deleteSpaceSuccess = (space: IndoorSpace): DeleteSpaceSuccess => ({
    type: SpaceActions.DeleteSpaceSuccess,
    space,
});

export interface AddDeviceToSpace extends BusinessActionRequestType {
    type: SpaceActions.AddDeviceToSpaceInit;
    locationId: string;
    spaceId: string;
    payload: AddDevicePayload;
}

export const addDeviceToSpace = (locationId: string, spaceId: string, payload: AddDevicePayload): AddDeviceToSpace => ({
    type: SpaceActions.AddDeviceToSpaceInit,
    requestType: RequestType.AddDeviceToSpace,
    requestActionType: RequestActionType.Init,
    locationId,
    spaceId,
    payload,
});

export interface AddDeviceToSpaceSuccess {
    type: SpaceActions.AddDeviceToSpaceSuccess;
    locationId: string;
    spaceId: string;
    device: SpaceDeviceEntity;
}

export const addDeviceToSpaceSuccess = (
    locationId: string,
    spaceId: string,
    device: SpaceDeviceEntity
): AddDeviceToSpaceSuccess => ({
    type: SpaceActions.AddDeviceToSpaceSuccess,
    locationId,
    spaceId,
    device,
});

// Remove device

export type RemoveDevicePayload = {
    locationId: string;
    spaceId: string;
    serialNumber: string;
};

export interface RemoveDeviceFromSpace extends BusinessActionRequestType {
    type: SpaceActions.RemoveDeviceFromSpaceInit;
    payload: RemoveDevicePayload;
}

export const removeDeviceFromSpace = (payload: RemoveDevicePayload): RemoveDeviceFromSpace => ({
    type: SpaceActions.RemoveDeviceFromSpaceInit,
    requestType: RequestType.RemoveDeviceFromSpace,
    requestActionType: RequestActionType.Init,
    payload,
});

export interface RemoveDeviceFromSpaceSuccess {
    type: SpaceActions.RemoveDeviceFromSpaceSuccess;
    locationId: string;
    spaceId: string;
    serialNumber: string;
}

export const removeDeviceFromSpaceSuccess = (
    locationId: string,
    spaceId: string,
    serialNumber: string
): RemoveDeviceFromSpaceSuccess => ({
    type: SpaceActions.RemoveDeviceFromSpaceSuccess,
    locationId,
    spaceId,
    serialNumber,
});

// Move device

export type MoveDevicePayload = {
    toSpaceId: string;
    toLocationId: string;
    deviceName: string;
    startNewSegment: boolean;
};

export interface MoveDeviceBetweenSpaces extends BusinessActionRequestType {
    type: SpaceActions.MoveDeviceBetweenSpacesInit;
    fromLocationId: string;
    fromSpaceId: string;
    serialNumber: string;
    payload: MoveDevicePayload;
    toSpaceName?: string;
}

export const moveDeviceBetweenSpaces = (
    fromLocationId: string,
    fromSpaceId: string,
    serialNumber: string,
    payload: MoveDevicePayload,
    toSpaceName?: string
): MoveDeviceBetweenSpaces => ({
    type: SpaceActions.MoveDeviceBetweenSpacesInit,
    requestType: RequestType.MoveDeviceBetweenSpaces,
    requestActionType: RequestActionType.Init,
    fromLocationId,
    fromSpaceId,
    serialNumber,
    payload,
    toSpaceName,
});

export interface MoveDeviceBetweenSpacesSuccess {
    type: SpaceActions.MoveDeviceBetweenSpacesSuccess;
    fromLocationId: string;
    fromSpaceId: string;
    serialNumber: string;
    payload: MoveDevicePayload;
}

export const moveDeviceBetweenSpacesSuccess = (
    fromLocationId: string,
    fromSpaceId: string,
    serialNumber: string,
    payload: MoveDevicePayload
): MoveDeviceBetweenSpacesSuccess => ({
    type: SpaceActions.MoveDeviceBetweenSpacesSuccess,
    fromLocationId,
    fromSpaceId,
    serialNumber,
    payload,
});

export type SpaceAction =
    | CreateSpaceSuccess
    | FetchSpaces
    | FetchSpacesSuccess
    | EditSpaceSuccess
    | DeleteSpaceSuccess
    | AddDeviceToSpace
    | AddDeviceToSpaceSuccess
    | RemoveDeviceFromSpaceSuccess
    | MoveDeviceBetweenSpacesSuccess;

export type SpaceDataActions = FetchSpaceSensorDataSuccess | FetchSpaceVirtualSensorDataSuccess;
