import React from 'react';
import { useTranslation } from 'react-i18next';
import IconWarning from '../../img/icon-warning';
import PrimaryButton from '../buttons/PrimaryButton';

type Props = {
    errorText?: string;
};

const Error = ({ errorText }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const isOnline = navigator.onLine;
    const translatedError = errorText ? txt(`ErrorCodes.${errorText}`) : txt('ErrorSubtitle');
    return (
        <div className="page-wrapper">
            <div className="no-content-page not-found">
                <IconWarning />
                <p className="no-content-page__title">
                    {txt(isOnline ? 'ErrorTitle' : 'ErrorHandling.YourAreNotOnline')}
                </p>
                <p className="no-content-page__subtitle">
                    {isOnline ? translatedError : txt('ErrorHandling.ConnectToTheInternetAndTryAgain')}
                </p>
                <p>
                    <PrimaryButton
                        color="secondary"
                        id="404TryAgainButton"
                        title={isOnline ? 'TryAgain' : 'ErrorHandling.RefreshPage'}
                        loading={false}
                        onClick={(): void => {
                            window.location.reload();
                        }}
                        testId="error-try-again"
                    />
                </p>
            </div>
        </div>
    );
};

export default Error;
