import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import { SUBSCRIPTION_CREATED } from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import CheckBox from 'commons/src/components/input/Checkbox';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { dateFormats } from 'commons/src/constants';
import { GroupType } from 'commons/src/models/commonEnums';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import {
    createSubscriptionForDirectCustomer,
    createSubscriptionForPartnerCustomer,
} from '../../../../actions/subscriptionActions';
import { subscriptionTermsAndConditions } from '../../../../constants';
import { SubscriptionInfo, SubscriptionsOrderRequest } from '../../../../models/subscriptionModels';
import { Store } from '../../../../reducers';
import { BusinessRequestType as RequestType } from '../../../../reducers/BusinessRequestType';
import BillingInfoSection from './BillingInfoSection';
import styles from './ReviewAndConfirmSubscription.module.scss';

type ParentProps = {
    setStepNumber: (step: number) => void;
    previewCostTable: React.ReactElement;
    newSubscription?: SubscriptionsOrderRequest;
    subscriptionInfo: SubscriptionInfo;
    childUserGroupId?: string;
    dateFormat: keyof typeof dateFormats;
    groupType?: GroupType;
    userAllowedToManageSubscription?: boolean;
};

type StateProps = {
    loading: boolean;
    error?: ErrorType;
};

type Props = ParentProps & StateProps;

const ReviewAndConfirmSubscription = ({
    setStepNumber,
    previewCostTable,
    newSubscription,
    subscriptionInfo,
    loading,
    error,
    childUserGroupId,
    dateFormat,
    groupType,
    userAllowedToManageSubscription,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const [agreedToTerms, setAgreedToTerms] = useState(false);

    const prevLoadingRef = useRef(loading);

    const confirmSubscriptionCreation = (): void => {
        if (groupType === GroupType.partner && childUserGroupId && newSubscription) {
            dispatch(createSubscriptionForPartnerCustomer(childUserGroupId, newSubscription));
            analyticsLogger(SUBSCRIPTION_CREATED, { groupType });
            prevLoadingRef.current = true;
        } else if (userAllowedToManageSubscription && groupType === GroupType.business && newSubscription) {
            dispatch(createSubscriptionForDirectCustomer(newSubscription));
            analyticsLogger(SUBSCRIPTION_CREATED, { groupType });
            prevLoadingRef.current = true;
        } else {
            prevLoadingRef.current = false;
        }
    };

    useEffect(() => {
        if (prevLoadingRef.current && !loading) {
            prevLoadingRef.current = false;
            if (!error) {
                setStepNumber(3);
            }
        }
    }, [prevLoadingRef, loading]);

    return (
        <div className="page-wrapper__medium">
            <h2>{txt('Subscription.ReviewAndConfirm')}</h2>
            <div className={styles.wrapper}>
                <BillingInfoSection
                    previewCostTable={previewCostTable}
                    subscriptionInfo={subscriptionInfo}
                    dateFormat={dateFormat}
                />
                <p className={styles.invoiceInfo}>{txt('Subscription.InvoiceInfo')}</p>
                <CheckBox
                    label={
                        <div>
                            <Trans i18nKey="Subscription.AgreeToSubscriptionTerms">
                                <a href={subscriptionTermsAndConditions} target="_blank" rel="noopener noreferrer">
                                    {subscriptionTermsAndConditions}
                                </a>
                            </Trans>
                        </div>
                    }
                    labelIsElement
                    id="agreeToSubscriptionTerms"
                    onChange={(): void => setAgreedToTerms(!agreedToTerms)}
                    checked={agreedToTerms}
                />
                <div className={styles.buttonWrapper}>
                    <PrimaryButton color="secondary" onClick={(): void => setStepNumber(1)} title="Navigate.Back" />
                    <PrimaryButton
                        onClick={confirmSubscriptionCreation}
                        title="Confirm"
                        disabled={!agreedToTerms}
                        loading={loading}
                        color="primary"
                    />
                </div>
                {error && !loading && <ResponseBox text={`ErrorCodes.${error.error}`} />}
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        requests: {
            [RequestType.CreateSubscriptionForDirectCustomer]: {
                loading: directCustomerLoading,
                error: directCustomerError,
            },
            [RequestType.CreateSubscriptionForPartnerCustomer]: { loading, error },
        },
    } = store;
    return {
        loading: loading || directCustomerLoading,
        error: error || directCustomerError,
    };
};

export default connect(mapStateToProps)(ReviewAndConfirmSubscription);
