import * as React from 'react';
import classNames from 'classnames';
import StandAloneSpinner from '../../img/StandAloneSpinner';

export type Props = {
    isLoading: boolean;
    children: React.ReactElement | null;
    className?: string;
};

const SpinnerBlocker = ({ isLoading, children, className }: Props): React.ReactElement | null => {
    if (isLoading) {
        return (
            <div className={classNames('modal__content__spinner', { [className || '']: !!className })}>
                <StandAloneSpinner />
            </div>
        );
    }
    return children;
};

export default SpinnerBlocker;
