import { all, takeEvery, call, put, CallEffect, PutEffect } from 'redux-saga/effects';
import {
    deleteHubSuccess,
    HubActionType,
    DeleteHub,
    FetchHubSuccess,
    FetchHub,
    fetchHubSuccess,
} from '../actions/HubActions';
import { RequestActionType, requestError, requestSuccess } from '../actions/requestActions';
import { deleteDevice, getHubData } from '../api/devices';
import { HubData } from '../models/commonTypeScript';
import { RequestActions } from '../models/RequestTypes';
import { CommonRequestType } from '../reducers/requestReducer';
import displayAlertBoxSaga from './displayAlertBox';
import { toErrorType } from './isErrorType';

export function* deleteHubSaga({ serialNumber, locationId }: DeleteHub): Generator {
    try {
        yield call(deleteDevice, serialNumber.toString());
        yield put(deleteHubSuccess(serialNumber, locationId));
        yield put(requestSuccess(CommonRequestType.DeleteHub, RequestActionType.Success));
        yield call(displayAlertBoxSaga, 'HubStrings.HubSuccessfullyUnpaired', false, true);
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, CommonRequestType.DeleteHub, RequestActionType.Error));
    }
}

type FetchHubSagaActionType = Generator<
    CallEffect<HubData> | PutEffect<FetchHubSuccess> | RequestActions,
    void,
    HubData
>;
export function* fetchHubSaga({ serialNumber }: FetchHub): FetchHubSagaActionType {
    try {
        const hub = yield call(getHubData, serialNumber);
        yield put(fetchHubSuccess(hub, serialNumber));
        yield put(requestSuccess(CommonRequestType.FetchHub, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, CommonRequestType.FetchHub, RequestActionType.Error));
    }
}

export default function* DeleteHubSagas(): Generator {
    yield all([takeEvery(HubActionType.DeleteHubInit, deleteHubSaga), takeEvery(HubActionType.FetchHub, fetchHubSaga)]);
}
