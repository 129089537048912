import { HubData } from '../models/commonTypeScript';
import { CommonActionRequestType, CommonRequestType } from '../reducers/requestReducer';
import { RequestActionType } from './requestActions';

export enum HubActionType {
    DeleteHubInit = 'DELETE_HUB/INIT',
    DeleteHubSuccess = 'DELETE_HUB_SUCCESS',
    FetchHub = 'FETCH_HUB/INIT',
    FetchHubSuccess = 'FETCH_HUB_SUCCESS',
}

export interface DeleteHub extends CommonActionRequestType {
    type: HubActionType.DeleteHubInit;
    serialNumber: string;
    locationId: string;
}
export const deleteHub = (serialNumber: string, locationId: string): DeleteHub => ({
    type: HubActionType.DeleteHubInit,
    requestType: CommonRequestType.DeleteHub,
    requestActionType: RequestActionType.Init,
    serialNumber,
    locationId,
});

export interface DeleteHubSuccess {
    type: HubActionType.DeleteHubSuccess;
    serialNumber: string;
    locationId: string;
}
export const deleteHubSuccess = (serialNumber: string, locationId: string): DeleteHubSuccess => ({
    type: HubActionType.DeleteHubSuccess,
    serialNumber,
    locationId,
});

export interface FetchHub {
    requestActionType: RequestActionType.Init;
    requestType: CommonRequestType.FetchHub;
    type: HubActionType.FetchHub;
    serialNumber: string;
}

export const fetchHub = (serialNumber: string): FetchHub => ({
    requestActionType: RequestActionType.Init,
    requestType: CommonRequestType.FetchHub,
    type: HubActionType.FetchHub,
    serialNumber,
});

export interface FetchHubSuccess {
    type: HubActionType.FetchHubSuccess;
    hub: HubData;
    serialNumber: string;
}

export const fetchHubSuccess = (hub: HubData, serialNumber: string): FetchHubSuccess => ({
    type: HubActionType.FetchHubSuccess,
    hub,
    serialNumber,
});

export type HubReducerActions = DeleteHub | DeleteHubSuccess | FetchHubSuccess;
