import React, { ReactElement, useEffect, useState } from 'react';
import { groupBy } from 'lodash';
import ReactPlaceholder from 'react-placeholder';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { analyticsLogger } from 'commons/src/analytics';
import { BUILDING_VIEWED_SPACES } from 'commons/src/analytics/AnalyticsEvents';
import FlipButton from 'commons/src/components/buttons/FlipButton';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ModalWrapper from 'commons/src/components/modals/ModalWrapper';
import { fullWidthListPlaceholder } from 'commons/src/components/placeholder';
import { roleRestrictions } from 'commons/src/constants';
import { FeatureToggleEnum, PlacementType } from 'commons/src/models/commonEnums';
import { fetchSpaces } from '../../../actions/spaceActions';
import { OutdoorSpace, IndoorSpace } from '../../../models/spaceModels';
import { Store } from '../../../reducers';
import SpaceFloorPlanView from '../../spaceFloorPlan/SpaceFloorPlanView';
import SpaceAddModal from '../addEdit/SpaceAddModal';
import { spacesSelector } from '../space/spaceSelectors';
import SpacesEmpty from './SpacesEmpty';
import styles from './SpacesLandingPage.module.scss';
import SpacesOutdoorElement from './SpacesOutdoorElement';
import SpacesView from './SpacesView';

const SpacesLandingPage = (): ReactElement => {
    const { buildingId = '' } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const userRole = useSelector((store: Store) => store.userSettings.selectedGroup?.role);
    const [addSpaceOpen, setAddSpaceOpen] = useState(false);
    const [showSpacesView, setShowSpacesView] = useState<boolean>(true);
    const [floorPlanFullScreenMode, setFloorPlanFullScreenMode] = useState<boolean>(false);

    const showFloorPlansToggleOn: boolean = useSelector((store: Store) =>
        store.userSettings.featureToggles.includes(FeatureToggleEnum.enableFloorPlans)
    );

    const {
        spaces: { spaces, availableSensors },
        request,
    } = useSelector((store: Store) => spacesSelector(store, buildingId));

    useEffect(() => {
        const viewQuery: string | null = searchParams.get('view');
        if (showFloorPlansToggleOn && viewQuery === 'floorplans') {
            setShowSpacesView(false);
        }
    }, [showFloorPlansToggleOn]);

    const spacePropsLoading = useSelector((store: Store) => Object.keys(store.config.spacePropDefs).length === 0);
    const spacesByPlacement = groupBy(spaces, 'placement');
    const outdoorSpaces: OutdoorSpace[] = spacesByPlacement[PlacementType.OUTDOOR] as OutdoorSpace[];
    const roomSpaces: IndoorSpace[] = (spacesByPlacement[PlacementType.ROOM] || []) as IndoorSpace[];

    const isLoading = request.loading && spaces.length === 0;

    useEffect(() => {
        dispatch(fetchSpaces(buildingId));
        analyticsLogger(BUILDING_VIEWED_SPACES, { buildingId });
    }, [buildingId]);

    const redirectToFloorPlans = (): void => {
        navigate(`/buildings/${buildingId}/insights?subPage=floorPlan`);
    };

    const onChangeView = (): void => {
        searchParams.set('view', !showSpacesView ? 'spaces' : 'floorplans');
        setSearchParams(searchParams);
        setShowSpacesView(!showSpacesView);
    };

    const spacesView = (): ReactElement => (
        <SpacesView
            spaces={roomSpaces}
            addSpace={(): void => setAddSpaceOpen(true)}
            availableSensors={availableSensors}
            buildingId={buildingId}
        />
    );

    const renderFloorPlanView = (): ReactElement => {
        return floorPlanFullScreenMode ? (
            <ModalWrapper
                isOpen
                onClose={(): void => setFloorPlanFullScreenMode(false)}
                size="fullscreen"
                customHeader={<div />}
            >
                <SpaceFloorPlanView
                    locationId={buildingId}
                    toggleFullscreenMode={(): void => setFloorPlanFullScreenMode(false)}
                    fullscreenMode
                />
            </ModalWrapper>
        ) : (
            <SpaceFloorPlanView
                locationId={buildingId}
                toggleFullscreenMode={(): void => setFloorPlanFullScreenMode(!floorPlanFullScreenMode)}
                fullscreenMode={floorPlanFullScreenMode}
            />
        );
    };

    return (
        <ReactPlaceholder ready={!isLoading && !spacePropsLoading} customPlaceholder={fullWidthListPlaceholder}>
            <div className="container" data-building-no-spaces-page>
                <div className={styles.linkToFloorPlan}>
                    <SpacesOutdoorElement space={outdoorSpaces ? outdoorSpaces[0] : undefined} />
                    {!showFloorPlansToggleOn && (
                        <PrimaryButton
                            color="secondary"
                            onClick={redirectToFloorPlans}
                            title="Space.GoToFloorPlan"
                            trailingIcon={<MaterialIcon name="map" />}
                        />
                    )}
                </div>
                <div className="page-section--full-width">
                    {roomSpaces.length === 0 ? (
                        <SpacesEmpty
                            userRole={userRole}
                            requiredRoleLevel={roleRestrictions.editDeviceOrBuilding}
                            onClick={(): void => setAddSpaceOpen(true)}
                        />
                    ) : (
                        <>
                            {showFloorPlansToggleOn && (
                                <div className={styles.flipButton}>
                                    <FlipButton
                                        id="spaces-view"
                                        onClick={onChangeView}
                                        leftSelected={showSpacesView}
                                        leftText="Spaces.ListView"
                                        rightText="Spaces.FloorPlan"
                                        leftIcon={<MaterialIcon name="list" />}
                                        rightIcon={<MaterialIcon name="map" />}
                                        testIdOff="toggle-floor-plans-view"
                                        testIdOn="toggle-spaces-view"
                                    />
                                </div>
                            )}
                            {showSpacesView ? spacesView() : renderFloorPlanView()}
                        </>
                    )}
                </div>
                {buildingId && (
                    <SpaceAddModal
                        isOpen={addSpaceOpen}
                        onClose={(): void => setAddSpaceOpen(false)}
                        locationId={buildingId}
                    />
                )}
            </div>
        </ReactPlaceholder>
    );
};

export default SpacesLandingPage;
