import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { PollDeviceData, pollDeviceData, stopPollDeviceData } from 'commons/src/actions/DeviceActions';
import { analyticsLogger, PageType, ReportType } from 'commons/src/analytics';
import { INSIGHT_CREATED_REPORT } from 'commons/src/analytics/AnalyticsEvents';
import Error from 'commons/src/components/errorComponents/Error';
import PageHeader from 'commons/src/components/headers/PageHeader';
import SubHeader from 'commons/src/components/headers/SubHeader';
import { IntercomAPI } from 'commons/src/components/Intercom';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { sensorGraphPeriods } from 'commons/src/constants';
import { DeviceTypeNames, SensorTypes } from 'commons/src/models/commonEnums';
import {
    ErrorType,
    onPollDevicePayload,
    OutdoorSensorData,
    SensorData,
    Units,
} from 'commons/src/models/commonTypeScript';
import {
    FetchOutdoorInsightData,
    fetchOutdoorInsightData,
    FetchOutdoorInsightDataType,
} from '../../../actions/outdoorInsightActions';
import { paths } from '../../../constants';
import { BuildingProps } from '../../../models/commonEnums';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import InsightMultipleDevices, { ReportData } from '../reportSelector/InsightMultipleDevices';
import OutdoorInsightResultView from './OutdoorInsightResultView';

interface ActionProps {
    onPollDeviceData: (payload: onPollDevicePayload) => void;
    getOutdoorInsightData: (payload: FetchOutdoorInsightDataType) => void;
}

type StateProps = {
    locationsLoading: boolean;
    locationsError: boolean;
    error?: ErrorType;
    sensorData: { [serialNumber: string]: SensorData };
    outdoorData: {
        history: { [sensorType: string]: OutdoorSensorData };
    };
    loading: boolean;
    units: Units;
};

type Props = StateProps & ActionProps;

const sensors = [SensorTypes.temp, SensorTypes.humidity, SensorTypes.pressure];

export const OutdoorInsightPageComponent = ({
    error,
    locationsError,
    locationsLoading,
    onPollDeviceData,
    sensorData,
    outdoorData,
    loading,
    units,
    getOutdoorInsightData,
}: Props): React.ReactElement => {
    const navigate = useNavigate();

    const { t: txt } = useTranslation();
    const [buildingId, setBuildingId] = useState('');
    const [selectedSensors, setSelectedSensors] = useState<string[]>([]);
    const [displayResultSection, setDisplayResultSection] = useState(false);
    const [sensorDataFailed, setSensorDataFailed] = useState(false);
    const [serialNumber, setSerialNumber] = useState<string>('');
    const [includeLogo, setIncludeLogo] = useState<boolean>(false);

    const optionalBuildingProps = [BuildingProps.floors, BuildingProps.buildingYear];

    useEffect(() => {
        return (): void => {
            stopPollDeviceData();
        };
    }, []);

    useEffect(() => {
        if (displayResultSection && !loading) {
            if (
                sensorData[serialNumber] &&
                sensorData[serialNumber][SensorTypes.temp] &&
                outdoorData.history &&
                outdoorData.history.temp
            ) {
                setSensorDataFailed(false);
            } else {
                setSensorDataFailed(true);
                setDisplayResultSection(false);
            }
        }
    }, [sensorData, outdoorData, displayResultSection, loading]);

    if (locationsError) {
        return <Error />;
    }

    const generateReport = (reportData: ReportData): void => {
        const { serialNumbers, locationId } = reportData;
        setSerialNumber(serialNumbers[0]);
        setBuildingId(locationId);
        setSelectedSensors(reportData.sensors);
        setIncludeLogo(!!reportData.includeLogo);
        analyticsLogger(INSIGHT_CREATED_REPORT, {
            pageType: PageType.Insight,
            reportType: ReportType.Outdoor,
            reportData,
        });

        IntercomAPI('trackEvent', 'generated-outdoor-insight-report', {
            serialNumbers,
            sensors: reportData.sensors,
            logoInReport: reportData.includeLogo,
        });

        onPollDeviceData({
            serialNumber: serialNumbers[0],
            selectedInterval: sensorGraphPeriods.week,
            fetching: true,
            loading: true,
        });
        getOutdoorInsightData({
            from: moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD'),
            locationId,
            unitPreferences: units,
        });
    };

    const errorText = { header: 'SomethingWentWrong', description: 'OutdoorInsight.NotAbleToFindTheOutdoorData' };

    const goBack = (): void => {
        navigate({ pathname: `/${paths.reports}` });
    };
    return (
        <div className="outdoor-insight-view">
            <PageHeader title={txt('OutdoorInsight.OutdoorVsIndoor')} />
            <div className="page-wrapper-flex">
                <SubHeader onGoBack={goBack} backTitle="Reports.Reports" actionButtons={[]} />
            </div>
            <div className="page-wrapper-flex page-wrapper__medium--white">
                <ReactPlaceholder ready={!locationsLoading} customPlaceholder={mediumFormLoader}>
                    <div className="form form__wide-container">
                        <div className="header-2">
                            <span className="text-large">{txt('OutdoorInsight.OutdoorQualityDescription')}</span>
                        </div>
                        <InsightMultipleDevices
                            setDisplayResultSection={setDisplayResultSection}
                            fetchReportDataLoading={loading}
                            fetchReportData={generateReport}
                            possibleSensorTypes={sensors}
                            acceptedDeviceTypes={[
                                DeviceTypeNames.wavePlus,
                                DeviceTypeNames.waveCo2,
                                DeviceTypeNames.mini,
                                DeviceTypeNames.viewPlus,
                                DeviceTypeNames.viewPlusBusiness,
                                DeviceTypeNames.viewCo2,
                                DeviceTypeNames.spaceCo2Mini,
                            ]}
                            minNumberOfDays={7}
                            allowLogo
                            reportStartTime={null}
                            reportEndTime={null}
                            selectSingleDevice
                            optionalBuildingProps={optionalBuildingProps}
                        />
                        {sensorDataFailed && (
                            <ResponseBox text={errorText.header} subtext={txt(errorText.description)} />
                        )}
                    </div>
                </ReactPlaceholder>
            </div>
            {displayResultSection && !error && buildingId && (
                <OutdoorInsightResultView
                    locationId={buildingId}
                    sensors={selectedSensors}
                    serialNumber={serialNumber}
                    includeLogo={includeLogo}
                    optionalBuildingProps={optionalBuildingProps}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { units },
        locations: { locations, error: locationsError, loading: loadingLocations },
        deviceSensorData: { sensorData },
        devicePage: { fetching: fetchingSensorData },
        outdoorInsight: { outdoorData },
        requests: {
            [RequestType.FetchOutdoorInsightData]: { loading },
        },
    } = state;

    return {
        locationsLoading: loadingLocations && locations.length === 0,
        sensorData,
        outdoorData,
        locationsError,
        units,
        loading: loading || fetchingSensorData,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onPollDeviceData: (payload: onPollDevicePayload): PollDeviceData => dispatch(pollDeviceData(payload)),
    getOutdoorInsightData: (payload: FetchOutdoorInsightDataType): FetchOutdoorInsightData =>
        dispatch(fetchOutdoorInsightData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OutdoorInsightPageComponent);
