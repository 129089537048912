import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceHolder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { UpdateApiClientActiveState, updateApiClientActiveState } from '../../../actions/apiClientActions';
import { analyticsLogger, IntegrationType, PageType } from '../../../analytics';
import { INTEGRATION_CLICKED_ADD, INTEGRATION_VIEWED_INTEGRATION } from '../../../analytics/AnalyticsEvents';
import LinkButton from '../../../components/buttons/LinkButton';
import Error from '../../../components/errorComponents/Error';
import SubHeader from '../../../components/headers/SubHeader';
import MaterialIcon from '../../../components/MaterialIcon';
import { userlane } from '../../../components/placeholder';
import {
    linkToAirthingsGithub,
    linkToApiDocs,
    linkToConsumerApiReference,
    paths,
    roleRestrictions,
} from '../../../constants';
import { ApiClient } from '../../../models/apiClient';
import { GroupType } from '../../../models/commonEnums';
import { ErrorType } from '../../../models/commonTypeScript';
import { ActionButton } from '../../../models/menuModels';
import { Store } from '../../../reducers';
import IntegrationRow from '../IntegrationRow';

type StateProps = {
    apiClients: ApiClient[];
    groupType?: GroupType;
    loading: boolean;
    error?: ErrorType;
};

type ActionProps = {
    updateClientDetails: (apiClient: ApiClient) => void;
};

type Props = StateProps & ActionProps;

const analyticsObject = {
    pageType: PageType.Integration,
    page: IntegrationType.API,
};

export const ApiIntegrationPageComponent = ({
    updateClientDetails,
    apiClients,
    loading,
    error,
    groupType,
}: Props): React.ReactElement => {
    const navigate = useNavigate();
    const { t: txt } = useTranslation();

    useEffect(() => analyticsLogger(INTEGRATION_VIEWED_INTEGRATION, analyticsObject));

    if (error) {
        return <Error />;
    }

    const goToAddClient = (): void => {
        navigate({ pathname: `/${paths.addApiClient}` });
        analyticsLogger(INTEGRATION_CLICKED_ADD, analyticsObject);
    };

    const goToIntegration = (id: string): void => {
        navigate({ pathname: `/${paths.apiIntegration}/${id}` });
    };

    const hooksRows = (): React.ReactElement[] | false =>
        apiClients.length > 0 &&
        apiClients.map(client => {
            const update = (active: boolean): void => {
                updateClientDetails({ ...client, active });
            };

            const clientId = [txt('ApiIntegration.ClientId'), ': ', client.id].join('');
            return (
                <IntegrationRow
                    key={client.id}
                    name={client.name}
                    id={client.id}
                    active={client.active}
                    updateIntegration={update}
                    onClick={goToIntegration}
                    firstRow={clientId}
                    secondRow={client.description}
                />
            );
        });

    const isConsumer = groupType === GroupType.consumer;

    const actionButtons: ActionButton[] = [
        {
            onClick: goToAddClient,
            title: isConsumer ? 'ApiIntegration.RequestApiClient' : 'ApiIntegration.NewApiClient',
            id: 'addApiClient',
            color: 'primary',
            testAttr: 'add-api-client',
            icon: <MaterialIcon name="add" />,
            requiredRoleLevel: roleRestrictions.thirdPartyIntegration,
            requiredGroupTypes: [],
        },
    ];
    return (
        <div>
            <SubHeader actionButtons={actionButtons} />
            <h2 className="settings__header settings__header--no-margin-top">{txt('ApiIntegration.ApiIntegration')}</h2>
            <div className="settings-details-container settings-details-container--margin">
                <div>
                    {isConsumer ? txt('ApiIntegration.ConsumerApiDescription') : txt('ApiIntegration.ApiDescription')}
                </div>
                {isConsumer && (
                    <div>
                        {txt('Integrations.LinkToApiCodeExamples')}
                        <a href={linkToAirthingsGithub} target="_blank" rel="noopener noreferrer">
                            github.com/airthings
                        </a>
                    </div>
                )}
                <LinkButton
                    link={isConsumer ? linkToConsumerApiReference : linkToApiDocs}
                    title={isConsumer ? 'ApiIntegration.LinkToConsumerReference' : 'ApiIntegration.LinkToDocs'}
                />
            </div>
            <ul className="list">
                <ReactPlaceHolder ready={!(loading && apiClients.length === 0)} customPlaceholder={userlane}>
                    {hooksRows()}
                </ReactPlaceHolder>
            </ul>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        apiClients: { apiClients },
        userSettings: { selectedGroup },
        commonRequests: {
            FETCH_API_CLIENTS: { loading, error },
        },
    } = state;
    return {
        apiClients,
        groupType: selectedGroup && selectedGroup.groupType,
        loading,
        error,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    updateClientDetails: (apiClient: ApiClient): UpdateApiClientActiveState =>
        dispatch(updateApiClientActiveState(apiClient)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiIntegrationPageComponent);
