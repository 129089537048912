import React, { useState, useMemo, useContext } from 'react';
import { t as txt } from 'i18next';
import { CSVLink } from 'react-csv';
import SubHeader from 'commons/src/components/headers/SubHeader';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ReactPdfDownloadModal from 'commons/src/components/PDF/ReactPdfDownloadModal';
import { roleRestrictions } from 'commons/src/constants';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import { ActionButton } from 'commons/src/models/menuModels';
import { SearchContext } from '../../../components/search/Search';
import { BuildingHealthRowData } from '../../../models/buildingHealthModels';
import { Store } from '../../../reducers';
import { useTypedSelector } from '../../../store';
import {
    assignStatus,
    formatBatteryLevel,
    formatConnections,
    formatFirmware,
    formatLastSeen,
    formatRssi,
} from './components/helpers/csv';

type Props = {
    hubs: BuildingHealthRowData[];
    devices: BuildingHealthRowData[];
    buildingId: string;
    isStreaming?: boolean;
};

const BuildingHealthSubHeader = ({ hubs, devices, buildingId, isStreaming }: Props): React.ReactElement => {
    const [csvModalOpened, setCsvModalOpened] = useState(false);
    const { bind } = useContext(SearchContext);

    const locations = useTypedSelector((state: Store) => state.locations.locations);
    const devicesMetadata = useTypedSelector(state => state.devices.devicesWithKeyInfo);
    const building = locations.find(location => location.id === buildingId);
    const buildingName = building?.name;

    const mapBuildingHealthRowToCSVData = (isHub: boolean) => (healthRow: BuildingHealthRowData) => {
        const metadata = devicesMetadata[healthRow.serialNumber];
        return {
            Name: healthRow.segmentName,
            Type: txt(isHub ? DeviceTypeNames.hub : metadata?.type),
            'Serial number': healthRow.serialNumber,
            Floor: isHub ? 'n/a' : healthRow.floor ?? '-',
            Battery: isHub ? 'n/a' : formatBatteryLevel(healthRow.batteryPercentage),
            RSSI: isHub ? 'n/a' : formatRssi(healthRow.rssi),
            Firmware: formatFirmware(healthRow.firmware),
            'Radio region': isHub ? healthRow.radioRegion ?? '-' : 'n/a',
            Connection: isHub ? formatConnections(healthRow) : 'n/a',
            'Last synced': formatLastSeen(healthRow.lastSeen),
            Status: assignStatus(isHub, healthRow.healthStatus),
        };
    };

    const hubsData = useMemo(() => hubs.map(mapBuildingHealthRowToCSVData(true)), [hubs, devicesMetadata]);
    const devicesData = useMemo(() => devices.map(mapBuildingHealthRowToCSVData(false)), [devices, devicesMetadata]);
    const fileName = `${buildingName} - Device list`;
    const content = [...hubsData, ...devicesData];

    const actionButton: ActionButton = {
        id: 'download_health_status_data',
        title: 'HealthStatus.DownloadCSV',
        color: 'tertiary',
        icon: <MaterialIcon name="download" />,
        testAttr: 'download_health_status_data',
        onClick: () => setCsvModalOpened(true),
        requiredGroupTypes: [],
        requiredRoleLevel: roleRestrictions.buildingHealth,
    };

    return (
        <>
            <SubHeader
                actionButtons={content.length ? [actionButton] : []}
                onSearchUpdate={bind}
                disabled={isStreaming}
            />
            {csvModalOpened && (
                <ReactPdfDownloadModal title="DownloadCSVTitle" onClose={(): void => setCsvModalOpened(false)}>
                    <div key={`csvDownloadElement-${fileName}`} className="flex flex--center small-padding-bottom">
                        <MaterialIcon name="download" />
                        <CSVLink data={content} separator=";" filename={`${fileName}.csv`}>
                            {fileName}
                        </CSVLink>
                    </div>
                </ReactPdfDownloadModal>
            )}
        </>
    );
};

export default BuildingHealthSubHeader;
