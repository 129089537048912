import React, { RefObject, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CircleButton from 'commons/src/components/buttons/CircleButton';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import ModalHeader from 'commons/src/components/modals/ModalHeader';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import {
    renewMqttClientCertificate,
    RenewMqttClientCertificate,
    UploadMqttClientCertificate,
    uploadMqttClientCertificate,
} from '../../../actions/mqttIntegrationActions';
import { MqttClient, MqttClientCertificate } from '../../../models/common';
import { Store } from '../../../reducers';
import { BusinessRequestType } from '../../../reducers/BusinessRequestType';

type PassedProps = {
    onClose: () => void;
    isOpen: boolean;
    isRenewing: boolean;
    client: MqttClient | undefined;
};

type StateProps = {
    certificate: MqttClientCertificate | undefined;
    loading: boolean;
};

type ActionProps = {
    onUploadCertificate: (clientId: string, certificate: string) => void;
    onRenewCertificate: (clientId: string, certificate: string) => void;
};

export type Props = PassedProps & StateProps & ActionProps;

const UploadCertificateModal = (props: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { onClose, isOpen, isRenewing, client, certificate, loading, onUploadCertificate, onRenewCertificate } =
        props;
    const uploadInputRef: RefObject<HTMLInputElement> = React.createRef();

    const certMaxSize = 35000;
    const [certificateToUpload, setCertificateToUpload] = useState('');
    const [certificateSizeTooLarge, setCertificateSizeTooLarge] = useState(false);
    const [fileName, setFileName] = useState('');
    const [invalidFileType, setInvalidFileType] = useState(false);
    const [uploadPressed, setUploadPressed] = useState(false);

    useEffect(() => {
        if (uploadPressed && !loading) {
            onClose();
        }
    }, [loading, certificate]);

    const triggerFileUpload = (): void => {
        const fileLoader = uploadInputRef.current;
        if (fileLoader) {
            fileLoader.click();
        }
    };

    const handleFileChange = ({ currentTarget }: SyntheticEvent<HTMLInputElement>): void => {
        const file = currentTarget.files && currentTarget.files.length > 0 && currentTarget.files[0];
        setInvalidFileType(false);
        if (!file) return;
        if (!file.type.includes('application/x-x509-ca-cert')) {
            setInvalidFileType(true);
        } else if (file.size > certMaxSize) {
            setCertificateSizeTooLarge(true);
        }

        // eslint-disable-next-line no-undef
        const reader = new FileReader();
        reader.onloadend = (): void => {
            const base64Cert: string = reader.result as string;
            setCertificateToUpload(base64Cert.split(',')[1]);
            setFileName(file.name);
        };
        reader.readAsDataURL(file);
    };

    const onUploadClick = (): void => {
        setUploadPressed(true);
        const valid =
            client &&
            fileName.length > 0 &&
            certificateToUpload.length > 0 &&
            !certificateSizeTooLarge &&
            !invalidFileType;
        if (!valid) return;
        if (isRenewing) {
            onRenewCertificate(client.id, certificateToUpload);
        } else {
            onUploadCertificate(client.id, certificateToUpload);
        }
    };

    const invalidFileMessage = (): string => {
        if (uploadPressed && !(certificateToUpload.length > 0)) {
            return txt('Mqtt.CertFileHint');
        }
        if (invalidFileType) {
            return txt('Mqtt.InvalidCertFileType');
        }
        if (certificateSizeTooLarge) {
            return txt('CertificateTooLargeMaxSize', { size: `${certMaxSize / 1000000}MB` });
        }
        return '';
    };

    return (
        <Modal
            isOpen={isOpen}
            appElement={document.body}
            onRequestClose={onClose}
            className="modal__content modal__content--size-medium"
            overlayClassName="modal modal__overlay"
        >
            <ModalHeader headerText="Mqtt.UploadCert" onClose={onClose} />
            <form>
                <div className="add-cert">
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        accept="application/x-x509-ca-cert"
                        ref={uploadInputRef}
                    />
                    <div className="input-container input-container--margin-bottom">
                        <div className="add-cert__input-upload">
                            <span>{txt('Mqtt.UploadCertLabel')}</span>
                            <div className="add-cert__input-element">
                                <CircleButton
                                    onClick={triggerFileUpload}
                                    testAttr="file-upload"
                                    iconName="upload"
                                    color="secondary"
                                />
                                <span className="add-cert__file-name">{fileName || txt('NoFileChosen')}</span>
                            </div>
                        </div>
                        {invalidFileMessage() !== '' && (
                            <ResponseBox text="SomethingWentWrong" subtext={invalidFileMessage()} />
                        )}
                    </div>
                    <div className="form__row form__button-container">
                        <div className="form__attr--element">
                            <PrimaryButton
                                color="secondary"
                                id="get-aws-cert-button"
                                onClick={onClose}
                                type="button"
                                title="Cancel"
                                testAttr="close-mqtt-upload-cert"
                            />
                        </div>
                        <div className="form__attr--element">
                            <PrimaryButton
                                id="upload-cert-button"
                                onClick={onUploadClick}
                                type="button"
                                title="Upload"
                                loading={loading}
                                color="primary"
                                testAttr="mqtt-upload-cert"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        mqtt: { certificate },
        requests: {
            [BusinessRequestType.UploadMqttClientCertificate]: { loading: uploadLoading },
            [BusinessRequestType.RenewMqttClientCertificate]: { loading: renewLoading },
        },
    } = state;
    return {
        certificate,
        loading: uploadLoading || renewLoading,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onUploadCertificate: (clientId: string, certificate: string): UploadMqttClientCertificate =>
        dispatch(uploadMqttClientCertificate(clientId, certificate)),
    onRenewCertificate: (clientId: string, certificate: string): RenewMqttClientCertificate =>
        dispatch(renewMqttClientCertificate(clientId, certificate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadCertificateModal);
