import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import { LatLngExpression } from 'leaflet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PageWrapper from 'commons/src/components/containers/PageWrapper';
import SpinnerBlocker from 'commons/src/components/modals/ModalSpinnerBlocker';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { FloorPlanPosition } from 'commons/src/models/commonTypeScript';
import { createFloorPlanSpaceZone, editFloorPlanSpaceZone } from '../../../actions/floorPlanSpaceActions';
import { FloorPlanData, SpaceFloorPlanMode } from '../../../models/spaceFloorPlanModels';
import { Store } from '../../../reducers';
import AddFloorPlan from '../addFloorPlan/AddFloorPlan';
import FloorMap from './FloorMap';
import FloorPlanHeader from './FloorPlanHeader';
import styles from './FloorPlanView.module.scss';

export type Props = {
    floorPlanId?: string;
    floorPlanData?: FloorPlanData;
    addingNewFloor: boolean;
    locationId: string;
    fullscreenMode: boolean;
    toggleFullScreenMode: () => void;
};
const FloorPlanView = ({
    floorPlanId,
    floorPlanData,
    addingNewFloor,
    locationId,
    fullscreenMode,
    toggleFullScreenMode,
}: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const floorPlans = useSelector((state: Store) => state.floorPlansSpace.floorPlans);
    const selectedFloorPlan = floorPlans.find(floorPlan => floorPlan.id === floorPlanId);
    const { loading, error } = useSelector((state: Store) => state.requests.GET_FLOOR_PLAN_DATA);
    const [mode, setMode] = useState<SpaceFloorPlanMode | undefined>(undefined);
    const [spaceZone, setSpaceZone] = useState<undefined | number[][] | LatLngExpression[][]>(undefined);
    const [selectedSpace, setSelectedSpace] = useState<string | undefined>(undefined);

    const onSaveSpaceZone = (): void => {
        if (selectedFloorPlan && spaceZone && selectedSpace) {
            const boundary = spaceZone.map(zone => ({ lat: zone[0], lng: zone[1] }));
            const payload: [{ boundary: FloorPlanPosition[] }, string, string, string] = [
                { boundary: boundary as FloorPlanPosition[] },
                locationId,
                selectedFloorPlan.id,
                selectedSpace,
            ];
            if (mode === SpaceFloorPlanMode.CREATE) {
                dispatch(createFloorPlanSpaceZone(...payload));
            } else {
                dispatch(editFloorPlanSpaceZone(...payload));
            }
        }
    };
    const onSetMode = (spaceMode: SpaceFloorPlanMode | undefined): void => {
        if (spaceMode === undefined) {
            setSpaceZone(undefined);
            setSelectedSpace(undefined);
        } else if (spaceMode === SpaceFloorPlanMode.EDIT) {
            const zoneToEdit = floorPlanData?.spaces.find(zone => zone.id === selectedSpace);
            setSpaceZone(zoneToEdit?.boundary.map(zone => [zone.lat, zone.lng]));
        }
        setMode(spaceMode);
    };

    const onUpdateZone = (zone: number[][] | LatLngExpression[][]): void => {
        setSpaceZone(zone);
    };

    return (
        <div className={classNames(styles.wrapper, { [styles.fullscreenMode]: fullscreenMode })}>
            {selectedFloorPlan && !addingNewFloor ? (
                <>
                    <FloorPlanHeader
                        selectedFloorPlan={selectedFloorPlan}
                        locationId={locationId}
                        floorPlanId={floorPlanId}
                        mode={mode}
                        selectedSpace={selectedSpace}
                        setMode={onSetMode}
                        saveZone={onSaveSpaceZone}
                        setSelectedSpace={setSelectedSpace}
                    />
                    <SpinnerBlocker isLoading={loading && !error} className={styles.spinnerBlock}>
                        {floorPlanData?.image ? (
                            <FloorMap
                                floorData={floorPlanData}
                                mode={mode}
                                locationId={locationId}
                                setSelectedSpace={setSelectedSpace}
                                selectedSpace={selectedSpace}
                                setSpaceZone={onUpdateZone}
                                spaceZone={spaceZone}
                                fullscreenMode={fullscreenMode}
                                toggleFullScreenMode={toggleFullScreenMode}
                            />
                        ) : (
                            <ResponseBox text={`ErrorCodes.${error?.error}`} />
                        )}
                    </SpinnerBlocker>
                </>
            ) : (
                <div className={styles.newFloorPlanWrapper}>
                    <PageWrapper pageType="slim">
                        <h3 className={styles.newFloorPlanHeader}>{txt('SpaceFloorPlan.AddNewFloorPlan')}</h3>
                    </PageWrapper>
                    <AddFloorPlan locationId={locationId} />
                </div>
            )}
        </div>
    );
};
export default FloorPlanView;
