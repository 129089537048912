import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import Input from 'commons/src/components/input/Input';
import ModalWrapper from 'commons/src/components/modals/ModalWrapper';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { GroupType } from 'commons/src/models/commonEnums';
import { updatePoRef } from '../../../actions/subscriptionActions';
import { Store } from '../../../reducers';
import styles from './EditPoModal.module.scss';

type Props = {
    poNumber: string;
    subscriptionNumber: string;
    onClose: () => void;
    isOpen: boolean;
    groupType: GroupType;
    customerUserGroupId?: string;
};
const EditPoModal = ({
    poNumber,
    isOpen,
    onClose,
    subscriptionNumber,
    groupType,
    customerUserGroupId,
}: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const [newPoRef, setNewPoRef] = React.useState('');
    const { loading, error } = useSelector((state: Store) => state.requests.UPDATE_PO_REF);
    const onSave = (): void => {
        dispatch(updatePoRef(subscriptionNumber, newPoRef.trim(), groupType, customerUserGroupId));
    };

    return (
        <ModalWrapper isOpen={isOpen} onClose={onClose} header="Subscription.UpdatePoRef" size="medium">
            <div className={styles.body}>
                <div className={styles.currentPo}>
                    {txt('Subscription.CurrentPo')}
                    <span className={styles.poRef}>{poNumber}</span>
                </div>
                <Input
                    type="text"
                    id="edit-po-input"
                    label="Subscription.NewPoRef"
                    validate={false}
                    onChange={(e): void => setNewPoRef(e.currentTarget.value)}
                    maxLength={100}
                />
                <div>{txt('Subscription.NewPoInfo')}</div>
                {error && <ResponseBox text={`ErrorCodes.${error.error}`} />}
                <div className={styles.buttonWrapper}>
                    <PrimaryButton color="secondary" onClick={onClose} title="Cancel" />
                    <PrimaryButton onClick={onSave} title="Subscription.Update" color="primary" loading={loading} />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default EditPoModal;
