import React from 'react';
import classNames from 'classnames';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { hubConnectionIsUp } from '../../commonFunctions';
import NotSynced from '../../components/device/NotSynced';
import OfflineDevice from '../../components/device/OfflineDevice';
import MaterialIcon from '../../components/MaterialIcon';
import Ethernet from '../../img/icon-ethernet';
import { HubResponseMetaData } from '../../models/commonTypeScript';
import { getHubConnectionString } from './hubFunctions';
import styles from './HubListElement.module.scss';
import HubListHeader from './HubListHeader';

export type Props = {
    name: string;
    serialNumber: string;
    dateFormat: string;
    numberOfConnectedDevices: number;
    numberOfDevices: number;
    metaData: HubResponseMetaData;
};

const HubListElement = (props: Props): React.ReactElement => {
    const { name, serialNumber, dateFormat, numberOfConnectedDevices, numberOfDevices, metaData } = props;
    const { t: txt } = useTranslation();
    const { lastSeen } = metaData;
    const hubRecentlySynced = hubConnectionIsUp(lastSeen);
    const numberOfLostDevices = hubRecentlySynced ? numberOfDevices - numberOfConnectedDevices : 0;
    const hubConnectionType = getHubConnectionString(metaData, txt);
    const language = txt('MomentLanguage');
    const offlineText = lastSeen
        ? txt('DeviceSettings.OfflineForTime', {
              time: moment.utc(lastSeen).local().locale(language).fromNow(true),
          })
        : 'NoHubConnection';

    const hubSyncedSection = (): JSX.Element => {
        if (hubRecentlySynced) {
            return (
                <div className={styles.aligned}>
                    <MaterialIcon name="cloud" />
                    <span className={styles.grayText}>{txt('Connected')}</span>
                </div>
            );
        }
        if (metaData.lastSeen) {
            return <OfflineDevice text={offlineText} extraClass={styles.offline} deviceOrHub="hub" />;
        }
        return <NotSynced text="neverSynced" extraClass={styles.notSynced} />;
    };

    const simCardSection = (): JSX.Element | null => {
        const { cell } = metaData;
        if (cell !== undefined) {
            return (
                <div className={styles.ethernetAndSim}>
                    {cell ? <MaterialIcon extraClass="icon-rotated" name="sim_card" /> : <Ethernet />}
                    <span className={styles.grayText}>{hubConnectionType}</span>
                </div>
            );
        }
        return null;
    };
    return (
        <NavLink to={`/hub/${serialNumber}`} className="tile-wrapper__link list-tile--link" data-tile>
            <div className={classNames('list-tile', styles.wrap)}>
                <div className={styles.info}>
                    <HubListHeader
                        name={name}
                        lastSeen={lastSeen}
                        serialNumber={serialNumber}
                        dateFormat={dateFormat}
                    />
                    <div className={styles.status}>
                        {hubRecentlySynced && metaData.lastSeen && (
                            <>
                                <div className={styles.aligned}>{hubSyncedSection()}</div>
                                <div className={styles.aligned}>{simCardSection()}</div>
                            </>
                        )}
                    </div>
                </div>
                <div className={hubRecentlySynced ? styles.noDisplay : styles.connected}>
                    {!hubRecentlySynced ? (
                        hubSyncedSection()
                    ) : (
                        <div className={styles.devices}>
                            <div>
                                <span className={styles.textBold}>{numberOfConnectedDevices}</span>
                                {` ${txt('HubStrings.ConnectedDevices')}`}
                            </div>
                            {numberOfLostDevices > 0 && (
                                <span className={styles.lostDevices}>
                                    <rect className={styles.senorLine} />
                                    <div>
                                        <span className={styles.textBold}> {numberOfLostDevices} </span>
                                        {` ${txt('HubStrings.LostRecently')}`}{' '}
                                    </div>
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </NavLink>
    );
};

export default HubListElement;
