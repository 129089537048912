import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { Store } from 'business-dashboard/src/reducers';
import {
    FetchDashboard,
    fetchDashboard,
    updateDashboardLayout,
    UpdateDashboardLayout,
} from '../../actions/DashboardActions';
import { analyticsLogger, PageType } from '../../analytics';
import { DASHBOARD_RESET_DASHBOARD, PUBLIC_DASHBOARD_CREATE_CLICKED } from '../../analytics/AnalyticsEvents';
import { userIsHbs } from '../../components/findUserType';
import SubHeader from '../../components/headers/SubHeader';
import MaterialIcon from '../../components/MaterialIcon';
import { businessPaths, paths, roleRestrictions } from '../../constants';
import { DashboardVisibility } from '../../models/commonEnums';
import { DashboardProps, DashboardTile } from '../../models/commonTypeScript';
import { MenuItem, ActionButton, OptionButtonType } from '../../models/menuModels';
import SharedDashboardLinkModal from './PublicDashboardSettings/ShareDashboardLinkModal';
import SwitchDashboardModal from './PublicDashboardSettings/SwitchDashboardModal';

type StateProps = {
    dashboardList?: { name: string; id: string }[];
    dashboardProps: DashboardProps | undefined;
};

type ActionProps = {
    updateDashboardData: (config: { tiles: DashboardTile[]; dashboardId: string }) => void;
    fetchDashboardData: () => void;
};

export type Props = StateProps & ActionProps;

export const DashboardSubHeaderComponent = ({
    dashboardList,
    fetchDashboardData,
    updateDashboardData,
    dashboardProps,
}: Props): React.ReactElement => {
    const navigate = useNavigate();
    const [openSwitchModal, setOpenSwitchModal] = useState(false);
    const [openPublicUrlModal, setOpenPublicUrlModal] = useState(false);

    const switchDashboard = (): void => {
        setOpenSwitchModal(!openSwitchModal);
    };

    const getPublicUrl = (): void => {
        setOpenPublicUrlModal(!openPublicUrlModal);
    };

    const onClickAddTile = (): void => {
        navigate({ pathname: `/${paths.addTile}` });
    };

    const onClickAddDashboard = (): void => {
        analyticsLogger(PUBLIC_DASHBOARD_CREATE_CLICKED, { pageType: PageType.PublicDashboard });
        navigate({ pathname: `/${businessPaths.addDashboard}` });
    };

    const resetDashboard = (): void => {
        if (dashboardProps && dashboardProps.id) {
            updateDashboardData({ tiles: [], dashboardId: dashboardProps.id });
            fetchDashboardData();
            analyticsLogger(DASHBOARD_RESET_DASHBOARD, { pageType: PageType.Dashboard });
        }
    };

    const visibility = (dashboardProps && dashboardProps.visibility) || DashboardVisibility.user;
    const hasPublicDashboards = !!dashboardList && dashboardList.length > 1;
    const dashboardIsPrivate = visibility === DashboardVisibility.user;

    const dashboardDropdownOptions: MenuItem[] = [
        {
            onClick: resetDashboard,
            text: 'ResetDashboard',
            id: 'resetDashboard',
            requiredRoleLevel: roleRestrictions.dashboardTiles,
            requiredGroupTypes: [],
        },
    ];

    const addDashboardMenuItem: MenuItem = {
        onClick: onClickAddDashboard,
        id: 'addPublicDashboard',
        text: 'DashboardSettings.AddPublicDashboard',
        requiredRoleLevel: roleRestrictions.editPublicDashboard,
        requiredGroupTypes: [],
    };

    const swapDashboardMenuItem: MenuItem = {
        id: 'swapDashboard',
        onClick: switchDashboard,
        text: 'PublicDashboard.SwapDashboard',
        requiredRoleLevel: roleRestrictions.editPublicDashboard,
        requiredGroupTypes: [],
    };

    const isHbs = userIsHbs();
    if (isHbs) {
        dashboardDropdownOptions.splice(0, 0, addDashboardMenuItem);
    }
    if (hasPublicDashboards) {
        dashboardDropdownOptions.splice(0, 0, swapDashboardMenuItem);
    }
    const optionButton: OptionButtonType = {
        id: 'addTile',
        title: 'AddTile',
        onClick: onClickAddTile,
        color: 'primary',
        options: dashboardDropdownOptions,
        testAttr: 'add-tile',
        requiredRoleLevel: roleRestrictions.dashboardTiles,
        requiredGroupTypes: [],
    };

    const actionButtons: ActionButton[] = !dashboardIsPrivate
        ? [
              {
                  onClick: getPublicUrl,
                  icon: <MaterialIcon name="share" />,
                  title: 'PublicDashboard.Share',
                  requiredRoleLevel: roleRestrictions.dashboardTiles,
                  id: 'publicDashboardShare',
                  color: 'tertiary',
                  testAttr: 'public-dashboard-share',
                  requiredGroupTypes: [],
              },
              {
                  onClick: (): void => navigate(`/${businessPaths.dashboardSettings}`),
                  icon: <MaterialIcon name="settings" />,
                  title: 'PublicDashboard.DashboardSettings',
                  requiredRoleLevel: roleRestrictions.editPublicDashboard,
                  id: 'publicDashboardSettings',
                  color: 'tertiary',
                  testAttr: 'public-dashboard-settings',
                  requiredGroupTypes: [],
              },
          ]
        : [];

    return (
        <div className="page-wrapper">
            {openSwitchModal && <SwitchDashboardModal onClose={switchDashboard} />}
            {openPublicUrlModal && <SharedDashboardLinkModal onClose={getPublicUrl} />}
            <SubHeader actionButtons={actionButtons} optionButton={optionButton} />
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        dashboardData: { dashboardProps, dashboardList },
    } = state;

    return {
        dashboardProps,
        dashboardList,
    };
};
const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    updateDashboardData: (config): UpdateDashboardLayout => dispatch(updateDashboardLayout(config)),
    fetchDashboardData: (): FetchDashboard => dispatch(fetchDashboard()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSubHeaderComponent);
