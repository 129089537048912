// radon home report options
export const roomTypeOptions = ['Bedroom', 'LivingRoom', 'Hall', 'Kitchen', 'Attic', 'Office', 'Other'];

export const ventilationTypeOptions = ['Natural', 'Mechanical', 'Balanced'];

export const floorOptions = ['Basement', 'FirstFloor', 'SecondFloor', 'ThirdFloor', 'FourthOrHigherFloor'];

export const buildingTypeOptions = ['House', 'Rental', 'School', 'Workplace', 'Other'];

export const businessBuildingTypes = [
    'School',
    'Residential',
    'Apartment',
    'Kindergarten',
    'HealthCenter',
    'Office',
    'Other',
];
