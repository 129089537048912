import React, { useEffect, useState } from 'react';
import moment, { Moment, unitOfTime } from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import FilterButton from 'commons/src/components/buttons/FilterButton';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import DatePicker from 'commons/src/components/DatePicker';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { dateFormats } from 'commons/src/constants';
import { TimePeriod } from 'commons/src/models/commonEnums';
import { Store } from '../../../reducers';

type ParentProps = {
    selectedPeriod: TimePeriod;
    setSelectedPeriod: (period: TimePeriod, fromDate: string, toDate: string) => void;
};

type StateProps = {
    dateFormat: string;
};

type Props = ParentProps & StateProps;

const PartnerSummaryDateSelector = ({ selectedPeriod, setSelectedPeriod, dateFormat }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const [startDate, setStartDate] = useState<Moment | null>(null);
    const [endDate, setEndDate] = useState<Moment | null>(null);

    const [displayDatePicker, setDisplayDatePicker] = useState(false);

    const isOutsideDateRange = (day: Moment): boolean => {
        if (!moment.isMoment(day)) return false;

        const today = moment();
        return day.isAfter(today.subtract(1, 'days').endOf('day'));
    };

    const isOutsideEndDateRange = (day: Moment, selectedStartDate: Moment | null): boolean => {
        const sevenDaysAfterStartDate = selectedStartDate
            ? moment(day).endOf('day').diff(moment(selectedStartDate).add(7, 'days').endOf('day')) > 0
            : false;
        const isBeforeStartDate = moment(day).endOf('day').isBefore(moment(selectedStartDate).endOf('day'));
        if (isBeforeStartDate || sevenDaysAfterStartDate) {
            return true;
        }
        return isOutsideDateRange(day);
    };

    const onStartDateChanged = (date: Moment): void => {
        if (moment(date).startOf('day').valueOf() !== moment(startDate).valueOf()) {
            setStartDate(date);
            const endDateMoreThan7DaysAfterStart =
                endDate && date && moment(date).add(7, 'days').endOf('day').isBefore(endDate);
            const startDateAfterEndDate = endDate && date && date.endOf('day').isAfter(endDate.endOf('day'));
            if (endDateMoreThan7DaysAfterStart || startDateAfterEndDate) {
                setEndDate(null);
            }
        }
    };

    const selectPeriod = (period: TimePeriod): void => {
        const to =
            period === TimePeriod.day
                ? moment().format('YYYY-MM-DD')
                : moment().subtract(1, 'day').format('YYYY-MM-DD');
        let from = moment().subtract(1, period as unitOfTime.DurationConstructor);
        if (period === TimePeriod.yesterday) {
            from = moment().subtract(1, 'day');
        }
        if (period !== TimePeriod.custom) {
            setStartDate(null);
            setEndDate(null);
        }
        setSelectedPeriod(period, from.format('YYYY-MM-DD'), to);
    };

    const fetchCustomTimeSpan = (): void => {
        if (startDate && endDate) {
            setSelectedPeriod(TimePeriod.custom, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
        }
    };

    useEffect(() => {
        fetchCustomTimeSpan();
    }, [startDate, endDate]);

    const onEndDateChanged = (date: Moment): void => {
        if (moment(date).startOf('day').valueOf() !== moment(endDate).valueOf()) {
            setEndDate(date);
        }
    };

    const customTitle =
        startDate && endDate
            ? `${startDate.format(dateFormats[dateFormat as keyof typeof dateFormats].shortFormat)} - ${endDate.format(
                  dateFormats[dateFormat as keyof typeof dateFormats].shortFormat
              )}`
            : 'TimeSelector.Custom';

    return (
        <div className="building-insight__time-selector">
            <div className="building-insight__time-selector__elements">
                {displayDatePicker ? (
                    <>
                        <PrimaryButton
                            color="tertiary"
                            icon={<MaterialIcon name={displayDatePicker ? 'close' : 'date_range'} />}
                            onClick={(): void => setDisplayDatePicker(false)}
                            testAttr="date-picker"
                        />
                        <div className="text-small">{txt('TimeSelector.CustomMaxDays', { days: '7' })}</div>
                        <div className="small-padding-right">
                            <DatePicker
                                id="startDate"
                                onChange={onStartDateChanged}
                                dateFormat={dateFormat as keyof typeof dateFormats}
                                selectedDate={startDate}
                                initialVisibleMonth={moment()}
                                placeholder="StartDate"
                                disabledDate={(day): boolean => isOutsideDateRange(day)}
                            />
                        </div>
                        <DatePicker
                            id="endDate"
                            onChange={onEndDateChanged}
                            dateFormat={dateFormat as keyof typeof dateFormats}
                            selectedDate={endDate}
                            initialVisibleMonth={moment()}
                            disabledDate={(day): boolean => isOutsideEndDateRange(day, startDate)}
                            placeholder="EndDate"
                        />
                    </>
                ) : (
                    <>
                        <FilterButton
                            title="NotificationAlerts.Yesterday"
                            isSelected={selectedPeriod === TimePeriod.yesterday}
                            onClick={(): void => selectPeriod(TimePeriod.yesterday)}
                        />
                        <FilterButton
                            title={customTitle}
                            isSelected={selectedPeriod === TimePeriod.custom}
                            onClick={(): void => setDisplayDatePicker(true)}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat },
    } = state;
    return { dateFormat };
};

export default connect(mapStateToProps)(PartnerSummaryDateSelector);
