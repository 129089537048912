import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import { SPACE_REMOVED_DEVICE } from 'commons/src/analytics/AnalyticsEvents';
import DeleteConfirmModal from 'commons/src/components/modals/DeleteConfirmModal';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import { removeDeviceFromSpace, RemoveDevicePayload } from '../../../../actions/spaceActions';
import { SpaceDeviceEntity } from '../../../../models/spaceModels';
import { Store } from '../../../../reducers';

type RemoveDeviceModalProps = {
    visible: boolean;
    hide: () => void;
    payload: RemoveDevicePayload;
    device: SpaceDeviceEntity;
};

const SpaceRemoveDeviceModal = ({ visible, hide, payload, device }: RemoveDeviceModalProps): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [isRequestInProgress, setIsRequestInProgress] = useState(false);
    const isHub = device.deviceType === DeviceTypeNames.hub; // TODO This modal is not used to delete a hub

    const dispatch = useDispatch();
    const request = useSelector((state: Store) => state.requests.REMOVE_DEVICE_FROM_SPACE);

    const handleDelete = (): void => {
        setIsRequestInProgress(true);
        dispatch(removeDeviceFromSpace(payload));
        analyticsLogger(SPACE_REMOVED_DEVICE, {
            payload,
        });
    };

    useEffect(() => {
        if (isRequestInProgress && !request.loading && !request.error) {
            setIsRequestInProgress(false);
            hide();
        }
    }, [isRequestInProgress, request]);

    if (!visible) return <div />;
    return (
        <DeleteConfirmModal
            onSubmit={handleDelete}
            onCancel={hide}
            onSubmitText="Remove"
            onCancelText="Cancel"
            title={isHub ? 'Space.RemoveHubModalTitle' : 'Space.RemoveDeviceModalTitle'}
            description={
                isHub
                    ? txt('Space.RemoveHubModal', { segmentName: device?.segmentName })
                    : txt('Space.RemoveDeviceModal', { segmentName: device?.segmentName })
            }
            error={!!request.error}
            errorText={`ErrorCodes.${request?.error}`}
            loading={request.loading}
            centerBody
        />
    );
};

export default SpaceRemoveDeviceModal;
