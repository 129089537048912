import React, { ReactElement, useState } from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { CSVDownloadDetails, downloadCSV } from '../../../actions/DownloadCSVActions';
import { analyticsLogger, PageType } from '../../../analytics';
import { DEVICE_DOWNLOADED_CSV } from '../../../analytics/AnalyticsEvents';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import DatePicker from '../../../components/DatePicker';
import Dropdown from '../../../components/dropdown/MultipleAttrDropdown';
import MaterialIcon from '../../../components/MaterialIcon';
import ErrorCatcher from '../../../components/modals/ModalErrorCatcher';
import SpinnerBlocker from '../../../components/modals/ModalSpinnerBlocker';
import ModalWrapper from '../../../components/modals/ModalWrapper';
import { dateFormats } from '../../../constants';
import { Store } from '../../../reducers';
import styles from './CSVDownloadModal.module.scss';

export interface CsvSegment {
    serialNumber: string;
    deviceType: string;
    name: string;
    segmentEndDate?: string;
    segmentStartDate?: string;
    segmentId: string;
}

export type StateProps = {
    isLoading: boolean;
    error: boolean;
    csvDownloadDetails: CSVDownloadDetails[];
    dateFormat: keyof typeof dateFormats;
};

type ParentProps = {
    onClose: () => void;
    segments: CsvSegment[];
};

export type Props = StateProps & ParentProps;

export const isOutsideEndDateRangeFunc = (segmentEndDate: string | undefined, startDate: moment.Moment | undefined) => {
    return (day: Moment): boolean => {
        if (!moment.isMoment(day)) return false;

        const isAfterSegmentEndDate: boolean =
            moment(day).endOf('day').diff(moment(segmentEndDate).endOf('day'), 'days') > 0;
        const isBeforeStartDate: boolean = moment(day).endOf('day').isBefore(moment(startDate).endOf('day'));
        const isAfterToday: boolean = day.diff(moment().endOf('day'), 'days') > 0;
        const isYearAfterStartDate: boolean =
            moment(day).endOf('day').diff(moment(startDate).add(1, 'years').endOf('day')) > 0;
        return isBeforeStartDate || isAfterToday || isYearAfterStartDate || isAfterSegmentEndDate;
    };
};

export const isOutsideStartDateRangeFunc = (
    segmentStartDate: string | undefined,
    segmentEndDate: string | undefined
) => {
    return (day: Moment): boolean => {
        if (!moment.isMoment(day)) return false;

        const isAfterSegmentEndDate: boolean =
            moment(day).endOf('day').diff(moment(segmentEndDate).endOf('day'), 'days') > 0;
        const afterToday: boolean = day.diff(moment().endOf('day'), 'days') > 0;
        const beforeStartDate: boolean =
            moment(day).endOf('day').diff(moment(segmentStartDate).endOf('day'), 'days') < 0;

        return afterToday || beforeStartDate || isAfterSegmentEndDate;
    };
};

export const CSVDownloadModalComponent = (props: Props): React.ReactElement => {
    const { csvDownloadDetails, onClose, segments, error, dateFormat, isLoading } = props;

    const { t: txt } = useTranslation();
    const dispatch: Dispatch = useDispatch();

    const [endDate, setEndDate] = useState<Moment | undefined>(undefined);
    const [startDate, setStartDate] = useState<Moment | undefined>(undefined);
    const [displayValidationStartDate, setDisplayValidationStartDate] = useState<boolean>(false);
    const [displayValidationEndDate, setDisplayValidationEndDate] = useState<boolean>(false);
    const [showDownloadResult, setShowDownloadResult] = useState<boolean>(false);
    const [selectedSegment, setSelectedSegment] = useState<CsvSegment>(segments[0]);

    const onCloseModal = (): void => {
        if (csvDownloadDetails) {
            csvDownloadDetails.forEach(details => {
                URL.revokeObjectURL(details.url);
            });
        }
        onClose();
    };

    const onEndDateChange = (date: Moment): void => {
        const endOfSelectedDay: Moment = date.endOf('day');
        setEndDate(endOfSelectedDay);
        setDisplayValidationEndDate(false);
    };

    const onStartDateChange = (date: Moment): void => {
        setEndDate(undefined);
        const startOfSelectedMonth: Moment = date.startOf('day');
        setStartDate(startOfSelectedMonth);
        setDisplayValidationStartDate(false);
    };

    const onSelectSegment = (option: { id: string; inputValue: string }): void => {
        setEndDate(undefined);
        setStartDate(undefined);
        setDisplayValidationEndDate(false);
        setDisplayValidationStartDate(false);
        setSelectedSegment(segments.filter(segment => segment.segmentId === option.id)[0]);
    };

    const isOutsideStartDateRange = isOutsideStartDateRangeFunc(
        selectedSegment.segmentStartDate,
        selectedSegment.segmentEndDate
    );
    const isOutsideEndDateRange = isOutsideEndDateRangeFunc(selectedSegment.segmentEndDate, startDate);

    const validateDownloadInput = (): boolean => {
        const startDatePicked: boolean = startDate === undefined;
        const endDatePicked: boolean = endDate === undefined;

        setDisplayValidationStartDate(startDatePicked);
        setDisplayValidationEndDate(endDatePicked);
        return !startDatePicked && !endDatePicked;
    };

    const onDownloadCSV = (): void => {
        const validInput: boolean = validateDownloadInput();
        if (validInput) {
            const from: string = moment(startDate).format('YYYY-MM-DD');
            const to: string = moment(endDate).format('YYYY-MM-DD');
            dispatch(downloadCSV(selectedSegment.serialNumber, from, to, selectedSegment.segmentId));
            setShowDownloadResult(true);
            analyticsLogger(DEVICE_DOWNLOADED_CSV, {
                pageType: PageType.Device,
                deviceType: selectedSegment.deviceType,
                from,
                to,
            });
        }
    };

    const renderDateSelectorComponent = (): ReactElement => {
        const dropdownOptions: { inputValue: string; id: string }[] = segments.map(segment => ({
            id: segment.segmentId,
            inputValue: `${segment.name} (${segment.serialNumber})`,
        }));
        return (
            <>
                {segments.length > 1 && (
                    <div className={styles.dropdown}>
                        <Dropdown
                            options={dropdownOptions}
                            id="csv-segments-dropdown"
                            defaultOption={dropdownOptions[0].inputValue}
                            testAttr="csv-segments-dropdown"
                            value={`${selectedSegment.name} (${selectedSegment.serialNumber})`}
                            onSelect={onSelectSegment}
                            title="DeviceHint"
                            optionsAlreadySorted
                        />
                    </div>
                )}
                <div className={styles.timeRangeText}>{txt('CsvExport.TimeRange')}</div>
                <div className={styles.datePickers}>
                    <div className={styles.datePicker}>
                        <DatePicker
                            id="startDate"
                            label="StartDate"
                            onChange={onStartDateChange}
                            dateFormat={dateFormat}
                            selectedDate={startDate}
                            disabledDate={isOutsideStartDateRange}
                            displayDateValidation={displayValidationStartDate}
                            testId="start-date"
                        />
                    </div>
                    <div className={styles.datePicker}>
                        <DatePicker
                            id="endDate"
                            label="EndDate"
                            onChange={onEndDateChange}
                            dateFormat={dateFormat}
                            selectedDate={endDate}
                            disabledDate={isOutsideEndDateRange}
                            displayDateValidation={displayValidationEndDate}
                            testId="end-date"
                        />
                    </div>
                </div>
                <div className={styles.button}>
                    <PrimaryButton
                        title="Confirm"
                        onClick={(): void => onDownloadCSV()}
                        color="primary"
                        testId="confirm-button"
                    />
                </div>
            </>
        );
    };

    const renderGenerateCsvComponent = (): ReactElement => {
        return (
            <ErrorCatcher error={error}>
                <SpinnerBlocker isLoading={isLoading}>
                    <div className={styles.modalContent}>
                        <div>
                            {txt('DownloadCSVText', {
                                start: moment(startDate).format(dateFormats[dateFormat].shortFormat),
                                end: moment(endDate).format(dateFormats[dateFormat].shortFormat),
                            })}
                        </div>
                        <div>{txt('DownloadCSVUTCText')}</div>
                        <div className={styles.linksWrapper}>
                            {csvDownloadDetails.map(csv => (
                                <div key={csv.filename}>
                                    <a className={styles.downloadLink} href={csv.url} download={csv.filename}>
                                        <MaterialIcon extraClass={styles.downloadIcon} name="download" />
                                        {csv.filename}
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </SpinnerBlocker>
            </ErrorCatcher>
        );
    };

    return (
        <ModalWrapper isOpen onClose={onCloseModal} header="DownloadCSVTitle" size="medium">
            <div className={styles.modalBody}>
                {showDownloadResult ? renderGenerateCsvComponent() : renderDateSelectorComponent()}
            </div>
        </ModalWrapper>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        downloadCSV: { loading, error, CSVDownloadDetails: csvDownloadDetails },
        userSettings: { dateFormat },
    }: Store = state;
    return {
        csvDownloadDetails,
        isLoading: loading,
        error,
        dateFormat,
    };
};

export default connect(mapStateToProps)(CSVDownloadModalComponent);
