import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { analyticsLogger } from 'commons/src/analytics';
import { SPACE_DELETED_SPACE, SPACE_VIEWED_SPACE } from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import DeleteConfirmModal from 'commons/src/components/modals/DeleteConfirmModal';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import CSVDownloadModal from 'commons/src/features/devicePage/dropdownOptions/CSVDownloadModal';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import { deleteSpace } from '../../../../actions/spaceActions';
import { MAX_DEVICES_PER_SPACE } from '../../../../constants';
import { IndoorSpace } from '../../../../models/spaceModels';
import { Store } from '../../../../reducers';
import SpaceEdit from '../../addEdit/SpaceEdit';
import HubDetails from '../hubs/HubDetails';
import { spacesSelector } from '../spaceSelectors';
import SpaceDevices from './SpaceDevices';
import styles from './SpaceDevicesSection.module.scss';

export type Props = {
    space: IndoorSpace;
};

const SpaceDevicesSection = ({ space }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const locationId = space?.locationId;

    const { request } = useSelector((state: Store) => spacesSelector(state, locationId));
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [showAddDeviceForm, setShowAddDeviceForm] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [csvModalOpen, setCsvModalOpen] = useState(false);

    const { loading: deleteLoading, error: deleteError } = useSelector((state: Store) => state.requests.DELETE_SPACE);

    const { devices: devicesAndHubs, hubs } = space;
    const devices = devicesAndHubs.filter(device => device.deviceType !== DeviceTypeNames.hub);

    const hide = (): void => setShowAddDeviceForm(false);
    const show = (): void => setShowAddDeviceForm(true);

    const hasAnyDevice = Boolean(space?.devices.length);
    const canAddMore = devices.length + hubs.length < MAX_DEVICES_PER_SPACE;

    useEffect((): void => {
        if (space) {
            analyticsLogger(SPACE_VIEWED_SPACE, {
                spaceId: space.id,
                locationId: space.locationId,
                spaceName: space.name,
            });
        }
    }, [space]);

    useEffect(() => {
        if (isDeleting && !deleteLoading && !deleteError) {
            navigate(`/buildings/${locationId}/spaces`);
        }
    }, [deleteLoading]);

    if (request.error) {
        return <ResponseBox text={txt(`ErrorCodes.${request.error.error}`)} />;
    }

    const activeDevicesInSpace = devices.length + hubs.length > 0;
    const onDelete = (): void => {
        if (!activeDevicesInSpace) {
            dispatch(deleteSpace(space));
            setIsDeleting(true);
            analyticsLogger(SPACE_DELETED_SPACE, {
                spaceId: space.id,
                spaceName: space.name,
                locationId: space.locationId,
            });
        }
    };

    return (
        <div className={styles.headerSection} data-space-page>
            <div className={styles.headerWrapper}>
                <div className={styles.deviceInfo}>
                    <h3 title={space.name} className={styles.header}>
                        {space.name}
                    </h3>
                </div>
                {csvModalOpen && (
                    <CSVDownloadModal
                        onClose={(): void => setCsvModalOpen(false)}
                        segments={[...devices, ...space.endedSegments].map(device => ({
                            serialNumber: device.serialNumber,
                            deviceType: device.deviceType,
                            segmentId: device.segmentId,
                            segmentStartDate: device.segmentStarted,
                            segmentEndDate: device.segmentEnded,
                            name: device.segmentName,
                        }))}
                    />
                )}
                {space && (
                    <div className={styles.buttonWrapper}>
                        {canAddMore && hasAnyDevice && (
                            <PrimaryButton
                                color="secondary"
                                onClick={show}
                                icon={<MaterialIcon name="add" />}
                                disabled={showAddDeviceForm}
                                testId="add-button"
                            />
                        )}
                        {(devices.length > 0 || space.endedSegments.length > 0) && (
                            <PrimaryButton
                                color="secondary"
                                onClick={(): void => setCsvModalOpen(true)}
                                icon={<MaterialIcon name="download" />}
                            />
                        )}
                        <PrimaryButton
                            color="secondary"
                            onClick={(): void => setSettingsOpen(true)}
                            icon={<MaterialIcon name="settings" />}
                        />
                        <PrimaryButton
                            onClick={(): void => setDeleteOpen(true)}
                            color="alert"
                            icon={<MaterialIcon name="delete" />}
                            testId="delete-button"
                        />
                        <SpaceEdit
                            isOpen={settingsOpen}
                            onClose={(): void => setSettingsOpen(currentOpen => !currentOpen)}
                            space={space}
                        />
                        {deleteOpen && (
                            <DeleteConfirmModal
                                onSubmit={onDelete}
                                onCancel={(): void => setDeleteOpen(false)}
                                onSubmitText="Delete"
                                description={txt(
                                    activeDevicesInSpace ? 'Space.DeleteWithDevices' : 'Space.DeleteSpaceDescription',
                                    { spaceName: space.name }
                                )}
                                error={!!deleteError}
                                errorText={`ErrorCodes.${deleteError?.error}`}
                                onCancelText={activeDevicesInSpace ? 'Close' : 'Cancel'}
                                title={activeDevicesInSpace ? 'Space.CantDeleteSpace' : 'Space.DeleteSpace'}
                                loading={deleteLoading}
                                hideDelete={activeDevicesInSpace}
                                centerBody
                            />
                        )}
                    </div>
                )}
            </div>
            {hubs.map(hub => (
                <HubDetails key={hub.serialNumber} hub={hub} locationId={space.locationId} />
            ))}
            {space && (
                <SpaceDevices space={space} devices={devices} show={show} hide={hide} showForm={showAddDeviceForm} />
            )}
        </div>
    );
};
export default SpaceDevicesSection;
