import { Draft, produce } from 'immer';
import { DeviceActionType, DeviceReducerActions } from 'commons/src/actions/DeviceActions';
import { SettingsActionType, SettingsReducerActions } from 'commons/src/actions/SettingsActions';
import { DeviceTypeNames, PlacementType, SensorTypes } from 'commons/src/models/commonEnums';
import { SpaceAction, SpaceActions } from '../actions/spaceActions';
import { IndoorSpace, Space, SpaceDevice, SpaceHub } from '../models/spaceModels';

export interface SpaceStore {
    spaces: {
        [locationId: string]: {
            spaces: Space[];
            availableSensors: SensorTypes[];
        };
    };
}

const initialStore: SpaceStore = {
    spaces: {},
};

const produceSpaceStateIndex =
    (state: SpaceStore) =>
    (locationId: string, spaceId: string): number =>
        state.spaces[locationId]?.spaces?.findIndex(({ id }) => id === spaceId);

/* eslint-disable no-param-reassign */
const reducer = (
    state: SpaceStore = initialStore,
    action: SpaceAction | SettingsReducerActions | DeviceReducerActions
): SpaceStore =>
    produce(state, draft => {
        const getSpaceIndex = produceSpaceStateIndex(state);
        switch (action.type) {
            case SpaceActions.FetchSpacesSuccess: {
                draft.spaces[action.locationId] = {
                    spaces: action.spaces,
                    availableSensors: action.availableSensors,
                };
                break;
            }
            case SpaceActions.CreateSpaceSuccess: {
                const { locationId } = action.space;
                draft.spaces[locationId].spaces.push(action.space);
                break;
            }
            case SpaceActions.AddDeviceToSpaceSuccess: {
                const spaceIndex: number = getSpaceIndex(action.locationId, action.spaceId);
                if (spaceIndex >= 0) {
                    const space = draft.spaces[action.locationId].spaces[spaceIndex] as Draft<IndoorSpace>;
                    if (action.device.deviceType === DeviceTypeNames.hub) {
                        space.hubs.push(action.device as SpaceHub);
                    } else {
                        space.devices.push(action.device as SpaceDevice);
                    }
                }
                break;
            }
            case SpaceActions.EditSpaceSuccess: {
                const spaceIndex: number = getSpaceIndex(action.space.locationId, action.space.id);
                if (spaceIndex >= 0) {
                    draft.spaces[action.space.locationId].spaces[spaceIndex] = action.space;
                }
                break;
            }
            case DeviceActionType.RenameSegmentSuccess: {
                const spaceIndex = action.spaceId && getSpaceIndex(action.locationId, action.spaceId);
                if (spaceIndex && spaceIndex >= 0) {
                    const spaceToEdit = draft.spaces[action.locationId].spaces[spaceIndex] as IndoorSpace;
                    const deviceIndex = spaceToEdit.devices.findIndex(
                        device => device.serialNumber === action.serialNumber
                    );
                    if (deviceIndex >= 0) {
                        (draft.spaces[action.locationId].spaces[spaceIndex] as Draft<IndoorSpace>).devices[
                            deviceIndex
                        ].segmentName = action.name;
                    }
                }
                break;
            }
            case SpaceActions.RemoveDeviceFromSpaceSuccess: {
                const spaceIndex: number = getSpaceIndex(action.locationId, action.spaceId);
                const space: Draft<IndoorSpace> = draft.spaces[action.locationId].spaces[
                    spaceIndex
                ] as Draft<IndoorSpace>;
                const deviceToDeleteIndex: number = space.devices.findIndex(
                    device => device.serialNumber === action.serialNumber
                );
                const hubToRemoveIndex: number = space.hubs.findIndex(hub => hub.serialNumber === action.serialNumber);
                if (deviceToDeleteIndex >= 0) {
                    space.devices.splice(deviceToDeleteIndex, 1);
                } else if (hubToRemoveIndex >= 0) {
                    space.hubs.splice(hubToRemoveIndex, 1);
                }
                break;
            }
            case SpaceActions.MoveDeviceBetweenSpacesSuccess: {
                const { fromSpaceId, serialNumber, payload, fromLocationId } = action;
                const { toLocationId, toSpaceId } = payload;

                const sourceSpaceIndex: number = getSpaceIndex(fromLocationId, fromSpaceId);
                const space = draft.spaces[fromLocationId].spaces[sourceSpaceIndex] as Draft<IndoorSpace>;
                const deviceToMove = space.devices.find(device => device.serialNumber === serialNumber);
                const hubToMove = space.hubs.find(hub => hub.serialNumber === serialNumber);

                if (deviceToMove !== undefined || hubToMove !== undefined) {
                    const destinationSpaceIndex: number = getSpaceIndex(toLocationId, toSpaceId);
                    if (sourceSpaceIndex >= 0 && destinationSpaceIndex >= 0) {
                        // Add device to destination space
                        const destinationSpace: Draft<IndoorSpace> = draft.spaces[toLocationId].spaces[
                            destinationSpaceIndex
                        ] as Draft<IndoorSpace>;

                        // Remove device serialNumber from source space
                        const spaceToDelete = draft.spaces[fromLocationId].spaces[
                            sourceSpaceIndex
                        ] as Draft<IndoorSpace>;

                        if (deviceToMove !== undefined) {
                            destinationSpace.devices.push(deviceToMove);
                            const itemToDeleteIndex = spaceToDelete.devices.findIndex(
                                device => device.serialNumber === serialNumber
                            );
                            spaceToDelete.devices.splice(itemToDeleteIndex, 1);
                        } else if (hubToMove !== undefined) {
                            destinationSpace.hubs.push(hubToMove);
                            const itemToDeleteIndex = spaceToDelete.hubs.findIndex(
                                hub => hub.serialNumber === serialNumber
                            );
                            spaceToDelete.hubs.splice(itemToDeleteIndex, 1);
                        }
                    }
                }
                break;
            }
            case SettingsActionType.DeletePropertyDefinitionSuccess: {
                Object.keys(draft.spaces).forEach(locationId => {
                    draft.spaces[locationId].spaces.forEach((space, index) => {
                        if (
                            space.placement === PlacementType.ROOM &&
                            (space as IndoorSpace).properties[action.definitionId]
                        ) {
                            delete (draft.spaces[locationId].spaces[index] as IndoorSpace).properties[
                                action.definitionId
                            ];
                        }
                    });
                });
                break;
            }
            case SettingsActionType.DeletePropertyValueSuccess: {
                Object.keys(draft.spaces).forEach(locationId => {
                    draft.spaces[locationId].spaces.forEach((space, spaceIndex) => {
                        if (
                            space.placement === PlacementType.ROOM &&
                            (space as IndoorSpace).properties[action.propertyId]
                        ) {
                            Object.keys((space as IndoorSpace).properties).forEach(propertyKey => {
                                const property = (space as IndoorSpace).properties[propertyKey];
                                if (propertyKey === action.propertyId && property.optionId === action.valueId) {
                                    delete (draft.spaces[locationId].spaces[spaceIndex] as IndoorSpace).properties[
                                        propertyKey
                                    ];
                                }
                            });
                        }
                    });
                });
                break;
            }
            default:
                break;
        }
    });
/* eslint-enable no-param-reassign */

export default reducer;
