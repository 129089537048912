import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import {
    ACCOUNT_SSO_ACTIVATE_DOMAIN,
    ACCOUNT_SSO_DEACTIVATE_DOMAIN,
    ACCOUNT_SSO_DELETE_DOMAIN,
} from 'commons/src/analytics/AnalyticsEvents';
import { copyToClipboard } from 'commons/src/commonFunctions';
import CircleButton from 'commons/src/components/buttons/CircleButton';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import LabeledText from 'commons/src/components/LabeledText';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import DeleteConfirmModal from 'commons/src/components/modals/DeleteConfirmModal';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import {
    deleteSsoClient,
    toggleActiveFederatedClient,
    verifySsoClientDomain,
} from '../../../../actions/singleSignOnActions';
import { getBusinessIntercomArticleLink } from '../../../../constants';
import { SingleSignOnClient } from '../../../../models/common';
import { IntercomArticleId } from '../../../../models/commonEnums';
import { Store } from '../../../../reducers';
import { BusinessRequestType } from '../../../../reducers/BusinessRequestType';
import styles from './SSODomainVerification.module.scss';
import { setVerificationStatus, VerificationStatus } from './SSOTags';

type StateProps = {
    singleSignOnClients: SingleSignOnClient[];
    verifyLoading: boolean;
    verifyError?: ErrorType;
    toggleActiveLoading: boolean;
    toggleActiveError?: ErrorType;
    deleteLoading: boolean;
    deleteError?: ErrorType;
};

type PassedProps = {
    singleSignOnClient: SingleSignOnClient;
};

export type Props = PassedProps & StateProps;

export const SSODomainVerification = ({
    singleSignOnClients,
    singleSignOnClient,
    verifyLoading,
    verifyError,
    toggleActiveLoading,
    toggleActiveError,
    deleteLoading,
    deleteError,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const [displayConfirmCopy, setDisplayConfirmCopy] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
    const [verificationError, setVerificationError] = useState(false);

    const onCopyToClipboard = (record: string): void => {
        copyToClipboard(record);
        setDisplayConfirmCopy(true);
    };

    const onVerify = (): void => {
        dispatch(verifySsoClientDomain(singleSignOnClient.singleSignOnId));
    };

    const onActivateButtonClick = (): void => {
        dispatch(toggleActiveFederatedClient(singleSignOnClient.singleSignOnId, true));
        analyticsLogger(ACCOUNT_SSO_ACTIVATE_DOMAIN, {});
    };

    const onDeleteModalSubmit = (): void => {
        dispatch(deleteSsoClient(singleSignOnClient.singleSignOnId));
        analyticsLogger(ACCOUNT_SSO_DELETE_DOMAIN, {});
    };

    const onDeactivateModalSubmit = (): void => {
        dispatch(toggleActiveFederatedClient(singleSignOnClient.singleSignOnId, false));
        analyticsLogger(ACCOUNT_SSO_DEACTIVATE_DOMAIN, {});
    };

    const prevToggleActiveLoadingRef = useRef(toggleActiveLoading);

    useEffect((): void => {
        if (prevToggleActiveLoadingRef.current && !toggleActiveLoading && !toggleActiveError) {
            setDeactivateModalOpen(false);
        }
        prevToggleActiveLoadingRef.current = toggleActiveLoading;
    }, [toggleActiveLoading, prevToggleActiveLoadingRef]);

    const prevVerifyLoadingRef = useRef(verifyLoading);

    useEffect((): void => {
        if (
            prevVerifyLoadingRef.current &&
            !verifyLoading &&
            !verifyError &&
            !singleSignOnClient.domainTxtRecordVerifiedAt
        ) {
            setVerificationError(true);
        }
        prevVerifyLoadingRef.current = verifyLoading;
    }, [verifyLoading, prevVerifyLoadingRef, singleSignOnClient]);

    return (
        <div>
            {deleteModalOpen && (
                <DeleteConfirmModal
                    title="DomainStatus.DeleteDomain"
                    description={txt('DomainStatus.DeleteDomainModalText', {
                        emailDomain: singleSignOnClient.domain,
                    })}
                    onCancel={(): void => setDeleteModalOpen(false)}
                    onSubmit={onDeleteModalSubmit}
                    onCancelText="Cancel"
                    onSubmitText="Delete"
                    loading={deleteLoading}
                    error={!!deleteError}
                    errorText={`ErrorCodes.${deleteError?.error}`}
                />
            )}
            {deactivateModalOpen && (
                <DeleteConfirmModal
                    title="DomainStatus.DeactivateDomain"
                    description={txt('DomainStatus.DeactivateDomainModalText', {
                        emailDomain: singleSignOnClient.domain,
                    })}
                    onCancel={(): void => setDeactivateModalOpen(false)}
                    onSubmit={onDeactivateModalSubmit}
                    onCancelText="Cancel"
                    onSubmitText="Deactivate"
                    loading={toggleActiveLoading}
                    error={!!toggleActiveError}
                    errorText={`ErrorCodes.${toggleActiveError?.error}`}
                />
            )}
            <p className={styles.headerText}>{txt('SingleSignOn.VerifyEmailDomainHeader')}</p>
            <div className="form__row form__row--spaced">
                <div className="form__field form__field--medium-width">
                    <LabeledText
                        label="SingleSignOn.TextRecordValue"
                        noPadding
                        value={singleSignOnClient.domainTxtRecord}
                        id="txt-record"
                    />
                    <CircleButton
                        onClick={(): void => onCopyToClipboard(singleSignOnClient.domainTxtRecord)}
                        testAttr="copy-text-record"
                        iconName="content_copy"
                        color={displayConfirmCopy ? 'confirmed' : 'secondary'}
                    />
                </div>
                <div className={styles.verifyBox}>
                    {!singleSignOnClient.domainTxtRecordVerifiedAt && !verifyLoading ? (
                        <PrimaryButton color="secondary" loading={verifyLoading} title="Verify" onClick={onVerify} />
                    ) : (
                        <VerificationStatus
                            status={setVerificationStatus(singleSignOnClient, verifyLoading, verifyError)}
                        />
                    )}
                </div>
            </div>
            {verificationError && (
                <div className="form__row">
                    <p className={styles.errorMessage}>{txt('DomainStatus.VerificationError')}</p>
                </div>
            )}
            {verifyError && (
                <div className={styles.errorWrapper}>
                    <ResponseBox text={`ErrorCodes.${verifyError.error}`} />
                </div>
            )}
            <div className="form__row">
                <a
                    className={styles.articleLink}
                    href={getBusinessIntercomArticleLink(IntercomArticleId.SSO_VERIFICATION)}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {txt('SingleSignOn.VerificationArticle')}
                </a>
            </div>
            <div className="form__row submit-container">
                {singleSignOnClient.active ? (
                    <PrimaryButton
                        color="secondary"
                        loading={toggleActiveLoading}
                        title="Deactivate"
                        onClick={(): void => setDeactivateModalOpen(true)}
                    />
                ) : (
                    <div className={styles.activateSection}>
                        <PrimaryButton
                            loading={toggleActiveLoading}
                            title="Activate"
                            testId="activate-domain"
                            disabled={!singleSignOnClient.domainTxtRecordVerifiedAt}
                            color={singleSignOnClient.domainTxtRecordVerifiedAt ? 'primary' : 'secondary'}
                            onClick={(): void => onActivateButtonClick()}
                        />
                        {!singleSignOnClient.domainTxtRecordVerifiedAt && (
                            <p className={styles.activateText}>{txt('DomainStatus.ActivateHelpText')}</p>
                        )}
                    </div>
                )}
            </div>
            {toggleActiveError && (
                <div className={styles.errorWrapper}>
                    <ResponseBox text={`ErrorCodes.${toggleActiveError.error}`} />
                </div>
            )}
            <div className="form__row">
                {singleSignOnClients.length > 1 && (
                    <div className={styles.activateSection}>
                        <PrimaryButton
                            loading={deleteLoading}
                            color="alert"
                            icon={<MaterialIcon name="delete" />}
                            title="DomainStatus.DeleteDomain"
                            testId="delete-domain"
                            onClick={(): void => setDeleteModalOpen(true)}
                        />
                        <p className={styles.activateText}>{txt('DomainStatus.DeleteHelpText')}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        settings: { singleSignOnClients },
        requests: {
            [BusinessRequestType.VerifySsoClientDomain]: { loading: verifyLoading, error: verifyError },
            [BusinessRequestType.ToggleActiveFederatedClient]: {
                loading: toggleActiveLoading,
                error: toggleActiveError,
            },
            [BusinessRequestType.DeleteSsoClient]: { loading: deleteLoading, error: deleteError },
        },
    } = state;
    return {
        singleSignOnClients,
        verifyLoading,
        verifyError,
        toggleActiveLoading,
        toggleActiveError,
        deleteLoading,
        deleteError,
    };
};

export default connect(mapStateToProps)(SSODomainVerification);
