import React, { useEffect, useRef, MutableRefObject, useState } from 'react';
import 'leaflet-draw/dist/leaflet.draw.css';
import {
    LatLngExpression,
    LatLng,
    featureGroup,
    Handler,
    Polygon,
    FeatureGroup as FeatureGroupType,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    EditToolbar,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Draw,
} from 'leaflet';
import { FeatureGroup, useMap } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { FloorPlanPosition } from 'commons/src/models/commonTypeScript';
import styles from './FloorEditor.module.scss';

type Props = {
    innerRef: React.MutableRefObject<{ zone?: Polygon } | undefined>;
    onZoneUpdated: (zone: number[][] | LatLngExpression[][]) => void;
    spaceZone?: undefined | number[][] | LatLngExpression[][];
    selectedSpace: string | undefined;
};

const FloorEditor = ({ innerRef, onZoneUpdated, spaceZone, selectedSpace }: Props): React.ReactElement => {
    const map = useMap();
    // keep created polygon reference in state between drawing a new zone and assigning it to a space
    const [createdPolygon, setCreatedPolygon] = useState<Polygon | undefined>();

    const editRef: MutableRefObject<{ editFeatureGroup: FeatureGroupType; editHandler: Handler } | undefined> =
        useRef();

    useEffect(() => {
        const editFeatureGroup = featureGroup();
        const editToolbar: EditToolbar = new EditToolbar({ featureGroup: editFeatureGroup });
        const editHandler = editToolbar.getModeHandlers(map)[0].handler;

        editRef.current = {
            editFeatureGroup,
            editHandler,
        };
    }, []);

    const onSpaceDrawingCreated = (event: { layer: Polygon }): void => {
        const polygon = event.layer;
        // create a reference to the newly drawn space that will be deleted when edit mode is entered
        setCreatedPolygon(polygon);
        const newZonePositions = (polygon.getLatLngs() as LatLng[][])[0].map(position => [position.lat, position.lng]);
        onZoneUpdated(newZonePositions);
    };

    const onSpaceDrawingEdited = (): void => {
        if (editRef.current) {
            const { editFeatureGroup } = editRef.current;
            const editedFeature = editFeatureGroup.getLayers()[0] as Polygon;
            const editedZoneBoundary = (editedFeature?.getLatLngs()[0] || []) as FloorPlanPosition[];
            if (editedZoneBoundary.length > 0) {
                onZoneUpdated(editedZoneBoundary.map(zone => [zone.lat, zone.lng]));
            }
        }
    };

    useEffect(() => {
        // This effect sets the state where the user can start drawing (i.e. when adding new zone).
        let rectangleDrawer: Draw.Rectangle;
        if (!selectedSpace) {
            rectangleDrawer = new Draw.Rectangle(map, { shapeOptions: { className: styles.editShape } });
            rectangleDrawer.enable();
        }
        return (): void => {
            rectangleDrawer?.disable();
        };
    }, [selectedSpace]);

    useEffect(() => {
        // This effect enables editing of zone boundary.
        // Because custom UI elements (e.g. the toolbar) are used for edit actions,
        // zones are added to and removed from the map manually.
        if (spaceZone && editRef && editRef.current && selectedSpace) {
            const { editFeatureGroup, editHandler } = editRef.current;
            const editHandlerRef = editHandler;
            const editFeatureGroupRef = editFeatureGroup;
            const zone = new Polygon(spaceZone as LatLngExpression[][], { className: styles.editShape });
            if (createdPolygon) {
                // delete the polygon layer before setting up the editable layer
                createdPolygon.remove();
                setCreatedPolygon(undefined);
            }
            map.addLayer(zone);
            editFeatureGroup.addLayer(zone);
            editHandler.enable();

            return (): void => {
                // eslint-disable-next-line no-param-reassign
                innerRef.current = {};
                if (editHandlerRef) editHandlerRef.disable();
                if (editFeatureGroupRef) editFeatureGroupRef.removeLayer(zone);
                map.removeLayer(zone);
            };
        }
        return (): void => undefined;
    }, [spaceZone, selectedSpace]);

    return (
        <FeatureGroup>
            <EditControl
                position="topright"
                draw={{
                    polyline: false,
                    polygon: false,
                    rectangle: false,
                    circle: false,
                    marker: false,
                    circlemarker: false,
                }}
                edit={{
                    edit: false,
                    remove: false,
                }}
                onEditVertex={onSpaceDrawingEdited}
                onCreated={onSpaceDrawingCreated}
            />
        </FeatureGroup>
    );
};

export default FloorEditor;
