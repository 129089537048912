import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './FlipButton.module.scss';

type Props = {
    onClick: (leftSelected: boolean) => void;
    leftSelected: boolean;
    leftText: string;
    rightText: string;
    capitalized?: boolean;
    disabled?: boolean;
    id?: string;
    testIdOn?: string;
    testIdOff?: string;
    leftIcon?: React.ReactElement;
    rightIcon?: React.ReactElement;
};

const FlipButton = ({
    leftSelected,
    onClick,
    testIdOn,
    testIdOff,
    leftText,
    rightText,
    capitalized,
    disabled,
    id,
    leftIcon,
    rightIcon,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const flipView = (leftSelect: boolean, e: SyntheticEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        if (disabled || leftSelected === leftSelect) {
            return;
        }
        onClick(leftSelect);
    };

    return (
        <div id={id} aria-label={txt('ToggleButton')} className={styles.wrapper}>
            <button
                type="button"
                onClick={(e): void => flipView(true, e)}
                className={classNames(styles.leftButton, {
                    [styles.selected]: leftSelected,
                    [styles.capitalized]: capitalized,
                })}
                {...{ 'data-testid': testIdOn }}
            >
                {leftIcon}
                {txt(leftText)}
            </button>
            <button
                type="button"
                onClick={(e): void => flipView(false, e)}
                className={classNames(styles.rightButton, {
                    [styles.selected]: !leftSelected,
                    [styles.capitalized]: capitalized,
                })}
                {...{ 'data-testid': testIdOff }}
            >
                {rightIcon}
                {txt(rightText)}
            </button>
        </div>
    );
};

export default FlipButton;
