import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ChipButton from 'commons/src/components/buttons/ChipButton';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import SearchDropdown from 'commons/src/components/dropdown/SearchDropdown';
import CheckBox from 'commons/src/components/input/Checkbox';
import RadioButtons from 'commons/src/components/input/Radio';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import DeviceIcon from 'commons/src/img/deviceIcons/icon_device';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import { DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';
import { paths, thirdPartyChannels } from '../../../constants';
import { ThirdPartyIntegrationMapping } from '../../../models/common';
import styles from './AlertLocationsOrDevices.module.scss';

export type Props = {
    devices: { [seriaNumber: string]: DeviceWithKeyInfo };
    editingAlert: boolean;
    thirdPartyMapping: ThirdPartyIntegrationMapping;
    selectedIntegrationType: string;
    selectedElements: { id: string; name: string }[];
    onNext: () => void;
    setSelectedAlertElements: (listOfElements: { id: string; name: string }[]) => void;
    locationViewSelected: boolean;
    setLocationViewSelected: (selected: boolean) => void;
};

export const AlertLocationsOrDevicesComponent = (props: Props): React.ReactElement => {
    const {
        devices,
        editingAlert,
        thirdPartyMapping,
        selectedIntegrationType,
        onNext,
        setSelectedAlertElements,
        selectedElements,
        locationViewSelected,
        setLocationViewSelected,
    } = props;
    const { t: txt } = useTranslation();

    const nonFDVIntegration =
        selectedIntegrationType === thirdPartyChannels.SLACK || selectedIntegrationType === thirdPartyChannels.EMAIL;

    const deviceList = nonFDVIntegration
        ? Object.keys(devices).map(sn => devices[sn])
        : Object.keys(devices)
              .map(sn => devices[sn])
              .filter(device => thirdPartyMapping.mapping.some(mapping => mapping.locationId === device.locationId));

    const locationList = nonFDVIntegration
        ? thirdPartyMapping.locations
        : thirdPartyMapping.locations.filter(location =>
              thirdPartyMapping.mapping.some(mappedLocation => mappedLocation.locationId === location.id)
          );

    const listOfPossibleElements = locationViewSelected
        ? locationList.map(location => ({ id: location.id, name: location.name }))
        : deviceList
              .filter(device => ![DeviceTypeNames.bacnet].includes(device.type as DeviceTypeNames))
              .map(device => ({ id: device.serialNumber, name: device.segmentName }));

    const toggleBuildingsOrDevices = (e: SyntheticEvent<HTMLInputElement>): void => {
        const selectionType = e.currentTarget.value;
        if (
            (selectionType === 'buildings' && !locationViewSelected) ||
            (selectionType === 'devices' && locationViewSelected)
        ) {
            setLocationViewSelected(!locationViewSelected);
            setSelectedAlertElements([]);
        }
    };

    const elementsNotSelected = listOfPossibleElements.filter(
        option => !selectedElements.some(selected => selected.id === option.id)
    );

    const removeFromSelection = (id: string): void => {
        setSelectedAlertElements(selectedElements.filter(selectedElement => selectedElement.id !== id));
    };

    const addElementAsSelected = (element: { id: string; name: string }): void => {
        setSelectedAlertElements([...selectedElements, element]);
    };

    const toggleAllOptions = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { checked } = e.currentTarget;
        if (checked) {
            setSelectedAlertElements(listOfPossibleElements);
        } else {
            setSelectedAlertElements([]);
        }
    };

    const noLocationsDescription = (): React.ReactElement => {
        const text = txt('NotificationAlerts.NoLocationsFound', {
            notificationAlerts: txt('NotificationAlerts.Channels'),
        });
        const integrationsTranslation = txt('Integrations.Integrations');
        const [textPart1, textPart2] = text.split(integrationsTranslation);
        return (
            <>
                <span>{textPart1}</span>
                <Link to={`/${paths.thirdParty}`}>{integrationsTranslation}</Link>
                <span>{textPart2}</span>
            </>
        );
    };

    const noLocationsFound = locationList.length === 0;
    const goToNextSection = (e: SyntheticEvent<HTMLElement>): void => {
        e.preventDefault();
        if (selectedElements.length > 0) {
            onNext();
        }
    };

    const selectorOptionsLocationOrDevices = [
        {
            value: 'devices',
            label: 'Devices',
            testId: 'alert-devices-radio',
        },
        {
            value: 'buildings',
            label: 'Buildings',
            testId: 'alert-buildings-radio',
        },
    ];

    return (
        <div className="settings-details-container">
            <form onSubmit={goToNextSection}>
                <div className="form notification-alert__form">
                    <p className="text-large text-paragraph">
                        {noLocationsFound
                            ? noLocationsDescription()
                            : txt('NotificationAlerts.SelectBuildingsOrDevicesDescription')}
                    </p>
                    <div className="form__row">
                        <div className="form__field">
                            <RadioButtons
                                buttons={selectorOptionsLocationOrDevices}
                                selectorName="buildingOrDevicesSelector"
                                row
                                onChange={toggleBuildingsOrDevices}
                                value={locationViewSelected ? 'buildings' : 'devices'}
                                labelId="building-device-selector"
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__field">
                            <CheckBox
                                label={
                                    locationViewSelected
                                        ? 'NotificationAlerts.SelectAllBuildings'
                                        : 'NotificationAlerts.SelectAllDevices'
                                }
                                id="select_all"
                                onChange={toggleAllOptions}
                                checked={listOfPossibleElements.length === selectedElements.length}
                                testId="select-all-checkbox"
                            />
                        </div>
                    </div>
                    <SearchDropdown
                        icon={
                            locationViewSelected ? (
                                <span className={styles.optionIcon}>
                                    <MaterialIcon name="business" />
                                </span>
                            ) : (
                                <DeviceIcon />
                            )
                        }
                        onSelect={addElementAsSelected}
                        listOfElements={elementsNotSelected}
                    />
                    <div className="form__row">
                        <div className="notification-alert__chips">
                            {selectedElements.length > 0 &&
                                selectedElements.map(element => (
                                    <ChipButton
                                        key={element.id}
                                        id={element.id}
                                        testId={element.id}
                                        onDelete={removeFromSelection}
                                        title={element.name}
                                    />
                                ))}
                        </div>
                    </div>
                    {!editingAlert && (
                        <div className="form__button-container">
                            <PrimaryButton
                                id="submit"
                                type="submit"
                                title="Next"
                                color="primary"
                                disabled={selectedElements.length === 0}
                                onClick={goToNextSection}
                                testAttr="save-buildings-or-devices"
                                testId="locations-or-devices-next"
                            />
                        </div>
                    )}
                </div>
            </form>
        </div>
    );
};

export default AlertLocationsOrDevicesComponent;
