import { LookupDomainResponse } from 'business-dashboard/src/models/common';
import { dateFormats } from '../constants';
import { PropertyType } from '../models/commonEnums';
import {
    DashboardLocale,
    ErrorType,
    Group,
    PartnerLogoDetails,
    NewsletterSignUpObject,
    Units,
    ConfigResponse,
} from '../models/commonTypeScript';
import { CommonActionRequestType, CommonRequestType } from '../reducers/requestReducer';
import { RequestActionType } from './requestActions';
import { SelectGroup, SelectGroupSuccess } from './selectGroupActions';

export enum SettingsActionType {
    FetchSpecs = 'FETCH_SPECS/INIT',
    FetchSpecsSuccess = 'FETCH_SPECS_SUCCESS',
    CreatePropertyDefinition = 'CREATE_PROPERTY_DEFINITION/INIT',
    CreatePropertyDefinitionSuccess = 'CREATE_PROPERTY_DEFINITION_SUCCESS',
    DeletePropertyDefinition = 'DELETE_PROPERTY_DEFINITION/INIT',
    DeletePropertyDefinitionSuccess = 'DELETE_PROPERTY_DEFINITION_SUCCESS',
    UpdatePropertyDefinition = 'UPDATE_PROPERTY_DEFINITION/INIT',
    UpdatePropertyDefinitionSuccess = 'UPDATE_PROPERTY_DEFINITION_SUCCESS',
    CreatePropertyValue = 'CREATE_PROPERTY_VALUE/INIT',
    CreatePropertyValueSuccess = 'CREATE_PROPERTY_VALUE_SUCCESS',
    DeletePropertyValue = 'DELETE_PROPERTY_VALUE/INIT',
    DeletePropertyValueSuccess = 'DELETE_PROPERTY_VALUE_SUCCESS',
    UpdatePropertyValue = 'UPDATE_PROPERTY_VALUE/INIT',
    UpdatePropertyValueSuccess = 'UPDATE_PROPERTY_VALUE_SUCCESS',
    UpdateUserProfile = 'UPDATE_USER_PROFILE/INIT',
    UpdateUserProfileSuccess = 'UPDATE_USER_PROFILE_SUCCESS',
    UpdateUserPreferences = 'UPDATE_USER_PREFERENCES/INIT',
    UpdateUserPreferencesSuccess = 'UPDATE_USER_PREFERENCES_SUCCESS',
}

export const FETCH_USER_INFO = 'FETCH_USER_INFO';
export interface FetchUserInfo {
    type: typeof FETCH_USER_INFO;
}
export const fetchUserInfo = (): FetchUserInfo => ({ type: FETCH_USER_INFO });

export const FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS';
interface UserSettingsPayload {
    name: string;
    email: string;
    dateFormat: keyof typeof dateFormats;
    units: Units;
    language: DashboardLocale;
    intercomUserHash: string;
    intercomUserHashB2B?: string;
    userId: string;
    groups: Group[];
}
export interface FetchUserInfoSuccess extends UserSettingsPayload {
    type: typeof FETCH_USER_INFO_SUCCESS;
}
export const fetchUserInfoSuccess = (payload: UserSettingsPayload): FetchUserInfoSuccess => ({
    type: FETCH_USER_INFO_SUCCESS,
    ...payload,
});

export const FETCH_PARTNER_PROPS_SUCCESS = 'FETCH_PARTNER_PROPS_SUCCESS';
export interface FetchPartnerPropsSuccess {
    type: typeof FETCH_PARTNER_PROPS_SUCCESS;
    partnerProps: PartnerLogoDetails;
}
export const fetchPartnerPropsSuccess = (partnerProps: PartnerLogoDetails): FetchPartnerPropsSuccess => ({
    type: FETCH_PARTNER_PROPS_SUCCESS,
    partnerProps,
});

export const FETCH_USER_INFO_ERROR = 'FETCH_USER_INFO_ERROR';
export interface FetchUserInfoError {
    type: typeof FETCH_USER_INFO_ERROR;
    error: ErrorType;
}
export const fetchUserInfoError = (error: ErrorType): FetchUserInfoError => ({ type: FETCH_USER_INFO_ERROR, error });

export interface UpdateUserSettingsPayload {
    units: Units;
    dateFormat?: string;
    language?: DashboardLocale;
}

export interface UpdateUserProfile {
    requestActionType: RequestActionType.Init;
    requestType: CommonRequestType.UpdateUserProfile;
    type: SettingsActionType.UpdateUserProfile;
    name: string;
}
export const updateUserProfile = (name: string): UpdateUserProfile => ({
    requestActionType: RequestActionType.Init,
    requestType: CommonRequestType.UpdateUserProfile,
    type: SettingsActionType.UpdateUserProfile,
    name,
});
export interface UpdateUserProfileSuccess {
    type: SettingsActionType.UpdateUserProfileSuccess;
    name: string;
}
export const updateUserProfileSuccess = (name: string): UpdateUserProfileSuccess => ({
    type: SettingsActionType.UpdateUserProfileSuccess,
    name,
});

export interface UpdateUserPreferences {
    requestActionType: RequestActionType.Init;
    requestType: CommonRequestType.UpdateUserPreferences;
    type: SettingsActionType.UpdateUserPreferences;
    request: UpdateUserSettingsPayload;
}
export const updateUserPreferences = (request: UpdateUserSettingsPayload): UpdateUserPreferences => ({
    requestActionType: RequestActionType.Init,
    requestType: CommonRequestType.UpdateUserPreferences,
    type: SettingsActionType.UpdateUserPreferences,
    request,
});
export interface UpdateUserPreferencesSuccess {
    type: SettingsActionType.UpdateUserPreferencesSuccess;
    request: UpdateUserSettingsPayload;
}
export const updateUserPreferencesSuccess = (request: UpdateUserSettingsPayload): UpdateUserPreferencesSuccess => ({
    type: SettingsActionType.UpdateUserPreferencesSuccess,
    request,
});

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export interface ChangePassword {
    type: typeof CHANGE_PASSWORD;
    payload: { oldPassword: string; newPassword: string };
}
export const changePassword = (payload: { oldPassword: string; newPassword: string }): ChangePassword => ({
    type: CHANGE_PASSWORD,
    payload,
});

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export interface ChangePasswordSuccess {
    type: typeof CHANGE_PASSWORD_SUCCESS;
}
export const changePasswordSuccess = (): ChangePasswordSuccess => ({ type: CHANGE_PASSWORD_SUCCESS });

export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export interface ChangePasswordError {
    type: typeof CHANGE_PASSWORD_ERROR;
    error: ErrorType;
}
export const changePasswordError = (error: ErrorType): ChangePasswordError => ({ type: CHANGE_PASSWORD_ERROR, error });

export const SIGN_UP_NEWSLETTER = 'SIGN_UP_NEWSLETTER';
export interface SignUpNewsletter {
    type: typeof SIGN_UP_NEWSLETTER;
    payload: NewsletterSignUpObject;
    isB2B: boolean;
}
export const signUpNewsletter = (payload: NewsletterSignUpObject, isB2B: boolean): SignUpNewsletter => ({
    type: SIGN_UP_NEWSLETTER,
    payload,
    isB2B,
});

export const SET_MOBILE_SETTINGS_MENU_IS_OPEN = 'SET_MOBILE_SETTINGS_MENU_IS_OPEN';
export interface SetMobileSettingsMenuIsOpen {
    type: typeof SET_MOBILE_SETTINGS_MENU_IS_OPEN;
    isOpen: boolean;
}
export const setMobileSettingsMenuIsOpen = (isOpen: boolean): SetMobileSettingsMenuIsOpen => ({
    type: SET_MOBILE_SETTINGS_MENU_IS_OPEN,
    isOpen,
});

export interface FetchDeviceDetails {
    requestActionType: RequestActionType.Init;
    requestType: CommonRequestType.FetchDeviceDetails;
    type: SettingsActionType.FetchSpecs;
}
export const fetchDeviceDetails = (): FetchDeviceDetails => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: CommonRequestType.FetchDeviceDetails,
    type: SettingsActionType.FetchSpecs,
});

export interface FetchSpecsSuccess {
    type: SettingsActionType.FetchSpecsSuccess;
    payload: ConfigResponse;
}
export const fetchSpecsSuccess = (payload: ConfigResponse): FetchSpecsSuccess => ({
    type: SettingsActionType.FetchSpecsSuccess,
    payload,
});

// Property definition creation

export interface CreatePropertyDefinition {
    requestActionType: RequestActionType.Init;
    requestType: CommonRequestType.CreatePropertyDefinition;
    type: SettingsActionType.CreatePropertyDefinition;
    name: string;
    propertyType: PropertyType; // For now the only option is to create selection custom properties
}

export const createPropertyDefinition = (name: string, propertyType: PropertyType): CreatePropertyDefinition => ({
    requestActionType: RequestActionType.Init,
    requestType: CommonRequestType.CreatePropertyDefinition,
    type: SettingsActionType.CreatePropertyDefinition,
    name,
    propertyType,
});

export interface DeletePropertyDefinition {
    requestActionType: RequestActionType.Init;
    requestType: CommonRequestType.DeletePropertyDefinition;
    type: SettingsActionType.DeletePropertyDefinition;
    definitionId: string;
}
export const deletePropertyDefinition = (definitionId: string): DeletePropertyDefinition => ({
    requestActionType: RequestActionType.Init,
    requestType: CommonRequestType.DeletePropertyDefinition,
    type: SettingsActionType.DeletePropertyDefinition,
    definitionId,
});

export interface DeletePropertyDefinitionSuccess {
    type: SettingsActionType.DeletePropertyDefinitionSuccess;
    definitionId: string;
}
export const deletePropertyDefinitionSuccess = (definitionId: string): DeletePropertyDefinitionSuccess => ({
    type: SettingsActionType.DeletePropertyDefinitionSuccess,
    definitionId,
});

export interface UpdatePropertyDefinition {
    requestActionType: RequestActionType.Init;
    requestType: CommonRequestType.UpdatePropertyDefinition;
    type: SettingsActionType.UpdatePropertyDefinition;
    definitionId: string;
    name: string;
}
export const updatePropertyDefinition = (definitionId: string, name: string): UpdatePropertyDefinition => ({
    requestActionType: RequestActionType.Init,
    requestType: CommonRequestType.UpdatePropertyDefinition,
    type: SettingsActionType.UpdatePropertyDefinition,
    definitionId,
    name,
});

export interface UpdatePropertyDefinitionSuccess {
    type: SettingsActionType.UpdatePropertyDefinitionSuccess;
    definitionId: string;
    name: string;
}
export const updatePropertyDefinitionSuccess = (
    definitionId: string,
    name: string
): UpdatePropertyDefinitionSuccess => ({
    type: SettingsActionType.UpdatePropertyDefinitionSuccess,
    definitionId,
    name,
});

export interface CreatePropertyDefinitionSuccess {
    type: SettingsActionType.CreatePropertyDefinitionSuccess;
    definitionId: string;
    name: string;
    propertyType: PropertyType;
}

export const createPropertyDefinitionSuccess = (
    definitionId: string,
    name: string,
    propertyType: PropertyType
): CreatePropertyDefinitionSuccess => ({
    type: SettingsActionType.CreatePropertyDefinitionSuccess,
    definitionId,
    name,
    propertyType,
});

// Property value creation

export interface CreatePropertyValue {
    requestActionType: RequestActionType.Init;
    requestType: CommonRequestType.CreatePropertyValue;
    type: SettingsActionType.CreatePropertyValue;
    propertyId: string;
    value: string;
    propertyType: PropertyType;
}

export const createPropertyValue = (
    propertyId: string,
    value: string,
    propertyType: PropertyType
): CreatePropertyValue => ({
    requestActionType: RequestActionType.Init,
    requestType: CommonRequestType.CreatePropertyValue,
    type: SettingsActionType.CreatePropertyValue,
    propertyId,
    value,
    propertyType,
});

export interface CreatePropertyValueSuccess {
    type: SettingsActionType.CreatePropertyValueSuccess;
    propertyId: string;
    createdValue: { id: string; value: string };
    propertyType: PropertyType;
}

export const createPropertyValueSuccess = (
    propertyId: string,
    createdValue: { id: string; value: string },
    propertyType: PropertyType
): CreatePropertyValueSuccess => ({
    type: SettingsActionType.CreatePropertyValueSuccess,
    propertyId,
    createdValue,
    propertyType,
});

export interface DeletePropertyValue {
    requestActionType: RequestActionType.Init;
    requestType: CommonRequestType.DeletePropertyValue;
    type: SettingsActionType.DeletePropertyValue;
    propertyId: string;
    valueId: string;
}
export const deletePropertyValue = (propertyId: string, valueId: string): DeletePropertyValue => ({
    requestActionType: RequestActionType.Init,
    requestType: CommonRequestType.DeletePropertyValue,
    type: SettingsActionType.DeletePropertyValue,
    propertyId,
    valueId,
});

export interface DeletePropertyValueSuccess {
    type: SettingsActionType.DeletePropertyValueSuccess;
    propertyId: string;
    valueId: string;
}
export const deletePropertyValueSuccess = (propertyId: string, valueId: string): DeletePropertyValueSuccess => ({
    type: SettingsActionType.DeletePropertyValueSuccess,
    propertyId,
    valueId,
});

export interface UpdatePropertyValue {
    requestActionType: RequestActionType.Init;
    requestType: CommonRequestType.UpdatePropertyValue;
    type: SettingsActionType.UpdatePropertyValue;
    propertyId: string;
    valueId: string;
    name: string;
}
export const updatePropertyValue = (propertyId: string, valueId: string, name: string): UpdatePropertyValue => ({
    requestActionType: RequestActionType.Init,
    requestType: CommonRequestType.UpdatePropertyValue,
    type: SettingsActionType.UpdatePropertyValue,
    propertyId,
    valueId,
    name,
});

export interface UpdatePropertyValueSuccess {
    type: SettingsActionType.UpdatePropertyValueSuccess;
    propertyId: string;
    valueId: string;
    name: string;
}
export const updatePropertyValueSuccess = (
    propertyId: string,
    valueId: string,
    name: string
): UpdatePropertyValueSuccess => ({
    type: SettingsActionType.UpdatePropertyValueSuccess,
    propertyId,
    valueId,
    name,
});

export enum DomainLookupActionType {
    LookupDomainInit = 'LOOKUP_DOMAIN/INIT',
    LookupDomainSuccess = 'LOOKUP_DOMAIN_SUCCESS',
}

export interface LookupDomain extends CommonActionRequestType {
    type: DomainLookupActionType.LookupDomainInit;
    emailDomain: string;
}
export const lookupDomain = (emailDomain: string): LookupDomain => ({
    requestActionType: RequestActionType.Init,
    requestType: CommonRequestType.LookupDomain,
    type: DomainLookupActionType.LookupDomainInit,
    emailDomain,
});

export interface LookupDomainSuccess {
    type: DomainLookupActionType.LookupDomainSuccess;
    response: LookupDomainResponse;
}
export const lookupDomainSuccess = (response: LookupDomainResponse): LookupDomainSuccess => ({
    type: DomainLookupActionType.LookupDomainSuccess,
    response,
});

export type SettingsReducerActions =
    | FetchUserInfoSuccess
    | ChangePassword
    | ChangePasswordSuccess
    | ChangePasswordError
    | SelectGroup
    | SelectGroupSuccess
    | FetchPartnerPropsSuccess
    | SetMobileSettingsMenuIsOpen
    | LookupDomain
    | LookupDomainSuccess
    | CreatePropertyDefinitionSuccess
    | CreatePropertyValueSuccess
    | FetchSpecsSuccess
    | DeletePropertyValueSuccess
    | UpdatePropertyValueSuccess
    | DeletePropertyDefinitionSuccess
    | UpdatePropertyDefinitionSuccess
    | UpdateUserProfileSuccess
    | UpdateUserPreferencesSuccess;
