import { SpaceProperty } from 'commons/src/models/commonTypeScript';
import { RoomProperties } from '../../../models/spaceModels';

export enum SpacePropertyEditState {
    EDIT_NAME,
    CREATE_OPTION,
    EDIT_OPTIONS,
}

export const getNamedSpacePropertiesWithValue = (
    spaceProps: { [key: string]: RoomProperties },
    propertyDefinitions: { [propertyId: string]: SpaceProperty }
): { [key: string]: string } => {
    return Object.keys(spaceProps).reduce((acc, property) => {
        const propDef = propertyDefinitions[property];
        return propDef ? { ...acc, [propDef.predefinedType]: spaceProps[property].value } : acc;
    }, {});
};
