import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder/lib';
import { useDispatch, useSelector } from 'react-redux';
import { PageType } from 'commons/src/analytics';
import { BUILDINGS_VIEWED_STATUS } from 'commons/src/analytics/AnalyticsEvents';
import sendAnalyticsEvent from 'commons/src/analytics/sendAnalyticsEvent';
import PageHeader from 'commons/src/components/headers/PageHeader';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import SpinnerBlocker from 'commons/src/components/modals/ModalSpinnerBlocker';
import ReactPdfDownloadModal from 'commons/src/components/PDF/ReactPdfDownloadModal';
import RenderComponentOrError from 'commons/src/components/responseMessages/RenderComponentOrError';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import useEffectOnceWhen from 'commons/src/hooks/useEffectsOnceWhen';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { fetchOrganizationHealthStatusSummary } from '../../../actions/healthStatusActions';
import { fetchOrganizationHealth } from '../../../actions/organizationHealthActions';
import { getBuildingHealthStatusCSVData } from '../../../api/healthStatus';
import MaterialTablePlaceholder from '../../../components/materialTable/MaterialTablePlaceholder';
import { ORGANIZATION_HEALTH_TABLE_LENGTH } from '../../../constants';
import { CardIssue } from '../../../models/buildingModels';
import { Store } from '../../../reducers';
import { useTypedSelector } from '../../../store';
import styles from './BuildingsHealthStatus.module.scss';
import BuildingsStatusSummaryBar from './BuildingsStatusSummaryBar';
import BuildingsStatusSummaryCard from './BuildingsStatusSummaryCard';
import OrganizationHealthTable from './OrganizationHealthTable';

export default function BuildingsHealthStatus(): React.ReactElement {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const [CSVDownloadModalOpen, setCSVDownloadModalOpen] = React.useState(false);
    const [csv, setCSVData] = React.useState<{ url: string; fileName: string }>();
    const [fetchCSVError, setFetchCSVError] = React.useState<ErrorType>();
    const [fetchCSVLoading, setFetchCSVLoading] = React.useState(false);

    const org = useTypedSelector((state: Store) => state.organizationHealth);
    const { selectedGroup } = useTypedSelector((state: Store) => state.userSettings);
    const orgRequest = useTypedSelector((state: Store) => state.requests.FETCH_ORGANIZATION_HEALTH);

    const orgBuildings = Object.values(org.locations);

    const stats = useMemo(
        () =>
            Object.values(org.locations).reduce(
                (acc, building) => {
                    const offlineDevices = building.deviceStats.offline;
                    const offlineHubs = building.hubStats.offline;
                    const isBuildingOffline = offlineDevices + offlineHubs > 0;
                    return {
                        ...acc,
                        offlineHubs: acc.offlineHubs + offlineHubs,
                        offlineDevices: acc.offlineDevices + offlineDevices,
                        offlineBuildings: isBuildingOffline ? acc.offlineBuildings + 1 : acc.offlineBuildings,
                        totalDevices: acc.totalDevices + building.deviceStats.total,
                        totalHubs: acc.totalHubs + building.hubStats.total,
                    };
                },
                { offlineBuildings: 0, offlineDevices: 0, offlineHubs: 0, totalDevices: 0, totalHubs: 0 }
            ),
        [org]
    );

    useEffect(() => {
        dispatch(fetchOrganizationHealthStatusSummary());
        dispatch(fetchOrganizationHealth());
    }, []);

    const getCSVData = useCallback(async () => {
        const response = await getBuildingHealthStatusCSVData().catch(err => {
            setFetchCSVError(err);
            setFetchCSVLoading(false);
        });
        if (response) {
            setFetchCSVLoading(false);
            setCSVData(response);
        }
    }, []);

    const onClickDownloadCSV = (): void => {
        setCSVDownloadModalOpen(true);
        setFetchCSVLoading(true);
        setFetchCSVError(undefined);
        getCSVData().catch(err => {
            setFetchCSVError(err);
        });
    };

    const summary = useSelector((store: Store) => store.buildingsHealthStatus.healthStatusSummary);
    const { offlineDevices = 0, offlineHubs = 0, lowBatteryDevices = 0 } = summary || {};

    const offlineHubsAndDevices = offlineDevices + offlineHubs;

    useEffectOnceWhen(() => {
        sendAnalyticsEvent({
            type: BUILDINGS_VIEWED_STATUS,
            payload: {
                pageType: PageType.Status,
                offlineBuildings: stats.offlineBuildings,
            },
        });
    }, !orgRequest.loading);

    return (
        <>
            <PageHeader title={txt('StatusPage.Header')} />
            <div className={styles.page}>
                <div className={styles.content}>
                    <div className={styles.summaryCards}>
                        <BuildingsStatusSummaryCard
                            numberOfDevicesWithIssue={offlineHubsAndDevices}
                            headerText={txt('BuildingStatus.OfflineHubsAndDevices')}
                            cardIssue={CardIssue.Offline}
                        />
                        <BuildingsStatusSummaryCard
                            numberOfDevicesWithIssue={lowBatteryDevices}
                            headerText={txt('BuildingStatus.lowBattery')}
                            cardIssue={CardIssue.Battery}
                        />
                    </div>
                    {CSVDownloadModalOpen && (
                        <ReactPdfDownloadModal
                            title="BuildingStatus.DownloadSummary"
                            description="BuildingStatus.DownloadSummaryDescription"
                            onClose={(): void => setCSVDownloadModalOpen(false)}
                        >
                            <SpinnerBlocker isLoading={fetchCSVLoading}>
                                <div>
                                    {fetchCSVError ? (
                                        <ResponseBox text={`ErrorCodes.${fetchCSVError.error}`} />
                                    ) : (
                                        <a className={styles.downloadLink} href={csv?.url}>
                                            <MaterialIcon name="download" />
                                            {`${selectedGroup?.id}-org-heath-status`}
                                        </a>
                                    )}
                                </div>
                            </SpinnerBlocker>
                        </ReactPdfDownloadModal>
                    )}
                    <BuildingsStatusSummaryBar summary={summary} onDownloadCsv={onClickDownloadCSV} />
                    <RenderComponentOrError error={orgRequest.error}>
                        <ReactPlaceholder
                            ready={!orgRequest.loading}
                            customPlaceholder={
                                <MaterialTablePlaceholder cells={3} rows={ORGANIZATION_HEALTH_TABLE_LENGTH / 2} />
                            }
                        >
                            <OrganizationHealthTable
                                totalBuildings={org.totalLocations}
                                organizationBuilding={orgBuildings}
                                isReady={!orgRequest.streaming}
                            />
                        </ReactPlaceholder>
                    </RenderComponentOrError>
                </div>
            </div>
        </>
    );
}
