import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import {
    deviceStatusColor,
    getDotColor,
    getValidLanguageTagForMoment,
    listSensorOrder,
    maxTileSensors,
    sortSensors,
} from '../../../commonFunctions';
import NotSynced from '../../../components/device/NotSynced';
import OfflineDevice from '../../../components/device/OfflineDevice';
import { userIsHbs } from '../../../components/findUserType';
import { SensorComponent } from '../../../components/sensors/SensorComponent';
import NoDataTileBody from '../../../components/tile/NoDataTileBody';
import { DeviceTypeNames, SensorTypes } from '../../../models/commonEnums';
import { CurrentSensorValuesType, DeviceType } from '../../../models/commonTypeScript';
import DeviceHealthStatus from '../../../models/deviceHealthStatus';
import DeviceListHeader from './DeviceListHeader';
import DeviceListStatusBlock from './DeviceListStatusBlock';

export type ParentProps = {
    device: DeviceType;
    url?: string;
};

type Props = ParentProps;

export const DeviceListElementComponent = ({
    device: {
        segmentName,
        latestSample,
        segmentStart,
        type,
        currentSensorValues,
        serialNumber,
        batteryPercentage,
        rssi,
        relayDevice,
        isHubConnectionLost,
        airRatingSensors,
        healthStatus,
    },
    url,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const displayStatusBar = false;
    const link = url ? `${url}/devices/${serialNumber}` : `/devices/${serialNumber}`;
    const offlineDevice = healthStatus === DeviceHealthStatus.offline;
    const notSynced = healthStatus === DeviceHealthStatus.notSynced;

    let tileBody;
    if (notSynced) {
        tileBody = <NotSynced text="neverSynced" />;
    } else if (offlineDevice) {
        if (type === DeviceTypeNames.ap1) {
            tileBody = <div />;
        } else {
            const validLanguageKey = getValidLanguageTagForMoment();
            const offlineText = latestSample
                ? txt('DeviceSettings.OfflineForTime', {
                      time: moment.utc(latestSample).local().locale(validLanguageKey).fromNow(true),
                  })
                : 'NoHubConnection';
            const noSycText =
                userIsHbs() && segmentStart && moment(segmentStart).isAfter(moment().subtract(24, 'hours'))
                    ? 'WaitForHubSync'
                    : offlineText;
            tileBody = <OfflineDevice text={noSycText} deviceOrHub="device" />;
        }
    } else if (!currentSensorValues || Object.keys(currentSensorValues).length === 0) {
        tileBody = <NoDataTileBody message="NotEnoughData" />;
    } else if (Object.keys(currentSensorValues).length > 0) {
        const orderedSensors = (): CurrentSensorValuesType[] =>
            sortSensors(currentSensorValues, listSensorOrder[type], maxTileSensors);
        tileBody = orderedSensors().map(sensor => {
            const dotColor = getDotColor(sensor.thresholds, sensor.type, sensor.value);
            return (
                <SensorComponent
                    dotColor={dotColor}
                    value={sensor.value}
                    sensorType={sensor.type}
                    unit={sensor.unit}
                    isRadonAverage={sensor.type === SensorTypes.radonShortTermAvg}
                    displayStatusBar={displayStatusBar}
                    key={`sensor-component-${sensor.type}-${serialNumber}`}
                />
            );
        });
    }

    const tileStatus = deviceStatusColor(airRatingSensors, !!isHubConnectionLost, currentSensorValues);

    return (
        <NavLink to={link} className="tile-wrapper__link list-tile--link" data-tile>
            <div className="list-tile">
                <div className="list-tile__main-info">
                    <DeviceListHeader
                        segmentName={segmentName}
                        latestSample={latestSample}
                        serialNumber={serialNumber}
                        tileStatus={tileStatus}
                        deviceType={type}
                    />
                    <DeviceListStatusBlock
                        deviceType={type}
                        segmentStart={segmentStart}
                        batteryPercentage={batteryPercentage}
                        rssi={rssi}
                        relayDevice={relayDevice}
                        isHubConnectionLost={isHubConnectionLost}
                    />
                </div>
                <div
                    className={classNames('list-tile__body', {
                        'list-tile__body--no-data': !currentSensorValues || notSynced || offlineDevice,
                    })}
                >
                    {tileBody}
                </div>
            </div>
        </NavLink>
    );
};

export default DeviceListElementComponent;
