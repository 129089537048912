import React, { ReactElement, SyntheticEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import spinner from '../../img/spinner';
import styles from './PrimaryButton.module.scss';

export type PrimaryButtonColor = 'primary' | 'secondary' | 'tertiary' | 'confirmed' | 'alert';
type Props = {
    title?: string;
    type?: 'button' | 'submit';
    autoFocus?: boolean;
    onClick: (e: SyntheticEvent<HTMLButtonElement>) => void | Promise<void>;
    id?: string;
    icon?: ReactElement;
    trailingIcon?: ReactElement;
    disabled?: boolean;
    loading?: boolean;
    testAttr?: string;
    testId?: string;
    translate?: boolean;
    color: PrimaryButtonColor;
};

const PrimaryButton = ({
    type = 'button',
    title,
    disabled = false,
    onClick,
    id,
    autoFocus,
    loading,
    testAttr,
    testId,
    icon,
    trailingIcon,
    translate = true,
    color,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <button
            type={type === 'submit' ? 'submit' : 'button'}
            disabled={disabled}
            id={id}
            className={classNames(styles.button, styles[color], {
                [styles.disabled]: disabled && !loading,
                [styles.iconButton]: icon && !title,
            })}
            onClick={(e): void => {
                if (!disabled) onClick(e);
            }}
            ref={(button): void => {
                if (autoFocus && button) button.focus();
            }}
            {...{ [`data-${testAttr || 'button'}`]: true }}
            {...{ 'data-testid': testId }}
        >
            {!loading && icon}
            {loading && spinner}
            {!loading && title && <div className={styles.title}>{translate ? txt(title) : title}</div>}
            {!loading && trailingIcon}
        </button>
    );
};

export default PrimaryButton;
