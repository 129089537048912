import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Error from 'commons/src/components/errorComponents/Error';
import PageHeader from 'commons/src/components/headers/PageHeader';
import SubHeader from 'commons/src/components/headers/SubHeader';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import { RequiredRoleLevel } from 'commons/src/models/commonEnums';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { ActionButton } from 'commons/src/models/menuModels';
import { getEmailSummary as getEmailSummaryApi } from '../../../api/buildingsApi';
import { paths } from '../../../constants';
import { SensorBreachThresholds } from '../../../models/common';
import { EmailSummaryUser, EmailSummaryConfig } from '../../../models/emailSummaryModels';
import { Store } from '../../../reducers';
import EmailSummaryForm from './EmailSummaryForm';

type StateProps = {
    loadingUser: boolean;
};

export const EmailSummaryComponent = ({ loadingUser }: StateProps): React.ReactElement => {
    const navigate = useNavigate();

    const { t: txt } = useTranslation();

    const [users, setUsers] = useState<EmailSummaryUser[]>([]);
    const [emailConfig, setEmailConfig] = useState<EmailSummaryConfig | undefined>(undefined);
    const [thresholds, setThresholds] = useState<SensorBreachThresholds>({});

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<ErrorType | undefined>(undefined);

    const onGetEmailSummary = useCallback(async (): Promise<void> => {
        await getEmailSummaryApi()
            .then(response => {
                setUsers(response.users);
                setEmailConfig(response.config);
                setThresholds(response.thresholds);
            })
            .catch(err => {
                setError(err);
            });
        setLoading(false);
    }, []);

    useEffect((): void => {
        onGetEmailSummary().catch();
    }, []);

    if (error) {
        return <Error />;
    }

    const actionButtons: ActionButton[] = [
        {
            onClick: (): void => navigate(`/${paths.buildings}`),
            testAttr: 'close-email-summary',
            title: 'Close',
            id: 'closeEmailSummary',
            color: 'secondary',
            requiredRoleLevel: RequiredRoleLevel.ABOVE_EDITOR,
            requiredGroupTypes: [],
        },
    ];

    return (
        <>
            <PageHeader title={txt(emailConfig ? 'EmailSummary.EditSummary' : 'EmailSummary.CreateSummary')} />
            <div className="page-wrapper page-wrapper__medium">
                <SubHeader actionButtons={actionButtons} />
            </div>
            <ReactPlaceholder ready={!loading && !loadingUser} customPlaceholder={mediumFormLoader}>
                <EmailSummaryForm users={users} config={emailConfig} thresholds={thresholds} />
            </ReactPlaceholder>
        </>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { loading: loadingUser },
    } = state;

    return {
        loadingUser,
    };
};

export default connect(mapStateToProps)(EmailSummaryComponent);
