import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import Error from 'commons/src/components/errorComponents/Error';
import PageHeader from 'commons/src/components/headers/PageHeader';
import SubHeader from 'commons/src/components/headers/SubHeader';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import { RequiredRoleLevel } from 'commons/src/models/commonEnums';
import { ActionButton } from 'commons/src/models/menuModels';
import {
    FetchThirdPartyIntegrationMapping,
    fetchThirdPartyIntegrationMapping,
    FetchThirdPartyIntegrations,
    fetchThirdPartyIntegrations,
} from '../../../actions/thirdPartyIntegrationActions';
import { paths } from '../../../constants';
import { ThirdPartyIntegration, ThirdPartyIntegrationAlert } from '../../../models/common';
import { Store } from '../../../reducers';
import AlertForm from './AlertForm';

type StateProps = {
    loadingLocations: boolean;
    loadingThirdPartyIntegrations: boolean;
    loadingIntegrationMapping: boolean;
    notificationAlerts: ThirdPartyIntegrationAlert[];
    thirdPartyIntegrations: ThirdPartyIntegration[];
    notificationSettingsLoading: boolean;
    error: boolean;
};

type ActionProps = {
    getThirdPartyIntegrations: () => void;
    fetchThirdPartyMapping: (integrationId: string) => void;
};

export type Props = ActionProps & StateProps;

export const AddEditAlertComponent = ({
    getThirdPartyIntegrations,
    loadingThirdPartyIntegrations,
    loadingIntegrationMapping,
    loadingLocations,
    notificationAlerts,
    thirdPartyIntegrations,
    notificationSettingsLoading,
    fetchThirdPartyMapping,
    error,
}: Props): React.ReactElement => {
    const { alertId } = useParams() as { alertId: string };
    const navigate = useNavigate();
    const [editingAlert] = useState(alertId);
    const { t: txt } = useTranslation();

    useEffect(() => {
        if (thirdPartyIntegrations.length === 0 && !loadingThirdPartyIntegrations) {
            getThirdPartyIntegrations();
        }
    }, []);

    useEffect(() => {
        if (editingAlert && !loadingThirdPartyIntegrations && !loadingLocations) {
            const alertExists = notificationAlerts.find(alert => alert.id === alertId);
            if (!alertExists) navigate(`/${paths.alerts}`);
            else {
                const { integrationId } = alertExists;
                fetchThirdPartyMapping(integrationId);
            }
        }
    }, [alertId, loadingThirdPartyIntegrations, loadingLocations]);

    useEffect(() => {
        document.body.className = 'blue-body';
        return (): void => {
            document.body.className = '';
        };
    }, []);

    if (error) {
        return <Error />;
    }
    const actionButtons: ActionButton[] = [
        {
            onClick: (): void => navigate(`/${paths.alerts}`),
            testAttr: 'close-add-edit-alert',
            title: 'Close',
            id: 'closeAddEditAlert',
            color: 'secondary',
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            requiredGroupTypes: [],
        },
    ];

    const pageLoading =
        (editingAlert && loadingIntegrationMapping) ||
        loadingThirdPartyIntegrations ||
        notificationSettingsLoading ||
        loadingLocations;

    return (
        <>
            <PageHeader title={txt(editingAlert ? 'NotificationAlerts.EditAlert' : 'NotificationAlerts.AddAlert')} />
            <div className="page-wrapper page-wrapper__medium">
                <SubHeader actionButtons={actionButtons} />
            </div>
            <ReactPlaceholder ready={!pageLoading} customPlaceholder={mediumFormLoader}>
                <AlertForm />
            </ReactPlaceholder>
        </>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        requests: {
            FETCH_THIRD_PARTY_INTEGRATIONS: { loading: loadingThirdPartyIntegrations, error: fetchIntegrationError },
            FETCH_THIRD_PARTY_INTEGRATION_MAPPING: { loading: loadingIntegrationMapping, error: fetchMappingError },
        },
        thirdPartyIntegrations: { thirdPartyIntegrations, notificationAlerts },
        locations: { loading: loadingLocations, error: locationError },
        userSettings: { loading: notificationSettingsLoading, error: notificationSettingsError },
    } = state;

    return {
        loadingThirdPartyIntegrations,
        loadingIntegrationMapping,
        loadingLocations,
        notificationSettingsLoading,
        thirdPartyIntegrations,
        notificationAlerts,
        error: locationError || !!notificationSettingsError || !!fetchIntegrationError || !!fetchMappingError,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    getThirdPartyIntegrations: (): FetchThirdPartyIntegrations => dispatch(fetchThirdPartyIntegrations()),
    fetchThirdPartyMapping: (integrationId: string): FetchThirdPartyIntegrationMapping =>
        dispatch(fetchThirdPartyIntegrationMapping(integrationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditAlertComponent);
