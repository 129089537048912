import { createSelector } from '@reduxjs/toolkit';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { ExtraSeries, FullDeviceData, SensorData } from 'commons/src/models/commonTypeScript';
import { RequestState } from 'commons/src/reducers/requestReducer';
import { Space } from '../../../models/spaceModels';
import { Store } from '../../../reducers';
import { BusinessRequestType } from '../../../reducers/BusinessRequestType';

export type SpaceOverview = {
    space?: Space;
    request: RequestState;
};

export type SpacesOverview = {
    spaces: { spaces: Space[]; availableSensors: SensorTypes[] };
    request: RequestState;
};

type SpaceSelectorData = {
    spaceDevices: string[];
    sensorData: { [segmentId: string]: SensorData };
    deviceData: { [segmentId: string]: FullDeviceData };
    extraSeries: { [segmentId: string]: ExtraSeries };
    sensorsInSpace: SensorTypes[];
};

const selectSpace = (state: Store, buildingId: string, spaceId: string): Space | undefined => {
    const spaces = state.spaces.spaces[buildingId] ?? [];
    return spaces?.spaces?.find(currentSpace => currentSpace.id === spaceId);
};

const selectSpaces = (state: Store, buildingId: string): { spaces: Space[]; availableSensors: SensorTypes[] } =>
    state.spaces.spaces[buildingId] ?? { spaces: [], availableSensors: [] };

const selectSpaceRequest = (state: Store): RequestState => state.requests[BusinessRequestType.FetchSpaceSensorData];
const selectSpaceVirtualSensorRequest = (state: Store): RequestState =>
    state.requests[BusinessRequestType.FetchSpaceVirtualSensorData];
const selectSpacesRequest = (state: Store): RequestState => state.requests.FETCH_SPACES;

export const spaceSelector = createSelector(
    [selectSpace, selectSpacesRequest],
    (space: Space | undefined, request: RequestState): SpaceOverview => ({ space, request })
);

const getSpaceData = (state: Store, spaceId: string): SpaceSelectorData => {
    const space = state.spaceData.space[spaceId];
    const spaceDevices = space?.devices ?? [];
    const { deviceData, sensorData, extraVirtualSeries } = state.spaceData;
    const spaceSensorData = {} as { [segmentId: string]: SensorData };
    const spaceExtraSeries = {} as { [segmentId: string]: ExtraSeries };
    const spaceDeviceData = {} as { [segmentId: string]: FullDeviceData };
    const spaceSensors = spaceDevices.reduce((sensors, segmentId) => {
        spaceSensorData[segmentId] = sensorData[segmentId];
        spaceDeviceData[segmentId] = deviceData[segmentId];
        spaceExtraSeries[segmentId] = extraVirtualSeries[segmentId];

        const singleDeviceData = deviceData[segmentId];
        const sensorsInSpace = singleDeviceData?.sensors.map(sensor => sensor.type) ?? [];
        return [...new Set([...sensors, ...sensorsInSpace])];
    }, [] as SensorTypes[]);

    return {
        spaceDevices,
        sensorsInSpace: spaceSensors,
        deviceData: spaceDeviceData,
        sensorData: spaceSensorData,
        extraSeries: spaceExtraSeries,
    };
};

export const spaceSensorData = createSelector(
    [getSpaceData, selectSpaceRequest, selectSpaceVirtualSensorRequest],
    (spaceData: SpaceSelectorData, requests: RequestState, virtualSensorRequest: RequestState) => ({
        spaceData,
        requests,
        virtualSensorRequest,
    })
);

export const spacesSelector = createSelector(
    [selectSpaces, selectSpacesRequest],
    (spaces: { spaces: Space[]; availableSensors: SensorTypes[] }, request: RequestState): SpacesOverview => ({
        spaces,
        request,
    })
);
