import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { hubConnectionIsUp, lastSyncedDate } from '../../commonFunctions';
import AccordionButton from '../../components/buttons/AccordionButton';
import MaterialIcon from '../../components/MaterialIcon';
import DeviceStatusPill from '../../components/pills/DeviceStatusPill';
import { dateFormats } from '../../constants';
import Ethernet from '../../img/icon-ethernet';
import { HubData } from '../../models/commonTypeScript';
import DeviceHealthStatus from '../../models/deviceHealthStatus';
import { Store } from '../../reducers';
import { getFirmwareVersionString, getHubConnectionString } from './hubFunctions';
import styles from './HubInfo.module.scss';

export type ParentProps = {
    hub: HubData | undefined;
};

type StateProps = {
    dateFormat: string;
};
export type Props = StateProps & ParentProps;

export const HubInfoComponent = ({ hub, dateFormat }: Props): React.ReactElement => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { t: txt } = useTranslation();
    if (!hub) {
        return <div />;
    }

    const { metaData } = hub;
    const hubRecentlySynced = hubConnectionIsUp(metaData.lastSeen);
    const syncedTime = metaData.lastSeen
        ? lastSyncedDate(metaData.lastSeen, dateFormat as keyof typeof dateFormats)
        : txt('neverSynced');

    const firmwareVersions = getFirmwareVersionString(metaData || {});

    const toggleDropdown = (): void => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const hubConnectionType = getHubConnectionString(hub.metaData, txt);

    const healthStatus = (): React.ReactElement => {
        switch (hub.healthStatus as DeviceHealthStatus) {
            case DeviceHealthStatus.offline:
                return (
                    <div className={styles.aligned}>
                        <div className={styles.alert}>
                            {txt('LastSyncedNoSemicolon')}
                            {metaData.lastSeen && !hubRecentlySynced && syncedTime}
                        </div>
                        <DeviceStatusPill status={hub.healthStatus as DeviceHealthStatus} deviceType="hub" />
                        <div className={styles.ethernetAndSim}>
                            {hub.metaData.cell ? (
                                <MaterialIcon extraClass="icon-rotated" name="sim_card" />
                            ) : (
                                <Ethernet />
                            )}
                            <span className={!hubRecentlySynced ? styles.ethernetAndSimText : ''}>
                                {hubConnectionType}
                            </span>
                        </div>
                    </div>
                );

            case DeviceHealthStatus.online:
                return (
                    <div className={styles.aligned}>
                        <div className={styles.iconAndText}>
                            <MaterialIcon name="cloud" />
                            <span className={styles.aligned}>{txt('Connected')}</span>
                        </div>
                        <div className={styles.ethernetAndSim}>
                            {hub.metaData.cell ? (
                                <MaterialIcon extraClass="icon-rotated" name="sim_card" />
                            ) : (
                                <Ethernet />
                            )}
                            <span className={!hubRecentlySynced ? styles.ethernetAndSimText : ''}>
                                {hubConnectionType}
                            </span>
                        </div>
                    </div>
                );
            case DeviceHealthStatus.notSynced:
                return <DeviceStatusPill status={hub.healthStatus as DeviceHealthStatus} deviceType="hub" />;
            default:
                return <div />;
        }
    };

    return (
        <div className={styles.infoHeader}>
            <div className={styles.content}>
                <div className={styles.deviceInfo}>
                    <div className={styles.iconAndText}>
                        <div className={styles.chip}>{txt('Hub')}</div>
                        <div className={styles.serialNumber}>{hub.serialNumber}</div>
                    </div>
                    <div>{healthStatus()}</div>
                </div>
                <AccordionButton
                    onClick={toggleDropdown}
                    expanded={isDropdownOpen}
                    id="buttondropdown"
                    title={isDropdownOpen ? 'LessDetails' : 'MoreDetails'}
                />
            </div>
            {isDropdownOpen && (
                <div className={styles.dropdownContent}>
                    <div>
                        {[txt('HubStrings.RadioRegion'), ': ']}
                        <span>{metaData?.region}</span>
                    </div>
                    <div>{[txt('HubStrings.Firmware'), ': ', firmwareVersions]}</div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat },
    } = state;
    return {
        dateFormat,
    };
};

export default connect(mapStateToProps)(HubInfoComponent);
