import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { selectGroup as selectGroupAction, SelectGroup } from 'commons/src/actions/selectGroupActions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import { getSelectedGroupForCustomer, isB2B, userHasAccessToGroup } from 'commons/src/features/group';
import useWindowSize from 'commons/src/hooks/useWindowSize';
import { Group, SelectedGroup } from 'commons/src/models/commonTypeScript';
import { PartnerCustomerDetails } from '../../../models/partner';
import { Store } from '../../../reducers';

type StateProps = {
    groups: Group[];
    customers: PartnerCustomerDetails[];
};

type ActionProps = {
    selectGroup: (selectedGroup: SelectedGroup) => void;
};

type ParentProps = {
    userGroupId: string;
};

type Props = StateProps & ActionProps & ParentProps;

type pixels = number;
const MOBILE_TABLE_BREAKPOINT: pixels = 1000;

const RedirectToDashboardButton = ({ groups, customers, selectGroup, userGroupId }: Props): React.ReactElement => {
    const { width } = useWindowSize();
    const selectedCustomer = customers.filter(customer => customer.userGroupId === userGroupId)[0];
    const dashboardButtonCellRef = useRef<HTMLDivElement | null>(null);
    const [hideDashboardButton, setHideDashboardButton] = useState(true);
    const hover = (): void => {
        setHideDashboardButton(false);
    };

    const endHover = (): void => {
        setHideDashboardButton(true);
    };

    useEffect((): (() => void) => {
        const refElement = dashboardButtonCellRef && dashboardButtonCellRef.current;
        if (dashboardButtonCellRef && dashboardButtonCellRef.current) {
            dashboardButtonCellRef.current.addEventListener('mouseover', hover);
            dashboardButtonCellRef.current.addEventListener('mouseleave', endHover);
        }
        return (): void => {
            if (refElement) {
                refElement.removeEventListener('mouseover', hover);
                refElement.removeEventListener('mouseleave', endHover);
            }
        };
    }, [dashboardButtonCellRef]);

    const goToDashboard = (selectedGroup: SelectedGroup): void => {
        selectGroup(selectedGroup);
    };

    const dashboardButton = (): React.ReactElement => {
        return (
            <div>
                {width > MOBILE_TABLE_BREAKPOINT ? (
                    <div className="partner-portal__status-customer-table__action-buttons">
                        {isB2B(selectedCustomer) && (
                            <PrimaryButton
                                color="secondary"
                                loading={false}
                                onClick={(): void => goToDashboard(getSelectedGroupForCustomer(selectedCustomer))}
                                title="Dashboard"
                                disabled={!userHasAccessToGroup(groups, userGroupId)}
                            />
                        )}
                    </div>
                ) : (
                    <div className="partner-portal__status-customer-mobile-table__action-buttons">
                        {isB2B(selectedCustomer) && (
                            <PrimaryButton
                                color="secondary"
                                loading={false}
                                onClick={(): void => goToDashboard(getSelectedGroupForCustomer(selectedCustomer))}
                                title="Dashboard"
                                disabled={!userHasAccessToGroup(groups, userGroupId)}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div ref={dashboardButtonCellRef}>
            {hideDashboardButton && width > MOBILE_TABLE_BREAKPOINT ? (
                <div className="partner-portal__status-customer-table__action-buttons">
                    <PrimaryButton color="tertiary" onClick={(): void => setHideDashboardButton(false)} title="..." />
                </div>
            ) : (
                dashboardButton()
            )}
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { groups },
        partner: { customers },
    } = store;

    return {
        groups,
        customers: [...customers],
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    selectGroup: (selectedGroup: SelectedGroup): SelectGroup => dispatch(selectGroupAction(selectedGroup)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RedirectToDashboardButton);
