import React from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import LabeledText from 'commons/src/components/LabeledText';
import LabeledTextWithChips from 'commons/src/components/LabeledTextWithChips';
import { IdentityProvider } from 'commons/src/models/commonEnums';
import { SingleSignOnClient } from '../../../../models/common';
import styles from './ClientInfo.module.scss';
import { identityProviderValue, secretPlaceholder } from './commonSSOFunctions';
import RedirectUriComponent from './RedirectUriToRegister';
import DomainVerification from './SSODomainVerification';

type PassedProps = {
    singleSignOnClient: SingleSignOnClient;
    setIsEditMode: (isEditMode: boolean) => void;
};

export type Props = PassedProps;

export const ClientInfo = ({ singleSignOnClient, setIsEditMode }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const identityProviderTranslation = (provider: string): string => txt(`IdentityProvider.${provider}`);

    const { clientId, scope, issuer, domain } = singleSignOnClient;
    const selectedScopes = scope.split(' ').map(clientScope => ({ id: clientScope, name: clientScope }));

    const microsoftTenant = issuer.split('/')[3];

    return (
        <div>
            <div className="form__row">
                <div className="form__field form__field--medium-width">
                    <div className={styles.paddingBottom}>
                        <LabeledText
                            label="SingleSignOn.EmailDomain"
                            noPadding
                            value={domain}
                            id="ssoDomain"
                            infoText="SsoInfoText.DomainInfo"
                        />
                    </div>
                </div>
            </div>
            <div className="form__row">
                <div className="form__field form__field--single-width">
                    <div className={styles.paddingBottom}>
                        <LabeledText
                            label="SingleSignOn.IdentityProvider"
                            noPadding
                            value={identityProviderTranslation(identityProviderValue(singleSignOnClient.issuer))}
                            id="identityProvider"
                        />
                    </div>
                </div>
            </div>
            {identityProviderValue(singleSignOnClient.issuer) === IdentityProvider.Microsoft && (
                <div className="form__row">
                    <div className="form__field form__field--medium-width">
                        <div className={styles.paddingBottom}>
                            <LabeledText
                                label="SingleSignOn.MicrosoftTenant"
                                noPadding
                                value={microsoftTenant}
                                id="microsftTenant"
                                infoText="SsoInfoText.TenantIdInfo"
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className="form__row">
                <div className="form__field form__field--medium-width">
                    <div className={styles.paddingBottom}>
                        <LabeledText label="SingleSignOn.Issuer" noPadding value={issuer} id="ssoIssuer" />
                    </div>
                </div>
            </div>
            <div className="form__row">
                <div className="form__field form__field--medium-width">
                    <div className={styles.paddingBottom}>
                        <LabeledText
                            label="SingleSignOn.ClientId"
                            noPadding
                            value={clientId}
                            id="clientId"
                            infoText="SsoInfoText.ClientIdInfo"
                        />
                    </div>
                </div>
            </div>
            <div className="form__row">
                <div className="form__field form__field--medium-width">
                    <div className={styles.paddingBottom}>
                        <LabeledText
                            label="SingleSignOn.ClientSecret"
                            noPadding
                            value={secretPlaceholder}
                            id="clientSecret"
                            infoText="SsoInfoText.ClientSecretInfo"
                        />
                    </div>
                </div>
            </div>
            <div className="form__row">
                <div className="form__field form__field--medium-width">
                    <div className={styles.scopeWrapper}>
                        <LabeledTextWithChips
                            label="SingleSignOn.Scope"
                            selectedElements={selectedScopes}
                            id="ssoScope"
                            infoText="SsoInfoText.ScopeInfo"
                        />
                    </div>
                </div>
            </div>
            <div className="form__row">
                <div className="form__attr--element">
                    <PrimaryButton
                        id="editSSO"
                        title="Edit"
                        color="secondary"
                        onClick={(): void => setIsEditMode(true)}
                        testId="edit-sso"
                    />
                </div>
            </div>
            <RedirectUriComponent />
            <DomainVerification singleSignOnClient={singleSignOnClient} />
        </div>
    );
};

export default ClientInfo;
