import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from 'commons/src/components/headers/PageHeader';
import SubHeader from 'commons/src/components/headers/SubHeader';
import { paths, roleRestrictions } from 'commons/src/constants';
import EndedSegmentPage from 'commons/src/features/devicePage/EndedSegmentPage';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { BuildingType, FullDeviceData } from 'commons/src/models/commonTypeScript';
import { ActionButton } from 'commons/src/models/menuModels';
import { fetchBuilding } from '../../actions/locationActions';
import { Store } from '../../reducers';
import { downloadCsvButton, endedSegmentOptionButton } from './DeviceMenuOptions';

type StateProps = {
    segments: { [segment: string]: FullDeviceData };
    demoMode: boolean;
    buildings: { [buildingId: string]: BuildingType };
};

type Props = StateProps;

export const PreviousMeasurementPageComponent = ({ segments, demoMode, buildings }: Props): React.ReactElement => {
    const { serialNumber, segmentId } = useParams() as { serialNumber: string; segmentId: string };
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const segment = segments[segmentId];
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [downloadCsvModalOpen, setDownloadCsvModalOpen] = useState(false);

    const locationId = segment && segment.locationId;
    const building = (locationId && buildings[locationId as keyof typeof buildings]) || undefined;

    useEffect(() => {
        if (!building && locationId) {
            dispatch(fetchBuilding(locationId));
        }
    }, [locationId]);

    const onBackClicked = (): void =>
        navigate(`/${locationId ? `${paths.buildings}/${locationId}/devices` : paths.buildings}`);

    const onDeleteModalOpen = (): void => {
        if (demoMode) return;
        setIsDeleteModalOpen(true);
    };

    const endedSegmentActionButtons: ActionButton[] = [
        {
            onClick: onDeleteModalOpen,
            title: 'DeleteEndedSegment',
            color: 'alert',
            testAttr: 'delete-ended-segment',
            id: 'deleteEndedSegment',
            requiredRoleLevel: roleRestrictions.editDeviceOrBuilding,
            requiredGroupTypes: [],
        },
    ];

    const deviceWithRadonSensor =
        segment && segment.sensors && segment.sensors.some(sensor => sensor.type === SensorTypes.radonShortTermAvg);
    const downloadButton =
        segment && endedSegmentOptionButton(() => setDownloadCsvModalOpen(true), deviceWithRadonSensor, navigate);
    if (downloadButton && downloadButton.options.length === 0) {
        endedSegmentActionButtons.push(downloadCsvButton(() => setDownloadCsvModalOpen(true)));
    }

    return (
        <div>
            <PageHeader
                title={segment && segment.segmentName}
                subHeaderClick={onBackClicked}
                subHeader={segment && segment.locationName}
            />
            <div className="container">
                <SubHeader
                    actionButtons={endedSegmentActionButtons}
                    optionButton={downloadButton && downloadButton.options.length > 0 ? downloadButton : undefined}
                />
            </div>
            <EndedSegmentPage
                serialNumber={serialNumber}
                timeZone={building?.timezone}
                segmentId={segmentId}
                isDeleteModalOpen={isDeleteModalOpen}
                setDeleteModalOpen={setIsDeleteModalOpen}
                downloadCsvModalOpen={downloadCsvModalOpen}
                setDownloadCsvModalOpen={setDownloadCsvModalOpen}
            />
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        endedSegments: { segments },
        userSettings: { demoMode },
        buildings: { buildings },
    } = state;
    return {
        segments,
        demoMode,
        buildings,
    };
};

export default connect(mapStateToProps)(PreviousMeasurementPageComponent);
