import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { BUILDING_VIEWED_DEVICES } from 'commons/src/analytics/AnalyticsEvents';
import { isVirtualDevice } from 'commons/src/commonFunctions';
import NoDevices from 'commons/src/components/emptyStates/NoDevices';
import SubHeader from 'commons/src/components/headers/SubHeader';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { fullWidthListPlaceholder } from 'commons/src/components/placeholder';
import { dateFormats, roleRestrictions } from 'commons/src/constants';
import DevicesList from 'commons/src/features/devices/LocationSection/DevicesList';
import { RequiredRoleLevel, Role } from 'commons/src/models/commonEnums';
import {
    AnyDeviceType,
    BuildingType,
    DeviceType,
    DeviceWithKeyInfo,
    EndedSegment,
    ErrorType,
    HubData,
} from 'commons/src/models/commonTypeScript';
import { ActionButton } from 'commons/src/models/menuModels';
import { Store } from '../../../reducers';
import { BusinessRequestType } from '../../../reducers/BusinessRequestType';
import styles from './BuildingDevices.module.scss';

type StateProps = {
    hubs: HubData[];
    dateFormat: keyof typeof dateFormats;
    allEndedMeasurements: EndedSegment[];
    buildings: { [locationId: string]: BuildingType };
    error?: ErrorType;
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    devices: { [serialNumber: string]: DeviceType };
    fetchBuildingWithDevicesLoading: boolean;
    userRole?: Role;
};

export type Props = StateProps;

export const BuildingDevicesComponent = ({
    buildings,
    error,
    devices,
    hubs,
    dateFormat,
    allEndedMeasurements,
    devicesWithKeyInfo,
    fetchBuildingWithDevicesLoading,
    userRole,
}: Props): React.ReactElement => {
    const { buildingId } = useParams<'buildingId'>() as { buildingId: string };
    const { t: txt } = useTranslation();
    const navigate = useNavigate();

    const building = buildings[buildingId];
    const endedMeasurements = allEndedMeasurements.filter(measurement => measurement.locationId === buildingId);

    const [searchText, setSearchText] = useState('');
    const [buildingDevicesFetched, setBuildingDevicesFetched] = useState(false);

    useEffect(() => {
        if (building) {
            analyticsLogger(BUILDING_VIEWED_DEVICES, { pageType: PageType.Building, buildingName: building.name });
        }
    }, [building]);

    const buildingDevices: DeviceWithKeyInfo[] = useMemo(
        () =>
            building
                ? Object.values(devicesWithKeyInfo).filter(device => building.devices.includes(device.serialNumber))
                : [],
        [building]
    );
    const physicalDevices: DeviceWithKeyInfo[] = useMemo(
        () => buildingDevices.filter(device => !isVirtualDevice(device.type as AnyDeviceType)),
        [buildingDevices]
    );

    const endedSegmentsFromPhysicalDevices = endedMeasurements.filter(
        device => !isVirtualDevice(device.deviceType as AnyDeviceType)
    );

    useEffect(() => {
        if (!buildingDevicesFetched) {
            const devicesInBuilding =
                (building && building.devices.map(serialNumber => devices[serialNumber]).filter(device => !!device)) ||
                [];
            const buildingDevicesInState =
                building &&
                (building.devices.length === 0 || (building.devices.length > 0 && devicesInBuilding.length > 0));
            setBuildingDevicesFetched(!!buildingDevicesInState);
        }
    }, [building, devices]);

    const devicesInLocation =
        !!building &&
        (physicalDevices.length > 0 || building.hubs.length > 0 || endedSegmentsFromPhysicalDevices.length > 0);
    if (!!building && !devicesInLocation) {
        return (
            <>
                <div className={styles.banner}>{txt('Spaces.DevicePageSpacesMigration')}</div>
                <div className="page-wrapper" data-building-no-devices-page>
                    <div className="page-section--full-width">
                        <NoDevices
                            locationId={building.id}
                            userRole={userRole}
                            requiredRoleLevel={roleRestrictions.editDeviceOrBuilding}
                        />
                    </div>
                </div>
            </>
        );
    }

    const onSearchUpdate = (text: string): void => {
        const textInLowerCase = text.trim().toLowerCase();
        setSearchText(textInLowerCase);
    };
    const redirectToSpaces = (): void => {
        navigate(`/buildings/${buildingId}/spaces`);
    };

    const actionButtons: ActionButton[] = [
        {
            id: 'exportMoved',
            title: 'Space.DeviceExportingMoved',
            onClick: redirectToSpaces,
            icon: <MaterialIcon name="info" />,
            color: 'secondary',
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            testAttr: 'export-moved',
            requiredGroupTypes: [],
        },
    ];

    return (
        <div data-building-devices-page>
            <ReactPlaceholder ready={!!building && buildingDevicesFetched} customPlaceholder={fullWidthListPlaceholder}>
                <div className={styles.banner}>{txt('Spaces.DevicePageSpacesMigration')}</div>
                <div className="page-wrapper">
                    <SubHeader onSearchUpdate={onSearchUpdate} actionButtons={actionButtons} />
                </div>
                <DevicesList
                    loading={!building}
                    devicesLoading={fetchBuildingWithDevicesLoading}
                    locations={building ? [building] : []}
                    devices={devices}
                    error={!!error}
                    dateFormat={dateFormat}
                    endedMeasurements={endedSegmentsFromPhysicalDevices}
                    onlyPhysicalDevices
                    hubs={hubs}
                    showLocationHeader={false}
                    searchText={searchText}
                />
            </ReactPlaceholder>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        devices: { devices, devicesWithKeyInfo, hubs, endedMeasurements },
        userSettings: { dateFormat, selectedGroup },
        buildings: { buildings },
        requests: {
            [BusinessRequestType.FetchBuildingWithDevices]: {
                error: fetchBuildingWithDevicesError,
                loading: fetchBuildingWithDevicesLoading,
            },
        },
    } = state;
    const userRole = selectedGroup?.role;

    return {
        dateFormat,
        devices,
        devicesWithKeyInfo,
        fetchBuildingWithDevicesLoading,
        buildings,
        hubs,
        userRole,
        error: fetchBuildingWithDevicesError,
        allEndedMeasurements: endedMeasurements,
    };
};

export default connect(mapStateToProps)(BuildingDevicesComponent);
