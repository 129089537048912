import { t as txt } from 'i18next';
import _ from 'lodash';
import moment from 'moment';
import { NetTypes } from 'commons/src/models/commonEnums';
import { BuildingHealthRowData } from '../../../../../models/buildingHealthModels';

export const formatFirmware = (firmware?: object): string => {
    if (firmware === undefined || _.isEmpty(firmware)) return '-';
    return Object.entries(firmware)
        .map(([key, value]) => {
            if (!value) return null;
            return `${key}: ${value}`;
        })
        .filter(Boolean)
        .join(', ')
        .toUpperCase();
};

export const formatConnections = ({ net, cell, rssi, rsrq }: BuildingHealthRowData): string => {
    if (!cell) return txt('HubStrings.Ethernet');
    if (!net) return '-';
    const strength = net === NetTypes['4G'] ? `(RSRQ: ${rsrq} dB)` : `(RSSI: ${rssi} dBm)`;
    const showStrength = net === NetTypes['4G'] ? Boolean(rsrq) : Boolean(rssi);
    return `${txt('HubStrings.SIM')}, ${net ?? ''} ${showStrength ? strength : null}`;
};

export const formatLastSeen = (lastSeen?: string): string => {
    if (!lastSeen) return txt('StatusPill.HubNotSynced');
    return moment.utc(lastSeen).local().locale(lastSeen).fromNow(true);
};

export const assignStatus = (isHub: boolean, status?: string): string => {
    if (isHub) {
        switch (status) {
            case 'OFFLINE':
                return txt('StatusPill.HubOffline');
            case 'ONLINE':
                return txt('StatusPill.HubOnline');
            case 'NOT_SYNCED':
                return txt('StatusPill.HubNotSynced');
            default:
                return '-';
        }
    }
    switch (status) {
        case 'OFFLINE':
            return txt('StatusPill.DeviceOffline');
        case 'ONLINE':
            return txt('StatusPill.DeviceOnline');
        case 'NOT_SYNCED':
            return txt('StatusPill.DeviceNotSynced');
        case 'LOW_BATTERY':
            return txt('StatusPill.DeviceNotSynced');
        default:
            return '-';
    }
};

export const formatRssi = (rssi?: number): string => {
    if (rssi === undefined) return '-';
    return `${rssi} dBm`;
};

export const formatBatteryLevel = (battery?: number): string => {
    if (battery === undefined) return '-';
    return `${battery}%`;
};
