import React, { SyntheticEvent, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { SelectionItem } from '../../models/commonTypeScript';
import ChipButton from '../buttons/ChipButton';
import InputLabel from './InputLabel';
import styles from './InputWithChips.module.scss';

export type Props = {
    selectedElements: SelectionItem[];
    onSubmit: (element: string) => void;
    onRemove: (id: string) => void;
    hint?: string;
    infoText?: string;
    validate: boolean;
    isValid?: boolean;
    label?: string;
    disabled?: boolean;
};

const InputWithChips = (props: Props): React.ReactElement => {
    const {
        onRemove,
        selectedElements,
        onSubmit,
        hint,
        infoText,
        validate,
        isValid = false,
        label,
        disabled = false,
    } = props;
    const { t: txt } = useTranslation();
    const [chips, setChips] = useState('');
    const submitKeys = ['Enter', ' ', ','];

    const onChange = (event: SyntheticEvent<HTMLInputElement>): void => {
        const value = event.currentTarget.value.trim();
        setChips(value);
    };

    const onBlur = (event: SyntheticEvent<HTMLInputElement>): void => {
        const value = event.currentTarget.value.trim();
        if (value.length > 0) {
            onSubmit(value);
            setChips('');
        }
    };

    const onChipsSubmission = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        const str = chips.replace(/,\s*$/, '');
        if (str.length > 0 && submitKeys.includes(event.key)) {
            onSubmit(str);
            setChips('');
        }
    };

    return (
        <div className={styles.chipsInputContainer}>
            <InputLabel htmlFor="inputWithChips" label={label} infoText={infoText} />
            <div className={styles.inputContainer}>
                <label
                    htmlFor="chipsInput"
                    className={classNames(
                        styles.chips,
                        !isValid && validate && styles.invalid,
                        disabled && styles.disabled
                    )}
                >
                    <div id="inputWithChips" className={styles.inputField}>
                        {selectedElements.map(elem => (
                            <ChipButton
                                key={elem.id}
                                title={elem.name}
                                id={elem.id}
                                onDelete={onRemove}
                                testId={`remove-chip-${elem.id}`}
                            />
                        ))}
                        {!disabled && (
                            <input
                                type="text"
                                className={classNames(styles.chipsInput, !isValid && validate && styles.invalid)}
                                id="chipsInput"
                                placeholder={txt('SingleSignOn.NewScope')}
                                value={chips}
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyUp={(e): void => {
                                    onChipsSubmission(e);
                                }}
                                autoComplete="off"
                            />
                        )}
                    </div>
                </label>
            </div>
            {validate && !isValid && hint && <div className="input-container__error">{hint && txt(hint)}</div>}
        </div>
    );
};

export default InputWithChips;
