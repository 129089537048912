import React, { SyntheticEvent, useEffect, useState } from 'react';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { KeyValuePairType } from '../../../models/common';
import MapInputRow, { isValidKey } from './MapInputRow';

export const MAX_ROWS = 200;

export type Props = {
    id: string;
    pairs: KeyValuePairType[];
    keyHeader: string;
    keyHint: string;
    valueHeader: string;
    valueHint: string;
    addButtonText: string;
    updatePair: (updatedMap: KeyValuePairType[]) => void;
    maxValueLength: number;
    maxKeyLength: number;
    displayValidation: boolean;
    maxRows?: number;
    hasHttpHeaderKey?: boolean;
};

export const pairsWithError = (valueMap: KeyValuePairType[], hasHttpHeaderKey?: boolean): boolean =>
    valueMap.filter(row => {
        const validKey = isValidKey(row.key, hasHttpHeaderKey);
        return !validKey;
    }).length > 0;

// Used for populating a Map (i.e. a list of Pairs).
export const MapInputComponent = (props: Props): React.ReactElement => {
    const [showPairValidation, setShowPairValidation] = useState(false);
    const [validateLastEmptyPair, setValidateLastEmptyPair] = useState(false);

    const {
        id,
        keyHeader,
        keyHint,
        valueHeader,
        valueHint,
        pairs,
        addButtonText,
        maxValueLength,
        maxKeyLength,
        displayValidation,
        updatePair,
        maxRows = MAX_ROWS,
        hasHttpHeaderKey,
    } = props;

    const numberOfRows = pairs.length;

    const addRow = (e: SyntheticEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        const pairWithError = pairsWithError(pairs, hasHttpHeaderKey);
        if (pairWithError) {
            setShowPairValidation(true);
            setValidateLastEmptyPair(true);
        } else if (pairs.length < maxRows) {
            setShowPairValidation(false);
            updatePair([...pairs, { key: '', value: '' }]);
        }
    };

    const removeRow = (index: number): void => {
        const updatedValueMap = [...pairs];
        updatedValueMap.splice(index, 1);
        updatePair(updatedValueMap);
    };

    const onChange = (updatedRowIndex: number, updatedPair: KeyValuePairType): void => {
        const updatedValueMap = [...pairs];
        updatedValueMap[updatedRowIndex] = updatedPair;
        updatePair(updatedValueMap);
    };

    useEffect(() => {
        if (pairs.length === 0) {
            updatePair([...pairs, { key: '', value: '' }]);
        }
    }, []);

    useEffect(() => {
        if (displayValidation && !showPairValidation) {
            setShowPairValidation(true);
            setValidateLastEmptyPair(false);
        }
    }, [displayValidation, showPairValidation]);

    const preventLastPairValidation = (isLast: boolean, pair: KeyValuePairType): boolean => {
        if (!isLast) return false;
        const isEmpty = pair.key.length === 0 && pair.value.length === 0;
        return isLast && isEmpty && !validateLastEmptyPair;
    };

    const rows = pairs.map((value, index): React.ReactElement => {
        const isLastPair = index === pairs.length - 1;
        const onUpdate = (newValue: KeyValuePairType): void => onChange(index, newValue);
        const onRemove = (): void => removeRow(index);
        return (
            <MapInputRow
                id={`map-${id}-row-${index.toString()}`}
                key={`map-${id.toLowerCase()}-row-${index.toString()}`}
                keyHeader={keyHeader}
                keyHint={keyHint}
                valueHeader={valueHeader}
                valueHint={valueHint}
                showValidation={showPairValidation && !preventLastPairValidation(isLastPair, value)}
                onChange={onUpdate}
                pair={value}
                maxValueLength={maxValueLength}
                maxKeyLength={maxKeyLength}
                removeRow={onRemove}
                displayRemoveButton={numberOfRows > 1}
                hasHttpHeaderKey={hasHttpHeaderKey}
            />
        );
    });

    return (
        <div id={id}>
            {rows}
            <div className="form__row">
                <PrimaryButton
                    color="secondary"
                    onClick={addRow}
                    type="button"
                    title={addButtonText}
                    testAttr="add-input"
                    disabled={pairs.length >= maxRows}
                    icon={<MaterialIcon name="add_circle_outline" />}
                    testId={`${id.toLowerCase()}-new-input`}
                />
            </div>
        </div>
    );
};

export default MapInputComponent;
