import React, { useState, useEffect, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { UpdateUserPreferences, updateUserPreferences, UpdateUserSettingsPayload } from '../../actions/SettingsActions';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import RadioButtons from '../../components/input/Radio';
import ModalHeader from '../../components/modals/ModalHeader';
import getUserPreferenceSelectors, { userSettingsSelectors } from '../../components/units/UserPreferenceOptions';
import { Units } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import { CommonRequestType } from '../../reducers/requestReducer';

type PassedProps = {
    settingsUpdated: () => void;
};

type StateProps = {
    dateFormat: string;
    units: Units;
    loading: boolean;
};

type ActionProps = {
    onSubmit: (settings: UpdateUserSettingsPayload) => void;
};

type Props = PassedProps & StateProps & ActionProps;

export const UpdateSettingsComponent = (props: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { dateFormat, units, loading, onSubmit, settingsUpdated } = props;
    const [selectedDateFormat, setSelectedDateFormat] = useState(dateFormat);
    const [selectedRadonUnit, setSelectedRadonUnit] = useState(units.radonUnit);
    const [selectedTempUnit, setSelectedTempUnit] = useState(units.tempUnit);
    const [selectedPressureUnit, setSelectedPressureUnit] = useState(units.pressureUnit);
    const [selectedLengthUnit, setSelectedLengthUnit] = useState(units.lengthUnit);
    const [selectedVocUnit, setSelectedVocUnit] = useState(units.vocUnit);
    const [initialLoadingState, setInitialLoadingState] = useState(loading);

    useEffect(() => {
        if (initialLoadingState && !loading) {
            settingsUpdated();
        }
        setInitialLoadingState(loading);
    }, [loading]);

    const updatePreferences = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { value, name } = e.currentTarget;
        if (name === userSettingsSelectors.dateFormat) setSelectedDateFormat(value);
        if (name === userSettingsSelectors.radonUnit) setSelectedRadonUnit(value);
        if (name === userSettingsSelectors.tempUnit) setSelectedTempUnit(value);
        if (name === userSettingsSelectors.pressureUnit) setSelectedPressureUnit(value);
        if (name === userSettingsSelectors.lengthUnit) setSelectedLengthUnit(value);
        if (name === userSettingsSelectors.vocUnit) setSelectedVocUnit(value);
    };

    const updateSettings = (e: React.SyntheticEvent<HTMLElement>): void => {
        e.preventDefault();
        onSubmit({
            units: {
                radonUnit: selectedRadonUnit,
                tempUnit: selectedTempUnit,
                pressureUnit: selectedPressureUnit,
                lengthUnit: selectedLengthUnit,
                vocUnit: selectedVocUnit,
            },
            dateFormat: selectedDateFormat,
        });
    };

    const settingsRow = (
        settingId: string,
        options: { label: string; value: string }[],
        header: string,
        selectedValue: string
    ): React.ReactElement => {
        return (
            <div className="settings__row settings__row--invite-settings" key={settingId}>
                <RadioButtons
                    buttons={options}
                    selectorName={settingId}
                    row
                    header={header}
                    onChange={updatePreferences}
                    value={selectedValue}
                    labelId={`user-settings-${settingId}`}
                />
            </div>
        );
    };

    return (
        <div>
            <ModalHeader headerText="UserSettings" />
            <div className="modal__content__body modal__content__body--large">
                <div className="modal__content__description">{txt('SetUserPreferences')}</div>
                <form onSubmit={updateSettings} className="form-flex">
                    <div className="modal__content__description modal__content__description--slim modal__content__description--left">
                        {getUserPreferenceSelectors(
                            settingsRow,
                            selectedRadonUnit,
                            selectedTempUnit,
                            selectedPressureUnit,
                            selectedVocUnit,
                            selectedDateFormat,
                            selectedLengthUnit
                        )}
                    </div>
                    <div className="modal__content__buttons modal__content__buttons--flex-end">
                        <PrimaryButton
                            title={txt('Save')}
                            type="submit"
                            testId="update-settings-button"
                            loading={loading}
                            onClick={updateSettings}
                            disabled={false}
                            color="primary"
                            testAttr="update-settings-button"
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat, units },
        commonRequests: {
            [CommonRequestType.UpdateUserPreferences]: { loading },
        },
    } = state;
    return { dateFormat, units, loading };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onSubmit: (settings): UpdateUserPreferences => dispatch(updateUserPreferences(settings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSettingsComponent);
