import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import { SUBSCRIPTION_SEATS_ADDED } from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import CheckBox from 'commons/src/components/input/Checkbox';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { dateFormats } from 'commons/src/constants';
import { GroupType } from 'commons/src/models/commonEnums';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { addSeatsToSubscription } from '../../../../actions/subscriptionActions';
import { subscriptionTermsAndConditions } from '../../../../constants';
import { EstimatedSubscription, Subscription, SubscriptionsOrderRequest } from '../../../../models/subscriptionModels';
import { Store } from '../../../../reducers';
import { BusinessRequestType as RequestType } from '../../../../reducers/BusinessRequestType';
import styles from './AddSeatsPreview.module.scss';
import SubscriptionSummary from './SubscriptionSummary';

type ParentProps = {
    costPreview?: EstimatedSubscription;
    oldSubscription: Subscription;
    setStepNumber: (step: number) => void;
    childUserGroupId?: string;
    dateFormat: keyof typeof dateFormats;
    subscriptionPayload?: SubscriptionsOrderRequest;
    poNumber: string;
    groupType?: GroupType;
    userAllowedToManageSubscription?: boolean;
};

type StateProps = {
    loading: boolean;
    error?: ErrorType;
};

type Props = ParentProps & StateProps;

const AddSeatsPreview = ({
    setStepNumber,
    oldSubscription,
    loading,
    error,
    childUserGroupId,
    costPreview,
    dateFormat,
    subscriptionPayload,
    poNumber,
    groupType,
    userAllowedToManageSubscription,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const [agreedToTerms, setAgreedToTerms] = useState(false);

    const prevLoadingRef = useRef(loading);

    const purchaseSeats = (): void => {
        if (userAllowedToManageSubscription && groupType && oldSubscription && subscriptionPayload) {
            dispatch(
                addSeatsToSubscription(
                    subscriptionPayload,
                    oldSubscription.subscriptionNumber,
                    groupType,
                    childUserGroupId
                )
            );
            analyticsLogger(SUBSCRIPTION_SEATS_ADDED, { groupType });
            prevLoadingRef.current = true;
        } else {
            prevLoadingRef.current = false;
        }
    };

    useEffect(() => {
        if (prevLoadingRef.current && !loading) {
            prevLoadingRef.current = false;
            if (!error) {
                setStepNumber(3);
            }
        }
    }, [prevLoadingRef, loading]);

    return (
        <div className="page-wrapper__medium">
            <h2>{txt('Subscription.ReviewAndConfirm')}</h2>
            <div className={styles.wrapper}>
                <SubscriptionSummary
                    oldSubscription={oldSubscription}
                    costPreview={costPreview}
                    dateFormat={dateFormat}
                    poNumber={poNumber}
                    displayDiscounts
                />
                <div className={styles.termsAndConditions}>
                    <CheckBox
                        label={
                            <div>
                                <Trans i18nKey="AddSeats.AgreeToSubscriptionTerms">
                                    <a href={subscriptionTermsAndConditions} target="_blank" rel="noopener noreferrer">
                                        {subscriptionTermsAndConditions}
                                    </a>
                                </Trans>
                            </div>
                        }
                        labelIsElement
                        id="agreeToSubscriptionTerms"
                        onChange={(): void => setAgreedToTerms(!agreedToTerms)}
                        checked={agreedToTerms}
                    />
                </div>
                <div className={styles.buttonWrapper}>
                    <PrimaryButton color="secondary" onClick={(): void => setStepNumber(1)} title="Navigate.Back" />
                    <PrimaryButton
                        onClick={purchaseSeats}
                        title="AddSeats.PurchaseSeats"
                        disabled={!agreedToTerms}
                        loading={loading}
                        color="primary"
                    />
                </div>
                {error && !loading && <ResponseBox text={`ErrorCodes.${error.error}`} />}
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        requests: {
            [RequestType.AddSeatsToSubscription]: { loading, error },
        },
    } = store;
    return {
        loading,
        error,
    };
};

export default connect(mapStateToProps)(AddSeatsPreview);
