import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceHolder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import PageHeader from 'commons/src/components/headers/PageHeader';
import SubHeader from 'commons/src/components/headers/SubHeader';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import { RequiredRoleLevel } from 'commons/src/models/commonEnums';
import { ActionButton } from 'commons/src/models/menuModels';
import {
    FetchAlertEvents,
    fetchAlertEvents,
    FetchThirdPartyIntegrations,
    fetchThirdPartyIntegrations,
} from '../../../actions/thirdPartyIntegrationActions';
import { alertRuleThresholdCategories, paths, alertSystemNotificationRules } from '../../../constants';
import { NotificationAlertEvent, ThirdPartyIntegrationAlert } from '../../../models/common';
import { Store } from '../../../reducers';
import AlertHistoryInfo from './AlertEventInfo';

type ActionProps = {
    onFetchAlertEvents: () => void;
    onFetchThirdPartyIntegrations: () => void;
};

type StateProps = {
    alertEvents: NotificationAlertEvent[];
    notificationAlerts: ThirdPartyIntegrationAlert[];
    loadingThirdPartyIntegrations: boolean;
    loadingAlertEvents: boolean;
    loadingLocations: boolean;
};

export type Props = ActionProps & StateProps;

export const AlertHistoryComponent = (props: Props): React.ReactElement => {
    const {
        onFetchAlertEvents,
        alertEvents,
        loadingThirdPartyIntegrations,
        onFetchThirdPartyIntegrations,
        loadingAlertEvents,
        loadingLocations,
        notificationAlerts,
    } = props;
    const { historyId: alertHistoryId } = useParams<'historyId'>() as { historyId: string };
    const navigate = useNavigate();
    const eventExists = alertEvents.find(alert => alert.id === alertHistoryId);
    const { t: txt } = useTranslation();

    useEffect(() => {
        if (!loadingAlertEvents && !loadingLocations && !eventExists) {
            navigate(`/${paths.history}`);
        }
    }, [loadingAlertEvents, loadingLocations, eventExists]);

    useEffect((): void => {
        if (alertEvents.length === 0 && !loadingAlertEvents) {
            onFetchAlertEvents();
        }
        if (notificationAlerts.length === 0 && !loadingThirdPartyIntegrations) {
            onFetchThirdPartyIntegrations();
        }
    }, []);

    let pageTitle = '';
    if (eventExists && eventExists.content.type === alertSystemNotificationRules.deviceOffline) {
        pageTitle = txt('NotificationAlerts.OfflineDeviceEventTitle');
    } else if (eventExists) {
        pageTitle = txt(eventExists.content.type).concat(
            eventExists.content.thresholdType === alertRuleThresholdCategories.allAbove
                ? txt('NotificationAlerts.IsHigh')
                : txt('NotificationAlerts.IsLow')
        );
    }

    const pageLoading = loadingAlertEvents || loadingThirdPartyIntegrations || loadingLocations;
    const actionButtons: ActionButton[] = [
        {
            onClick: (): void => navigate(`/${paths.history}`),
            testAttr: 'close-alert-element',
            title: 'Close',
            id: 'closeAlertElement',
            color: 'secondary',
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            requiredGroupTypes: [],
        },
    ];
    return (
        <>
            <PageHeader title={pageTitle} />
            <div className="page-wrapper__medium">
                <SubHeader actionButtons={actionButtons} />
            </div>
            <ReactPlaceHolder ready={!pageLoading} customPlaceholder={mediumFormLoader}>
                <div>{eventExists && <AlertHistoryInfo alertEvent={eventExists} />}</div>
            </ReactPlaceHolder>
        </>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        thirdPartyIntegrations: { alertEvents, notificationAlerts },
        requests: {
            FETCH_THIRD_PARTY_INTEGRATIONS: { loading: loadingThirdPartyIntegrations },
            FETCH_ALERT_EVENTS: { loading: loadingAlertEvents },
        },
        locations: { loading: loadingLocations },
    } = state;

    return {
        alertEvents,
        loadingLocations,
        loadingAlertEvents,
        loadingThirdPartyIntegrations,
        notificationAlerts,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onFetchAlertEvents: (): FetchAlertEvents => dispatch(fetchAlertEvents()),
    onFetchThirdPartyIntegrations: (): FetchThirdPartyIntegrations => dispatch(fetchThirdPartyIntegrations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertHistoryComponent);
