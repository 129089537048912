import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { mapMoldLinkToLanguage } from 'commons/src/constants';
import IconMold from 'commons/src/img/sensorIcons/IconMold';
import IconVirusRisk from 'commons/src/img/sensorIcons/IconVirusRisk';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { Store } from '../../reducers';
import VirusInfoModal from '../reports/virusRisk/VirusInfoModal';
import styles from './InsightInfoButtons.module.scss';

type Props = {
    sensor: SensorTypes;
};
const InsightInfoButtons = ({ sensor }: Props): React.ReactElement => {
    const [virusRiskModalOpen, setVirusRiskModalOpen] = useState(false);
    const { language } = useSelector((state: Store) => state.userSettings);

    const toggleModalState = (): void => setVirusRiskModalOpen(!virusRiskModalOpen);
    const goToMoldInsight = (): void => {
        const moldLink = mapMoldLinkToLanguage[language];
        const newWindow = window.open(moldLink, '_blank', 'noopener noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    return (
        <div className={styles.wrapper}>
            {virusRiskModalOpen && <VirusInfoModal onClose={toggleModalState} />}
            {sensor === SensorTypes.virusRisk && (
                <PrimaryButton
                    onClick={toggleModalState}
                    title="VirusInsight.LearnMore"
                    icon={<IconVirusRisk />}
                    color="secondary"
                    trailingIcon={<MaterialIcon name="keyboard_arrow_right" />}
                />
            )}
            {sensor === SensorTypes.mold && (
                <PrimaryButton
                    color="secondary"
                    onClick={goToMoldInsight}
                    title="WhatIsmold"
                    icon={<IconMold />}
                    trailingIcon={<MaterialIcon name="keyboard_arrow_right" />}
                />
            )}
        </div>
    );
};

export default InsightInfoButtons;
