import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { FetchLocationsHistory, fetchLocationsHistory } from '../../actions/LocationActions';
import Error from '../../components/errorComponents/Error';
import { userIsHbs } from '../../components/findUserType';
import PageHeader from '../../components/headers/PageHeader';
import SubHeader from '../../components/headers/SubHeader';
import { businessPaths, dateFormats } from '../../constants';
import { DeviceType, EndedSegment, Group, HubData, LocationType } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import DevicesList from './LocationSection/DevicesList';

export type StateProps = {
    loading: boolean;
    locations: LocationType[];
    devices: { [key: string]: DeviceType };
    error: boolean;
    dateFormat: keyof typeof dateFormats;
    endedMeasurements: EndedSegment[];
    hubs: HubData[];
    groups: Group[];
};

type ActionProps = {
    getPastMeasurements: () => void;
};

export type Props = StateProps & ActionProps;

export const Devices = ({
    locations,
    loading,
    devices,
    error,
    dateFormat,
    endedMeasurements,
    getPastMeasurements,
    hubs,
    groups,
}: Props): React.ReactElement => {
    const navigate = useNavigate();
    const [search] = useSearchParams();
    const { t: txt } = useTranslation();
    const [searchText, setSearchText] = useState('');

    const businessUser = userIsHbs();

    useEffect((): void => {
        if (!businessUser && groups.length > 0) {
            getPastMeasurements();
        }
    }, [groups]);

    if (businessUser) {
        navigate(`/${businessPaths.buildings}`);
    }

    if (error) {
        return <Error />;
    }

    const onSearchUpdate = (text: string): void => {
        const input = text.toLowerCase();
        setSearchText(input);
    };

    return (
        <div>
            <PageHeader title={txt('AllDevices')} />
            <div className="page-wrapper">
                <SubHeader onSearchUpdate={onSearchUpdate} actionButtons={[]} />
            </div>
            <DevicesList
                locationQuery={search.toString()}
                loading={loading}
                devicesLoading={loading}
                locations={locations}
                devices={devices}
                error={error}
                dateFormat={dateFormat}
                endedMeasurements={endedMeasurements}
                hubs={hubs}
                showLocationHeader
                searchText={searchText}
            />
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        devices: { devices, endedMeasurements, hubs },
        locations: { loading: locationsLoading, locations, error },
        userSettings: { dateFormat, groups, loading: loadingUser },
        app: { error: appError },
    } = state;

    return {
        endedMeasurements,
        devices,
        loading: locationsLoading || loadingUser,
        hubs,
        locations,
        error: error || appError,
        dateFormat,
        groups,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    getPastMeasurements: (): FetchLocationsHistory => dispatch(fetchLocationsHistory()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
