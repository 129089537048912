import React, { useState } from 'react';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { analyticsLogger, PageType, ReportType } from 'commons/src/analytics';
import { INSIGHT_CREATED_REPORT } from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import Error from 'commons/src/components/errorComponents/Error';
import PageHeader from 'commons/src/components/headers/PageHeader';
import SubHeader from 'commons/src/components/headers/SubHeader';
import { IntercomAPI } from 'commons/src/components/Intercom';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import {
    FetchVirusRiskInsightData,
    fetchVirusRiskInsightData,
    FetchVirusRiskInsightDataType,
} from '../../../actions/insightActions';
import { paths } from '../../../constants';
import { BuildingProps } from '../../../models/commonEnums';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import InsightMultipleDevices, { ReportData } from '../reportSelector/InsightMultipleDevices';
import VirusInfoModal from './VirusInfoModal';
import VirusRiskResult from './VirusRiskResultView';

interface ActionProps {
    getVirusRiskData: (payload: FetchVirusRiskInsightDataType) => void;
}

type StateProps = {
    locationsLoading: boolean;
    locationsError: boolean;
    fetchReportDataLoading: boolean;
    error?: ErrorType;
};

type Props = StateProps & ActionProps;

export const VirusRiskPageComponent = ({
    error,
    locationsError,
    fetchReportDataLoading,
    getVirusRiskData,
    locationsLoading,
}: Props): React.ReactElement => {
    const navigate = useNavigate();

    const { t: txt } = useTranslation();

    const [reportStartTime, setReportStartTime] = useState<Moment | null>(null);
    const [reportEndTime, setReportEndTime] = useState<Moment | null>(null);
    const [displayResultSection, setDisplayResultSection] = useState(false);
    const [modalOpen, setModalOpen] = useState({ open: false, selectedTab: 0 });
    const optionalBuildingProps = [BuildingProps.floors, BuildingProps.buildingYear];

    const closeModal = (): void => setModalOpen({ open: false, selectedTab: modalOpen.selectedTab });

    if (locationsError) {
        return <Error />;
    }

    const fetchReportData = (reportData: ReportData): void => {
        const { serialNumbers, from, to, locationId, includeLogo } = reportData;
        analyticsLogger(INSIGHT_CREATED_REPORT, {
            pageType: PageType.Insight,
            reportType: ReportType.VirusRisk,
            reportData,
        });
        IntercomAPI('trackEvent', 'generated-virus-insight-report', {
            serialNumbers,
            from,
            to,
            logoInReport: includeLogo,
        });
        getVirusRiskData({ from, to, serialNumbers, locationId, includeLogo });
    };

    const goBack = (): void => {
        navigate({ pathname: `/${paths.reports}` });
    };
    return (
        <div className="radon-insight-view">
            <PageHeader title={txt('VirusInsight.VirusRiskInsight')} />
            <div className="page-wrapper-flex">
                <SubHeader onGoBack={goBack} backTitle="Reports.Reports" actionButtons={[]} />
            </div>
            {modalOpen.open && <VirusInfoModal onClose={closeModal} selectedTab={modalOpen.selectedTab} />}
            <div className="page-wrapper-flex page-wrapper__medium--white">
                <ReactPlaceholder ready={!locationsLoading} customPlaceholder={mediumFormLoader}>
                    <div className="form form__wide-container">
                        <div className="header-2 flex flex--spaced">
                            <span className="text-large">{txt('VirusInsight.VirusRiskDescription')}</span>
                            <PrimaryButton
                                color="secondary"
                                onClick={(): void => setModalOpen({ open: true, selectedTab: 0 })}
                                title="Insight.WhatIsThis"
                            />
                        </div>
                        <InsightMultipleDevices
                            setDisplayResultSection={setDisplayResultSection}
                            fetchReportDataLoading={fetchReportDataLoading}
                            fetchReportData={fetchReportData}
                            reportStartTime={reportStartTime}
                            reportEndTime={reportEndTime}
                            setReportStartTime={setReportStartTime}
                            setReportEndTime={setReportEndTime}
                            acceptedDeviceTypes={[
                                DeviceTypeNames.wavePlus,
                                DeviceTypeNames.waveCo2,
                                DeviceTypeNames.viewPlusBusiness,
                                DeviceTypeNames.viewPlus,
                                DeviceTypeNames.viewCo2,
                                DeviceTypeNames.spaceCo2Mini,
                            ]}
                            minNumberOfDays={0}
                            maxNumberOfDays={31}
                            allowLogo
                            optionalBuildingProps={optionalBuildingProps}
                        />
                        {error && error.error && (
                            <div className="form form__wide-container">
                                <ResponseBox text="SomethingWentWrong" subtext={txt(`ErrorCodes.${error.error}`)} />
                            </div>
                        )}
                    </div>
                </ReactPlaceholder>
            </div>
            {displayResultSection && !error && reportStartTime && reportEndTime && (
                <VirusRiskResult
                    from={reportStartTime}
                    to={reportEndTime}
                    setInfoModalOpen={setModalOpen}
                    optionalBuildingProps={optionalBuildingProps}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        locations: { locations, loading, error: locationsError },
        requests: {
            [RequestType.FetchVirusRiskInsightData]: { loading: fetchReportDataLoading, error },
        },
    } = state;
    return {
        error,
        locationsLoading: loading && locations.length === 0,
        locationsError,
        fetchReportDataLoading,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    getVirusRiskData: (payload: FetchVirusRiskInsightDataType): FetchVirusRiskInsightData =>
        dispatch(fetchVirusRiskInsightData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VirusRiskPageComponent);
