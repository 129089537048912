import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DropdownList.module.scss';

type Props = {
    title: string;
    onSelect: (id: string) => void;
    options: { id: string; text: string }[];
    id: string;
};

const DropdownList = ({ options, onSelect, title, id }: Props): ReactElement => {
    const { t: txt } = useTranslation();

    return (
        <div className={styles.listWrapper}>
            <label htmlFor={id} className={styles.header}>
                {txt(title)}
            </label>
            <div id={id} role="list" className={styles.list}>
                {options.map(optionElement => (
                    <div
                        role="button"
                        id={optionElement.id}
                        tabIndex={0}
                        onKeyUp={(e): void => {
                            if (e.key === 'Enter') onSelect(optionElement.id);
                        }}
                        onClick={(): void => onSelect(optionElement.id)}
                        key={`dropdown-option-element-${optionElement.id}`}
                        className={styles.listElement}
                    >
                        {txt(optionElement.text)}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DropdownList;
