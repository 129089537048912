import React, { FocusEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import RadioButtons from 'commons/src/components/input/Radio';
import SimpleTreeView from 'commons/src/components/lists/TreeView/SimpleTreeView';
import { devicesWithCo2 } from 'commons/src/constants';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import { BuildingType, DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';
import { PresenceInsightFilterType } from '../../../models/common';
import { Store } from '../../../reducers';
import styles from './PresenceInsightFilter.module.scss';

type StateProps = {
    filters: {
        [locationId: string]: {
            [filterType: string]: { name: string; devices: string[] }[];
        };
    };
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
};

type ParentProps = {
    locationId?: string;
    setSelectedFilters: (filterDetails: { filterType: PresenceInsightFilterType; selectedOptions: string[] }) => void;
    selectedFilters: {
        filterType: PresenceInsightFilterType;
        selectedOptions: string[];
    };
    onClose: () => void;
    onBlur: (e: FocusEvent<HTMLElement>) => void;
    building: BuildingType;
};

type Props = ParentProps & StateProps;

export const notSetOptionName = 'NotSet';

const PresenceInsightFilterModal = ({
    locationId,
    filters,
    devicesWithKeyInfo,
    setSelectedFilters,
    selectedFilters,
    onClose,
    onBlur,
    building,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [selectedFilter, setSelectedFilter] = useState(selectedFilters.filterType || '');
    const [filterOptions, setFilterOptions] = useState<{ id: string; name: string }[]>([]);
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<string[]>(selectedFilters.selectedOptions || []);
    const [filterOptionsWithCo2, setFilterOptionsWithCo2] = useState<{
        [filterType: string]: { name: string; devices: string[] }[];
    }>({});

    const updateFilterOptions = (): void => {
        const devicesInBuilding: string[] = building?.devices || [];
        const devicesWithCo2InBuilding = devicesInBuilding.filter(device => {
            const deviceInfo = devicesWithKeyInfo[device];
            return deviceInfo && devicesWithCo2.includes(deviceInfo.type as DeviceTypeNames);
        });

        const filterOptionsForBuilding = (locationId && filters[locationId]) || {};
        const newCo2FilterOptions: { [filterType: string]: { name: string; devices: string[] }[] } = {};

        Object.keys(filterOptionsForBuilding).forEach(filter => {
            const optionsForFilter = filterOptionsForBuilding[filter];
            const devicesInUse = optionsForFilter.flatMap(option => option.devices);
            const devicesNotInFilter: string[] = devicesWithCo2InBuilding.filter(
                device => !devicesInUse.includes(device)
            );
            const optionForPropertyNotSet = { name: notSetOptionName, devices: devicesNotInFilter };
            const availableOptionsForFilter = optionsForFilter.map(option => {
                const updatedDeviceList = option.devices.filter(device => devicesWithCo2InBuilding.includes(device));
                return { ...option, devices: updatedDeviceList };
            });
            availableOptionsForFilter.push(optionForPropertyNotSet);
            newCo2FilterOptions[filter] = availableOptionsForFilter;
        });

        setFilterOptionsWithCo2(newCo2FilterOptions);
    };

    const updateFilters = (filter: string): void => {
        let newFilterOptions: { id: string; name: string }[] = [];

        if (filter === PresenceInsightFilterType.floors) {
            newFilterOptions = (filterOptionsWithCo2?.floors || []).map(option => {
                const optionName =
                    option.name === notSetOptionName
                        ? txt('FloorTypeOptions.NoFloorSet')
                        : `${txt('Floor')} ${option.name}`;
                const deviceCount = option.devices.length;
                return {
                    id: option.name,
                    name: `${optionName} (${deviceCount} ${txt('Devices')})`,
                };
            });
        } else if (filter === PresenceInsightFilterType.roomTypes) {
            newFilterOptions = (filterOptionsWithCo2?.roomTypes || []).map(option => {
                const optionName =
                    option.name === notSetOptionName ? 'RoomTypeOptions.NoRoomType' : `RoomTypeOptions.${option.name}`;
                const deviceCount = option.devices.length;
                return {
                    id: option.name,
                    name: `${txt(optionName)} (${deviceCount} ${txt('Devices')})`,
                };
            });
        }

        const sortedFilterOptions = newFilterOptions.sort((option1, option2) => {
            if (option1.id === notSetOptionName) return -1;
            if (option2.id === notSetOptionName) return 1;
            return option1.name.localeCompare(option2.name);
        });

        setFilterOptions(sortedFilterOptions);
    };

    const setFilterType = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { value } = e.currentTarget;
        updateFilters(value);
        setSelectedFilter(value as PresenceInsightFilterType);
        setSelectedFilterOptions([]);
    };

    useEffect(() => {
        updateFilterOptions();
    }, [filters]);

    useEffect(() => {
        updateFilters(selectedFilters.filterType);
    }, [filterOptionsWithCo2]);

    const applySelection = (): void => {
        setSelectedFilters({ selectedOptions: selectedFilterOptions, filterType: selectedFilter });
        onClose();
    };

    const selectOption = (selected: { id: string; changeAll: boolean }): void => {
        let newFilterOptions: string[];
        if (selected.changeAll) {
            const allIsSelected = selectedFilterOptions.length === filterOptions.length;
            if (allIsSelected) {
                newFilterOptions = [];
            } else {
                newFilterOptions = filterOptions.map(opt => opt.id);
            }
        } else {
            const selectedOptionIndex = selectedFilterOptions.findIndex(option => option === selected.id);
            if (selectedOptionIndex < 0) {
                newFilterOptions = [...selectedFilterOptions, selected.id];
            } else {
                newFilterOptions = selectedFilterOptions.filter(option => option !== selected.id);
            }
        }
        setSelectedFilterOptions(newFilterOptions);
    };

    const presenceFilterOptions = [
        {
            value: PresenceInsightFilterType.floors,
            label: 'Building.Floors',
            customStyle: 'modal__content__radio',
        },
        {
            value: PresenceInsightFilterType.roomTypes,
            label: 'RoomType',
            customStyle: 'modal__content__radio',
        },
    ];

    return (
        <div className={styles.filterModal} onBlur={onBlur}>
            <div>{txt('FloorPlan.Filter')}</div>
            <div>
                <RadioButtons
                    buttons={presenceFilterOptions}
                    selectorName="filterSelector"
                    row
                    onChange={setFilterType}
                    value={selectedFilter}
                    labelId="filterSelector"
                />
            </div>
            <SimpleTreeView
                listId="testing"
                options={filterOptions}
                selectedOptions={selectedFilterOptions}
                onChange={selectOption}
            />
            <div className={styles.buttons}>
                <PrimaryButton color="secondary" onClick={onClose} title="Cancel" />
                <PrimaryButton onClick={applySelection} title="PresenceInsight.Apply" color="primary" />
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        segmentPropertiesStore: { filters },
        devices: { devicesWithKeyInfo },
    } = state;
    return {
        filters,
        devicesWithKeyInfo,
    };
};
export default connect(mapStateToProps)(PresenceInsightFilterModal);
