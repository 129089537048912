import React, { useEffect, useState } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { BUILDING_VIEWED_CONTROL, GROUP_CLICKED_ADD } from 'commons/src/analytics/AnalyticsEvents';
import { isVirtualDevice } from 'commons/src/commonFunctions';
import SubHeader from 'commons/src/components/headers/SubHeader';
import { fullWidthListPlaceholder } from 'commons/src/components/placeholder';
import { dateFormats, roleRestrictions } from 'commons/src/constants';
import DevicesList from 'commons/src/features/devices/LocationSection/DevicesList';
import { AnyDeviceType, BuildingType, DeviceType, EndedSegment, ErrorType } from 'commons/src/models/commonTypeScript';
import { ActionButton } from 'commons/src/models/menuModels';
import { paths } from '../../../constants';
import { Store } from '../../../reducers';
import { BusinessRequestType } from '../../../reducers/BusinessRequestType';

type StateProps = {
    dateFormat: keyof typeof dateFormats;
    allEndedMeasurements: EndedSegment[];
    buildings: { [locationId: string]: BuildingType };
    error?: ErrorType;
    devices: { [serialNumber: string]: DeviceType };
    fetchBuildingWithDevicesLoading: boolean;
};

export type Props = StateProps;

export const BuildingVirtualDevicesComponent = ({
    buildings,
    error,
    devices,
    dateFormat,
    allEndedMeasurements,
    fetchBuildingWithDevicesLoading,
}: Props): React.ReactElement => {
    const { buildingId } = useParams<'buildingId'>() as { buildingId: string };
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const [buildingDevicesFetched, setBuildingDevicesFetched] = useState(false);
    const building = buildings[buildingId];
    const endedMeasurements = allEndedMeasurements.filter(measurement => measurement.locationId === buildingId);

    const virtualEndedSegment = endedMeasurements.filter(device => isVirtualDevice(device.deviceType as AnyDeviceType));

    useEffect(() => {
        if (building) {
            analyticsLogger(BUILDING_VIEWED_CONTROL, { pageType: PageType.Building, buildingName: building.name });
        }
    }, [building]);

    useEffect(() => {
        if (!buildingDevicesFetched) {
            const devicesInBuilding =
                (building && building.devices.map(serialNumber => devices[serialNumber]).filter(device => !!device)) ||
                [];
            const buildingDevicesInState =
                building &&
                (building.devices.length === 0 || (building.devices.length > 0 && devicesInBuilding.length > 0));
            setBuildingDevicesFetched(!!buildingDevicesInState);
        }
    }, [building, devices]);

    const onSearchUpdate = (text: string): void => {
        const textToLowerCase = text.trim().toLowerCase();
        setSearchText(textToLowerCase);
    };

    const addDeviceGroup = (): void => {
        analyticsLogger(GROUP_CLICKED_ADD, { pageType: PageType.Building });
        navigate({ pathname: `/${paths.buildings}/${building && building.id}/add-group` });
    };

    const actionButtons: ActionButton[] = [
        {
            id: 'configButton',
            title: 'ZoneGrouping.AddGroup',
            onClick: addDeviceGroup,
            color: 'primary',
            requiredRoleLevel: roleRestrictions.editDeviceOrBuilding,
            testAttr: 'add-control-device',
            requiredGroupTypes: [],
        },
    ];

    return (
        <div data-building-devices-page>
            <ReactPlaceholder ready={!!building && buildingDevicesFetched} customPlaceholder={fullWidthListPlaceholder}>
                <div className="page-wrapper">
                    <SubHeader onSearchUpdate={onSearchUpdate} actionButtons={actionButtons} />
                </div>
                <DevicesList
                    loading={!building}
                    devicesLoading={fetchBuildingWithDevicesLoading}
                    locations={building ? [building] : []}
                    devices={devices}
                    error={!!error}
                    dateFormat={dateFormat}
                    endedMeasurements={virtualEndedSegment}
                    hubs={[]}
                    showLocationHeader={false}
                    onlyVirtualDevices
                    searchText={searchText}
                />
            </ReactPlaceholder>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        devices: { devices, endedMeasurements },
        userSettings: { dateFormat },
        buildings: { buildings },
        requests: {
            [BusinessRequestType.FetchBuildingWithDevices]: {
                error: fetchBuildingWithDevicesError,
                loading: fetchBuildingWithDevicesLoading,
            },
        },
    } = state;

    return {
        dateFormat,
        devices,
        fetchBuildingWithDevicesLoading,
        buildings,
        error: fetchBuildingWithDevicesError,
        allEndedMeasurements: endedMeasurements,
    };
};

export default connect(mapStateToProps)(BuildingVirtualDevicesComponent);
