import React from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';

type Props = {
    errorText: string | undefined;
    onClose: () => void;
};

export const AddThirdPartyIntegrationErrorViewComponent = ({ errorText, onClose }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className="page-wrapper__medium page-wrapper__medium--white">
            <div className="form form__wide-container">
                <h2 className="form__header--padding-bottom">{txt('ThirdParty.AuthorizationError')}</h2>
                <div className="centered third-party__error-view">
                    <MaterialIcon extraClass="third-party__error-view__error-circle" name="report" />
                </div>
                <p className="centered">{errorText}</p>
                <div className="form__row form__button-container third-party__button-margin--large">
                    <PrimaryButton
                        type="button"
                        title="Close"
                        color="primary"
                        onClick={onClose}
                        loading={false}
                        testAttr="close-view"
                        testId="close-third-party-error-view"
                    />
                </div>
            </div>
        </div>
    );
};

export default AddThirdPartyIntegrationErrorViewComponent;
