import React, { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import MultipleAttrDropdown from 'commons/src/components/dropdown/MultipleAttrDropdown';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { mobileMax } from 'commons/src/constants';
import { SpaceFloorPlan } from '../../models/spaceFloorPlanModels';
import styles from './FloorPlanNavigation.module.scss';

type Props = {
    selectedFloorPlanId: string | undefined;
    setSelectedFloorPlanId: (floorId: string | undefined) => void;
    floorPlans: SpaceFloorPlan[];
    setAddingNewFloor: () => void;
    addingNewFloor: boolean;
    fullScreenMode: boolean;
};

const FloorPlanNavigation = ({
    setSelectedFloorPlanId,
    selectedFloorPlanId,
    floorPlans,
    setAddingNewFloor,
    addingNewFloor,
    fullScreenMode,
}: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const [mobileView, setMobileView] = useState(window.innerWidth <= mobileMax);

    const sortedFloorPlans = [...floorPlans].sort((a, b) => {
        if (a.floor === undefined) return 1;
        if (b.floor === undefined) return -1;
        const sortOrder: number = a.floor - b.floor;
        if (sortOrder === 0) {
            return a.name.localeCompare(b.name);
        }
        return sortOrder;
    });

    useEffect(() => {
        const selectedFloorIndex = sortedFloorPlans.findIndex(floorPlan => floorPlan.id === selectedFloorPlanId);
        if (
            sortedFloorPlans.length > 0 &&
            !addingNewFloor &&
            (selectedFloorPlanId === undefined || selectedFloorIndex === -1)
        ) {
            const floorId = sortedFloorPlans[0].id;
            setSelectedFloorPlanId(floorId);
        }
    }, [sortedFloorPlans]);

    const onWindowSizeChange = (): void => {
        const width = window.innerWidth;
        setMobileView(width <= mobileMax);
    };

    useEffect((): (() => void) => {
        window.addEventListener('resize', onWindowSizeChange);
        return (): void => window.removeEventListener('resize', onWindowSizeChange);
    }, []);

    const selectFloorPlanFromDropdown = (selected: { id: string; inputValue: string }): void => {
        if (selected.id === 'add-floor') {
            setAddingNewFloor();
        } else {
            setSelectedFloorPlanId(selected.id);
        }
    };

    if (mobileView) {
        const floorPlanOptions = sortedFloorPlans.map(floorPlan => ({
            id: floorPlan.id,
            inputValue: `${txt('SpaceFloorPlan.FloorNr', {
                floorNr: floorPlan.floor !== undefined ? floorPlan.floor : '-',
            })} - ${floorPlan.name}`,
        }));
        const addFloorOption = { id: 'add-floor', inputValue: `+ ${txt('FloorPlan.AddFloorplan')}` };
        const addFloorOptionExists = floorPlanOptions.some(option => option.id === addFloorOption.id);
        if (!addFloorOptionExists) {
            floorPlanOptions.push(addFloorOption);
        }

        return (
            <MultipleAttrDropdown
                title="FloorPlan.Floorplans"
                id="floorPlanSelector"
                testAttr="floor-plan-selector"
                defaultOption={addFloorOption.inputValue}
                options={floorPlanOptions}
                onSelect={selectFloorPlanFromDropdown}
                value={
                    addingNewFloor || !selectedFloorPlanId
                        ? addFloorOption.inputValue
                        : floorPlanOptions.find(option => option.id === selectedFloorPlanId)?.inputValue
                }
                slim
                testId="floor-plan-selector"
                optionsAlreadySorted
            />
        );
    }

    return (
        <div className={classNames(styles.wrapper, { [styles.fullscreen]: fullScreenMode })}>
            {sortedFloorPlans.map(floorPlan => (
                <div
                    key={floorPlan.id}
                    className={`${styles.navElement} ${
                        selectedFloorPlanId === floorPlan.id ? styles.selectedFloorPlan : ''
                    }`}
                    onClick={(): void => setSelectedFloorPlanId(floorPlan.id)}
                    role="button"
                    tabIndex={0}
                    onKeyUp={(e): void => {
                        if (e.key === 'Enter') setSelectedFloorPlanId(floorPlan.id);
                    }}
                >
                    <div>
                        <div className={styles.floorNr}>
                            {txt('SpaceFloorPlan.FloorNr', {
                                floorNr: floorPlan.floor !== undefined ? floorPlan.floor : '-',
                            })}
                        </div>
                        <div className={styles.floorName}>{floorPlan.name}</div>
                    </div>
                    <img src={floorPlan.image} alt={floorPlan.name} className={styles.image} />
                </div>
            ))}
            {addingNewFloor ? (
                <div className={`${styles.navElement} ${styles.selectedFloorPlan}`}>
                    <div className={styles.info}>
                        <div className={styles.floorNr}>{txt('SpaceFloorPlan.FloorNr', { floorNr: '-' })}</div>
                        <div className={styles.floorName}>{txt('SpaceFloorPlan.NamePlaceholder')}</div>
                    </div>
                    <div className={styles.imagePlaceHolder} />
                </div>
            ) : (
                <div
                    className={styles.addFloorPlanButton}
                    onClick={setAddingNewFloor}
                    role="button"
                    data-testid="data-add-new-floor-button"
                    tabIndex={0}
                    onKeyUp={(e): void => {
                        if (e.key === 'Enter') setAddingNewFloor();
                    }}
                >
                    <MaterialIcon name="add" />
                    {txt('FloorPlan.AddFloorplan')}
                </div>
            )}
        </div>
    );
};
export default FloorPlanNavigation;
