import fetch, { urlWithQueryParams } from 'commons/src/api/fetch';
import getFetchHeaders from 'commons/src/api/getFetchHeaders';
import { Resolution } from 'commons/src/models/commonEnums';
import { AggregationDigest } from '../models/eventAggregationModels';

export const fetchSpaceAggregation = async (
    spaceId: string,
    params: { from: string; to: string; resolution?: Resolution }
): Promise<AggregationDigest> => {
    const url = urlWithQueryParams({
        path: `/aggregates/spaces/${spaceId}`,
        ...params,
    });

    return fetch(url.toString(), {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
};

export default fetchSpaceAggregation;
