import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import PageHeader from 'commons/src/components/headers/PageHeader';
import SubHeader from 'commons/src/components/headers/SubHeader';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import { RequiredRoleLevel } from 'commons/src/models/commonEnums';
import { ActionButton } from 'commons/src/models/menuModels';
import { fetchMqttClients, FetchMqttClients } from '../../../actions/mqttIntegrationActions';
import { paths } from '../../../constants';
import { MqttClient } from '../../../models/common';
import { Store } from '../../../reducers';
import { BusinessRequestType } from '../../../reducers/BusinessRequestType';
import MqttCreateForm from './MqttCreateForm';
import MqttEditForm from './MqttEditForm';

type StateProps = {
    clients: MqttClient[];
    loadingMqttClients: boolean;
    loadingLocations: boolean;
    loadingUserSettings: boolean;
};

type ActionProps = {
    getMqttClients: () => void;
};

type Props = StateProps & ActionProps;

export const AddEditMqttClientView = ({
    clients,
    getMqttClients,
    loadingMqttClients,
    loadingLocations,
    loadingUserSettings,
}: Props): React.ReactElement => {
    const { clientId } = useParams<'clientId'>() as { clientId: string };
    const navigate = useNavigate();
    const { t: txt } = useTranslation();
    const [editingClient, setEditingClient] = useState(!!clientId);

    useEffect(() => {
        setEditingClient(!!clientId);
    }, [clientId]);

    useEffect(() => {
        document.body.className = 'blue-body';
        return (): void => {
            document.body.className = '';
        };
    }, []);

    useEffect(() => {
        if (clients.length === 0 && !loadingMqttClients && !loadingUserSettings) {
            getMqttClients();
        }
    }, [loadingUserSettings]);

    useEffect(() => {
        if (clientId && !loadingMqttClients && !loadingLocations) {
            const clientExists = clients.find(client => client.id === clientId);
            if (!clientExists) navigate(`/${paths.mqtt}`);
        }
    }, [clientId, loadingMqttClients, loadingLocations]);

    const close = (): void => {
        navigate(`/${paths.mqtt}`);
    };

    const actionButton: ActionButton[] = [
        {
            onClick: close,
            testAttr: 'close-add-edit-mqtt-client',
            title: 'Close',
            id: 'closeAddEditMqttClient',
            color: 'secondary',
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            requiredGroupTypes: [],
        },
    ];
    return (
        <>
            <PageHeader title={editingClient ? txt('Mqtt.EditClient') : txt('Mqtt.NewClient')} />
            <div className="page-wrapper__medium">
                <SubHeader actionButtons={actionButton} />
            </div>
            <ReactPlaceholder ready={!(loadingLocations || loadingMqttClients)} customPlaceholder={mediumFormLoader}>
                {editingClient ? <MqttEditForm /> : <MqttCreateForm />}
            </ReactPlaceholder>
        </>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { loading: loadingUserSettings },
        mqtt: { clients },
        requests: {
            [BusinessRequestType.FetchMqttClients]: { loading: loadingMqttClients },
        },
        locations: { loading: loadingLocations },
    } = state;

    return {
        clients,
        loadingLocations,
        loadingMqttClients,
        loadingUserSettings,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    getMqttClients: (): FetchMqttClients => dispatch(fetchMqttClients()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditMqttClientView);
