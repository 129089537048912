import { t as txt } from 'i18next';
import { DurationInputArg1, DurationInputArg2 } from 'moment';
import moment from 'moment-timezone';
import { colors } from 'commons/src/constants';
import areaRangeData from 'commons/src/features/devicePage/graphDataFunctions';
import { Rating, SensorTypes } from 'commons/src/models/commonEnums';
import {
    ExtraSeries,
    FullDeviceData,
    SelectedPeriod,
    SensorData,
    ThresholdRange,
} from 'commons/src/models/commonTypeScript';
import { addNullPointsToChartData } from '../../../reports/insightFunctions';
import { rangeSeriesName, SpaceGraphData } from './spaceGraphConfig';

export const setThresholds = (
    ranges: ThresholdRange[],
    compareSensor?: boolean
): { value?: number; color: string }[] => {
    if (ranges.length <= 1) {
        return [{ color: compareSensor ? colors.greyShuttle : colors.greyRiverBed }];
    }
    return ranges.map(threshold => {
        let color = compareSensor ? colors.redAuburn : colors.graphRed;
        if (threshold.rating === Rating.GOOD) {
            color = compareSensor ? colors.greenForest : colors.graphGreen;
        } else if (threshold.rating === Rating.FAIR) {
            color = compareSensor ? colors.yellowHotButter : colors.graphYellow;
        }
        return { value: threshold.to, color };
    });
};

const getSpaceGraphData = (
    sensorData: { [segmentId: string]: SensorData },
    extraSeries: { [segmentId: string]: ExtraSeries },
    sensor: SensorTypes,
    selectedInterval: SelectedPeriod,
    deviceData: { [segmentId: string]: FullDeviceData },
    compareSensor: boolean,
    ranges?: ThresholdRange[]
): SpaceGraphData[] => {
    const rangeSeries: SpaceGraphData[] = [];
    return (
        Object.keys(sensorData).map((segmentId): SpaceGraphData => {
            const sensorExtraSeries = extraSeries[segmentId]?.[sensor]?.[selectedInterval.name] || {};
            const rangeDataForSensor = areaRangeData(sensorExtraSeries, sensor) || [];
            const colorZones = ranges ? setThresholds(ranges, compareSensor) : [];
            if (rangeDataForSensor && rangeDataForSensor.length > 0) {
                rangeSeries.push({
                    data: rangeDataForSensor as [number, number | null][],
                    id: `range-data-${sensor}-${segmentId}`,
                    name: rangeSeriesName,
                    type: 'arearange',
                    lineWidth: 0,
                    showInLegend: false,
                    zones: colorZones,
                    opacity: 0.3,
                });
            }
            const graphStartPoint =
                selectedInterval.startDate ||
                moment().subtract(
                    selectedInterval.number as DurationInputArg1,
                    selectedInterval.period as DurationInputArg2
                );
            const graphEndPoint = selectedInterval.endDate || moment();

            return {
                data: addNullPointsToChartData(
                    sensorData[segmentId]?.[sensor]?.[selectedInterval.name] || [],
                    graphStartPoint || null,
                    graphEndPoint || null
                ),
                name: deviceData[segmentId]?.segmentName ?? txt(sensor),
                id: `${sensor}-${segmentId}${compareSensor ? '-compare' : '-main'}`,
                zones: colorZones,
                dashStyle: compareSensor ? 'ShortDash' : undefined,
                yAxis: compareSensor ? 1 : 0,
            };
        }) || []
    )
        .concat(rangeSeries)
        .filter(x => x.data?.length > 0);
};

export default getSpaceGraphData;
