import { generatePath } from 'react-router-dom';
import fetch from 'commons/src/api/fetch';
import getFetchHeaders from 'commons/src/api/getFetchHeaders';
import { FloorPlanPosition } from 'commons/src/models/commonTypeScript';
import { FloorPlanData, SpaceFloorPlan } from '../models/spaceFloorPlanModels';

export const uploadFloorPlan = async (
    payload: { name: string; image: string; floor: number },
    locationId: string
): Promise<{ id: string }> => {
    const url = generatePath('/locations/:locationId/v2/floor-plans', { locationId });
    return fetch<{ id: string }>(url, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(payload),
    });
};

export const getFloorPlans = async (locationId: string): Promise<{ floorPlans: SpaceFloorPlan[] }> => {
    const url = generatePath('/locations/:locationId/v2/floor-plans', { locationId });
    return fetch(url, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
};

export const getFloorPlanDetails = async (locationId: string, floorPlanId: string): Promise<FloorPlanData> => {
    const url = generatePath('/locations/:locationId/v2/floor-plans/:floorPlanId', { locationId, floorPlanId });
    return fetch(url, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
};

export const editFloorPlan = async (
    payload: SpaceFloorPlan,
    floorPlanId: string,
    locationId: string
): Promise<void> => {
    const url = generatePath('/locations/:locationId/v2/floor-plans/:floorPlanId', { locationId, floorPlanId });
    return fetch(url, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify(payload),
    });
};

export const deleteFloorPlan = async (floorPlanId: string, locationId: string): Promise<void> => {
    const url = generatePath('/locations/:locationId/v2/floor-plans/:floorPlanId', { locationId, floorPlanId });
    return fetch(url, {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    });
};

export const createSpaceFloorPlanZone = async (
    payload: { boundary: FloorPlanPosition[] },
    spaceId: string,
    floorPlanId: string,
    locationId: string
): Promise<void> => {
    const url = generatePath('/locations/:locationId/v2/floor-plans/:floorPlanId/spaces/:spaceId', {
        locationId,
        floorPlanId,
        spaceId,
    });
    return fetch<void>(url, {
        method: 'POST',
        headers: await getFetchHeaders(),
        body: JSON.stringify(payload),
    });
};

export const editSpaceFloorPlanZone = async (
    payload: { boundary: FloorPlanPosition[] },
    spaceId: string,
    floorPlanId: string,
    locationId: string
): Promise<void> => {
    const url = generatePath('/locations/:locationId/v2/floor-plans/:floorPlanId/spaces/:spaceId', {
        locationId,
        floorPlanId,
        spaceId,
    });
    return fetch<void>(url, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify(payload),
    });
};

export const deleteSpaceFloorPlanZone = async (
    spaceId: string,
    floorPlanId: string,
    locationId: string
): Promise<void> => {
    const url = generatePath('/locations/:locationId/v2/floor-plans/:floorPlanId/spaces/:spaceId', {
        locationId,
        floorPlanId,
        spaceId,
    });
    return fetch<void>(url, {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    });
};
