import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ReactPdfDownloadModal from 'commons/src/components/PDF/ReactPdfDownloadModal';
import { CSVFiles } from '../../../../models/common';
import styles from '../BuildingStatus.module.scss';

type Props = {
    csvFiles: CSVFiles[];
    modalDescription: string;
};

const DownloadCsvButton = ({ csvFiles, modalDescription }: Props): React.ReactElement => {
    const [downloadCsvModalOpen, setDownloadCsvModalOpen] = useState(false);

    if (csvFiles.length === 0) {
        return <div />;
    }

    return (
        <div className={styles.buttonWrapper}>
            <PrimaryButton
                id="download-csv"
                type="button"
                title="DownloadCSVTitle"
                loading={false}
                disabled={false}
                color="primary"
                icon={<MaterialIcon name="download" />}
                onClick={(): void => setDownloadCsvModalOpen(true)}
                testAttr="csv-download-button"
            />
            {downloadCsvModalOpen && (
                <ReactPdfDownloadModal
                    title="DownloadCSVTitle"
                    description={modalDescription}
                    onClose={(): void => setDownloadCsvModalOpen(false)}
                >
                    <div>
                        {csvFiles.map(data => (
                            <div
                                key={`csvDownloadElement-${data.fileName}`}
                                className="flex flex--center small-padding-bottom"
                            >
                                <MaterialIcon name="download" />
                                <CSVLink data={data.content} separator=";" filename={`${data.fileName}.csv`}>
                                    {data.fileName}
                                </CSVLink>
                            </div>
                        ))}
                    </div>
                </ReactPdfDownloadModal>
            )}
        </div>
    );
};

export default DownloadCsvButton;
