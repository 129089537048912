import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import Dropdown from 'commons/src/components/dropdown/MultipleAttrDropdown';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { dateFormats } from 'commons/src/constants';
import spinner from 'commons/src/img/spinner';
import { BuildingType, ErrorType, LocationType } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../reducers';
import BuildingSettingsModalComponent from '../../buildings/buildingEditDetails/BuildingSettingsModal';
import BuildingInfo from './BuildingInfo';
import OpeningHours from './OpeningHoursTable';

export type ParentProps = {
    setBuilding: (locationId: string) => void;
    building: BuildingType | undefined;
    validate: boolean;
    optionalBuildingProps: string[];
};

type StateProps = {
    locations: LocationType[];
    loading: boolean;
    dateFormat: keyof typeof dateFormats;
    error?: ErrorType;
};

export type Props = ParentProps & StateProps;

type OptionSelector = { id: string; inputValue: string };

export const BuildingInsightComponent = (props: Props): React.ReactElement => {
    const { setBuilding, locations, building, optionalBuildingProps, validate, dateFormat, error, loading } = props;

    const { t: txt } = useTranslation();
    const [editBuildingOpen, setEditBuildingOpen] = useState(false);

    const locationOptions = locations.map(location => ({ id: location.id, inputValue: location.name }));

    const selectBuilding = (selected: OptionSelector): void => setBuilding(selected.id);

    const errorMessage = error ? `ErrorCodes.${error.error}` : undefined;
    return (
        <>
            <div className="insight-tile__header insight-tile__header--padded insight-tile__header--no-border">
                <h2 id="building-header">{txt('RadonInsight.BuildingInfo')}</h2>
            </div>
            {editBuildingOpen && building && (
                <BuildingSettingsModalComponent onClose={(): void => setEditBuildingOpen(false)} building={building} />
            )}
            <div className="form__row">
                <div className="form__field--standard-width">
                    <Dropdown
                        id="buildingSelector"
                        title="Building.Building"
                        options={locationOptions}
                        loading={false}
                        validate={!building}
                        defaultOption="Select"
                        value={building ? building.name : null}
                        onSelect={selectBuilding}
                        testAttr="select-building"
                        hint={errorMessage}
                        isValid={!error}
                        testId="select-building"
                    />
                </div>
                {building && (
                    <div className="form__button-container form__button-container--no-padding form__button-container--margin-bottom">
                        <PrimaryButton
                            color="secondary"
                            onClick={(): void => setEditBuildingOpen(true)}
                            title="Edit"
                            id="editBuilding"
                            testAttr="edit-building"
                            icon={<MaterialIcon extraClass="icon-circle" name="edit" />}
                            testId="edit-building"
                        />
                    </div>
                )}
                {!building && loading && <div className="centered">{spinner}</div>}
            </div>
            {error && <div className="small-padding-bottom" />}
            <BuildingInfo building={building} validate={validate} optionalBuildingProps={optionalBuildingProps} />
            <OpeningHours
                optionalBuildingProps={optionalBuildingProps}
                building={building}
                dateFormat={dateFormat}
                validate={validate}
            />
        </>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        locations: { locations },
        userSettings: { dateFormat },
        requests: {
            FETCH_BUILDING_WITH_DEVICES: {
                loading: fetchBuildingWithDevicesLoading,
                error: fetchBuildingWithDevicesError,
            },
            FETCH_BUILDING: { error: fetchBuildingError },
        },
    } = state;
    return {
        locations,
        dateFormat,
        loading: fetchBuildingWithDevicesLoading,
        error: fetchBuildingError || fetchBuildingWithDevicesError,
    };
};

export default connect(mapStateToProps)(BuildingInsightComponent);
