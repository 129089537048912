import React, { ReactElement, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import { BUILDING_SORTED_SPACE_LIST } from 'commons/src/analytics/AnalyticsEvents';
import SubHeader from 'commons/src/components/headers/SubHeader';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { dateFormats, roleRestrictions } from 'commons/src/constants';
import { RequiredRoleLevel, SensorTypes } from 'commons/src/models/commonEnums';
import { ActionButton } from 'commons/src/models/menuModels';
import { Store } from 'commons/src/reducers';
import { IndoorSpace, IndoorSpaceWithProps } from '../../../models/spaceModels';
import { getNamedSpacePropertiesWithValue } from '../properties/SpacePropertyEditCommon';
import { sortSpaces } from '../space/spacesFunctions';
import SpacesCsvModal from './SpacesCsvModal';
import SpacesHeader from './SpacesHeader';
import SpacesRow from './SpacesRow';
import styles from './SpacesView.module.scss';

export type Props = {
    addSpace: () => void;
    spaces: IndoorSpace[];
    availableSensors: SensorTypes[];
    buildingId: string;
};

const SpacesView = ({ addSpace, spaces, availableSensors, buildingId }: Props): ReactElement => {
    const [searchText, setSearchText] = useState('');
    const { spacePropDefs: propertyDefinitions } = useSelector((store: Store) => store.config);
    const [selectedSensors, setSelectedSensors] = useState<SensorTypes[]>(availableSensors.slice(0, 5));
    const [csvModalOpen, setCsvModalOpen] = useState(false);
    const [sortBy, setSortBy] = useState<string>('floor');
    const [ascending, setAscending] = useState<boolean>(false);
    const dateFormat: keyof typeof dateFormats = useSelector((store: Store) => store.userSettings.dateFormat);

    const spacesWithCustomProps = useMemo(() => {
        return spaces.map(space => {
            const spaceVariables = getNamedSpacePropertiesWithValue(space.properties, propertyDefinitions);
            return { ...space, spaceVariables };
        });
    }, [spaces, propertyDefinitions]);

    const filteredSpaces: IndoorSpaceWithProps[] = useMemo(
        () => spacesWithCustomProps.filter(space => space.name.toLowerCase().includes(searchText.toLowerCase())),
        [spacesWithCustomProps, searchText]
    );

    const sortedSpaces = useMemo(
        () => sortSpaces(filteredSpaces, sortBy, ascending),
        [filteredSpaces, sortBy, ascending]
    );

    const actionButtons: ActionButton[] = [
        {
            id: 'addSpace',
            title: 'AddSpace.AddSpace',
            onClick: addSpace,
            color: 'secondary',
            requiredRoleLevel: roleRestrictions.editSpace,
            testAttr: 'add-space',
            requiredGroupTypes: [],
            icon: <MaterialIcon name="add" />,
        },
        {
            id: 'exportButton',
            title: 'CsvExport.Export',
            onClick: (): void => setCsvModalOpen(true),
            color: 'secondary',
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            testAttr: 'export',
            requiredGroupTypes: [],
            icon: <MaterialIcon name="download" />,
        },
    ];

    const onSortClick = (sortByColumn: string): void => {
        analyticsLogger(BUILDING_SORTED_SPACE_LIST, { sortBy: sortByColumn });
        if (sortBy === sortByColumn) {
            setAscending(!ascending);
        } else {
            setSortBy(sortByColumn);
            setAscending(false);
        }
    };

    return (
        <div>
            <SubHeader onSearchUpdate={setSearchText} actionButtons={actionButtons} small />
            {csvModalOpen && (
                <SpacesCsvModal
                    dateFormat={dateFormat}
                    locationId={buildingId}
                    devices={[...spaces.flatMap(it => it.devices), ...spaces.flatMap(it => it.endedSegments)]}
                    onClose={(): void => setCsvModalOpen(false)}
                />
            )}
            <div className={styles.sensorsHeader}>
                <SpacesHeader
                    availableSensors={availableSensors}
                    setSelectedSensors={setSelectedSensors}
                    selectedSensors={selectedSensors}
                    buildingId={buildingId}
                    sortBy={sortBy}
                    setSortBy={onSortClick}
                    ascending={ascending}
                />
            </div>
            <div className={styles.spacesWrapper}>
                {sortedSpaces.map(space => (
                    <SpacesRow key={`space-element-${space.id}`} space={space} selectedSensors={selectedSensors} />
                ))}
            </div>
        </div>
    );
};

export default SpacesView;
