import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import PrimaryButton from '../buttons/PrimaryButton';
import ModalHeader from './ModalHeader';

type Props = {
    title: string;
    description: string | React.ReactElement;
    onSubmit: () => void;
    onCancel: () => void;
    onSubmitText: string;
    onCancelText: string;
    loading: boolean;
    error?: boolean;
    errorText?: string;
    hideDelete?: boolean;
    regularSubmitButton?: boolean;
    centerBody?: boolean;
};

const DeleteConfirmModal = (props: Props): React.ReactElement => {
    const {
        title,
        description,
        onSubmitText,
        onCancelText,
        onSubmit,
        onCancel,
        loading,
        error,
        errorText,
        hideDelete,
        regularSubmitButton,
        centerBody,
    } = props;
    const { t: txt } = useTranslation();
    return (
        <Modal
            isOpen
            appElement={document.body}
            onRequestClose={onCancel}
            className="modal__content modal__content--size-medium"
            overlayClassName="modal modal__overlay"
        >
            <ModalHeader headerText={title} />
            <div className={classNames('modal__content__body', { 'modal__content__body--center': centerBody })}>
                {description}
            </div>
            <div className="modal__content__buttons">
                <PrimaryButton
                    color="secondary"
                    title={onCancelText}
                    loading={false}
                    disabled={loading}
                    onClick={onCancel}
                    autoFocus
                    testId="modal-cancel"
                />
                {!hideDelete && (
                    <PrimaryButton
                        title={onSubmitText}
                        loading={loading}
                        onClick={onSubmit}
                        disabled={loading}
                        color={regularSubmitButton ? 'primary' : 'alert'}
                        testId="modal-confirm"
                    />
                )}
            </div>
            {error && errorText && <p className="error-message error-message--center">{txt(errorText)}</p>}
        </Modal>
    );
};

DeleteConfirmModal.defaultProps = {
    error: false,
    errorText: undefined,
    hideDelete: false,
    regularSubmitButton: false,
};

export default DeleteConfirmModal;
