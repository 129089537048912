import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { SensorIcon } from 'commons/src/components/sensors/SensorUnit';
import { dateFormats, sensorUnits } from 'commons/src/constants';
import { SensorThresholds } from 'commons/src/models/commonTypeScript';
import { alertRuleThresholdCategories, paths, alertSystemNotificationRules } from '../../../constants';
import { NotificationAlertEvent, ThirdPartyIntegrationAlert } from '../../../models/common';
import { Store } from '../../../reducers';

export type ParentProps = {
    alertEvent: NotificationAlertEvent;
};

type StateProps = {
    dateFormat: keyof typeof dateFormats;
    notificationAlerts: ThirdPartyIntegrationAlert[];
    thresholds: { [sensor: string]: SensorThresholds };
};

type Props = StateProps & ParentProps;

export const AlertEventInfoComponent = ({
    alertEvent,
    dateFormat,
    notificationAlerts,
    thresholds,
}: Props): React.ReactElement => {
    const navigate = useNavigate();
    const { t: txt } = useTranslation();
    if (!alertEvent) return <div />;

    const editAlert = (): void => {
        const foundAlert = notificationAlerts.find(alert => alert.id === alertEvent.content.triggerId);
        if (foundAlert) navigate(`../alert/${foundAlert.id}`);
        else navigate({ pathname: `/${paths.history}` });
    };

    const renderAlertTypeIcon = (iconName: string): React.ReactElement => (
        <div className="notification-alert__icons">
            <div
                className={classNames('device-svg-icons notification-alert__icons__circle', {
                    'notification-alert__icons__circle--yellow':
                        iconName === alertSystemNotificationRules.deviceOffline,
                })}
            >
                <SensorIcon sensor={iconName} />
            </div>
        </div>
    );

    const hourFormat =
        alertEvent &&
        moment
            .utc(alertEvent.dateTime)
            .local()
            .format(dateFormats[dateFormat as keyof typeof dateFormats].hourFormat);
    const segmentName = [txt('Device'), ': ', alertEvent.content.segmentName].join('');
    const locationName = [txt('Location'), ': ', alertEvent.content.locationName].join('');

    let eventDescription;
    if (alertEvent.content.type === alertSystemNotificationRules.deviceOffline) {
        eventDescription = txt('NotificationAlerts.OfflineDeviceEventDescription', {
            serialNumber: alertEvent.content.serialNumber,
        });
    } else {
        const sensorUnit = thresholds[alertEvent.content.type].unit;
        eventDescription = txt('NotificationAlerts.SensorEventDescription', {
            sensor: txt(alertEvent.content.type),
            threshold: txt(
                alertEvent.content.thresholdType === alertRuleThresholdCategories.allAbove
                    ? txt('NotificationAlerts.Over')
                    : txt('NotificationAlerts.Under')
            ),
            sensorType: alertEvent.content.thresholdValue + sensorUnits[sensorUnit],
            duration: alertEvent.content.duration.toString(),
        });
    }

    return (
        <div className="page-wrapper__medium page-wrapper__medium--white">
            <div className="form form__wide-container notification-alert__history">
                <div className="form__row">
                    {renderAlertTypeIcon(alertEvent.icon)}
                    <div className="text-small text-paragraph">
                        <div>{[txt('Time'), ': ', hourFormat]}</div>
                        <div>{segmentName}</div>
                        <div>{locationName}</div>
                    </div>
                </div>
                <div className="inline-header-lined inline-header-lined--margin-top" />
                <p className="text-large text-paragraph form__row--padded-medium">
                    {eventDescription}
                    <Trans i18nKey="NotificationAlerts.HeadToDevice">
                        <Link className="third-party--link" to={`/${paths.devices}/${alertEvent.content.serialNumber}`}>
                            {alertEvent.content.serialNumber}
                        </Link>
                    </Trans>
                </p>
                <h3>{txt('NotificationAlerts.SelectedChannel')}</h3>
                <div className="text-paragraph">{txt(`ThirdParty.${alertEvent.type}`)}</div>
                <div className="inline-header-lined inline-header-lined--extra-margin" />
                <p className="text-paragraph form__row--padded-medium">
                    {txt('NotificationAlerts.EditAlertDescription')}
                </p>
                <PrimaryButton
                    id="editNotificationAlertsButton"
                    title="NotificationAlerts.EditAlert"
                    onClick={editAlert}
                    icon={<MaterialIcon name="edit" />}
                    color="primary"
                    testId="edit-alert"
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        thirdPartyIntegrations: { notificationAlerts },
        config: { thresholds },
        userSettings: { dateFormat },
    } = state;

    return {
        dateFormat,
        notificationAlerts,
        thresholds,
    };
};

export default connect(mapStateToProps)(AlertEventInfoComponent);
