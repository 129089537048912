import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { getLanguageNameString, isFallBackLanguage } from '../../../commonFunctions';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import WhiteSectionContainer from '../../../components/containers/WhiteSectionContainer';
import { userIsHbs } from '../../../components/findUserType';
import InputLabel from '../../../components/input/InputLabel';
import { mediumSectionLoader } from '../../../components/placeholder';
import { userSettingsSelectors } from '../../../components/units/UserPreferenceOptions';
import { dateFormats, sensorUnits } from '../../../constants';
import { Units } from '../../../models/commonTypeScript';
import { Store } from '../../../reducers';
import EditUserPreferences from './EditUserPreferences';
import styles from './UserPreferences.module.scss';

type Props = {
    dateFormat: string;
    units: Units;
    demoMode: boolean;
    language: string;
    loading: boolean;
};

export const UserPreferencesComponent = ({
    dateFormat,
    units,
    demoMode,
    language,
    loading,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [isEditing, setIsEditing] = useState(false);
    const toggleEditing = (): void => setIsEditing(!isEditing);
    const languageTag = language.split('-')[0];

    const userSettingsRow = (id: string, label: string, value: string): React.ReactElement => {
        return (
            <div className={styles.row}>
                <InputLabel htmlFor={id} label={label} />
                <div id={id}>{value}</div>
            </div>
        );
    };

    return (
        <>
            <h2 className="settings__header">{txt('UserSettings')}</h2>
            <WhiteSectionContainer>
                {isEditing ? (
                    <EditUserPreferences onClose={toggleEditing} />
                ) : (
                    <ReactPlaceholder ready={!loading} customPlaceholder={mediumSectionLoader}>
                        <form>
                            {userSettingsRow(
                                'language',
                                'Language',
                                isFallBackLanguage(language) ? getLanguageNameString(languageTag) : txt(languageTag)
                            )}
                            {userSettingsRow(
                                userSettingsSelectors.radonUnit,
                                'radonShortTermAvg',
                                sensorUnits[units.radonUnit as keyof typeof sensorUnits]
                            )}
                            {userSettingsRow(
                                userSettingsSelectors.tempUnit,
                                'Temperature',
                                txt(units.tempUnit === 'c' ? 'Units.UnitCelsius' : 'Units.UnitFahrenheit')
                            )}
                            {userSettingsRow(
                                userSettingsSelectors.pressureUnit,
                                'pressure',
                                sensorUnits[units.pressureUnit as keyof typeof sensorUnits]
                            )}
                            {userSettingsRow(
                                userSettingsSelectors.vocUnit,
                                'voc',
                                sensorUnits[units.vocUnit as keyof typeof sensorUnits]
                            )}
                            {userSettingsRow(
                                userSettingsSelectors.dateFormat,
                                'DateFormat',
                                dateFormats[dateFormat as keyof typeof dateFormats].format === 'DD.MM.YYYY'
                                    ? txt('DateFormattingDDMMYYYY')
                                    : txt('DateFormattingMMDDYYYY')
                            )}
                            {userIsHbs() &&
                                userSettingsRow(
                                    userSettingsSelectors.lengthUnit,
                                    'LengthUnit',
                                    units.lengthUnit
                                        ? txt(units.lengthUnit === 'm' ? 'Units.UnitMeter' : 'Units.UnitFeet')
                                        : ' - '
                                )}
                            <PrimaryButton
                                color="secondary"
                                id="editUserPreferencesButton"
                                title="EditInfo"
                                onClick={toggleEditing}
                                disabled={demoMode}
                                testId="edit-info"
                            />
                        </form>
                    </ReactPlaceholder>
                )}
            </WhiteSectionContainer>
        </>
    );
};

const mapStateToProps = (store: Store): Props => {
    const {
        userSettings: { dateFormat, units, demoMode, language, loading },
    } = store;

    return {
        dateFormat,
        units,
        demoMode,
        language,
        loading,
    };
};

export default connect(mapStateToProps)(UserPreferencesComponent);
