import React, { useState } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import { Option } from 'commons/src/components/dropdown/MultipleAttrDropdown';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { IndoorSpace } from '../../../../models/spaceModels';
import { ModalState } from './moveSpaceCommons';
import styles from './SpaceMoveDeviceStep2.module.scss';

type MoveDeviceModalProps = {
    space: IndoorSpace;
    setModalState: (state: ModalState) => void;
    selectedSpace: Option;
    startNewSegment: boolean | undefined;
    setStartNewSegment: (startNew: boolean | undefined) => void;
};

const SpaceMoveDeviceModalStep2 = ({
    space,
    setModalState,
    selectedSpace,
    startNewSegment,
    setStartNewSegment,
}: MoveDeviceModalProps): React.ReactElement => {
    const { t: txt } = useTranslation();

    const [showNoSelectionError, setShowNoSelectionError] = useState<boolean>(false);

    const onSelectDataPlan = (): void => {
        if (startNewSegment === undefined) {
            setShowNoSelectionError(true);
        } else {
            setModalState(ModalState.STEP3);
        }
    };

    const onSetStartNewSegment = (startNew: boolean): void => {
        setShowNoSelectionError(false);
        setStartNewSegment(startNew);
    };

    return (
        <>
            <div className={styles.form}>
                <div className={styles.description}>{txt('MoveSpaceDevice.SelectHistoricalData')}</div>
                <div className={styles.row}>
                    <div
                        className={classNames(styles.option, {
                            [styles.optionSelected]: startNewSegment === true,
                        })}
                        role="button"
                        tabIndex={0}
                        onClick={(): void => onSetStartNewSegment(true)}
                        onKeyUp={(e): void => {
                            if (e.key === 'Enter') onSetStartNewSegment(true);
                        }}
                    >
                        <MaterialIcon name="cut" />
                        <div>
                            <Trans
                                i18nKey={txt('MoveSpaceDevice.KeepHistoricalData', {
                                    currentSpace: space.name,
                                    newSpace: selectedSpace.inputValue,
                                })}
                                components={{ bold: <span className={styles.bold} /> }}
                            />
                        </div>
                    </div>
                    <div
                        className={classNames(styles.option, {
                            [styles.optionSelected]: startNewSegment === false,
                        })}
                        role="button"
                        tabIndex={0}
                        onClick={(): void => onSetStartNewSegment(false)}
                        onKeyUp={(e): void => {
                            if (e.key === 'Enter') onSetStartNewSegment(false);
                        }}
                    >
                        <MaterialIcon name="move_group" />
                        <div>
                            <Trans
                                i18nKey={txt('MoveSpaceDevice.CarryOverHistoricalData', {
                                    newSpace: selectedSpace?.inputValue,
                                })}
                                components={{ bold: <span className={styles.bold} /> }}
                            />
                        </div>
                    </div>
                </div>
                {showNoSelectionError && (
                    <div className={styles.warningText}>{txt('MoveSpaceDevice.SelectOptionWarning')}</div>
                )}
            </div>
            <div className={styles.buttons}>
                <PrimaryButton color="secondary" onClick={(): void => setModalState(ModalState.STEP1)} title="Back" />
                <PrimaryButton onClick={onSelectDataPlan} title="Continue" color="primary" />
            </div>
        </>
    );
};

export default SpaceMoveDeviceModalStep2;
