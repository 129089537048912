import React, { ReactElement } from 'react';
import classNames from 'classnames';
import style from './PageWrapper.module.scss';

type Props = {
    pageType: 'slim' | 'medium' | 'wide' | 'full';
    children: React.ReactNode;
    whiteBackground?: boolean;
};

const PageWrapper = ({ children, pageType, whiteBackground }: Props): ReactElement => {
    return (
        <div className={classNames(style.wrapper)}>
            <div
                className={classNames(style.innerWrapper, {
                    [style.slim]: pageType === 'slim',
                    [style.medium]: pageType === 'medium',
                    [style.wide]: pageType === 'wide',
                    [style.full]: pageType === 'full',
                    [style.white]: whiteBackground,
                })}
            >
                {children}
            </div>
        </div>
    );
};

export default PageWrapper;
