import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { analyticsLogger } from 'commons/src/analytics';
import { GROUP_CREATE_GROUP } from 'commons/src/analytics/AnalyticsEvents';
import { isVirtualDevice } from 'commons/src/commonFunctions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import PageHeader from 'commons/src/components/headers/PageHeader';
import SubHeader from 'commons/src/components/headers/SubHeader';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { RequiredRoleLevel } from 'commons/src/models/commonEnums';
import { AnyDeviceType, BuildingType, DeviceWithKeyInfo, ErrorType } from 'commons/src/models/commonTypeScript';
import { ActionButton } from 'commons/src/models/menuModels';
import { fetchBuildingWithDevices, FetchBuildingWithDevices } from '../../../actions/locationActions';
import { AddZoneGroup, addZoneGroup } from '../../../actions/zoneGroupActions';
import { paths } from '../../../constants';
import { AddZoneGroupPayload } from '../../../models/common';
import { Store } from '../../../reducers';
import { BusinessRequestType } from '../../../reducers/BusinessRequestType';
import AddGroupForm from './AddGroupForm';

type StateProps = {
    buildings: { [buildingId: string]: BuildingType };
    loadingLocation: boolean;
    loading: boolean;
    loadingBuilding: boolean;
    error?: ErrorType;
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
};

type ActionProps = {
    addGroup: (payload: AddZoneGroupPayload, locationId: string) => void;
    onFetchBuilding: (locationId: string) => void;
};

export type Props = StateProps & ActionProps;

export const AddGroupComponent = (props: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const {
        buildings,
        addGroup,
        loading,
        loadingLocation,
        error,
        onFetchBuilding,
        loadingBuilding,
        devicesWithKeyInfo,
    } = props;
    const { buildingId: locationId } = useParams<'buildingId'>() as { buildingId: string };
    const navigate = useNavigate();
    const location = buildings[locationId];
    const [triggerSubmit, setTriggerSubmit] = useState(false);

    const backToBuilding = (): void => {
        const buildingWithVirtualDevices =
            location &&
            location.devices.find(snr => {
                const deviceType = devicesWithKeyInfo[snr] && devicesWithKeyInfo[snr].type;
                return deviceType && isVirtualDevice(deviceType as AnyDeviceType);
            });
        if (buildingWithVirtualDevices) {
            navigate({ pathname: `/${paths.buildings}/${locationId}/control` });
        } else navigate({ pathname: `/${paths.buildings}/${locationId}/devices` });
    };

    useEffect(() => {
        if (!location && !loadingBuilding) {
            onFetchBuilding(locationId);
        }

        document.body.className = 'blue-body';
        return (): void => {
            document.body.className = '';
        };
    }, []);

    useEffect(() => {
        if (error && !loading && triggerSubmit) {
            setTriggerSubmit(false);
        }
    }, [error]);

    useEffect((): void => {
        if (!loadingLocation && !location) {
            navigate({ pathname: '/' });
        }
    }, [loadingLocation]);

    const actionButtons: ActionButton[] = [
        {
            onClick: backToBuilding,
            testAttr: 'close-add-group',
            id: 'closeAddGroup',
            title: 'Close',
            color: 'secondary',
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            requiredGroupTypes: [],
        },
    ];

    const saveGroupType = (): void => {
        setTriggerSubmit(true);
    };
    const submitValidPayload = (payload: AddZoneGroupPayload): void => {
        analyticsLogger(GROUP_CREATE_GROUP, { payload, locationId });
        addGroup(payload, locationId);
    };

    return (
        <>
            <PageHeader title={txt('ZoneGrouping.AddGroup')} />
            <div className="page-wrapper page-wrapper__medium">
                <SubHeader actionButtons={actionButtons} />
            </div>
            <ReactPlaceholder ready={!!location} customPlaceholder={mediumFormLoader}>
                <div className="page-wrapper page-wrapper__medium page-wrapper__medium--white">
                    <div className="settings-details-container">
                        <h2>{txt('ZoneGrouping.Group')}</h2>
                        <div className="form notification-alert__form">
                            <AddGroupForm
                                locationId={locationId}
                                setPayload={submitValidPayload}
                                triggerSubmit={triggerSubmit}
                                setTriggerSubmit={setTriggerSubmit}
                            />
                            {error && (
                                <ResponseBox text="SomethingWentWrong" subtext={txt(`ErrorCodes.${error.error}`)} />
                            )}
                            <div className="form__button-container form__button-container--margin-top">
                                <PrimaryButton
                                    id="submit"
                                    type="button"
                                    title="ZoneGrouping.CreateGroup"
                                    color="primary"
                                    loading={loading}
                                    disabled={false}
                                    onClick={saveGroupType}
                                    testAttr="create-group"
                                    testId="create-group"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ReactPlaceholder>
        </>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        requests: {
            [BusinessRequestType.AddZoneGroup]: { loading, error },
            [BusinessRequestType.FetchBuildingWithDevices]: { loading: loadingBuilding },
        },
        devices: { devicesWithKeyInfo },
        userSettings: { loading: loadingUserInfo },
        buildings: { buildings },
    } = state;
    return {
        buildings,
        loadingLocation: loadingBuilding || loadingUserInfo,
        loadingBuilding,
        loading,
        devicesWithKeyInfo,
        error,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onFetchBuilding: (locationId: string): FetchBuildingWithDevices => dispatch(fetchBuildingWithDevices(locationId)),
    addGroup: (payload, locationId): AddZoneGroup => dispatch(addZoneGroup(payload, locationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddGroupComponent);
