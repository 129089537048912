import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { SensorTypes } from 'commons/src/models/commonEnums';
import styles from './SpacesHeader.module.scss';
import SpacesTuneModal from './SpacesTuneModal';

export type Props = {
    availableSensors: SensorTypes[];
    selectedSensors: SensorTypes[];
    setSelectedSensors: (sensors: SensorTypes[]) => void;
    buildingId: string;
    ascending: boolean;
    setSortBy: (sortBy: string) => void;
    sortBy: string;
};

const SpacesHeader = ({
    availableSensors,
    setSelectedSensors,
    selectedSensors,
    buildingId,
    setSortBy,
    sortBy,
    ascending,
}: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const [modalOpen, setIsModalOpen] = useState(false);

    const sortByMarker = ascending ? <MaterialIcon name="arrow_drop_up" /> : <MaterialIcon name="arrow_drop_down" />;
    return (
        <div className={styles.body}>
            {modalOpen && (
                <SpacesTuneModal
                    onClose={(): void => setIsModalOpen(false)}
                    availableSensors={availableSensors}
                    selectedSensors={selectedSensors}
                    setSelectedSensors={setSelectedSensors}
                    buildingId={buildingId}
                />
            )}
            <div
                className={styles.floorColumn}
                role="button"
                tabIndex={0}
                onClick={(): void => setSortBy('floor')}
                onKeyUp={(e): void => {
                    if (e.key === 'Enter') setSortBy('floor');
                }}
            >
                {txt(`SpaceProperties.Floor`)}
                {sortBy === 'floor' && sortByMarker}
            </div>
            <div
                role="button"
                tabIndex={0}
                onClick={(): void => setSortBy('name')}
                onKeyUp={(e): void => {
                    if (e.key === 'Enter') setSortBy('name');
                }}
                className={styles.nameColumn}
            >
                {txt(`Name`)} {sortBy === 'name' && sortByMarker}
            </div>
            <div className={styles.sensorsWrapper}>
                <div className={styles.sensors}>
                    {selectedSensors.map(sensor => (
                        <div
                            key={sensor}
                            role="button"
                            tabIndex={0}
                            onClick={(): void => setSortBy(sensor)}
                            onKeyUp={(e): void => {
                                if (e.key === 'Enter') setSortBy(sensor);
                            }}
                            className={styles.sensorColumn}
                        >
                            {txt(sensor)}
                            {sortBy === sensor && sortByMarker}
                        </div>
                    ))}
                </div>
                <div className={styles.tuneColumn}>
                    <TertiaryButton
                        onClick={(): void => setIsModalOpen(true)}
                        icon={<MaterialIcon name="tune" />}
                        noColor
                        testId="open-modal"
                    />
                </div>
            </div>
        </div>
    );
};

export default SpacesHeader;
