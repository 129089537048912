import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownOptions from 'commons/src/components/dropdown/DropdownOptions';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { roleRestrictions } from 'commons/src/constants';
import { MenuItem } from 'commons/src/models/menuModels';
import { SpaceHub } from '../../../../models/spaceModels';
import MoveHubModal, { HubDeleteModal, HubSettingsModal } from './HubModals';

type Props = {
    hub: SpaceHub;
    locationId: string;
};

enum ModalType {
    Move = 'move',
    Delete = 'delete',
    Settings = 'settings',
}
const HubOptions = ({ hub, locationId }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const [modalTypeOpen, setModalTypeOpen] = React.useState<ModalType | null>(null);

    const onMoveOptionHandler = (): void => {
        setModalTypeOpen(ModalType.Move);
    };

    const onRemoveOptionHandler = (): void => {
        // dispatch(clearError(RequestType.RemoveDeviceFromSpace));
        setModalTypeOpen(ModalType.Delete);
    };
    const onOpenSettings = (): void => {
        setModalTypeOpen(ModalType.Settings);
    };

    const onCloseModal = (): void => {
        setModalTypeOpen(null);
    };
    const hubDropdownOptions: MenuItem[] = [
        {
            onClick: onOpenSettings,
            text: txt('Settings'),
            requiredRoleLevel: roleRestrictions.editSpace,
            disabled: false,
            requiredGroupTypes: [],
        },
        {
            onClick: onMoveOptionHandler,
            text: txt('Move'),
            requiredRoleLevel: roleRestrictions.editSpace,
            disabled: false,
            requiredGroupTypes: [],
        },
        {
            onClick: onRemoveOptionHandler,
            text: txt('Remove'),
            requiredRoleLevel: roleRestrictions.editSpace,
            disabled: false,
            requiredGroupTypes: [],
        },
    ];

    return (
        <div>
            {modalTypeOpen === ModalType.Settings && (
                <HubSettingsModal hub={hub} onClose={onCloseModal} locationId={locationId} open />
            )}
            {modalTypeOpen === ModalType.Move && (
                <MoveHubModal hub={hub} onClose={onCloseModal} open locationId={locationId} />
            )}
            {modalTypeOpen === ModalType.Delete && (
                <HubDeleteModal hub={hub} onClose={onCloseModal} locationId={locationId} open />
            )}
            <DropdownOptions
                options={hubDropdownOptions}
                id={`device_options_${hub.serialNumber}`}
                dotMenu
                customButton={<MaterialIcon name="more_horiz" />}
            />
        </div>
    );
};

export default HubOptions;
